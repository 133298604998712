import React, { ReactNode } from 'react';

import styles from './ProfileButton.module.scss';
import Icon, { IconProps } from 'components/Icon/Icon';
import { IconName } from 'components/Icon/IconList';
import { ProfileButtonProps } from './ProfileButtonTypes';
import cx from 'classnames';
import { Link } from 'react-router-dom';

const ProfileButtonContent = ({
  children,
  icon,
}: {
  children: ReactNode;
  icon?: IconProps;
}) => {
  return (
    <>
      {icon && <Icon className={styles.icon} {...icon} />}
      {children}
      <Icon
        name={IconName.Mark}
        width={16}
        height={16}
        className={styles.button}
      />
    </>
  );
};

const ProfileButton = ({
  link,
  children,
  onClick,
  color,
  icon,
}: ProfileButtonProps) => {
  return link ? (
    <Link
      to={link}
      className={cx(styles.group, color && styles[color], styles.link)}
    >
      <ProfileButtonContent icon={icon}>{children}</ProfileButtonContent>
    </Link>
  ) : (
    <div className={cx(styles.group, color && styles[color])} onClick={onClick}>
      <ProfileButtonContent icon={icon}>{children}</ProfileButtonContent>
    </div>
  );
};

export default ProfileButton;
