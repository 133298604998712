import styles from './HistoryTabs.module.scss';

import React from 'react';
import cn from 'classnames';
import { Tab } from '@headlessui/react';
import { useTranslation } from 'react-i18next';

import { UserDto } from 'dtos';

import PaymentsHistory from 'components/PaymentsHistory';
import BalancesHistory from 'components/BalancesHistory';

const TABS: string[] = ['tab.exchangeHistory', 'tab.balanceHistory'];

export interface HistoryTabsProps {
  friendId?: UserDto['id'];
}

function HistoryTabs(props: HistoryTabsProps): JSX.Element | null {
  const { t } = useTranslation();

  return (
    <Tab.Group as="div" className={styles.wrap} defaultIndex={0}>
      <div className={styles.tabsWrap}>
        <Tab.List className={styles.tabs}>
          {TABS.map((tabName) => {
            return (
              <Tab as={React.Fragment} key={tabName}>
                {({ selected }) => (
                  <button
                    className={cn(styles.tab, selected && styles.selected)}
                    children={t(tabName)}
                  />
                )}
              </Tab>
            );
          })}
        </Tab.List>
      </div>

      <Tab.Panels>
        <Tab.Panel>
          <PaymentsHistory {...props} />
        </Tab.Panel>
        <Tab.Panel>
          <BalancesHistory {...props} />
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  );
}

export default HistoryTabs;
