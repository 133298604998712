import React from 'react';
import { useTranslation } from 'react-i18next';

import Invitation from 'components/Invitation/Invitation';

import type { IInvitationsListProps } from './InvitationsListTypes';

import styles from './InvitationsList.module.scss';

function InvitationsList({ list }: IInvitationsListProps) {
    const { t } = useTranslation();

    return (
        <div className={styles.list}>
            {list.length !== 0 ? (
                list.map((invitation, idx) => (
                    <Invitation
                        key={idx}
                        invitation={invitation}
                        className={styles.item}
                    />
                ))
            ) : (
                <span>{t('component.invitation.emptyList')}</span>
            )}
        </div>
    )
}

export default InvitationsList;
