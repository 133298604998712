import styles from './ExchangeTabs.module.scss';

import { FC, Fragment, useMemo } from 'react';
import cn from 'classnames';
import findIndex from 'lodash/findIndex';
import { Tab } from '@headlessui/react';

import { ExchangerWidgetProps } from '../../ExchangerWidget';
import { useExchangerStore, useExchangerStoreActions } from '../../store';

export const ExchangeTabs: FC<ExchangerWidgetProps> = (props) => {
  const { providerId, userId } = props;
  const { queryKey, tabs, activeOption, isInitialization, calculateExchangePaymentForm } = useExchangerStore(
    providerId,
    userId
  );

  const { setExchangeOption } = useExchangerStoreActions({
    queryKey,
    providerId,
    userId,
  });
  const exchangeTabs = useMemo(() => {
    return !isInitialization && Number(tabs?.length) > 0
      ? (tabs || []).map(tab => ({name: tab.name, localizedName: tab.localizedName}))
      : [];
  }, [tabs, isInitialization]);

  const defaultTab = useMemo(() => {
    if (activeOption) {
      const idx = findIndex(exchangeTabs, (t) => t.name === activeOption);
      return idx >= 0 ? idx : 0;
    }
    return 0;
  }, [exchangeTabs, activeOption]);

  const changeTab = (idx: number) => {
    const currentTab = exchangeTabs[idx].name;
    setExchangeOption(currentTab);
    calculateExchangePaymentForm({form: {}});
  };

  if (exchangeTabs.length <= 1) {
    return null;
  }

  if (isInitialization) {
    // FIXME: add skeleton
    return <></>;
  }

  return (
    <Tab.Group
      as="div"
      defaultIndex={defaultTab}
      selectedIndex={defaultTab}
      onChange={changeTab}
      className={styles.wrap}
    >
      <Tab.List className={styles.tabs}>
        {exchangeTabs.map((tab, index) => (
          <Tab as={Fragment} key={tab.name}>
            <button
              type="button"
              className={cn(styles.tab, defaultTab === index && styles.selected)}
            >
              {tab.localizedName}
            </button>
          </Tab>
        ))}
      </Tab.List>
    </Tab.Group>
  );
};
