import styles from './Friend.module.scss';

import React from 'react';

import { Layout } from 'components/Layout/Layout';
import Icon from 'components/Icon/Icon';
import { IconName } from 'components/Icon/IconList';
import Friend from 'components/Friend';

import { useConfigurationModal } from 'entities1/configurationModal/services/useConfigurationModal';

function FriendPage(): JSX.Element {
  const { state, openModal } = useConfigurationModal();

  return (
    <Layout>
      <Layout.TopBar>
        <div className={styles.wrap}>
          <Layout.Back />
          <Icon
            name={IconName.CogWheel}
            width={19}
            height={20}
            onClick={openModal}
            className={state?.visible ? styles.active : undefined}
          />
        </div>
      </Layout.TopBar>
      <Layout.Content>
        <Friend />
      </Layout.Content>
    </Layout>
  );
}

export default FriendPage;
