import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';

import { Language } from 'dtos';

import { translations } from './translations';

i18next
  .use(detector)
  .use(initReactI18next)
  .init({
    detection: {
      lookupQuerystring: 'lang',
    },
    resources: translations,

    fallbackLng: Language.En,
    interpolation: {
      escapeValue: false,
    },
    // debug: process.env.NODE_ENV === 'development',
    load: 'languageOnly',
    supportedLngs: [Language.En, Language.Ru],
  });

if (i18next.language.includes('-')) {
  i18next.changeLanguage(i18next.language.split('-')[0]);
}

export default i18next;
