import { useCallback, useEffect, useMemo, useState } from 'react';
import type { QueryKey } from '@tanstack/react-query';

import { queryClient } from 'shared/config/reactQuery';

const EMPTY: string = '';

export const useExchangeRatesFilter = (queryKey: QueryKey) => {
  const filterQueryKey = useMemo<QueryKey>(() => {
    return [...queryKey, 'filter'];
  }, [queryKey]);

  const selectedCurrencyCode: string = String(
    queryClient.getQueryData<string[]>(filterQueryKey) || EMPTY
  );
  const [, setSelectedCurrencyCode] = useState<string>(selectedCurrencyCode);

  const persistSelectedCurrencyCodes = useCallback(
    (currencyCodes: string[]): void => {
      queryClient.removeQueries({ queryKey, exact: true });
      const updatedCurrencyCodes = queryClient.setQueryData<string[]>(
        filterQueryKey,
        (prev) => {
          if (!prev) return currencyCodes;

          const code: string = String(currencyCodes);
          return prev.includes(code)
            ? prev.filter((c) => c !== code)
            : currencyCodes;
        }
      );
      localStorage.setItem(
        filterQueryKey.join(''),
        JSON.stringify(updatedCurrencyCodes)
      );
      setSelectedCurrencyCode(String(updatedCurrencyCodes));
    },
    [queryKey, filterQueryKey]
  );

  useEffect(() => {
    const stringifyCurrencyCodes = localStorage.getItem(
      filterQueryKey.join('')
    );
    if (stringifyCurrencyCodes) {
      const currencyCodes: string[] = JSON.parse(stringifyCurrencyCodes);
      setSelectedCurrencyCode(String(currencyCodes));
      queryClient.setQueryData(filterQueryKey, () => [...currencyCodes]);
    }
  }, [filterQueryKey]);

  return {
    selectedCurrencyCode,
    persistSelectedCurrencyCodes,
  };
};
