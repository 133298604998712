
import { BaseIconProps } from '../types';

const ArrowLeftNew = (props: BaseIconProps) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" { ...props }>
    <path d="M3 12H21M3 12L9 6M3 12L9 18" stroke="white" strokeLinecap="round"/>
  </svg>
);

export default ArrowLeftNew;
