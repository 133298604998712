import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import isEmpty from 'lodash/isEmpty';

import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Modal from 'components/Modal/Modal';
import { ModalTypes } from 'slices/modal';
import { RootState } from 'shared/redux-store';
import { hide } from 'slices/modal';
import { Avatar, AvatarSize } from 'shared/ui/Avatar/Avatar';
import { useChangeBalanceInfoMutation } from 'services/balances';
import Input from 'components/Input/Input';

import styles from './index.module.scss';
import Button, { Variant } from 'components/Button/Button';

function BalanceInfoModal(): JSX.Element | null {
  const { t } = useTranslation();

  const { visible, data: modalData } = useSelector(
    (state: RootState) => state.modal[ModalTypes.BalanceInfo]
  );
  const { balance } = modalData;
  const dispatch = useDispatch();
  const [saveBalanceInfoById, { isLoading }] = useChangeBalanceInfoMutation();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const [name, setName] = useState(balance?.balanceName || '');
  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    setName(String(balance?.balanceName));
  }, [balance?.balanceName]);

  if (!visible || isEmpty(balance)) {
    return null;
  }

  const onFileSelect = (event: Event) => {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      setSelectedFile(input.files[0]);
    }
  };

  const createUploadInput = () => {
    const input: HTMLInputElement = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = onFileSelect;
    fileInputRef.current = input;
    input.click();
  };

  const removeUploadInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.remove();
      fileInputRef.current = null;
    }
  };

  const handleCloseModal = (): void => {
    setName('');
    setSelectedFile(null);
    removeUploadInput();
    dispatch(hide({ id: ModalTypes.BalanceInfo }));
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (event.target.value === '') {
      setError(t('modal.balanceInfo.emptyNameError'));
    }

    if (error && event.target.value !== '') {
      setError(undefined);
    }

    setName(event.target.value);
  };

  const handleChangeAvatar = (): void => {
    createUploadInput();
  };

  const handleSaveButtonClick = async (
    event: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    if (error) {
      return;
    }

    const formData = new FormData();
    if (selectedFile) {
      // именно с таким названием
      formData.append('balancePhoto', selectedFile);
    }

    toast.promise(
      saveBalanceInfoById({
        balanceId: balance.id,
        contactId: modalData.contactId,
        balanceName: name,
        balancePhoto: selectedFile ? formData : undefined,
      }).unwrap(),
      {
        loading: t('status.saving'),
        success: () => {
          handleCloseModal();
          return <b>{t('status.successChangeBalance')}</b>;
        },
        error: ({ error }) => {
          return <b>{error}</b>;
        },
      }
    );
  };

  return (
    <Modal visible={visible} onClose={handleCloseModal}>
      <Avatar
        username={String(balance?.balanceName)}
        profilePictureUrl={
          selectedFile
            ? URL.createObjectURL(selectedFile)
            : String(balance?.balancePhotoUrl)
        }
        size={AvatarSize.Big}
        className={styles.avatar}
        onChangePhoto={handleChangeAvatar}
      />

      <Input
        label={t('modal.balanceInfo.namePlaceholder')}
        labelClassName={styles.label}
        value={name}
        className={styles.input}
        onChange={handleInputChange}
        error={error}
      />

      <Button
        variant={Variant.Outlined}
        className={styles.saveButton}
        onClick={handleSaveButtonClick}
        isLoading={isLoading}
        disabled={isLoading || Boolean(error)}
      >
        {t('modal.balanceInfo.saveButton')}
      </Button>

      <Button
        variant={Variant.Minimal}
        onClick={handleCloseModal}
        className={styles.cancelButton}
      >
        {t('modal.balanceInfo.cancelButton')}
      </Button>
    </Modal>
  );
}

export default BalanceInfoModal;
