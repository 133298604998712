import styles from './CrossExchange.module.scss';

import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import capitalize from 'lodash/capitalize';

import Exchange from 'components/Exchange/Exchange';
import Icon from 'components/Icon/Icon';
import { IconName } from 'components/Icon/IconList';

import type { ExchangeRateWidgetDto, CrossExchangeWidgetDto } from 'dtos';
import { InputTarget } from 'widgets/ExchangerWidget/types';
import { formatNumber } from 'utils';

type CrossExchangeProps = {
  exchanges?: ExchangeRateWidgetDto[];
  exchange?: CrossExchangeWidgetDto;
  inputTarget?: InputTarget;
  customOfferMode?: boolean;
  updateCrossExchangeRates: (rates: ExchangeRateWidgetDto[]) => void;
};

function CrossExchange({
  exchanges,
  exchange,
  inputTarget,
  customOfferMode,
  updateCrossExchangeRates,
}: CrossExchangeProps): JSX.Element {
  const [showExchanges, setShowExchanges] = useState<boolean>(false);
  const toggleShowExchange = () => {
    setShowExchanges((prev) => !prev);
  };

  const [directExchange, setDirectExchange] = useState(exchange);

  useEffect(() => {
    if (customOfferMode) {
      setShowExchanges(true);
    } else {
      setShowExchanges(false);
    }
  }, [customOfferMode]);

  const [firstCrossExchange, setFirstCrossExchange] = useState(exchanges && exchanges[0]);
  const [secondCrossExchange, setSecondCrossExchange] = useState(exchanges && exchanges[1]);

  useEffect(() => {
    setDirectExchange(exchange);
    if (exchanges) {
      exchanges[0] ? setFirstCrossExchange(exchanges[0]) : setFirstCrossExchange(undefined);
      exchanges[1] ? setSecondCrossExchange(exchanges[1]) : setSecondCrossExchange(undefined);
    }
  }, [exchanges, exchange, customOfferMode]);

  const exchangeRates: ExchangeRateWidgetDto[] = [];
  firstCrossExchange && exchangeRates.push(firstCrossExchange);
  secondCrossExchange && exchangeRates.push(secondCrossExchange);

  useEffect(() => {
    if (exchangeRates.length > 1) {
      updateCrossExchangeRates(exchangeRates);
    } else {
      directExchange && updateCrossExchangeRates([{ ...directExchange }]);
    }
  }, [firstCrossExchange, secondCrossExchange, directExchange]);

  if (!exchanges || !exchange) {
    return <></>;
  }

  const isNeedToShowExchanges: boolean = exchanges.length > 1;

  if (exchanges.length < 0 || !directExchange) return <></>;

  const type = inputTarget === 'sell' ? 'sell' : 'buy';
  const hasBetterRate = directExchange.newBuyCurrencyAmount || directExchange.newSellCurrencyAmount;
  let betterAmountType = capitalize(type);
  if (type === 'buy' && directExchange.newBuyCurrencyAmount) {
    betterAmountType = 'Buy';
  } else if (type === 'sell' && directExchange.newSellCurrencyAmount) {
    betterAmountType = 'Sell';
  } else if (type === 'buy' && directExchange.newSellCurrencyAmount) {
    betterAmountType = 'Sell';
  } else if (type === 'sell' && directExchange.newBuyCurrencyAmount) {
    betterAmountType = 'Buy';
  }

  const updatedExchanges = [firstCrossExchange, secondCrossExchange];

  return (
    <div className={styles.container}>
      <div className={styles.mainExchange}>
        <Exchange
          currency={directExchange}
          disableInputs={exchanges.length > 1 || !customOfferMode}
          setExchange={setDirectExchange}
        />

        {isNeedToShowExchanges && (
          <Icon
            className={styles.icon}
            name={IconName.Question}
            width={32}
            height={32}
            onClick={toggleShowExchange}
          />
        )}
      </div>

      {hasBetterRate && (
        <div className={styles.betterExchange}>
          <div>
            <Trans
              i18nKey="labels.betterExchangeRate"
              values={{
                rate: exchange.betterExchangeRate ? formatNumber(exchange.betterExchangeRate || 0) : null,
              }}
            />
          </div>
          <div>
            <Trans
              i18nKey="labels.betterExchangeFrom"
              values={{
                amount: formatNumber(
                  exchange[`new${betterAmountType as 'Sell' | 'Buy'}CurrencyAmount`] || 0
                ),
                code: exchange[`${betterAmountType.toLowerCase() as InputTarget}Currency`].code,
              }}
            />
          </div>
        </div>
      )}

      {showExchanges && isNeedToShowExchanges && (
        <>
          <hr className={styles.line} />

          <div className={styles.exchanges}>
            {updatedExchanges.map(
              (exchange, index) =>
                exchange && (
                  <Exchange
                    key={exchange.sellCurrency.code}
                    currency={exchange}
                    disableInputs={!customOfferMode}
                    setExchange={index === 0 ? setFirstCrossExchange : setSecondCrossExchange}
                  />
                )
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default CrossExchange;
