import { useMemo } from 'react';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';

import {
  CurrencyDto,
  CurrencyInformationResponseDtoV3,
  PaymentFormDtoV5,
  PaymentMethodDto,
  PaymentMethodType,
} from 'dtos';
import {
  findCurrencyPaymentMethodInfoByTypeAndName,
  findPaymentMethodByType,
  getFirstPaymentMethod,
} from '../utils';

type UseIsSwapPossible = (
  args: Partial<{
    currentFormData: Pick<PaymentFormDtoV5, 'buyExchangeUnit' | 'buyExchangeUnitAmount' | 'sellExchangeUnit' | 'sellExchangeUnitAmount' | 'customerId' | 'providerId'>;
    currencies: CurrencyInformationResponseDtoV3[];
  }>
) => boolean;

export const useIsSwapPossible: UseIsSwapPossible = ({
  currentFormData,
  currencies,
}) => {
  const sellCurrencyCode = currentFormData?.sellExchangeUnit?.code;
  const sellCurrency = useMemo(() => {
    return find(currencies, (c) => isEqual(c.code, sellCurrencyCode));
  }, [currencies, sellCurrencyCode]);

  const buyCurrencyCode = currentFormData?.buyExchangeUnit?.code;
  const buyCurrency = useMemo(() => {
    return find(currencies, (c) => isEqual(c.code, buyCurrencyCode));
  }, [currencies, buyCurrencyCode]);

  return useMemo(() => {
    if (!currentFormData || !sellCurrency || !buyCurrency) {
      return false;
    }
    const sellMethodType: PaymentMethodType =
      (currentFormData?.sellExchangeUnit as CurrencyDto).paymentMethod.type ??
      getFirstPaymentMethod(sellCurrency.paymentMethods);
    const sellMethodName: PaymentMethodDto['name'] =
      (currentFormData?.sellExchangeUnit as CurrencyDto).paymentMethod.name;
    const sellMethod: string | undefined =
      findCurrencyPaymentMethodInfoByTypeAndName(
        sellCurrency.paymentMethods,
        sellMethodType,
        sellMethodName
      ) ||
      (findPaymentMethodByType(sellCurrency.paymentMethods, sellMethodType)
        ?.methods?.[0]);

    const buyMethodType: PaymentMethodType =
      (currentFormData?.buyExchangeUnit as CurrencyDto).paymentMethod.type ??
      getFirstPaymentMethod(buyCurrency.paymentMethods);
    const buyMethodName: PaymentMethodDto['name'] =
      (currentFormData?.buyExchangeUnit as CurrencyDto).paymentMethod.name;
    const buyMethod: string | undefined =
      findCurrencyPaymentMethodInfoByTypeAndName(
        buyCurrency.paymentMethods,
        buyMethodType,
        buyMethodName
      ) ||
      (findPaymentMethodByType(buyCurrency.paymentMethods, buyMethodType)?.methods?.[0]);

    return Boolean(
      sellMethod && buyMethod
    );
  }, [currentFormData, sellCurrency, buyCurrency]);
};
