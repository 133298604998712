import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../fetchBaseQueryWithAuth';
import { PushSubscriptionDto } from 'dtos';

export const pushSubscriptionsApi = createApi({
  reducerPath: 'subscriptionsApi',
  baseQuery: baseQueryWithReauth(true),
  tagTypes: ['Subscription'],
  endpoints: (builder) => ({
    getPushSubscriptions: builder.query<PushSubscriptionDto[], void>({
      query: () => ({
        url: '/push-notification/subscription',
      }),
      providesTags: (result) =>
        result ? result.map(({ endpoint }) => ({ type: 'Subscription', id: endpoint })) : ['Subscription'],
    }),

    getPushPublicKey: builder.query<string, void>({
      query: () => ({
        url: '/push-notification/public-key',
        responseHandler: (response) => response.text(),
      }),
    }),

    addPushSubscription: builder.mutation<void, PushSubscription>({
      query: (body) => ({
        url: '/push-notification/subscribe',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Subscription' }],
    }),

    removePushSubscription: builder.mutation<void, string>({
      query: (endpoint) => ({
        url: '/push-notification/unsubscribe',
        method: 'POST',
        params: { endpoint },
      }),
      invalidatesTags: (_result, _error, endpoint) => [{ type: 'Subscription', id: endpoint }],
    }),
  }),
});

export const {
  useGetPushSubscriptionsQuery,
  useLazyGetPushPublicKeyQuery,
  useAddPushSubscriptionMutation,
  useRemovePushSubscriptionMutation,
} = pushSubscriptionsApi;
