import React from 'react';

import Notification from 'components/Notification';
import SubscriptionsLayout from './layout';

function Subscriptions(): JSX.Element {
  return (
    <SubscriptionsLayout>
      <Notification />
    </SubscriptionsLayout>
  );
}

export default Subscriptions;
