import { PaymentParticipantRole, PaymentStatus, PaymentType } from 'dtos';

export const STATUSES: PaymentStatus[] = [
  PaymentStatus.Initialized,
  PaymentStatus.InProgress,
  PaymentStatus.Success,
  PaymentStatus.Cancelled,
];

export const ROLES: PaymentParticipantRole[] = Object.values(
  PaymentParticipantRole
);

export const TYPES: PaymentType[] = Object.values(
  PaymentType
);
