import React, { ChangeEvent, PropsWithChildren, useState } from 'react';

import Modal from 'components/Modal/Modal';
import Input from 'components/Input/Input';

import type { ProfileButtonProps } from 'components/ProfileButton/ProfileButtonTypes';
import Button, { Variant } from 'components/Button/Button';

import UserService from 'api/user';

const INITIAL_VISIBLE: boolean = false;
const EMPTY: HTMLInputElement['value'] = '';

export type DeleteUserButtonProps = PropsWithChildren<{
  logout: () => void;
}>;

function DeleteUserButton({
  children,
  logout,
}: DeleteUserButtonProps): JSX.Element {
  const [userId, setUserId] = useState<HTMLInputElement['value']>(EMPTY);
  const changeUserId = (evt: ChangeEvent<HTMLInputElement>): void => {
    const value: HTMLInputElement['value'] = evt.target.value;
    const numericValue = Number(value);
    if (Number.isInteger(numericValue)) setUserId(value);
  };

  const [visible, setVisible] = useState<boolean>(INITIAL_VISIBLE);
  const openModal = () => setVisible(true);
  const closeModal = () => {
    setVisible(INITIAL_VISIBLE);
    setUserId(EMPTY);
  };

  const deleteAccount = async (): Promise<void> => {
    try {
      const numericUserId = Number(userId);
      const isValidUserId =
        Number.isInteger(numericUserId) && numericUserId > 0;
      await UserService.deleteUser(isValidUserId ? numericUserId : undefined);
      if (!isValidUserId) logout();
    } catch (e) {
      e instanceof Error && alert(e.message);
    }
  };

  return (
    <>
      {React.Children.toArray(children).map((child) => {
        if (!React.isValidElement<ProfileButtonProps>(child)) return null;

        return React.cloneElement(child, {
          ...child.props,
          onClick: openModal,
        });
      })}

      <Modal visible={visible} onClose={closeModal}>
        <Input
          value={userId}
          onChange={changeUserId}
          placeholder="Pass the id of account you want to delete"
        />

        <Button variant={Variant.Outlined} onClick={deleteAccount} danger>
          Delete account
        </Button>
      </Modal>
    </>
  );
}

export default DeleteUserButton;
