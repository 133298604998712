import styles from '../Layout.module.scss';

import React, { PropsWithChildren } from 'react';
import cn from 'classnames';

export interface SubTitleProps extends PropsWithChildren {
  className?: string;
}

function SubTitle({ children, className }: SubTitleProps) {
  if (!children) return null;

  return <p className={cn(styles.subtitle, className)}>{children}</p>;
}

export default SubTitle;
