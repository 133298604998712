import styles from './ExchangeRatesTable.module.scss';

import React from 'react';

import type { ProviderRatesProps } from 'components/ProviderExchangeRates';
import ExchangeRateCard from './ExchangeRatesRow';

import { isExchangeRateInheritedDto } from 'utils';

export interface ExchangeRatesTableProps
  extends Omit<ProviderRatesProps, 'provider' | 'fetchNextPage'> {}

function ExchangeRatesTable({
  id,
  rates,
  toggle,
}: ExchangeRatesTableProps): JSX.Element | null {
  if (rates.length === 0) return null;

  return (
    <table cellPadding={0} cellSpacing={0} className={styles.table}>
      <thead className={styles.heads}>
        <tr>
          <th>Selling</th>
          <th></th>
          <th>Buying</th>
          <th>Rate</th>
          <th>{id === 'me' ? 'Active' : 'Resell'}</th>
        </tr>
      </thead>

      <tbody>
        {rates.map((r) => (
          <ExchangeRateCard
            key={isExchangeRateInheritedDto(r) ? r.exchangeRate.id : r.id}
            rate={r}
            onClick={() => toggle(r)}
          />
        ))}
      </tbody>
    </table>
  );
}

export default ExchangeRatesTable;
