import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { BalanceResponseDto, Language, TaskResponseDto, TaskStillInProgressDto, UserDto } from 'dtos';
import { BalanceType } from 'widgets/CreateBalanceTransactionModal/index';

export enum ModalTypes {
  BalanceInfo = 'BalanceInfo',
  BalanceArchive = 'BalanceArchive',
  CreateBalanceTransaction = 'CreateBalanceTransaction',
  ObligationTransfer = 'ObligationTransfer',
  CallMeBack = 'CallMeBack',
  Update = 'Update',
  TaskFormulating = 'TaskFormulating',
  GeneratePaymentInvitation = 'GeneratePaymentInvitation',
  ChoosePaymentFriends = 'ChoosePaymentFriends',
  PostponeTaskModal = 'PostponeTaskModal',
  TaskInProgressAnswering = "TaskInProgressAnswering",
  DelegateTask = 'DelegateTask'
}

interface ModalBalanceInfoParams {
  balance: BalanceResponseDto;
  contactId: UserDto['id'];
}

interface ModalBalanceArchiveParams {
  balanceId: BalanceResponseDto['id'];
  contactId: UserDto['id'];
}

interface ModalCreateBalanceTransactionParams {
  contact: UserDto;
  code?: string;
  type?: BalanceType;
  balance?: BalanceResponseDto,
}

interface ModalObligationTransferParams {
  fromContact: UserDto;
  fromBalance: BalanceResponseDto;
  code: string;
  value: string;
}

interface ModalCallMeBackParams {
  providerId: number;
  name?: string;
  phone?: string;
  language: Language;
  utm: string;
  policyPrivacy: boolean;
  sent?: boolean;
  isSending?: boolean;
}

interface ModalTaskFormulatingParams {
  task?: TaskResponseDto;
  isNeedInitialAssessment?: boolean;
  onCloseModal?: () => void;
}

interface ModalPostponeTaskParams {
  task: TaskResponseDto;
}

interface ModalDelegateTaskParams {
  task: TaskResponseDto;
}

interface ModalTaskStillInProgressParams {
  task: TaskResponseDto;
  taskStillInProgressDto: TaskStillInProgressDto
}

interface ModalChoosePaymentFriends {
  user?: UserDto;
}

const initialState = {
  [ModalTypes.BalanceInfo]: {
    visible: false,
    data: ({} as ModalBalanceInfoParams),
  },
  [ModalTypes.BalanceArchive]: {
    visible: false,
    data: ({} as ModalBalanceArchiveParams)
  },
  [ModalTypes.CreateBalanceTransaction]: {
    visible: false,
    data: ({} as ModalCreateBalanceTransactionParams)
  },
  [ModalTypes.ObligationTransfer]: {
    visible: false,
    data: ({} as ModalObligationTransferParams)
  },
  [ModalTypes.CallMeBack]: {
    visible: false,
    data: ({} as ModalCallMeBackParams)
  },
  [ModalTypes.Update]: {
    visible: false,
    data: {}
  },
  [ModalTypes.TaskFormulating]: {
    visible: false,
    data: ({} as ModalTaskFormulatingParams)
  },
  [ModalTypes.GeneratePaymentInvitation]: {
    visible: false,
    data: ({} as ModalChoosePaymentFriends)
  },
  [ModalTypes.ChoosePaymentFriends]: {
    visible: false,
    data: ({})
  },
  [ModalTypes.PostponeTaskModal]: {
    visible: false,
    data: ({} as ModalPostponeTaskParams)
  },
  [ModalTypes.TaskInProgressAnswering]: {
    visible: false,
    data: ({} as ModalTaskStillInProgressParams)
  },
  [ModalTypes.DelegateTask]: {
    visible: false,
    data: ({} as ModalDelegateTaskParams)
  }
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    open(state, action: PayloadAction<{ id: ModalTypes, data: ModalBalanceInfoParams | ModalBalanceArchiveParams | ModalCreateBalanceTransactionParams | ModalObligationTransferParams | ModalCallMeBackParams | ModalPostponeTaskParams | ModalTaskStillInProgressParams | ModalDelegateTaskParams | {} }>) {
      state[action.payload.id].visible = true;
      state[action.payload.id].data = action.payload.data;
    },
    hide(state, action: PayloadAction<{ id: ModalTypes }>) {
      state[action.payload.id].visible = false;
      state[action.payload.id].data = ({} as (ModalBalanceInfoParams | ModalBalanceArchiveParams | ModalCreateBalanceTransactionParams | ModalObligationTransferParams | ModalCallMeBackParams | ModalPostponeTaskParams | ModalTaskStillInProgressParams | ModalDelegateTaskParams | {}));
    },
  },
})

export const { open, hide } = modalSlice.actions
