import { useDispatch, useSelector } from 'react-redux';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RootState } from 'shared/redux-store';
import * as Sentry from '@sentry/react';
import cn from 'classnames';
import { hide, ModalTypes } from 'slices/modal';
import Modal from 'components/Modal/Modal';
import Button, { Variant } from 'components/Button/Button';
import { useStillInProgressAnswerMutation } from 'services/tasks';
import styles from './TaskInProgressAnsweringModal.module.scss';
import { Successfully } from 'modules/Successfully';
import AudioRecorder from 'components/AudioRecorder';
import { QAndADto } from 'dtos';
import QAndAChat from 'components/QAndAChat';
import { getFormattedFullDateTime } from 'utils';

function TaskInProgressAnsweringModal(): JSX.Element | null {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { visible, data } = useSelector(
    (state: RootState) => state.modal[ModalTypes.TaskInProgressAnswering]
  );

  const [inputText, setInputText] = useState<string>('');
  const [taskInProgressDto, setTaskInProgressDto] = useState(data.taskStillInProgressDto);
  const [isFinished, setIsFinished] = useState(false);
  const [isAudioRecording, setIsAudioRecording] = useState(false);
  const [allQAndA, setAllQAndA] = useState<QAndADto[]>();
  const [isFlowBlocked, setIsFlowBlocked] = useState(false);

  useEffect(() => {
    setTaskInProgressDto(data.taskStillInProgressDto);
  }, [data]);

  const [stillInProgressAnswer, { isLoading }] = useStillInProgressAnswerMutation();

  useEffect(() => {
    setIsFlowBlocked(isLoading || isAudioRecording);
  }, [isAudioRecording, isLoading]);

  const onInputChange = (evt: ChangeEvent<HTMLTextAreaElement>): void => {
    setInputText(evt.target.value);
  };

  const closeModal = (): void => {
    setIsFinished(false);
    setInputText('');
    setAllQAndA(undefined);
    dispatch(hide({ id: ModalTypes.TaskInProgressAnswering }));
    setIsFinished(false);
  };

  const onNextButtonClick = async () => {
    try {
      const response = await stillInProgressAnswer({
        taskId: data.task.id,
        question: taskInProgressDto.nextQuestion,
        answer: inputText,
      }).unwrap();
      if (response.taskStillInProgressDto.nextQuestion) {
        setTaskInProgressDto(response.taskStillInProgressDto);
        setAllQAndA(response.taskStillInProgressDto.allQAndADto);
      } else if (response.taskStillInProgressDto.reason && response.taskStillInProgressDto.date) {
        setTaskInProgressDto(response.taskStillInProgressDto);
        setAllQAndA(undefined);
        setIsFinished(true);
      }
      setInputText('');
    } catch (e) {
      Sentry.captureException(e);
      alert(t('status.smthWrong'));
    }
  };

  if (!visible) {
    return null;
  }
  const canAnswer = inputText?.length > 5;

  return (
    <Modal
      visible={visible}
      onClose={isFlowBlocked ? () => {} : closeModal}
      modalClassName={styles.modal}
      className={cn(styles.modalContent, isFinished && styles.successModalContent)}
    >
      {isFinished ? (
        <>
          <Successfully>
            <div className={styles.successChild}>
              {taskInProgressDto.date && (
                <h3 className={styles.postponedTo}>
                  {t('modal.stillInProgress.postponedTo') + getFormattedFullDateTime(taskInProgressDto.date)}
                </h3>
              )}
              {taskInProgressDto.reason && (<p className={styles.summary}>{taskInProgressDto.reason}</p>)}
            </div>
          </Successfully>
          <Button className={styles.confirmButton} variant={Variant.Minimal} onClick={closeModal}>
            {t('modal.stillInProgress.closeButton')}
          </Button>
        </>
      ) : (
        <>
          <Button
            className={cn(styles.topButton)}
            variant={Variant.Minimal}
            onClick={closeModal}
            disabled={isFlowBlocked}
          >
            {t('modal.stillInProgress.closeButton')}
          </Button>
          <div className={styles.caseBlock}>
            {taskInProgressDto?.nextQuestion && (
              <div className={styles.questionBlock}>
                <div className={styles.question}>{taskInProgressDto.nextQuestion}</div>
              </div>
            )}
          </div>

          <QAndAChat qAndA={allQAndA} />

          <div className={styles.inputArea}>
            <textarea
              className={styles.textarea}
              value={inputText}
              onChange={onInputChange}
              autoComplete="off"
              autoFocus={true}
              disabled={isFlowBlocked}
            />
            <AudioRecorder
              setInput={setInputText}
              className={styles.audioInput}
              isFlowBlocked={isFlowBlocked}
              setAudioRecording={setIsAudioRecording}
            />
          </div>

          <Button
            className={styles.downButton}
            variant={Variant.Minimal}
            onClick={() => {
              onNextButtonClick();
            }}
            disabled={!canAnswer || isFlowBlocked}
            isLoading={isLoading}
          >
            {t('modal.stillInProgress.nextButton')}
          </Button>
        </>
      )}
    </Modal>
  );
}

export default TaskInProgressAnsweringModal;
