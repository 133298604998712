import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../fetchBaseQueryWithAuth';
import { ContactModificationRequest, PageContactDto, PageUserDto, ProvidersDto, UserDto } from 'dtos';
import { FriendsParams } from 'api/balances/types';

type PageParams = {
  page?: number;
  limit?: number;
};

type UpdateContactDto = {
  contactId: UserDto['id'];
  details: ContactModificationRequest;
};

type SearchContactsDto = {
  searchString?: string;
} & PageParams

export const INITIAL_PAGE: FriendsParams['page'] = 1;
export const INITIAL_LIMIT: FriendsParams['limit'] = 20;

export const contactsApi = createApi({
  reducerPath: 'contactsApi',
  baseQuery: baseQueryWithReauth(true),
  tagTypes: ['Contact', 'Provider', 'Customer'],
  endpoints: (builder) => ({
    getContacts: builder.query<PageContactDto, SearchContactsDto>({
      query: (params) => ({
        url: `/v1/contacts`,
        params,
      }),
      providesTags: (result) =>
        result?.content
          ? [...result.content.map(({ userDto }) => ({ type: 'Contact' as const, id: userDto.id })), 'Contact']
          : ['Contact'],
    }),

    getContactById: builder.query<UserDto, number>({
      query: (contactId) => ({
        url: `/v2/contacts/${contactId}`,
      }),
      providesTags: (_result, _error, contactId) => [{ type: 'Contact', id: contactId }],
    }),

    getCustomers: builder.query<PageUserDto, SearchContactsDto>({
      query: (params) => ({
        url: '/v1/contacts/customers',
        params,
      }),
      providesTags: (result) =>
        result?.content
          ? [...result.content.map(({ id }) => ({ type: 'Customer' as const, id: id })), 'Customer']
          : ['Customer'],
    }),

    updateContactById: builder.mutation<void, UpdateContactDto>({
      query: ({ contactId, details }) => ({
        url: `/v1/contacts/${contactId}`,
        body: details,
        method: 'PUT',
      }),
      // async onQueryStarted(contactId, { dispatch, queryFulfilled }) {
      //   try {
      //     const { data } = await queryFulfilled;
      //     dispatch(
      //       contactsApi.util.updateQueryData('getContactById', undefined, draft => {
      //         const hasAssistant = draft.find(assistant => assistant.id === assistantUserId);
      //         if (!hasAssistant) {
      //           draft.push(data);
      //         }
      //       })
      //     );
      //   } catch { }
      // },
      invalidatesTags: (_result, _error, { contactId }) => [{ type: 'Contact', id: contactId }],
    }),

    deleteContact: builder.mutation<void, number>({
      query: (contactId) => ({
        url: `/v1/contacts/${contactId}`,
        method: 'DELETE',
      }),
      // Там где используем, то перезагружаем страницу вместо перезапроса за пользователем
      // CNT-354
      // invalidatesTags: (_result, _error, contactId) => [{ type: 'Contact', id: contactId }],
    }),

    stopBeingCustomer: builder.mutation<void, number>({
      query: (providerId) => ({
        url: '/users/me/make-provider-contact',
        params: { providerId },
        method: 'POST',
      }),
      invalidatesTags: (_result, _error, providerId) => [{ type: 'Contact', id: providerId }],
    }),

    stopBeingProvider: builder.mutation<void, number>({
      query: (customerId) => ({
        url: '/users/me/make-customer-contact',
        params: { customerId },
        method: 'POST',
      }),
      invalidatesTags: (_result, _error, customerId) => [{ type: 'Contact', id: customerId }],
    }),

    getContactProviders: builder.query<ProvidersDto, void>({
      query: () => ({
        url: `/v2/contacts/providers`,
      }),
      providesTags: (result) => {
        const value: ('Provider' | { type: 'Provider'; id: number; })[] = []

        if (result?.special) {
          result.special.forEach((userDto) => {
            value.push({ type: 'Provider' as const, id: userDto.id });
          });
        }
        if (result?.contacts) {
          result.contacts.forEach((userDto) => {
            value.push({ type: 'Provider' as const, id: userDto.id });
          });
        }

        value.push('Provider');

        return value;
      },
    }),
    getContactProviderById: builder.query<UserDto, UserDto['id']>({
      query: (id) => ({
        url: `/contacts/providers/${id}`,
      }),
      providesTags: (_result, _error, id) => [{ type: 'Provider', id }],
    }),
  }),
});

export const {
  useDeleteContactMutation,
  useGetContactByIdQuery,
  useUpdateContactByIdMutation,
  useLazyGetContactsQuery,
  useGetContactsQuery,
  useStopBeingCustomerMutation,
  useStopBeingProviderMutation,

  // providers
  useGetContactProvidersQuery,
  useGetContactProviderByIdQuery,

  // customers
  useLazyGetCustomersQuery,
} = contactsApi;
