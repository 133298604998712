import { useQuery, UseQueryResult } from '@tanstack/react-query';

import CurrencyService from 'api/currency';

import type { CurrencyDto } from 'dtos';

export const useGetCurrencies = (): UseQueryResult<CurrencyDto[]> => {
  return useQuery({
    queryKey: ['currencies'],
    queryFn: CurrencyService.getCurrencies,
  });
};
