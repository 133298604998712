import styles from './Layout.module.scss';

import React, { PropsWithChildren } from 'react';
import cn from 'classnames';

import Header from './Header';
import Title from './Title';
import SubTitle from './SubTitle';
import GoBackButton from './GoBackButton';
import Content from './Content';
import Navigation from './Navigation';
import GoBackNewButton from "./GoBackNewButton";

export interface LayoutProps extends PropsWithChildren {
  hasMenu?: boolean;
  className?: string;
}

function Layout({
  children,
  hasMenu = true,
  className,
}: LayoutProps): JSX.Element | null {
  return (
    <div className={cn(styles.layout, className)}>
      {children}

      {hasMenu && <Navigation />}
    </div>
  );
}

function PlaidContent({ children }: PropsWithChildren): JSX.Element {
  return <div className={styles.plaidContent}>{children}</div>;
}

Layout.Title = Title;
Layout.Subtitle = SubTitle;
Layout.Back = GoBackButton;
Layout.BackNew = GoBackNewButton;
Layout.TabBar = Navigation;
Layout.TopBar = Header;
Layout.Content = Content;
Layout.Plaid = PlaidContent;

export { Layout };
