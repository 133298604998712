import { useInfiniteQuery } from '@tanstack/react-query';

import { useLazyGetBalancesForObligationTransferQuery } from 'services/balances';
import { BalancesForObligationTransferResponse, FriendsParams } from 'api/balances/types';

const INITIAL_PAGE: FriendsParams['page'] = 1;
const INITIAL_LIMIT: FriendsParams['limit'] = 10;
export const useGetInfiniteBalancesForObligationTransfer = (
  balanceId: number,
  searchString?: string,
  enabled: boolean = true
) => {
  const [getBalancesForObligationTransfer] = useLazyGetBalancesForObligationTransferQuery();
  return useInfiniteQuery({
    queryKey: ['BalancesForObligationTransfer', balanceId, searchString],
    queryFn: async ({ pageParam = INITIAL_PAGE }) => {
      const res = (await getBalancesForObligationTransfer({
        balanceId,
        searchString,
        page: pageParam,
        limit: INITIAL_LIMIT,
      }).unwrap()) as BalancesForObligationTransferResponse;
      return { pageParam, ...res };
    },
    getNextPageParam: (lastPage) => {
      return lastPage.last ? undefined : (lastPage.pageParam || INITIAL_PAGE) + 1;
    },
    onSuccess: (data) => {
      const pages = data.pages;
      if (!Array.isArray(pages)) return;
    },
    staleTime: 1000,
    enabled,
  });
};
