import { useEffect, useRef } from 'react';

export const useScrollTo = <T extends HTMLElement>(
  selector: string,
  options?: ScrollToOptions
) => {
  const ref = useRef<T | null>(null);

  useEffect(() => {
    const elem = ref.current;
    if (elem == null) return;

    const candidate = elem.querySelector(selector);
    if (candidate) {
      const parent = candidate.parentElement as HTMLElement;
      const children = Array.from(parent.children);
      const index = children.findIndex(elem => elem.getAttribute('data-selected') === 'true') || 0;
      const rect = candidate.getBoundingClientRect();
      const width = (rect.width + 5) * index;
      const clientLeft =
        (document.body.clientWidth - (parent?.offsetWidth ?? 0)) / 2;

      elem.scrollTo({
        behavior: 'smooth',
        left: width - clientLeft,
        ...options,
      });
    }
  }, [ref, selector, options]);

  return ref;
};
