import styles from './User.module.scss';

import React, { useMemo, useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import Icon from 'components/Icon/Icon';
import { IconName } from 'components/Icon/IconList';

import type { UserDto } from 'dtos';

import { Avatar, AvatarSize } from 'shared/ui/Avatar/Avatar';

import UserBadge from 'shared/ui/UserBadge';

import { getFormattedDays, getUserName, getUserPhoto } from 'utils/index';
import UserService from 'api/user';
import ConfigurationModal from './ConfigurationModal';
import { useConfigurationModal } from 'entities1/configurationModal/services/useConfigurationModal';

export type ReactUserProps = Partial<{
  user: UserDto;
  arrow: boolean;
  size: AvatarSize;
  filled?: boolean;
  className?: string;
  withEditing?: boolean;
}>;

function ReactUser({
  user,
  arrow,
  filled,
  className,
  size = AvatarSize.Default,
  withEditing = false,
}: ReactUserProps): JSX.Element {
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const userRegisterDays = useMemo(
    () => (user && getFormattedDays(user.accountRegistrationDate)) || 0,
    [user]
  );
  const userName = useMemo(() => user ? getUserName(user) : '', [user]);
  const userPhoto = useMemo(() => user ? getUserPhoto(user) : '', [user]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [loading, setLoading] = useState<Boolean>(false);
  const { openModal } = useConfigurationModal();

  const onFileSelect = (event: Event) => {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
        setSelectedFile(input.files[0]);
    }
  };

  const createUploadInput = () => {
    const input: HTMLInputElement = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = onFileSelect;
    fileInputRef.current = input;
    input.click();
  };

  const removeUploadInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.remove();
      fileInputRef.current = null;
    }
  };

  const changeCustomPhoto = () => {
    if (user?.customPhotoUrl) {
      openModal();

      return;
    }

    createUploadInput()
  }

  const removeCustomPhoto = async () => {
    try {
      await UserService.deleteCustomPhoto();

      // TODO: Решить через `refetchData && refetchData();`, но учесть блок <Navigation />
      window.location.reload();
    } catch (error) {
      if (!(error instanceof Error)) return;

      console.error(error.message);
    } finally {
      removeUploadInput();
      setLoading(false);
    }
  }

  const uploadCustomPhoto = useCallback(async () => {
    if (selectedFile && !loading) {
      setLoading(true);

      const formData = new FormData();
      formData.append('file', selectedFile);

      try {
        await UserService.uploadCustomPhoto(formData);

        // TODO: Решить через `refetchData && refetchData();`, но учесть блок <Navigation />
        window.location.reload();
      } catch (error) {
        if (!(error instanceof Error)) return;

        console.error(error.message);
      } finally {
        setLoading(false);
        setSelectedFile(null);
      }
    }
  }, [selectedFile, loading]);

  useEffect(() => {
    uploadCustomPhoto();
  }, [uploadCustomPhoto]);

  if (!user) {
    return <></>;
  }

  return (
    <div className={cn(styles.userData, filled && styles.filled, className)}>
      <Avatar
        size={size}
        username={userName}
        profilePictureUrl={userPhoto}
        onChangePhoto={withEditing ? changeCustomPhoto : undefined}
      />

      <div className={styles.descriptionWrapper}>
        <div>
          <p className={styles.description}>{userName}</p>

          <div className={styles.info}>
            <UserBadge user={user} />

            <span className={styles.registerDays}>
              {Math.round(userRegisterDays) + ' ' + t('date.days')}
            </span>
          </div>
        </div>

        {arrow && (
          <Icon
            className={styles.icon}
            height={19}
            width={19}
            name={IconName.Wmark}
            fill="none"
          />
        )}
      </div>

      <ConfigurationModal
        onChange={createUploadInput}
        onRemove={removeCustomPhoto}
      />
    </div>
  );
}

export default React.memo(ReactUser);
