import styles from './ExchangeSlide.module.scss';

import { FC } from 'react';

import { SkuModelDto } from 'dtos';

export interface SkuModelDtoWithId
  extends SkuModelDto {
  id: number;
}

export const ExchangeSlide: FC<SkuModelDtoWithId> = (
  props
) => {
  const { picture, localizedModelName } = props;
  return (
    <div
      className={styles.slide}
      style={{ backgroundImage: `url(${picture})` }}
    >
      <h4 className={styles.title}>{localizedModelName}</h4>
    </div>
  );
};
