import styles from './Info.module.scss';
import React, { useMemo } from 'react';
import Amount from 'components/Amount';
import { formatDate } from 'utils';
import { Avatar, AvatarSize } from 'shared/ui/Avatar/Avatar';
import { BalanceTransactionProps } from 'components/BalancesHistory/BalanceTransaction';
import { TransactionType } from 'dtos';
import * as Sentry from '@sentry/react';
import { toast } from 'react-hot-toast';
import { getUserId } from 'shared/lib/auth';
import { useCancelTransactionMutation } from 'services/balances';
import Button, { Variant } from 'components/Button/Button';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTransactionInfoModal } from 'entities1/transactionInfoModal/services/useTransactionInfoModal';

function Info({
                balanceName,
                balancePhotoUrl,
                bucketName,
                transactionComment,
                transactionValue,
                transactionCreatedAt,
                authorName,
                authorId,
                type,
                id,
                canBeCanceled,
                contactId,
                balanceId,
              }: BalanceTransactionProps) {
  const { t } = useTranslation();
  const userId = Number(getUserId());

  const [cancelTransactionMutation] = useCancelTransactionMutation();
  const { closeModal } = useTransactionInfoModal();
  const isCanceled = type === TransactionType.Canceled;
  const canUserCancel = canBeCanceled && authorId === userId;

  const onCancelButtonClick = async (evt: React.MouseEvent<HTMLElement>) => {
    evt.stopPropagation();
    try {
      await cancelTransactionMutation({ transactionId: id, contactId: contactId, balanceId: balanceId }).unwrap();
      closeModal();
    } catch (e) {
      Sentry.captureException(e);
      if (!(e instanceof Error)) return;
      toast.error(e.message);
    }
  };

  const formattedDate = useMemo(() => {
    const date = new Date(transactionCreatedAt);
    return formatDate(date);
  }, [transactionCreatedAt]);

  //[TODO]: CT-532 нет информации о телеграм чате в транзакции
  return (
    <div className={styles.wrap}>
      {isCanceled && (
        <div className={styles.canceledHeader}>{t('component.transaction.canceled')}</div>)
      }
      <div className={styles.header}>
        <span>{formattedDate}</span>
        <div className={cn(isCanceled && styles.canceled)}>
          <Amount value={transactionValue} sign colored={!isCanceled} />
          <p className={styles.transactionBucket}>{bucketName}</p>
        </div>
      </div>

      <div className={styles.info}>
        <span>{authorName}</span>
        <div className={styles.balanceInfo}>
          <Avatar
            username={balanceName || ''}
            profilePictureUrl={balancePhotoUrl}
            size={AvatarSize.Small}
            className={styles.avatar}
          />
          <span>{balanceName}</span>
        </div>
        {/* {telegramChatLink && (
          <a href={telegramChatLink} target="_blank" rel="noreferrer">
            {telegramChatLink}
          </a>
        )} */}
        <p className={styles.transactionComment}>{transactionComment}</p>
      </div>
      {canUserCancel && (
        <Button variant={Variant.White} className={styles.cancelButton} onClick={onCancelButtonClick}>
          {t('component.transaction.cancelButton')}
        </Button>
      )}
    </div>
  );
}

export default Info;
