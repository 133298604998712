export default function getRandomUUID(): string {
  if (typeof crypto !== 'undefined' && typeof crypto.randomUUID === 'function') {
    return crypto.randomUUID().replaceAll('-', '').slice(0, 25);
  }

  return (String(Math.random()))
    .repeat(5)
    .replaceAll('.', '')
    .slice(0, 50);
}
