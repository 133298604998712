import styles from './PaymentsFilters.module.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import Filter from 'components/Filter';

import { PaymentParticipantRole, PaymentStatus, PaymentType } from 'dtos';

import {
  usePersistedPaymentsByStatus,
  usePersistedPaymentsFilterByRole,
  usePersistedPaymentsFilterByType,
} from 'entities1/payments/model/hooks';

import { STATUSES, ROLES, TYPES } from './constants';

export interface PaymentsFiltersProps {
  onGetPayments: () => void;
}

function PaymentsFilters({ onGetPayments }: PaymentsFiltersProps): JSX.Element {
  const { t } = useTranslation();

  const { selectedStatuses, persistSelectedStatuses } =
    usePersistedPaymentsByStatus();
  const { selectedRoles, persistSelectedRoles } =
    usePersistedPaymentsFilterByRole();
  const { selectedTypes, persistSelectedTypes } =
    usePersistedPaymentsFilterByType();

  const selectStatusAndGetPayments = (statuses: PaymentStatus[]): void => {
    persistSelectedStatuses(statuses);
    onGetPayments();
  };

  const selectRoleAndGetPayments = (roles: PaymentParticipantRole[]): void => {
    persistSelectedRoles(roles);
    onGetPayments();
  };

  const selectTypeAndGetPayments = (types: PaymentType[]): void => {
    persistSelectedTypes(types);
    onGetPayments();
  };

  return (
    <div className={styles.filters}>
      <Filter
        value={selectedTypes}
        onChange={selectTypeAndGetPayments}
        values={TYPES}
        title={`${t('labels.type')}`}
        active={selectedTypes.length > 0}
        multiple
      />
      <Filter
        value={selectedStatuses}
        onChange={selectStatusAndGetPayments}
        values={STATUSES}
        title={`${t('labels.status')}`}
        active={selectedStatuses.length > 0}
        multiple
      />

      <Filter
        value={selectedRoles}
        onChange={selectRoleAndGetPayments}
        values={ROLES}
        title={`${t('labels.role')}`}
        active={selectedRoles.length > 0}
        multiple
      />
    </div>
  );
}

export default PaymentsFilters;
