import styles from './ExchangeErrorMessage.module.scss';

import { FC } from 'react';

import { ExchangerWidgetProps } from '../../ExchangerWidget';
import { useExchangerStore } from '../../store';

export const ExchangeErrorMessage: FC<ExchangerWidgetProps> = (props) => {
  const { providerId, userId } = props;
  const { errorData } = useExchangerStore(providerId, userId);

  if (!errorData?.message) {
    return null;
  }

  return <p className={styles.error}>{errorData.message}</p>;
};
