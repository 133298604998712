import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import { ShareProviderButton } from 'modules/ShareProviderButton';

import Modal from 'components/Modal/Modal';
import Button, { Variant } from 'components/Button/Button';

import {
  ContactRelationType,
  UserDto,
  UserRelationType,
} from 'dtos';

import { useConfigurationModal } from 'entities1/configurationModal/services/useConfigurationModal';

import { useContactRelations } from 'shared/hooks/useContactRelations';

import styles from './index.module.scss';
import {
  useDeleteContactMutation,
  useStopBeingCustomerMutation,
  useStopBeingProviderMutation,
} from 'services/contacts';
import { useAddAssistantMutation } from 'services/assistants';

export interface ConfigurationModalProps {
  friend: UserDto;
}

function ConfigurationModal({
  friend,
}: ConfigurationModalProps): JSX.Element | null {
  const { t } = useTranslation();
  const { state, closeModal } = useConfigurationModal();
  const [sure, setSure] = useState<boolean>(false);
  const [
    addAssistant,
    { isLoading: isAddingAssistant, isSuccess: isSuccessAdded },
  ] = useAddAssistantMutation();
  const friendId: number = friend.id;
  const friendRelationType: ContactRelationType =
    friend.contactRelationType;
  const isProvider =
    friendRelationType === ContactRelationType.Provider ||
    friendRelationType === ContactRelationType.CustomerProvider;
  const isCustomer =
    friendRelationType === ContactRelationType.Customer ||
    friendRelationType === ContactRelationType.CustomerProvider;

  const makeAnAssistant = async (): Promise<void> => {
    toast.promise(addAssistant(friendId).unwrap(), {
      loading: t('status.adding'),
      success: () => {
        return <b>{t('status.successAddedAssistant')}</b>;
      },
      error: ({ error }) => {
        return <b>{error}</b>;
      },
    });
  };

  const { providerRelation, customerProviderRelation } =
    useContactRelations(friend);
  const showShareProviderButton: boolean =
    providerRelation || customerProviderRelation;

  const [deleteConcact, { isLoading: isLoadingDeleteContact }] =
    useDeleteContactMutation();

  const handleDeleteButton = () => {
    if (!sure) {
      setSure(true);
    } else {
      toast.promise(
        deleteConcact(friendId)
          .unwrap()
          .finally(() => {
            handleCloseModal();
          }),
        {
          loading: t('status.deleting'),
          success: () => {
            window.location.replace('/');
            return t('status.userDeleted');
          },
          error: ({ data }) => {
            return <b>{data.message}</b>;
          },
        }
      );
    }
  };

  const [stopBeingCustomer, { isLoading: isLoadingStopBeingCustomer }] =
    useStopBeingCustomerMutation();
  const handleStopBeingCustomerButton = () => {
    toast.promise(
      stopBeingCustomer(friendId).unwrap(),
      {
        loading: t('status.processing'),
        success: () => {
          return t('status.success');
        },
        error: ({ data }) => {
          return <b>{data.message}</b>;
        },
      }
    );
  };

  const [stopBeingProvider, { isLoading: isLoadingStopBeingProvider }] =
    useStopBeingProviderMutation();
  const handleStopBeingProviderButton = () => {
    toast.promise(
      stopBeingProvider(friendId).unwrap(),
      {
        loading: t('status.processing'),
        success: () => {
          return t('status.success');
        },
        error: ({ data }) => {
          return <b>{data.message}</b>;
        },
      }
    );
  };

  const handleCloseModal = () => {
    setSure(false);
    closeModal();
  };

  if (!state) {
    return null;
  }

  return (
    <Modal visible={state.visible} onClose={handleCloseModal}>
      {showShareProviderButton && (
        <ShareProviderButton
          providerId={friendId}
          userRelationType={
            isProvider ? UserRelationType.Customer : UserRelationType.Contact
          }
        />
      )}
      {!isSuccessAdded && (
        <Button
          variant={Variant.Outlined}
          onClick={makeAnAssistant}
          isLoading={isAddingAssistant}
          disabled={isAddingAssistant}
        >
          {t('button.makeAnAssistant')}
        </Button>
      )}
      {isProvider && (
        <Button
          variant={Variant.Outlined}
          onClick={handleStopBeingCustomerButton}
          isLoading={isLoadingStopBeingCustomer}
          disabled={isLoadingStopBeingCustomer}
        >
          {t('button.stopBeingCustomer')}
        </Button>
      )}
      {isCustomer && (
        <Button
          variant={Variant.Outlined}
          onClick={handleStopBeingProviderButton}
          isLoading={isLoadingStopBeingProvider}
          disabled={isLoadingStopBeingProvider}
        >
          {t('button.stopBeingProvider')}
        </Button>
      )}
      <Button
        variant={Variant.Outlined}
        onClick={handleDeleteButton}
        className={styles.remove}
        isLoading={isLoadingDeleteContact}
        disabled={isLoadingDeleteContact}
      >
        {!sure ? t('button.deleteContact') : t('button.areYouSure')}
      </Button>
    </Modal>
  );
}

export default ConfigurationModal;
