import { useSearchParams } from 'react-router-dom';

import { REDEEM_INVITATION, HOME } from 'pages/paths';

import { useRedirect } from 'shared/hooks/useRedirect';

import checkIsAuth from 'utils/checkIsAuth';

function InvitationPage(): JSX.Element | null {
  const [urlSearchParams] = useSearchParams();
  const shareToken = urlSearchParams.get('share');

  const loggedIn: boolean = checkIsAuth();

  useRedirect(loggedIn, `${REDEEM_INVITATION}?share=${shareToken}`, {replace: true});
  useRedirect(!loggedIn, `${HOME}?share=${shareToken}`, {replace: true});
  return null;
}

export default InvitationPage;
