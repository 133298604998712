import styles from './Success.module.scss';

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from 'components/Icon/Icon';
import { IconName } from 'components/Icon/IconList';
import Amount from 'components/Amount';

import { formatDate } from 'utils';
import { ObligationTransferData } from '../../index';

const TIMEOUT: number = 3000;

export interface SuccessProps {
  readonly data: ObligationTransferData;
  onSuccess: () => void;
}

function Success({ data, onSuccess }: SuccessProps): JSX.Element | null {
  const { t } = useTranslation();

  useEffect(() => {
    if (data?.sent) {
      setTimeout(() => onSuccess(), TIMEOUT);
    }
  }, [data?.sent, onSuccess]);

  if (!data?.sent) return null;

  const amount: number = data.value ? parseFloat(data.value.replaceAll(',', '.')) : 0;

  const title: string = t(`button.transferred`);

  return (
    <div className={styles.success}>
      <div className={styles.iconWrap}>
        <Icon name={IconName.Check} width={42} height={32} />
      </div>

      <h3 className={styles.title}>
        {title}
        <Amount value={amount} code={data.code} />
      </h3>

      {data.comment ? <p className={styles.comment}>{data.comment}</p> : null}

      <span className={styles.date}>{formatDate(Date.now())}</span>
    </div>
  );
}

export default Success;
