import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { TASK_MAIN } from './paths';

import { useGetMeQuery } from 'services/users';

const RedirectWhenLoggedInRoute = () => {
  const { data: user, isFetching: isUserFetching} = useGetMeQuery();

  if (!user && isUserFetching && typeof window !== 'undefined') {
    return <></>;
  }

  if (!user) return <Outlet />;
  return <Navigate to={TASK_MAIN} replace />;
};

export default RedirectWhenLoggedInRoute;
