import styles from './Filter.module.scss';

import React, { useMemo } from 'react';
import { Listbox } from '@headlessui/react';
import cn from 'classnames';

import Icon from 'components/Icon/Icon';
import { IconName } from 'components/Icon/IconList';
import { LABEL } from 'components/Filter/label';
import CheckMark from './CheckMark';

import { isLabelKey } from './label';

const hasMultipleProp = (props: object): props is { multiple: true } => {
  return 'multiple' in props && typeof props.multiple === 'boolean';
};

type FilterProps<Value> = {
  value: Value;
  onChange: (value: Value) => void;
  title?: string;
  active?: boolean;
  className?: string;
};

export type FilterPropsConstructor<Value> = Value extends unknown[]
  ? FilterProps<Value> & { values: Value; multiple: true }
  : FilterProps<Value> & { values: Value[] };

function Filter<T>({
  value,
  values,
  title,
  active,
  className,
  ...listBoxProps
}: FilterPropsConstructor<T>): JSX.Element | null {
  const buttonTitle = useMemo(() => {
    if (title)
      return hasMultipleProp(listBoxProps) || !value ? title : String(value);

    return String(value);
  }, [title, value, listBoxProps]);

  if (!Array.isArray(values) || values.length === 0) {
    return null;
  }

  return (
    <div className={cn(styles.filter, className)}>
      <Listbox value={value} {...listBoxProps}>
        <Listbox.Button className={cn(styles.value, active && styles.active)}>
          {buttonTitle}

          <Icon name={IconName.Filter} width={15} height={15} />
        </Listbox.Button>

        <Listbox.Options className={styles.options}>
          {values.map((v) => (
            <Listbox.Option as={React.Fragment} key={v as React.Key} value={v}>
              {({ selected }) => (
                <li className={cn(styles.option, selected && styles.selected)}>
                  <CheckMark selected={selected} />
                  {isLabelKey(v) ? LABEL[v] : String(v)}
                </li>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    </div>
  );
}

export default Filter;
