import styles from '../../ExchangerWidget.module.scss';

import { useMemo } from 'react';
import cn from 'classnames';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import find from 'lodash/find';
import head from 'lodash/head';

import { Select } from 'components/Select';
import {
  CurrencyDto,
  CurrencyInformationResponseDtoV3,
  CurrencyPaymentMethodInfo,
  PaymentFormDtoV5,
} from 'dtos';

import { ExchangerWidgetScopeProps } from '../../ExchangerWidget';
import { useExchangerStore } from '../../store';
import { isBuyScope, findPaymentMethodByType } from '../../utils';
import SkeletonWithWrapper from 'shared/ui/SkeletonWithWrapper';

export interface ExchangeCurrencySelectProps extends ExchangerWidgetScopeProps {
  isPublic?: boolean;
}

export function ExchangeCurrencySelect(
  props: ExchangeCurrencySelectProps
): JSX.Element | null {
  const { providerId, userId, scope, editOfferMode } = props;
  const {
    activeOption,
    tabs,
    currentFormData,
    calculateExchangePaymentForm,
    isInitialization,
    favouriteCurrenciesPayments = {},
  } = useExchangerStore(providerId, userId);
  const currencies = tabs?.find((t) => t.name === activeOption)?.currencies;

  const buyScope = isBuyScope(scope);
  const buyOrSellExchangeUnitProp: keyof PaymentFormDtoV5 = buyScope
    ? 'buyExchangeUnit'
    : 'sellExchangeUnit';
  const code: CurrencyDto['code'] | undefined = (
    currentFormData?.[buyOrSellExchangeUnitProp] as CurrencyDto
  )?.code;

  const codes = useMemo(() => {
    if (isInitialization) {
      return [];
    }

    const list = currencies?.filter((type) =>
      Boolean(type.paymentMethods.map((m) => m.methods).flat())
    );
    return map(list, (c) => c.code);
  }, [currencies, isInitialization]);

  if (isInitialization) {
    return <SkeletonWithWrapper height={42} />;
  }

  const changeExchangeCode = (code: CurrencyDto['code']) => {
    const currencyInfo: CurrencyInformationResponseDtoV3 | undefined = find(currencies, (c) =>
      isEqual(c.code, code)
    );
    if (!currencyInfo || !currentFormData) {
      return;
    }
    const paymentMethod = (currentFormData[buyOrSellExchangeUnitProp] as CurrencyDto).paymentMethod;
    const { type, name } = paymentMethod;
    const { paymentMethods } = currencyInfo;

    const currentPaymentType =
      (favouriteCurrenciesPayments && favouriteCurrenciesPayments[code]?.type) || type;
    const currentPaymentMethodName =
      (favouriteCurrenciesPayments && favouriteCurrenciesPayments[code]?.name) || name;

    const paymentMethodByType = findPaymentMethodByType(paymentMethods, currentPaymentType);
    const nextPaymentMethodTypeName = paymentMethodByType
      ? currentPaymentType
      : paymentMethods[0].paymentMethodType;
    const currentPaymentMethod = findPaymentMethodByType(paymentMethods, nextPaymentMethodTypeName);

    const nextPaymentMethodName: CurrencyPaymentMethodInfo['methodName'] | undefined = find(
      currentPaymentMethod?.methods,
      (m) => isEqual(m, currentPaymentMethodName)
    )
      ? currentPaymentMethodName
      : head(currentPaymentMethod?.methods);

    if (!nextPaymentMethodName) {
      return;
    }

    calculateExchangePaymentForm({
      form: {
        [buyOrSellExchangeUnitProp]: {
          code,
          paymentMethod: {
            type: nextPaymentMethodTypeName,
            name: nextPaymentMethodName,
          },
        },
      }
    });
  };

  if (!code || (isBuyScope(scope) && activeOption !== 'Currency')) {
    return null;
  }

  return (
    <Select
      value={code}
      values={codes}
      onChange={changeExchangeCode}
      className={cn(styles.select, styles.code)}
      buttonClassName={styles.btn}
      optionsClassName={styles.options}
    />
  );
}
