import styles from './index.module.scss';

import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { UseQueryResult } from '@tanstack/react-query';
import AddNewFeeRangeButton from 'features/feeRate/AddNewFeeRangeButton';
import FeeRatesTable from 'entities1/feeRate/ui/FeeRatesTable';
import type { FeeRangeServiceSettings } from 'entities1/feeRate/model/types/feeRate';
import {
  FEE_RANGE_ENDPOINT,
  FEE_RATE_INCREMENT_VALUE,
  FEE_RANGE_DEFAULTS_ENDPOINT,
} from 'entities1/feeRate/model/constants';
import {
  useGetFeeRatesQueryByUserId,
  useAddNewFeeRangeMutation,
  useChangeFeeRangeMutation,
  useDeleteFeeRangeMutation,
} from 'entities1/feeRate/model/services';

import { useCurrentUser } from 'shared/hooks/useCurrentUser';

import type { FeeRateRangeDto } from 'dtos';

import { debounce } from 'utils';

const INPUT_TIMEOUT: number = 250;

export interface FeeRangesProps {
  defaults?: boolean;
}

function FeeRanges({ defaults }: FeeRangesProps): JSX.Element {
  const currentUser = useCurrentUser();
  const { id: userId } = useParams();

  const settings = useMemo((): FeeRangeServiceSettings => {
    const queryKey = defaults ? ['feeRateDefaults'] : ['feeRates', userId];
    const zeroBoundQueryKey = defaults
      ? ['zeroBoundDefaults']
      : ['zeroBound', userId];
    return {
      userId: defaults ? currentUser.id : Number(userId),
      apiEndpoint: defaults ? FEE_RANGE_DEFAULTS_ENDPOINT : FEE_RANGE_ENDPOINT,
      queryKey,
      zeroBoundQueryKey,
      shouldUpdateExchanger: !defaults,
    };
  }, [userId, currentUser.id, defaults]);

  const ratesQueryResult: UseQueryResult<FeeRateRangeDto[]> =
    useGetFeeRatesQueryByUserId(settings);
  const addRateMutation = useAddNewFeeRangeMutation(settings);
  const updateRateMutation = useChangeFeeRangeMutation(settings);
  const deleteRateMutation = useDeleteFeeRangeMutation(settings);

  const { data: table } = ratesQueryResult;

  const addFeeRange = useCallback(() => {
    const lastFeeRange = Array.isArray(table) && table.length > 0
      ? table[table.length - 1]
      : { lowerBound: 0, feeRate: 0 };

    addRateMutation.mutate({
      userId: Number(settings.userId),
      lowerBound: lastFeeRange.lowerBound + FEE_RATE_INCREMENT_VALUE,
      feeRate: lastFeeRange.feeRate,
    });
  }, [addRateMutation, table, settings.userId]);

  const updateFeeRange = useCallback(
    (rate: FeeRateRangeDto) => updateRateMutation.mutate(rate),
    [updateRateMutation]
  );

  const debounceUpdateFeeRange = useCallback(
    debounce(updateFeeRange, INPUT_TIMEOUT),
    [updateFeeRange]
  );

  const removeFeeRange = useCallback(
    (id: FeeRateRangeDto['id']) => deleteRateMutation.mutate(id),
    [deleteRateMutation]
  );

  return (
    <div className={styles.widget}>
      <FeeRatesTable
        ratesQueryResult={ratesQueryResult}
        onRateUpdate={debounceUpdateFeeRange}
        onRateRemove={removeFeeRange}
      />

      <div className={styles.addButton}>
        <AddNewFeeRangeButton onClick={addFeeRange} />
      </div>
    </div>
  );
}

export default FeeRanges;
