import { useEffect, useMemo } from 'react';
import {
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryResult,
} from '@tanstack/react-query';

import ExchangeRateInheritanceService from 'api/exchange-rate-inheritance';
import type { InheritanceExchangeRatesByProviderIdResponse } from 'api/exchange-rate-inheritance/types';
import type { UserDto } from 'dtos';

import { queryClient } from 'shared/config/reactQuery';

type InfiniteExchangeRatesByProviderId = {
  pageParam?: number;
} & InheritanceExchangeRatesByProviderIdResponse;

const INITIAL_PAGE: number = 1;
const LIMIT: number = 20;

export const useGetExchangeRatesByProviderId = (
  id: UserDto['id']
): UseInfiniteQueryResult<InfiniteExchangeRatesByProviderId> => {
  const queryKey = useMemo<QueryKey>(
    () => ['exchange-rates-by-provider', id],
    [id]
  );

  useEffect(() => {
    return () => queryClient.removeQueries(queryKey);
  }, [queryKey]);

  return useInfiniteQuery({
    queryKey,
    queryFn: async ({ pageParam = 1, queryKey }) => {
      const currencies =
        queryClient.getQueryData<string[]>([...queryKey, 'filter']) || [];

      const res =
        await ExchangeRateInheritanceService.getExchangeRatesByProviderId({
          id,
          limit: LIMIT,
          page: pageParam || INITIAL_PAGE,
          currency: currencies.length > 0 ? currencies.join(',') : undefined,
        });
      return { pageParam, ...res };
    },
    getNextPageParam: (lastPage) => {
      return lastPage.last
        ? undefined
        : (lastPage.pageParam || INITIAL_PAGE) + 1;
    },
    enabled: Boolean(id),
  });
};
