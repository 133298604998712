import { useEffect, useState } from 'react';
import cn from 'classnames';

import { Slider, SliderProps } from 'shared/ui/Slider';
import { ProvidersDto, UserDto } from 'dtos';

import { ProviderSlide } from '../ProviderSlide';
import { Title } from '../Title';
import styles from './ProvidersSlider.module.scss';

export interface ProvidersSliderProps
  extends Pick<SliderProps<UserDto>, 'firstSlide' | 'lastSlide'> {
  provider: UserDto | null;
  providers: ProvidersDto;
  onChangeProvider?: (providerId?: UserDto['id']) => void;
  showTitle?: boolean;
  isMyself?: boolean;
}

export function ProvidersSlider({
  provider,
  providers,
  onChangeProvider,
  firstSlide,
  lastSlide,
  showTitle,
  isMyself,
}: ProvidersSliderProps): JSX.Element | null {
  const [selectedProviderId, setSelectedProviderId] = useState<
    UserDto['id'] | null
  >(provider?.id ?? null);

  const changeSelectedProvider = (slide?: UserDto) => {
    setSelectedProviderId(slide?.id ?? null);
    onChangeProvider && onChangeProvider(slide?.id);
  };

  useEffect(() => {
    if (selectedProviderId !== provider?.id) {
      setSelectedProviderId(provider?.id ?? null);
    }
  }, [provider?.id, selectedProviderId]);

  if (!provider) {
    return null;
  }

  return (
    <div className={styles.wrap}>
      <Slider
        slide={provider}
        slides={[...providers.special, ...providers.contacts]}
        slideRenderer={ProviderSlide}
        onSlideChange={changeSelectedProvider}
        sliderClassName={styles.providers}
        slideClassName={cn(styles.provider, {[styles[`divider${providers.special.length}`]]: providers.special.length > 0 && providers.contacts.length > 0})}
        firstSlide={firstSlide}
        lastSlide={lastSlide}
      />

      {showTitle && (
        <Title provider={provider} selectedProviderId={selectedProviderId} isMyself={isMyself} />
      )}
    </div>
  );
}
