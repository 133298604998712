import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../fetchBaseQueryWithAuth';
import { delayedPromiseRetry } from 'delayed-promise-retry';
import isEmpty from 'lodash/isEmpty';

const getAudioTranscription = (baseQuery: any, key: string) => {
  return delayedPromiseRetry(async () => {
    const result = await baseQuery({
      url: '/openai/transcribe-audio',
      params: { requestKey: key },
      responseHandler: 'text',
      method: 'GET'
    });

    if (typeof result?.data === 'string' && !isEmpty(result.data)) {
      return result;
    }

    if (isEmpty(result?.data)) {
      throw new Error('Error Null');
    }

    if (result.error) {
      return result;
    }

    throw new Error('Error Waiting');
  }, 20, 1000);
};

export const openaiApi = createApi({
  reducerPath: 'openaiApi',
  baseQuery: baseQueryWithReauth(true),
  endpoints: (builder) => ({
    transcribeAudio: builder.mutation<string, { audio: File }>({
      queryFn: (params, _api, _extraOptions, baseQuery) => {
        const formData = new FormData();
        formData.append("audio", params.audio, params.audio.name);

        return baseQuery({
          url: `/openai/transcribe-audio`,
          method: 'POST',
          body: formData,
          responseHandler: 'text',
        })
          // @ts-ignore
          .then((data) => {
            if (typeof data?.data === 'string') {
              const key = data.data;
              return getAudioTranscription(baseQuery, key)
            }
          })
      },
    })
  })
})

export const {
  useTranscribeAudioMutation
} = openaiApi;
