import get from 'lodash/get';
import head from 'lodash/head';
import isError from 'lodash/isError';
import isObject from 'lodash/isObject';

import i18n from 'i18next';
import { useCallback, useMemo } from 'react';
import { QueryKey, useQuery } from '@tanstack/react-query';

import ExchangerService from 'api/exchanger';
import {
  ExchangeRateWidgetDto,
  ExchangerInitializeRequest,
  ExchangerTabV4,
  Language,
  PaymentFormDtoV5,
  TargetField,
} from 'dtos';
import { isResponseWithErrorMessage } from 'utils';

import { UserId, InputTarget, FavouriteCurrencyPayments } from '../types';
import {
  exchangerQueryKey,
  getExchangeInitQueryParams,
  getFavouriteCurrenciesPaymentsLSKey,
  getPaymentFormLSKey,
  isValidLocalStorageEntry,
} from '../utils';
import { useExchangeMutation } from '../hooks';
import { useExchangerStoreActions } from './useExchangerStoreActions';
import { LocalStorage } from 'shared/localStorage';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { useSearchParams } from 'react-router-dom';
import { getExchangeUnitDto } from 'dtos/dtoMapper';

type ErrorData = {
  message: string | null;
  isErrorOnInit: boolean;
  isErrorOnUpdate: boolean;
  status?: number | null;
};
export interface UseExchangerStore {
  activeOption: string;
  inputTarget: InputTarget;
  favouriteCurrenciesPayments: FavouriteCurrencyPayments,
  exchangerByOption: Record<
    string,
    {
      form?: PaymentFormDtoV5 & { currentTab: string };
      exchangeCalculationResponse?: Pick<PaymentFormDtoV5, 'exchangeRateWidgets' | 'crossExchangeWidget'> | null;
    }
  >;
  // currentFormData: PaymentFormDtoV3;
  currentActiveTab: ExchangerTabV4;
  tabs: ExchangerTabV4[];
  currentTab: string,
  errorData: ErrorData;
  utmTag?: string
}

let controller: AbortController | null = null;

export const useExchangerStore = (
  providerId: UserId = null,
  userId: UserId = null,
  options?: { cache: boolean },
  customRates?: ExchangeRateWidgetDto[],
  customOfferMode?: boolean,
  editOfferMode?: boolean,
) => {
  const [searchParams] = useSearchParams();
  const language = i18n.language as Language | undefined;
  const category = searchParams.get('category');
  const preferredCity = searchParams.get('preferredCity');
  const utmTag = searchParams.get('utmTag');

  const { id: currentUserId } = useCurrentUser();
  const queryKey: QueryKey = useMemo(
    () => exchangerQueryKey(providerId || currentUserId, userId || currentUserId),
    [providerId, userId, currentUserId]
  );

  const { setPaymentForm, setErrorData } = useExchangerStoreActions({
    queryKey,
    providerId,
    userId,
  });

  const { data, isLoading: isInitialization } = useQuery<UseExchangerStore>({
    refetchOnMount: false,
    enabled: !options?.cache && Boolean(providerId || userId),
    queryKey,
    queryFn: async () => {
      const lsEntry = LocalStorage.getItem(
        getPaymentFormLSKey(providerId || currentUserId, userId || currentUserId)
      );
      const favouriteLS = LocalStorage.getItem(
        getFavouriteCurrenciesPaymentsLSKey(providerId || currentUserId, userId || currentUserId)
      );
      let prevActiveOption: string | undefined;
      let inputTarget = get(lsEntry, 'inputTarget', 'buy') as InputTarget;
      let preferredFormData = null;

      prevActiveOption = get(lsEntry, 'activeOption');
      if (isValidLocalStorageEntry(lsEntry)) {
        preferredFormData = lsEntry.formDataByOption ?? null;
      }

      let favouriteCurrenciesPayments: null | FavouriteCurrencyPayments = null;
      if (isObject(favouriteLS)) {
        favouriteCurrenciesPayments = favouriteLS as FavouriteCurrencyPayments;
      }

      // тут подмешиваем текущего пользователя для запроса
      const params: ExchangerInitializeRequest = getExchangeInitQueryParams(
        providerId || currentUserId,
        userId || currentUserId,
        { preferredFormData, currentTab: (category || prevActiveOption), inputTarget, language, preferredCity: preferredCity }
      );

      const payload = await ExchangerService.getInitialization(params);

      const tabs = payload.tabs;
      const currentTab = tabs.find(tab => tab.name === (category || prevActiveOption));
      const currentActiveTab = currentTab || head(tabs) as ExchangerTabV4;

      const exchangerByOption = tabs.reduce((acc, tab) => {
        const option = tab.name;
        // @ts-ignore
        acc[option] = {
          form: {
            buyExchangeUnit: tab.initialData?.buyExchangeUnit,
            sellExchangeUnit: tab.initialData?.sellExchangeUnit,
            buyExchangeUnitAmount: tab.initialData?.buyExchangeUnitAmount,
            sellExchangeUnitAmount: tab.initialData?.sellExchangeUnitAmount,
            providerId: tab.initialData?.providerId,
            customerId: tab.initialData?.customerId,
            subcategory: tab.initialData?.subcategory,
            isEditable: tab.initialData?.isEditable,
          },
          exchangeCalculationResponse: {
            exchangeRateWidgets: tab.initialData?.exchangeRateWidgets,
            crossExchangeWidget: tab.initialData?.crossExchangeWidget,
          },
        }

        return acc;
      }, {});

      return {
        tabs: category ? [currentActiveTab] : payload.tabs,
        currentActiveTab,
        activeOption: currentActiveTab.name,
        exchangerByOption,
        favouriteCurrenciesPayments,
        utmTag: utmTag,
        errorData: {
          message: null,
          isErrorOnInit: false,
          isErrorOnUpdate: false,
        },
        // FIXME: remove it and add correct types, also add error boundary for the whole react app
      } as UseExchangerStore;
    },
    onError: (e: unknown) => {
      if (!isError(e)) {
        return;
      }
      setErrorData({
        isErrorOnInit: true,
        message: isResponseWithErrorMessage(e)
          ? e.response.data.message
          : e.message,
        status: isResponseWithErrorMessage(e) ? e.response.status : null,
      });
    },
    // retry: 1,
  });

  const { getExchangeCalculation, isCalculating } = useExchangeMutation({
    mutationKey: queryKey,
    providerId: providerId || currentUserId,
    userId: userId || currentUserId,
    errorData: data?.errorData,
    customRates: customRates,
    customOfferMode: customOfferMode,
    editOfferMode,
    exchangeCalculationResponse: data?.exchangerByOption?.[data?.activeOption]?.exchangeCalculationResponse ?? null
  });

  // Нужно уводить это в опции каждой вкладки, но там лежит форма, надо разбивать на мелкие части
  const inputTarget: InputTarget = data?.activeOption === 'Currency' ? (data?.inputTarget || 'buy') : 'buy';

  const calculateExchangePaymentForm = useCallback(
    ({form, target, nextInputTarget, customOfferMode, editOfferMode}: {
      form: Parameters<typeof setPaymentForm>[0] & { currentTab?: string },
      target?: 'buyExchangeUnitAmount' | 'sellExchangeUnitAmount',
      nextInputTarget?: 'buy' | 'sell',
      customOfferMode?: boolean,
      editOfferMode?: boolean,
    }) => {
      const paymentForm = setPaymentForm(form);
      if (paymentForm) {
        if (controller) {
          controller.abort();
        }

        const currentTarget = target ? target.slice(0, target.indexOf('Exchange')) : inputTarget;

        controller = new AbortController();
        const signal = controller.signal;

        getExchangeCalculation({
          exchangerFormData: {
            ...paymentForm,
            sellExchangeUnit: getExchangeUnitDto(paymentForm.sellExchangeUnit, false),
            buyExchangeUnit: getExchangeUnitDto(paymentForm.buyExchangeUnit, false)
          },
          targetField: `${currentTarget.toUpperCase()}_CURRENCY` as TargetField,
          signal,
          nextInputTarget,
          customOfferMode,
          editOfferMode,
        });
      }
    },
    [setPaymentForm, inputTarget, getExchangeCalculation, customRates]
  );

  return {
    queryKey,
    activeOption: data?.activeOption,
    utmTag: data?.utmTag,
    exchangeCalculationResponse:
      data?.exchangerByOption?.[data?.activeOption]?.exchangeCalculationResponse ??
      null,
    tabs: data?.tabs,
    currentFormData: data?.exchangerByOption?.[data?.activeOption]?.form,
    errorData: data?.errorData,
    isInitialization,
    isCalculating,
    calculateExchangePaymentForm,
    inputTarget: data?.inputTarget,
    favouriteCurrenciesPayments: data?.favouriteCurrenciesPayments,
  };
};

export type UseExchangerStoreReturn = ReturnType<typeof useExchangerStore>;
