import styles from './Friend.module.scss';

import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import toast from 'react-hot-toast';

import BalancesSummary from 'components/BalancesSummary';
import HistoryTabs from 'components/HistoryTabs';
import Button, { Variant } from 'components/Button/Button';
import ConfigurationModal from './ConfigurationModal';

import { UserCard } from 'entities1/user';

import { AvatarSize } from 'shared/ui/Avatar/Avatar';
import { useContactRelations } from 'shared/hooks/useContactRelations';
import { useCreateBalanceMutation, useGetBalancesByContactIdQuery } from 'services/balances';
import { useGetContactByIdQuery } from 'services/contacts';

function Friend(): JSX.Element | null {
  const { id } = useParams();
  const { t } = useTranslation();
  const [createBalance, { isLoading: isLoadingCreatingBalance }] =
    useCreateBalanceMutation();

  const friendId: number = Number(id);

  const { data: friend, isLoading } = useGetContactByIdQuery(friendId, {
    skip: !friendId,
  });

  const { data: balances = [], isLoading: isLoadingBalances } =
    useGetBalancesByContactIdQuery(friendId, { refetchOnMountOrArgChange: true, skip: !friendId });

  const { customerRelation, customerProviderRelation } =
    useContactRelations(friend);
  const showConfigureFeeRangesButton: boolean =
    customerRelation || customerProviderRelation;

  const handleCreateBalanceClick = async () => {
    toast.promise(createBalance(friendId).unwrap(), {
      loading: t('status.creating'),
      success: () => {
        return <b>{t('status.successCreatedBalance')}</b>;
      },
      error: ({ error }) => {
        return <b>{error}</b>;
      },
    });
  };

  if (!friend) {
    return null;
  }

  return (
    <div className={styles.friend}>
      <UserCard
        user={friend}
        loading={isLoading}
        avatarSize={AvatarSize.Default}
        hideRegisterDays
        reversedContent
        withEditing
      />

      {showConfigureFeeRangesButton && (
        <Link to={`/fees/${friendId}`}>
          <Button variant={Variant.Gray}>
            {t('button.configureFeeRanges')}
          </Button>
        </Link>
      )}

      <Button
        variant={Variant.Gray}
        onClick={handleCreateBalanceClick}
        disabled={isLoadingCreatingBalance}
        isLoading={isLoadingCreatingBalance}
      >
        {t('button.createBalance')}
      </Button>

      <BalancesSummary balances={balances} isLoading={isLoadingBalances} />

      <HistoryTabs friendId={friendId} />

      <ConfigurationModal friend={friend} />
    </div>
  );
}

export default Friend;
