import styles from './Details.module.scss';

import React, { useMemo } from 'react';
import cn from 'classnames';
import truncate from 'lodash/truncate';

import {
  CurrencyDto,
  GoodsDto,
  PaymentForListDtoV4,
  PaymentMethodType,
  PaymentStatus,
  PaymentType,
  SkuIdDto,
  UserDto,
} from 'dtos';

type DetailsProps = {
  payment: PaymentForListDtoV4;
  username: UserDto['customName'] | UserDto['username'];
  role: 'provider' | 'customer';
};

function Details({ payment, username }: DetailsProps): JSX.Element {
  const sellExchangeUnit = payment.sellExchangeUnit;
  const buyExchangeUnit = payment.buyExchangeUnit;
  const payMethod = getFormattedPaymentMethod(sellExchangeUnit);
  const receiveMethod = getFormattedPaymentMethod(buyExchangeUnit);
  const hasMethods: boolean = !!payMethod && !!receiveMethod;
  const isTransaction = payment.type === PaymentType.Transaction;
  const sellString = sellExchangeUnit ? `${payment.sellAmount} ${getFormattedExchangeUnitName(sellExchangeUnit)}` : undefined;
  const buyString = buyExchangeUnit ? `${payment.buyAmount} ${getFormattedExchangeUnitName(buyExchangeUnit)}` : undefined;
  const transactionTitle = getFormattedTitle(payment.title);

  const color: string = useMemo(() => {
    const status: PaymentForListDtoV4['paymentStatus'] = payment.paymentStatus;

    switch (status) {
      case PaymentStatus.Success:
        return 'success';
      case PaymentStatus.Cancelled:
        return 'cancelled';
      default:
        return '';
    }
  }, [payment.paymentStatus]);

  return (
    <div className={styles.details}>
      <div className={styles.info}>
          <div className={styles.paymentTitle}>
            {isTransaction ? (
              <div className={styles.paymentTitle}>
                <span className={cn(styles[color], styles.number)}>{sellString || buyString}</span>
              </div>
            ) : (
              <div className={cn(styles[color], styles.paymentTitle)}>
                <span className={styles.number}>{sellString}</span>
                <span className={styles.arrow}>→</span>
                <span className={styles.number}>{buyString}</span>
              </div>
            )}
          </div>
        {hasMethods && (
          <div className={cn(styles.exchangeMethod)}>
            {payMethod}
            <span>→</span>
            {receiveMethod}
          </div>
        )}
        {isTransaction && (
          <span className={styles.number}>{transactionTitle}</span>
        )}
        <div className={styles.user}>{username}</div>
      </div>
    </div>
  );
}

export default Details;

const getFormattedPaymentMethod = (exchangeUnit?: Partial<CurrencyDto & GoodsDto & SkuIdDto>) => {
  return exchangeUnit?.paymentMethod?.type === PaymentMethodType.Cash ?
    `CASH(${exchangeUnit.paymentMethod.name})` :
    exchangeUnit?.paymentMethod?.name || exchangeUnit?.city
}

const getFormattedExchangeUnitName = (exchangeUnit?: Partial<CurrencyDto & GoodsDto & SkuIdDto>) => {
  return truncate(exchangeUnit?.code || exchangeUnit?.name, { length: 10, omission: '…' })
}

const getFormattedTitle = (title: string) => {
   return truncate(title, { length: 35, omission: '…' });
}
