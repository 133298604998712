import { useCallback } from 'react';
import Skeleton from 'react-loading-skeleton';
import { AddNewProviderButton, ProvidersSlider } from './components';

import { useProvidersStore } from 'shared/store/providers';
import { UserDto } from 'dtos';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';

export interface HorizontalProvidersListProps {
  onChangeProvider?: (providerId?: UserDto['id']) => void;
}

export function HorizontalProvidersList({
  onChangeProvider: onChangeProviderOuter,
}: HorizontalProvidersListProps): JSX.Element | null {
  const { providers, primaryProvider, changePrimaryProvider, isLoading } = useProvidersStore();
  const { id: currentUserId } = useCurrentUser();

  const onChangeProvider = useCallback(
    (id?: UserDto['id']) => {
      changePrimaryProvider(id);

      if (typeof onChangeProviderOuter === 'function') {
        onChangeProviderOuter(id);
      }
    },
    [onChangeProviderOuter, changePrimaryProvider]
  );

  if (providers == null || isLoading) {
    return <Skeleton height={145} />;
  }

  return (
    <ProvidersSlider
      provider={primaryProvider}
      providers={providers}
      onChangeProvider={onChangeProvider}
      lastSlide={<AddNewProviderButton />}
      showTitle
      isMyself={currentUserId === primaryProvider?.id}
    />
  );
}
