import { createApi } from '@reduxjs/toolkit/query/react';
import {
  UpdateSkuPriceDto,
} from 'dtos';
import { baseQueryWithReauth } from '../fetchBaseQueryWithAuth';

export const exchangeApi = createApi({
  reducerPath: 'exchangeApi',
  baseQuery: baseQueryWithReauth(true),
  endpoints: (builder) => ({
    updatePrice: builder.mutation<void, UpdateSkuPriceDto>({
      query: (params) => ({
        url: '/exchange-offer/update-price',
        method: 'POST',
        body: params,
      }),
    }),
    resetUpdatingMethod: builder.mutation<void, string>({
      query: (skuIdentifier) => ({
        url: '/exchange-offer/reset-updating-method',
        method: 'POST',
        params: { skuIdentifier },
      }),
    }),
  }),
});

export const {
  useUpdatePriceMutation,
  useResetUpdatingMethodMutation,
} = exchangeApi;
