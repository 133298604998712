import styles from './PaymentsStatuses.module.scss';

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { PaymentsFiltersProps } from 'widgets/PaymentsFilters';

import Tag from './Tag';

import {
  usePersistedPaymentsByStatus,
  usePersistedPaymentsFilterByRole,
  usePersistedPaymentsFilterByType,
} from 'entities1/payments/model/hooks';
import { PaymentParticipantRole, PaymentStatus, PaymentType } from 'dtos';

import { isPaymentStatus, isPaymentType } from 'utils';

export type PaymentStatusesProps = PaymentsFiltersProps;

function PaymentsFilterTags({
  onGetPayments,
}: PaymentStatusesProps): JSX.Element | null {
  const { t } = useTranslation();
  const { selectedRoles, removeSelectedRoles, clearSelectedRoles } =
    usePersistedPaymentsFilterByRole();
  const { selectedStatuses, removeSelectedStatus, clearSelectedStatuses } =
    usePersistedPaymentsByStatus();
  const { selectedTypes, removeSelectedTypes, clearSelectedTypes } =
    usePersistedPaymentsFilterByType();

  const tags: (PaymentStatus | PaymentParticipantRole | PaymentType)[] = useMemo(() => {
    return [...selectedStatuses, ...selectedRoles, ...selectedTypes];
  }, [selectedRoles, selectedStatuses, selectedTypes]);

  const removeTag = (tagName: PaymentStatus | PaymentParticipantRole | PaymentType): void => {
    if (isPaymentStatus(tagName)) {
      removeSelectedStatus(tagName);
    } else if (isPaymentType(tagName)) {
      removeSelectedTypes(tagName);
    } else {
      removeSelectedRoles(tagName);
    }
    onGetPayments();
  };

  const clearTags = (): void => {
    clearSelectedStatuses();
    clearSelectedRoles();
    clearSelectedTypes();
    onGetPayments();
  };

  if (tags.length === 0) {
    return null;
  }

  return (
    <ul className={styles.tags}>
      {tags.map((tagName) => (
        <Tag
          key={tagName}
          title={tagName}
          onRemoveTag={() => removeTag(tagName)}
        />
      ))}
      {tags.length > 1 ? (
        <Tag onRemoveTag={clearTags}>{t('button.clearFilters')}</Tag>
      ) : null}
    </ul>
  );
}

export default PaymentsFilterTags;
