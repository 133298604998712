import styles from './Successfully.module.scss';
import Icon from 'components/Icon/Icon';
import { IconName } from 'components/Icon/IconList';
import React, { PropsWithChildren } from 'react';

interface SuccessfullyProps extends PropsWithChildren {
  className?: string;
}

export function Successfully(props: SuccessfullyProps): JSX.Element {
  const { className, children} = props;
  return (
    <div className={className}>
      <div className={styles.success}>
        <div className={styles.iconWrap}>
          <Icon name={IconName.Check} width={42} height={32} />
        </div>
        {children}
      </div>
    </div>
  );
}
