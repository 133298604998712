import {
  CurrencyDto,
  CurrencyResponseDto,
  ExchangeUnitDto,
  ExchangeUnitResponseDto, GoodsDto,
  GoodsResponseDto,
  SkuIdDto, SkuParameterDto,
} from './index';

export function getExchangeUnitDto(exchangeUnitResponseDto: ExchangeUnitResponseDto, isExchangeCreation: boolean): ExchangeUnitDto {
  let type: 'CurrencyResponseDto' | 'GoodsResponseDto'
    if ('code' in exchangeUnitResponseDto) {
      type = 'CurrencyResponseDto'
    } else {
      type = 'GoodsResponseDto'
    }
    if (type === 'CurrencyResponseDto') {
      const currencyDto = exchangeUnitResponseDto as CurrencyResponseDto
      return {
        code: currencyDto.code,
        paymentMethod: { type: currencyDto.paymentMethod.type, name: currencyDto.paymentMethod.name },
      } as CurrencyDto;
    } else {
      const goodsResponseDto = exchangeUnitResponseDto as GoodsResponseDto
      if (goodsResponseDto.skuIdentifier !== undefined && isExchangeCreation) {
        return { skuIdentifier: goodsResponseDto.skuIdentifier } as SkuIdDto
      } else {
        const skuParameterDtos = goodsResponseDto.skuParameters.map((skuParam) => {
          return { name: skuParam.name, type: skuParam.type, value: skuParam.value } as SkuParameterDto;
        })
        return {
          name: goodsResponseDto.name,
          city: goodsResponseDto.city,
          measureUnit: goodsResponseDto.measureUnit,
          skuParameters: skuParameterDtos
        } as GoodsDto
      }
    }
}
