import { useMutation, useQueryClient } from '@tanstack/react-query';
import instance from 'api';
import type { FeeRangeServiceSettings } from '../types/feeRate';

import type { FeeRateRangeDto } from 'dtos';

type FeeRateRangeId = FeeRateRangeDto['id'];

const useDeleteFeeRangeMutation = ({
  queryKey,
  apiEndpoint,
  userId,
  shouldUpdateExchanger,
}: FeeRangeServiceSettings) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id: FeeRateRangeId) => {
      const { data } = await instance.delete(apiEndpoint, { params: { id } });
      return data;
    },
    onMutate: async (id: FeeRateRangeId) => {
      await queryClient.cancelQueries({ queryKey });
      const previousFeeRates =
        queryClient.getQueryData<FeeRateRangeDto[]>(queryKey);
      if (previousFeeRates) {
        const rates = previousFeeRates;
        const deletedFeeRange = rates.find((rate) => rate.id === id);
        const deletedFeeRangeIndex =
          deletedFeeRange && rates.indexOf(deletedFeeRange);
        if (deletedFeeRangeIndex) {
          queryClient.setQueryData<FeeRateRangeDto[]>(queryKey, [
            ...rates.slice(0, deletedFeeRangeIndex),
            ...rates.slice(deletedFeeRangeIndex + 1),
          ]);
        }
      }
      return { previousFeeRates };
    },
    onSuccess: () => {
      if (shouldUpdateExchanger) {
        queryClient.refetchQueries(['exchanger', userId]);
      }
    },
    onError: (err, requestBody, context) => {
      if (context?.previousFeeRates) {
        queryClient.setQueryData<FeeRateRangeDto[]>(queryKey, [
          ...context.previousFeeRates,
        ]);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });
};

export { useDeleteFeeRangeMutation };
