import { TaskParticipantRole, TaskResponseDto, TaskStatus } from 'dtos';
import { useTranslation } from 'react-i18next';
import styles from './TaskInfo.module.scss';
import AIPrioritizedIcon from 'components/icons/AIPrioritized';
import React from 'react';
import AIPrioritized from 'components/AIPrioritized';
import isEmpty from 'lodash/isEmpty';
import TaskParticipant from './TaskParticipant';
import checkIfTaskDelegatedByUser from 'utils/checkIfTaskDelegatedByUser';
import checkIfTaskDelegatedToUser from 'utils/checkIfTaskDelegatedToUser';

export interface TaskInfoProps {
  task: TaskResponseDto;
  showOriginalDescription?: boolean;
}

function TaskInfo({ task, showOriginalDescription }: TaskInfoProps): JSX.Element | null {
  const { t } = useTranslation();

  const isToBeFormulated = task.status === TaskStatus.ToBeFormulated;
  const reviewer = task.participants.find((participant) =>
    participant.role === TaskParticipantRole.Reviewer
  );
  const implementer = task.participants.find((participant) =>
    participant.role === TaskParticipantRole.Implementer
  );

  const isDelegatedByUser = checkIfTaskDelegatedByUser(task);
  const isDelegatedToUser = checkIfTaskDelegatedToUser(task);

  const isShowDelegatedTo = isDelegatedToUser && reviewer !== undefined;
  const isShowDelegatedBy = isDelegatedByUser && implementer !== undefined;

  let taskStatus: string | undefined;

  if (task.status === TaskStatus.InProgress) {
    taskStatus = t('page.taskInfo.statuses.inProgress');
  } else if (task.status === TaskStatus.ToBeFormulated) {
    taskStatus = t('page.taskInfo.statuses.draft');
  } else if (task.status === TaskStatus.ReadyForWork) {
    taskStatus = t('page.taskInfo.statuses.readyForWork');
  } else if (task.status === TaskStatus.Postponed) {
    taskStatus = t('page.taskInfo.statuses.blocked');
  } else if (task.status === TaskStatus.Done) {
    taskStatus = t('page.taskInfo.statuses.done');
  } else {
    taskStatus = undefined;
  }

  return (
    <>
      <div className={styles.task}>
        {task.title && (
          <div className={styles.section}>
            {task.status === TaskStatus.InProgress && (
              <AIPrioritized description={t('page.taskInfo.aiPrioritizedDescription')} darkTheme={true} />
            )}
            <div className={styles.taskTitle}>{task.title}</div>
          </div>
        )}

        {(isShowDelegatedBy || isShowDelegatedTo) && (
          <div className={styles.section}>
            <div className={styles.sectionHeader}>
              <div className={styles.sectionTitle}>
                {isShowDelegatedBy ? t('page.taskInfo.implementer') : t('page.taskInfo.reviewer') }
              </div>
            </div>
            <TaskParticipant user={isShowDelegatedBy ? implementer.user : reviewer!!.user} />
          </div>
        )}

        {(showOriginalDescription || isToBeFormulated) && (
          <div className={styles.section}>
            <div className={styles.sectionHeader}>
            <div className={styles.sectionTitle}>
                {t('page.taskInfo.originalDescription')}
              </div>
            </div>
            <div className={styles.sectionDescription}>{task.originalTask}</div>
          </div>
        )}

        {task.modifiedTask && !isToBeFormulated && (
          <div className={styles.section}>
            <div className={styles.sectionHeader}>
              <div className={styles.sectionTitle}>
                {t('page.taskInfo.description')}
              </div>
              <AIPrioritizedIcon className={styles.aiIcon} />
              <div className={styles.aiProposed}>
                {t('page.taskInfo.aiProposed')}
              </div>
            </div>
            <div className={styles.sectionDescription}>{task.modifiedTask}</div>
          </div>
        )}

        {task.motivation && !isToBeFormulated && task.status !== TaskStatus.Done && (
          <div className={styles.section}>
            <div className={styles.sectionHeader}>
              <div className={styles.sectionTitle}>{t('page.taskInfo.whyImportantNow')}</div>
            </div>
            <div className={styles.sectionDescription}>{task.motivation}</div>
          </div>
        )}

        {isDelegatedByUser && (
          <div className={styles.section}>
            <div className={styles.sectionHeader}>
              <div className={styles.sectionTitle}>{t('page.taskInfo.status')}</div>
            </div>
            <div className={styles.sectionDescription}>{taskStatus}</div>
          </div>
        )}

        {task.status === TaskStatus.Postponed && !isEmpty(task.comment) && (
          <div className={styles.section}>
            <div className={styles.sectionHeader}>
              <div className={styles.sectionTitle}>{t('page.taskInfo.postponeComment')}</div>
            </div>
            <div className={styles.sectionDescription}>{task.comment}</div>
          </div>
        )}
      </div>
    </>
  );
}

export default TaskInfo;
