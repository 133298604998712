import { useMemo } from 'react';
import find from 'lodash/find';
import omit from 'lodash/omit';
import head from 'lodash/head';

import {
  CurrencyInformationResponseDtoV3,
  PaymentMethodDto,
} from 'dtos';
import { ExchangeTypeSelect } from '../ExchangeTypeSelect';
import { ExchangeMethodSelect } from '../ExchangeMethodSelect';
import { ExchangerWidgetScopeProps } from '../../ExchangerWidget';
import { getBuyOrSellExchangeUnitProp } from '../../utils';
import { useExchangerStore } from '../../store';

export function ExchangeTypeAndMethodSelects(
  props: ExchangerWidgetScopeProps
): JSX.Element {
  const { providerId, userId, scope } = props;
  const buyOrSellExchangeUnitProp = getBuyOrSellExchangeUnitProp(scope);
  const { activeOption, currentFormData, isInitialization, tabs } =
    useExchangerStore(providerId, userId);

  const currencies = tabs?.find(t => t.name === activeOption)?.currencies;
  const goods = tabs?.find(t => t.name === activeOption)?.goods;

  const foundCurrency: CurrencyInformationResponseDtoV3 | undefined =
    useMemo(() => {
      if (isInitialization) {
        return;
      }

      if (currentFormData && currentFormData?.[buyOrSellExchangeUnitProp]?.code) {
        return find(
          currencies,
          (c) => c.code === currentFormData[buyOrSellExchangeUnitProp].code
        );
      }
      return head(currencies);
    }, [
      currentFormData,
      activeOption,
      currencies,
      buyOrSellExchangeUnitProp,
      isInitialization,
    ]);

  const paymentMethod: PaymentMethodDto | undefined = currentFormData?.[buyOrSellExchangeUnitProp]?.paymentMethod;
  const currentCity = goods?.find(g => g.cityName === currentFormData?.[buyOrSellExchangeUnitProp]?.city);
  const city = omit(currentCity, 'subcategories');

  const childrenProps = { foundCurrency, paymentMethod, city, ...props };

  return (
    <>
      <ExchangeTypeSelect {...childrenProps} />

      <ExchangeMethodSelect {...childrenProps} />
    </>
  );
}
