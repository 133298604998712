import { BaseIconProps } from '../types';

const ArrowUpIcon = (props: BaseIconProps) => (
  <svg width="8" height="11" viewBox="0 0 8 11" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4.35355 2.14645C4.15829 1.95118 3.84171 1.95118 3.64645 2.14645L0.464466 5.32843C0.269204 5.52369 0.269204 5.84027 0.464466 6.03553C0.659728 6.2308 0.976311 6.2308 1.17157 6.03553L4 3.20711L6.82843 6.03553C7.02369 6.2308 7.34027 6.2308 7.53553 6.03553C7.7308 5.84027 7.7308 5.52369 7.53553 5.32843L4.35355 2.14645ZM4.5 10.5V2.5H3.5V10.5H4.5ZM0 1.5H8V0.5H0V1.5Z"
    />
  </svg>
);

export default ArrowUpIcon;
