import type { AxiosResponse } from 'axios';

import instance from 'api';
import type { PaymentDetailsRequestDto, PaymentDetailsResponseDto } from 'dtos';

import type { AccountPaymentDetailsParams } from './types';
import type { DeleteAccountPaymentDetailsParams } from './types';

const DETAILS_URL: string = '/details';

export default class AccountPaymentDetailsService {
  static async getDetails(
    params: AccountPaymentDetailsParams
  ): Promise<PaymentDetailsResponseDto[]> {
    const { data } = await instance.get<PaymentDetailsResponseDto[]>(
      DETAILS_URL,
      { params }
    );
    return data;
  }

  static async addDetails(
    accountPaymentDetailsDto: PaymentDetailsRequestDto
  ): Promise<PaymentDetailsResponseDto> {
    const { data } = await instance.post<
      PaymentDetailsResponseDto,
      AxiosResponse<PaymentDetailsResponseDto>,
      PaymentDetailsRequestDto
    >(DETAILS_URL, accountPaymentDetailsDto);
    return data;
  }
  //TODO: change to PUT request method
  static async addAsFavorite(
    accountPaymentDetailsDto: PaymentDetailsRequestDto
  ): Promise<void> {
    await instance.post<void, AxiosResponse<void>, PaymentDetailsRequestDto>(
      DETAILS_URL + '/favorite',
      accountPaymentDetailsDto
    );
  }

  static async deleteDetails(
    params: DeleteAccountPaymentDetailsParams
  ): Promise<void> {
    await instance.delete<void>(DETAILS_URL, { params });
  }
}
