import styles from './Invitations.module.scss';

import React, { Fragment, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useInView } from 'react-intersection-observer';
import { Listbox, Tab } from '@headlessui/react';

import Icon from 'components/Icon/Icon';
import Modal from 'components/Modal/Modal';
import { IconName } from 'components/Icon/IconList';
import InvitationsList from 'components/InvitationsList/InvitationsList';
import InvitationGenerationForm from 'components/InvitationGenerationForm/InvitationGenerationForm';
import ContentLoader from 'components/ContentLoader/ContentLoader';

import UserService from 'api/user';
import type { InvitationTokenResponse } from 'api/user/types';
import { UserRelationType, Language } from 'dtos';

import { useCurrentUserPermissions } from 'shared/hooks/useCurrentUserPermissions';

import type { languageItem } from './InvitationsTypes';
import Toggle from '../../components/Toggle';

function Invitations(): JSX.Element | null {
  const { t } = useTranslation();
  const { ref, inView } = useInView();

  const DEFAULT_INVITATIONS_LIMIT: number = 10;

  const customerLanguageList: languageItem[] = [
      {
          id: 0,
          value: undefined,
          label: t('component.customerLanguage.list.unspecified'),
      },
      {
          id: 1,
          value: Language.En,
          label: t('component.customerLanguage.list.english'),
      },
      {
          id: 2,
          value: Language.Ru,
          label: t('component.customerLanguage.list.russian'),
      },
  ]

  const tabList = [
    {
        id: 0,
        title: t('tab.invitationsOptions'),
    },
    {
        id: 1,
        title: t('tab.invitationsHistory'),
    }
]

  const [isShowQRCode, setIsShowQRCode] = useState<boolean>(false);
  const [qRCode, setQRCode] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [invitationLanguage, setInvitationLanguage] = useState<languageItem>(customerLanguageList[0]);
  const [userRelationType, setUserRelationType] = useState<UserRelationType>(UserRelationType.Contact);
  const [contentLimit, setContentLimit] = useState<boolean>(false);
  const [reusable, setReusable] = useState(false);

  const { data, fetchNextPage, refetch } = useInfiniteQuery<InvitationTokenResponse>({
    queryKey: ['invitations'],
    queryFn: async ({ pageParam = 1 }) => {
      return await UserService.getInvitations({
        page: pageParam,
        limit: DEFAULT_INVITATIONS_LIMIT,
      });
    },
    getNextPageParam: (lastPage, pages) => {
      // Прячем див-лоадер если все страницы загружены
      if (lastPage.totalPages === pages.length && !contentLimit) {
        setContentLimit(true);
      }

      return lastPage.last ? undefined : lastPage.number + 2;
    },
    keepPreviousData: true,
  });

  const preparedInvitations = useMemo(() => {
    const pages = data?.pages || [];

    return pages.map((page) => page.content).flat();
  }, [data?.pages]);

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage]);

  const closeQrCodeModal = () => setIsShowQRCode(false);

  const { isProvider } = useCurrentUserPermissions();

  useEffect(() => {
    if (isProvider) {
      setUserRelationType(UserRelationType.Customer);
    } else {
      setUserRelationType(UserRelationType.Contact);
    }
  }, [ isProvider ])

  return (
    <Fragment>
      <Modal
        visible={Boolean(isShowQRCode && qRCode)}
        onClose={closeQrCodeModal}
      >
        {qRCode ? <img src={qRCode} alt="qr" /> : null}
      </Modal>

      <Tab.Group
        selectedIndex={activeTab}
        onChange={setActiveTab}
      >
        <Tab.List className={styles.navTabsList}>
          {tabList.map(({ id, title }) => (
            <Tab
              key={id}
              as={Fragment}
            >
              {({ selected }) => (
                <button
                  className={cn(
                    styles.navTabsListItem,
                    {
                      [styles.active]: selected,
                    },
                  )}
                >
                  {title}
                </button>
              )}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className={styles.navTabsPanels}>
          <Tab.Panel className={styles.navTabsPanel}>
            <div className={styles.invitationOptions}>
              {isProvider && (
                <div className={styles.customerLanguageWrap}>
                  <Listbox onChange={(index: number) => setInvitationLanguage(customerLanguageList[index])}>
                    <Listbox.Button className={styles.customerLanguageSelect}>
                      {({ open }) => (
                        <>
                          <h2 className={styles.customerLanguageTitle}>{t('component.customerLanguage.title')}</h2>

                          <p
                            className={styles.customerLanguageLabel}>{invitationLanguage.id === 0 ? t('component.customerLanguage.optional') : invitationLanguage.label}</p>

                          <Icon
                            name={IconName.Wmark}
                            height={20}
                            width={20}
                            fill="none"
                            className={cn(styles.customerLanguageIcon, open && styles.open)}
                          />
                        </>
                      )}
                    </Listbox.Button>
                    <Listbox.Options className={styles.customerLanguageOptions}>
                      {customerLanguageList.map(({ id, label }, index) => (
                        <Listbox.Option
                          key={id}
                          value={index}
                          className={styles.customerLanguageOption}
                        >
                          {label}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Listbox>
                </div>
              )}

              <div className={styles.reusableToggle}>
                <h2 className={styles.customerLanguageTitle}>{t('component.reusableInvite.title')}</h2>
                <Toggle isToggled={reusable} setIsToggled={setReusable} />
              </div>
            </div>

            <InvitationGenerationForm
              invitationLanguage={invitationLanguage}
              userRelationType={userRelationType}
              setQRCode={setQRCode}
              setIsShowQRCode={setIsShowQRCode}
              reusable={reusable}
              refetchInvitationList={refetch}
            />
          </Tab.Panel>
          <Tab.Panel className={styles.navTabsPanel}>
            <InvitationsList list={preparedInvitations} />

            {(!contentLimit && !!preparedInvitations.length) && (
              <ContentLoader refNode={ref} />
            )}
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </Fragment>
  );
}

export default Invitations;
