import { useCallback } from 'react';
import { QueryKey, useQuery } from '@tanstack/react-query';

import { PaymentParticipantRole } from 'dtos';

import { queryClient } from 'shared/config/reactQuery';

const QUERY_KEY: QueryKey = ['payments', 'roles'];
export const STORAGE_KEY: string = 'roles';

export const usePersistedPaymentsFilterByRole = () => {
  const { data } = useQuery({
    queryKey: QUERY_KEY,
    queryFn: () => {
      const stringifyStatuses = localStorage.getItem(STORAGE_KEY);
      if (stringifyStatuses) {
        return JSON.parse(stringifyStatuses);
      } else {
        localStorage.setItem(STORAGE_KEY, JSON.stringify([]));
        return [];
      }
    },
  });

  const selectedRoles = data ?? [];

  const persistSelectedRoles = useCallback(
    (roles: PaymentParticipantRole[]): void => {
      queryClient.removeQueries({ queryKey: ['payments'], exact: true });
      localStorage.setItem(STORAGE_KEY, JSON.stringify(roles));
      queryClient.setQueryData(QUERY_KEY, () => roles);
    },
    []
  );

  const removeSelectedRoles = useCallback(
    (role: PaymentParticipantRole) => {
      const roles =
        queryClient.getQueryData<PaymentParticipantRole[]>(QUERY_KEY);
      if (roles) {
        const updatedRoles = roles.filter((r) => r !== role);
        persistSelectedRoles(updatedRoles);
      }
    },
    [persistSelectedRoles]
  );

  const clearSelectedRoles = useCallback(() => {
    localStorage.removeItem(STORAGE_KEY);
    persistSelectedRoles([]);
  }, [persistSelectedRoles]);

  return {
    selectedRoles,
    persistSelectedRoles,
    removeSelectedRoles,
    clearSelectedRoles,
  };
};
