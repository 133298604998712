import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useGetPaymentHistoryQuery } from 'services/payments';
import Button from 'components/Button/Button';
import CloseIcon from 'components/icons/Close';
import Content from 'components/Layout/Content';

import styles from './index.module.scss';


function PaymentChatHistory(): JSX.Element {
  const { t } = useTranslation();
  const { id } = useParams();
  const paymentId = Number(id);
  const navigate = useNavigate();

  const { data, isError } = useGetPaymentHistoryQuery(paymentId);

  const goTo = (): void => {
    navigate(-1);
  };

  return (
    <Content unsetTopPadding className={styles.content}>
      <Button onClick={goTo} className={styles.goBackButton}>
        <CloseIcon />
      </Button>

      {isError ? (
        <div className={styles.notFound}>{t('page.paymentChatHistory.notFound')}</div>
      ) : (
        <iframe title="chat" srcDoc={data} className={styles.iframe} />
      )}
    </Content>
  );
}

export default PaymentChatHistory;
