import isEmpty from 'lodash/isEmpty';
import { InputTarget } from '../types';
import {
  ExchangerInitializeRequest,
  UserDto,
  TargetField,
  Language,
  ExchangerFormData,
  PaymentFormDtoV5,
} from 'dtos';
import { getExchangeUnitDto } from 'dtos/dtoMapper';

type Options = {
  preferredFormData?: Partial<Record<string, PaymentFormDtoV5 & { currentTab: string; }>> | null;
  currentTab?: string | null;
  inputTarget?: InputTarget;
  language?: Language;
  preferredCity?: string | null;
};

/**
 * Return tuple with endpoint and parameter for exchanger init request
 *
 * @param providerId number
 * @param userId number
 * @return [endpoint params]
 * */

export const getExchangeInitQueryParams = (
  providerId: UserDto['id'],
  customerId: UserDto['id'],
  options?: Options,
): ExchangerInitializeRequest => {
  let query: ExchangerInitializeRequest = {
    customerId,
    providerId,
  };

  if (options?.preferredFormData && !isEmpty(options?.preferredFormData)) {
    const target = options?.inputTarget === 'sell' ? 'sell' : 'buy';

    query.preferredFormDataList = Object.entries(options.preferredFormData).reduce((acc, [tabName, data]) => {
      const { sellExchangeUnit, buyExchangeUnit, sellExchangeUnitAmount, buyExchangeUnitAmount } = data as PaymentFormDtoV5;
      return acc.concat({
        sellExchangeUnitDto: getExchangeUnitDto(sellExchangeUnit, false),
        buyExchangeUnitDto: getExchangeUnitDto(buyExchangeUnit, false),
        sellExchangeUnitAmount ,
        buyExchangeUnitAmount,

        currentTab: tabName,
        targetField: `${target.toUpperCase()}_CURRENCY` as TargetField,
      })
    }, [] as ExchangerFormData[]);
  }

  if (options?.language) {
    query.language = options.language;
  }

  if (options?.preferredCity) {
    query.preferredCity = options.preferredCity;
  }


  return query;
};
