import styles from './PaymentExchange.module.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import type { IntermediaryExchangeDto, IntermediaryExchangeDtoV4 } from 'dtos';

import { UserCard } from 'entities1/user';

import { AvatarSize } from 'shared/ui/Avatar/Avatar';

type PaymentExchangeProps = {
  exchangeInfo: IntermediaryExchangeDtoV4;
  isTransaction?: boolean;
};

function PaymentExchange({ exchangeInfo, isTransaction }: PaymentExchangeProps): JSX.Element {
  const { t } = useTranslation();

  if (!exchangeInfo) return <></>;

  const info = [
    {
      user: exchangeInfo.customer,
      method: exchangeInfo.sellExchangeUnit?.paymentMethod?.name || exchangeInfo.sellExchangeUnit?.city,
      amount: exchangeInfo.sellAmount,
      unit: exchangeInfo.sellExchangeUnit?.measureUnit,
      itemId: exchangeInfo.sellExchangeUnit?.code || exchangeInfo.sellExchangeUnit?.name,
      userType: 'requester',
      showExchangeInfo: !isTransaction,
    },
    {
      user: exchangeInfo.provider,
      method: !isTransaction && (exchangeInfo.buyExchangeUnit?.paymentMethod?.name || exchangeInfo.buyExchangeUnit?.city),
      amount: exchangeInfo.buyAmount,
      unit: exchangeInfo.buyExchangeUnit?.measureUnit,
      itemId: exchangeInfo.buyExchangeUnit?.code || exchangeInfo.buyExchangeUnit?.name,
      userType: 'provider',
      showExchangeInfo: true,
    },
  ];

  return (
    <div>
      <div className={styles.usersWrapper}>
        {info.map(({ user, method, amount, itemId, userType, unit, showExchangeInfo }) => (
          <div className={styles.userInfo} key={user?.id}>
            <UserCard
              user={user}
              hideRegisterDays
              hideBadge
              avatarSize={AvatarSize.Small}
              className={styles.userCard}
            />

            <p className={styles.text}>
              {showExchangeInfo ? t(`page.paymentInfo.${userType}.gives`) : t('page.paymentInfo.customer')}
            </p>
            {showExchangeInfo && (
              <div className={styles.info}>
                <b className={styles.amount}>
                  {amount}
                  {unit && ` ${unit}`} {itemId}
                </b>

                {method && <span className={styles.sticker}>{method}</span>}
              </div>
            )}
          </div>
        ))}
      </div>

      {(Number.isFinite(exchangeInfo.feeRate) || Number.isFinite(exchangeInfo.feeAmount)) && !isTransaction && (
        <div className={styles.feeWrapper}>
          {exchangeInfo.feeRate !== undefined && (
            <div className={styles.inline}>
              <p>{t('page.paymentInfo.providerFeeRate')}</p>

              <span>{exchangeInfo.feeRate * 100} %</span>
            </div>
          )}

          {exchangeInfo.feeAmount !== undefined && (
            <div className={styles.inline}>
              <p>{t('page.paymentInfo.providerFeeAmount')}</p>

              <span>
                {exchangeInfo.feeAmount} {exchangeInfo.feeCurrency?.code}
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export { PaymentExchange };
