import React, { useMemo } from 'react';

import type { BalanceResponseDto, UserDto } from 'dtos';
import { Avatar, AvatarSize } from 'shared/ui/Avatar/Avatar';
import SkeletonWithWrapper from 'shared/ui/SkeletonWithWrapper';
import { getUserName, getUserPhoto } from 'utils';
import BalanceCard from 'components/BalanceCard';

import styles from './UserWithBalanceInfo.module.scss';

export interface UserWithBalanceInfoProps {
  user?: UserDto;
  balance?: BalanceResponseDto;
  loading?: boolean;
}

function UserWithBalanceInfo(props: UserWithBalanceInfoProps): JSX.Element | null {
  const { user, balance, loading } = props
  const userName = useMemo(() => (user ? getUserName(user) : ''), [user]);
  const userPhoto = useMemo(() => user && getUserPhoto(user), [user]);
  const userAvatarSize = AvatarSize.Payment;
  const balanceAvatarSize = AvatarSize.SuperSmall

  if (loading) {
    return <SkeletonWithWrapper height={64} />;
  }

  if (!user) {
    return null;
  }

  return (
    <div className={styles.container}>
      <Avatar username={userName} profilePictureUrl={userPhoto} size={userAvatarSize} />

      <div className={styles.info}>
        <span>{userName}</span>
        {balance && (
          <BalanceCard
            balance={balance}
            titleClassName={styles.balanceName}
            pictureSize={balanceAvatarSize}
          />
        )}
      </div>
    </div>
  );
}

export { UserWithBalanceInfo };
