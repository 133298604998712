import styles from './InputWithCharUnderline.module.scss';

import React from 'react';

import Input from 'components/Input/Input';
import type { InputProps } from 'components/Input/InputTypes';

function InputWithCharUnderline({ maxLength = 5, ...props }: InputProps) {
  return (
    <Input
      maxLength={maxLength}
      className={styles.inputWithCharUnderline}
      {...props}
    />
  );
}

export default InputWithCharUnderline;
