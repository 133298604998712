import every from 'lodash/every';
import find from 'lodash/find';
import isObject from 'lodash/isObject';
import values from 'lodash/values';
import isNil from 'lodash/isNil';
import has from 'lodash/has';

import {
  CurrencyInformationResponseDto,
  CurrencyInformationResponseDtoV3,
  PaymentFormDto,
  PaymentMethodsResponseDtoV3,
  PaymentMethodType,
} from 'dtos';
import { ExchangerLocalStorageEntry, ExchangerScope, UserId } from '../types';

export { getExchangeInitQueryParams } from './getExchangeInitQueryParams';
// export { migrateExchangerPaymentFormInLS } from './exchangerPaymentFormMigration';

export const exchangerQueryKey = (
  providerId: UserId = null,
  userId: UserId = null
) => ['exchangerWidget', `providerId-${providerId}`, `userId-${userId}`];

export const isBuyScope = (scope: ExchangerScope): boolean =>
  scope === ExchangerScope.Buy;

export const getBuyOrSellExchangeUnitProp = (
  scope: ExchangerScope
): 'buyExchangeUnit' | 'sellExchangeUnit' => {
  return scope === ExchangerScope.Buy ? 'buyExchangeUnit' : 'sellExchangeUnit';
};

const paymentMethodsPriority: string[] = [
  PaymentMethodType.Cash,
  PaymentMethodType.Transfer,
  PaymentMethodType.Goods,
];

export const getFirstPaymentMethodType = (
  methods: CurrencyInformationResponseDto['paymentMethods']
) => {
  return Object.keys(methods)
    .sort((a, b) => {
      const aIdx = paymentMethodsPriority.indexOf(a);
      const bIdx = paymentMethodsPriority.indexOf(b);
      if (aIdx > bIdx) return 1;
      if (aIdx < bIdx) return -1;
      return 0;
    })
    .at(0) as PaymentMethodType;
};

export const findPaymentMethodByType = (
  paymentMethods: PaymentMethodsResponseDtoV3[],
  type: PaymentMethodsResponseDtoV3['paymentMethodType']
): PaymentMethodsResponseDtoV3 | undefined =>
  find(paymentMethods, (m) => m.paymentMethodType === type);

export const findCurrencyPaymentMethodInfoByTypeAndName = (
  paymentMethods: PaymentMethodsResponseDtoV3[],
  type: PaymentMethodsResponseDtoV3['paymentMethodType'],
  name: string
): string | undefined =>
  findPaymentMethodByType(paymentMethods, type)?.methods.find(
    (m) => m === name
  );

export const getFirstPaymentMethod = (
  paymentMethods: CurrencyInformationResponseDtoV3['paymentMethods']
): string => {
  return paymentMethods[0].methods[0];
};

export const getPaymentFormLSKey = (
  providerId: UserId,
  userId: UserId
): string => {
  return `paymentForm_v5-${providerId}-${userId}`;
};

export const getFavouriteCurrenciesPaymentsLSKey = (
  providerId: UserId,
  userId: UserId
) => `favourite_currencies_payments_v2_${providerId}_${providerId}`

export const isSatisfiesPaymentFormDto = (
  dto: unknown
): dto is PaymentFormDto => {
  if (!(dto instanceof Object)) return false;
  const hasSellCurrencyCode: boolean =
    'sellCurrency' in dto &&
    dto.sellCurrency instanceof Object &&
    'code' in dto.sellCurrency &&
    typeof dto.sellCurrency.code === 'string';
  const hasBuyCurrencyAmount: boolean =
    'buyCurrencyAmount' in dto && typeof dto.buyCurrencyAmount === 'number';
  return hasSellCurrencyCode && hasBuyCurrencyAmount;
};

export const isSatisfiesPaymentFormMap = (
  obj: unknown
): obj is Record<string, PaymentFormDto | undefined> => {
  if (isNil(obj)) return false;
  return every(values(obj), isSatisfiesPaymentFormDto);
};

export const isValidLocalStorageEntry = (
  obj: unknown
): obj is ExchangerLocalStorageEntry => {
  if (!isObject(obj)) {
    return false;
  }
  return (
    has(obj, 'activeOption') &&
    has(obj, 'formDataByOption')
    // isSatisfiesPaymentFormMap(
    //   (obj as ExchangerLocalStorageEntry)?.formDataByOption
    // )
  );
};
