import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import PaymentsHistoryWidget from 'widgets/PaymentsHistoryWidget';

import { Layout } from 'components/Layout/Layout';
import PaymentsFilterTags from 'components/PaymentsFilterTags';

import PaymentsFilters from 'widgets/PaymentsFilters';

import { useGetInfinitePayments } from 'entities1/payments/model/services/useGetInfinitePayments';

import { debounce } from 'utils';

const PaymentsPage = () => {
  const { t } = useTranslation();

  const { data, fetchNextPage, isLoading, isFetchingNextPage } =
    useGetInfinitePayments({
      queryKey: ['payments'],
      filterable: true,
    });

  const debouncedGetPayments = useCallback(
    debounce(() => {
      return fetchNextPage && fetchNextPage({ pageParam: 1 });
    }, 500),
    [fetchNextPage]
  );

  return (
    <Layout>
      <Layout.TopBar>
        <Layout.Back />

        <Layout.Title>{t('page.payments.title')}</Layout.Title>

        <PaymentsFilters onGetPayments={debouncedGetPayments} />

        <PaymentsFilterTags onGetPayments={debouncedGetPayments} />
      </Layout.TopBar>

      <Layout.Content>
        <PaymentsHistoryWidget
          data={data}
          isLoading={isLoading}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
        />
      </Layout.Content>
    </Layout>
  );
};

export default PaymentsPage;
