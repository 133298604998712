import React, { useCallback, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import Icon from 'components/Icon/Icon';
import { IconName } from 'components/Icon/IconList';

import { PaymentDetailsResponseDto } from 'dtos';
import AccountPaymentDetailsService from 'api/account-payment-details';
import { queryClient } from 'shared/config/reactQuery';

import { hashCode } from 'utils';

import styles from './Details.module.scss';
import cn from 'classnames';
import SelectArrow from 'components/icons/SelectArrow';
import Modal from 'components/Modal/Modal';
import truncate from 'lodash/truncate';

type DetailsProps = {
  details: PaymentDetailsResponseDto[];
  onSelect: (text: string) => void;
  method: string;
  code: string;
};

export default function Details(props: DetailsProps): JSX.Element | null {
  const { onSelect, details, method, code } = props;
  const { t } = useTranslation();
  const [style, setStyle] = useState<any>({});
  const [visible, setVisible] = useState<boolean>(false);
  const ref = useRef<null | HTMLUListElement>(null);

  const deleteDetails = useCallback(
    async (details: string): Promise<void> => {
      toast.promise(
        AccountPaymentDetailsService.deleteDetails({ method, code, details }),
        {
          loading: t('status.saving'),
          success: () => {
            queryClient.setQueryData<PaymentDetailsResponseDto[]>(
              ['exchanger', 'details', { method, code }],
              (prev) => {
                if (!prev) return;

                return prev.filter((d) => d.details !== details);
              }
            );
            return <b>{t('status.settingsSaved')}</b>;
          },
          error: (error) => {
            return <b>{error.message}</b>;
          },
        }
      );
    },
    [t, method, code]
  );

  if (Array.isArray(details) && details?.length === 0) {
    return null;
  }

  const handleIconClick = (text: string) => () => {
    deleteDetails(text);
  };

  const currentDetails = details.map((detail) => ({
    ...detail,
    id: hashCode(detail.details),
    handleIconClick,
  }));

  const handleSelect = (details: string) => () => {
    onSelect(details);
    changeVisible();
  };

  const MAX_SHOWN_VALUES: number = 4;

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!e.target) {
      return;
    }
    const position = (e.target as HTMLDivElement).getBoundingClientRect();
    setStyle({ top: position.top + position.height });
    changeVisible();
  };
  const changeVisible = () => {
    setVisible((state) => !state);
  };
  const scrollable = currentDetails.length > MAX_SHOWN_VALUES;

  return (
    <>
      <div className={styles.btn} onClick={handleClick}>
        <SelectArrow className={styles.btn} />
      </div>
      <Modal
        visible={visible}
        className={styles.modalContent}
        onClose={changeVisible}
        style={style}
        modalClassName={styles.modal}
      >
        <div className={cn(styles.select)}>
          <ul
            className={cn(styles.options, scrollable && styles.scrollable)}
            ref={ref}
          >
            {currentDetails.map((v) => (
                <li
                  key={String(v.details)}
                  className={styles.option}
                >
                  <Detail details={v.details} isPrimary={v.isPrimary} handleIconClick={v.handleIconClick}
                    onSelect={handleSelect}
                  />
                </li>
              ))}
          </ul>
        </div>
      </Modal>
    </>

  );
}

type DetailProp = PaymentDetailsResponseDto & {
  handleIconClick: (text: string) => () => void;
  onSelect: (text: string) => () => void;
};

export function Detail(props: DetailProp): JSX.Element {

  return (
    <>
      <div className={styles.container} onClick={props.onSelect(props.details)}>
        <span className={styles.text}>{truncate(props.details, { length: 30, omission: '…' })}</span>
      </div>
      <div className={styles.iconWrapper}>
        <Icon
          name={IconName.Close2}
          className={styles.icon}
          width={12}
          height={12}
          viewBox="0 0 10 10"
          onClick={props.handleIconClick(props.details)}
        />
      </div>
    </>
  );
}
