import React, { ReactNode } from 'react';
import cx from 'classnames';

import styles from './Table.module.scss';

type TableProps = {
  children: ReactNode;
  className: string;
};

export const Table = ({ children, className }: TableProps) => {
  return <div className={cx(styles.table, className)}>{children}</div>;
};
