import styles from './GoBackNewButton.module.scss';

import React from 'react';
import { To, useNavigate } from 'react-router-dom';

import ArrowLeftNew from "components/icons/ArrowLeftNew";
import cn from "classnames";

const PREVIOUS_PATH: number = -1;

export interface GoBackButtonProps {
  to?: To;
  className?: string;
}

function GoBackNewButton({ to, className }: GoBackButtonProps): JSX.Element {
  const navigate = useNavigate();

  const goTo = (): void => {
    to ? navigate(to) : navigate(PREVIOUS_PATH);
  };

  return (
    <button
      type="button"
      onClick={goTo}
      className={cn(styles.backButton, className)}
    >
      <ArrowLeftNew/>
    </button>
  );
}

export default GoBackNewButton;
