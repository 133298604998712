import { BaseIconProps } from '../types';

const Icon = (props: BaseIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="currentColor"
    {...props}
  >
    <path d="M18.631 16.348c-2.125-.96-2.206-5.08-2.208-5.184V8.4a6.433 6.433 0 0 0-3.935-5.921A2.49 2.49 0 0 0 10 0a2.49 2.49 0 0 0-2.488 2.479A6.433 6.433 0 0 0 3.577 8.4v2.764c-.002.104-.082 4.224-2.208 5.184a.564.564 0 0 0 .232 1.078H6.99a3.17 3.17 0 0 0 .838 1.63A2.995 2.995 0 0 0 10 20c.818 0 1.59-.335 2.173-.943a3.17 3.17 0 0 0 .837-1.63H18.4a.564.564 0 0 0 .232-1.079zm-2.8-1.973c.224.677.54 1.357.989 1.923H3.18c.449-.566.765-1.246.989-1.923H15.83zM10 1.128c.62 0 1.143.416 1.306.983a6.427 6.427 0 0 0-2.612 0A1.362 1.362 0 0 1 10 1.128zm-5.295 10.04V8.4A5.301 5.301 0 0 1 10 3.105 5.301 5.301 0 0 1 15.295 8.4v2.772c0 .085.014.977.239 2.074H4.466a11.78 11.78 0 0 0 .239-2.078zM10 18.872c-.86 0-1.612-.62-1.853-1.446h3.706c-.24.825-.992 1.446-1.853 1.446z" />
    <path d="M10 3.897a4.405 4.405 0 0 0-4.4 4.4.564.564 0 0 0 1.127 0A3.276 3.276 0 0 1 10 5.025a.564.564 0 1 0 0-1.128z" />
  </svg>
);

export default Icon;
