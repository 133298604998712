import styles from './index.module.scss';

import React, { useCallback, useState } from 'react';
import type { UseQueryResult } from '@tanstack/react-query';

import CurrencyInput from 'components/Input/CurrencyInput/CurrencyInput';
import type { InputProps } from 'components/Input/InputTypes';

import { RangeValue } from 'entities1/feeRate/model/constants';

import type { FeeRateRangeDto } from 'dtos';

type RateInputProps = {
  rate: FeeRateRangeDto;
  prefix?: string;
  suffix?: string;
  ratesQueryResult: UseQueryResult<FeeRateRangeDto[]>;
  onRateUpdate: (feeRateRangeDto: FeeRateRangeDto) => void;
} & InputProps;

enum RateName {
  FeeRate = 'feeRate',
  LowerBound = 'lowerBound',
}

const FRACTION_DIGITS: number = 2;

function RateInput({
  name,
  rate,
  ratesQueryResult,
  suffix,
  disabled,
  onRateUpdate,
}: RateInputProps): JSX.Element {
  const fixedLowerBound: string = rate.lowerBound.toFixed(FRACTION_DIGITS);
  const [lowerBound, setLowerBound] =
    useState<HTMLInputElement['value']>(fixedLowerBound);

  const fixedFeeRate: string = rate.feeRate.toFixed(FRACTION_DIGITS);
  const [feeRate, setFeeRate] =
    useState<HTMLInputElement['value']>(fixedFeeRate);

  const { data: table, isSuccess: hasTable } = ratesQueryResult;

  const updateFeeRange = useCallback(() => {
    const hasNoDecimals: boolean = feeRate.split(',')[1] === '';

    const feeRateNumber =
      name === RateName.FeeRate
        ? hasNoDecimals
          ? NaN
          : Number(feeRate.replace(',', '.'))
        : rate.feeRate;

    const lowerBoundNumber =
      name === RateName.LowerBound ? Number(lowerBound) : rate.lowerBound;

    if (hasTable) {
      const firstFeeRange = table[0];
      const hasCorrectFeeRange =
        isNaN(feeRateNumber) ||
        isNaN(lowerBoundNumber) ||
        (lowerBoundNumber === 0 && firstFeeRange.id !== rate.id);

      const isLowerBoundAlreadyExists =
        table
          .map((rate) => rate.lowerBound)
          .filter((lB) => lB === lowerBoundNumber).length > 0 &&
        rate.lowerBound !== lowerBoundNumber;

      if (hasCorrectFeeRange || isLowerBoundAlreadyExists) return;
    }

    onRateUpdate({
      id: rate.id,
      lowerBound: lowerBoundNumber,
      feeRate: feeRateNumber,
    });
  }, [
    name,
    onRateUpdate,
    rate.feeRate,
    rate.id,
    rate.lowerBound,
    feeRate,
    lowerBound,
    hasTable,
    table,
  ]);

  const changeLowerBoundOrFeeRate = (value?: string) => {
    const defaultValue: string = '';

    if (name === RateName.LowerBound) {
      setLowerBound(value || defaultValue);
      updateFeeRange();
      return;
    }

    if (Number(value) > RangeValue.max || Number(value) < RangeValue.min) {
      return;
    }

    setFeeRate(value || defaultValue);
    updateFeeRange();
  };

  const lowerBoundOrFeeRate: HTMLInputElement['value'] =
    name === RateName.LowerBound ? lowerBound : feeRate;

  return (
    <CurrencyInput
      name={name}
      value={lowerBoundOrFeeRate}
      onValueChange={changeLowerBoundOrFeeRate}
      inputClassName={styles.input}
      className={styles.inputWrapper}
      suffix={suffix}
      disabled={disabled}
      decimalsLimit={2}
    />
  );
}

export default RateInput;
