import { useState, useCallback } from 'react';
import cn from 'classnames';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import Button, { Variant } from 'components/Button/Button';
import { IconName } from 'components/Icon/IconList';
import Icon from 'components/Icon/Icon';
import { INSTALL_OLD } from 'pages/paths';
import useDeviceInfo from 'shared/hooks/useDeviceInfo';
import { Layout } from 'components/Layout/Layout';
import TelegramBotAuth from 'components/TelegramBotAuth';

import Tag from 'components/icons/Tag';
import ArrowRight from 'components/icons/ArrowRight';

import styles from './Main.module.scss';
import getRandomUUID from 'utils/getRandomUUID';

const UUID_KEY = getRandomUUID();
const successHandler = () => {
  setTimeout(() => {
    window.location.replace('/');
  }, 100);
};

const InstallInstruction = ({ code }: { code: string | null }) => {
  const navigate = useNavigate();
  const [skip, skipInstall] = useState(false);
  const [isCopied, setCopied] = useState(false);
  const { t } = useTranslation();
  const { isDesktop } = useDeviceInfo();

  const copyInviteCode = useCallback(
    () =>
      navigator.clipboard.writeText(code as string).then(() => {
        setCopied(true);
      }),
    [code]
  );

  if (skip) {
    return <TelegramBotAuth keyId={UUID_KEY} onSuccess={successHandler}/>;
  }

  if (code === null || (typeof code === 'string' && code.length !== 5)) {
    return isDesktop ? (
      <TelegramBotAuth keyId={UUID_KEY} onSuccess={successHandler} />
    ) : (
      <div className={styles.button}>
        <Button
          className={styles.installButton}
          variant={Variant.Exchanger}
          onClick={() => navigate(INSTALL_OLD)}
        >
          {t('install.installPWA')}
          <Icon name={IconName.Forward} width={24} height={24} />
        </Button>
        <p onClick={() => skipInstall(true)} className={styles.skip}>
          {t('install.skip')}
        </p>
      </div>
    );
  }

  return (
    <div className={styles.buttonContainer}>
      <ol className={styles.list}>
        {!isCopied && (
          <li
            onClick={copyInviteCode}
            className={cn(styles.active, styles.listItem)}
          >
            <strong className={styles.code} />
            <br />
            {t('page.main.step1')}
          </li>
        )}
        {isCopied && (
          <li className={styles.listItem}>
            <strong className={styles.code}>{code}</strong> <br />
            <span className={cn({ [styles.active]: isCopied && isDesktop })}>
              {t('page.main.invitationCodeCopied')}
            </span>
          </li>
        )}
        {isDesktop && (
          <li className={styles.listItem}>{t('page.main.step2Desktop')}</li>
        )}
        {!isDesktop && (
          <li
            className={cn(styles.listItem, { [styles.active]: isCopied })}
            onClick={() => navigate(INSTALL_OLD)}
          >
            {t('page.main.step2Touch')}
          </li>
        )}
        <li className={styles.listItem}>
          <Trans
            i18nKey="page.main.step3"
            components={{
              arrow: <ArrowRight className={styles.arrowIcon} />,
              tag: <Tag className={styles.tagIcon} />,
            }}
          />
        </li>
      </ol>

      {isDesktop && (
        <TelegramBotAuth keyId={UUID_KEY} className={styles.loginButton} onSuccess={successHandler} />
      )}
    </div>
  );
};

const MainPage = () => {
  const { isPWAInstalled, isDesktop } = useDeviceInfo();
  const [urlSearchParams] = useSearchParams();
  const shareToken = urlSearchParams.get('share');

  // @ts-ignore
  if (typeof window === 'undefined' || window.__ONLY_BUTTON__) {
    return <TelegramBotAuth keyId={UUID_KEY} onSuccess={successHandler} />;
  }

  return (
    <Layout hasMenu={false}>
      <section className={styles.container}>
        <video
          className={styles.hello}
          src="/hello.mp4"
          playsInline
          autoPlay
          muted
        />
        <h1 className={styles.heading}>
          Automate deals <br />
          across your <br />
          web of trust
        </h1>
        <img
          className={styles.logo}
          src="/logo180.png"
          alt="Connect App Logo"
        />
        <p className={styles.slogan}>Invitation-only financial network</p>
        {(isPWAInstalled || isDesktop) && !shareToken ? (
          <TelegramBotAuth keyId={UUID_KEY} onSuccess={successHandler} />
        ) : (
          <InstallInstruction code={shareToken} />
        )}
      </section>
    </Layout>
  );
};

export default MainPage;
