import { BaseIconProps } from '../types';

const List = (props: BaseIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="47"
    height="47"
    viewBox="0 0 47 47"
    fill="currentColor"
    {...props}
  >
    <circle cx="11.5" cy="14.5" r="3.5" />
    <path d="M16 13h23v3H16z" />
    <circle cx="11.5" cy="23.5" r="3.5" />
    <path d="M16 22h23v3H16z" />
    <circle cx="11.5" cy="32.5" r="3.5" />
    <path d="M16 31h23v3H16z" />
  </svg>
);

export default List;
