import styles from './BalanceTransaction.module.scss';

import React from 'react';
import truncate from 'lodash/truncate';

import Amount from 'components/Amount';
import { Avatar, AvatarSize } from 'shared/ui/Avatar/Avatar';
import { formatDate } from 'utils';
import { useTranslation } from 'react-i18next';
import { TransactionType } from 'dtos';
import cn from 'classnames';

export interface BalanceTransactionProps {
  balanceName?: string;
  balancePhotoUrl?: string;
  bucketName: string;
  transactionComment: string;
  transactionValue: number;
  transactionCreatedAt: string;
  authorName?: string;
  authorId: number;
  id: number;
  type: TransactionType;
  canBeCanceled: boolean;
  contactId?: number;
  balanceId?: number;
}

function BalanceTransaction({
  balanceName,
  balancePhotoUrl,
  bucketName,
  transactionComment,
  transactionValue,
  transactionCreatedAt,
  authorName,
  type,
}: BalanceTransactionProps): JSX.Element {
  const { t } = useTranslation();
  const formattedDate: string = formatDate(new Date(transactionCreatedAt));
  const isCanceled = type === TransactionType.Canceled

  if (type === TransactionType.Reversal) {
    return (<></>)
  }

  return (
    <div className={cn(styles.card, isCanceled && styles.canceledCard)}>
      <Avatar
        username={String(balanceName)}
        profilePictureUrl={balancePhotoUrl}
        size={AvatarSize.Small}
      />
      <div className={styles.info}>
        <p className={cn(styles.comment, isCanceled && styles.canceled)}>{truncate(transactionComment, { length: 20, omission: '…' })}</p>
        <div className={styles.additionalInfo}>{balanceName}</div>
        <div className={styles.additionalInfo}>{formattedDate}</div>
      </div>
      <div className={styles.amountInfo}>
        <div className={styles.amount}>
          <div className={cn(isCanceled && styles.canceled)}>
            <Amount value={transactionValue} sign colored={!isCanceled} />
            {bucketName.substring(0, 10)}
          </div>
          <p className={styles.authorName}>
            {t('component.transaction.added', { name: authorName })}
          </p>
        </div>
      </div>
    </div>
  );
}

export default React.memo(BalanceTransaction);
