import React, { ReactNode } from 'react';

import { SkeletonTheme } from 'react-loading-skeleton';

const SkeletonProvider = ({ children }: { children: ReactNode }) => (
  <SkeletonTheme baseColor="#202020" highlightColor="#444">
    {children}
  </SkeletonTheme>
);

export default SkeletonProvider;
