import React, { Fragment, PropsWithChildren } from 'react';
import cn from 'classnames';
import { Tab as TabUI } from '@headlessui/react';

import styles from './Tab.module.scss';

type TabProps = PropsWithChildren<{}>;

export const StyledTab = ({ children }: TabProps) => {
  return (
    <TabUI as={Fragment}>
      {({ selected }) => (
        <div className={cn(styles.tab, { [styles.selected]: selected })}>
          {children}
        </div>
      )}
    </TabUI>
  );
};
