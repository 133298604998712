import {FC, useEffect} from 'react';

import CrossExchange from 'components/CrossExchange/CrossExchange';
import { ExchangerWidgetProps } from '../../ExchangerWidget';
import { useExchangerStore } from '../../store';
import SkeletonWithWrapper from 'shared/ui/SkeletonWithWrapper';
import { ExchangeRateWidgetDto } from "dtos";

import styles from './CrossExchangeInfo.module.scss';

export interface CrossExchangeInfoProps  extends ExchangerWidgetProps {
  updateCrossExchangeRates: (rates: ExchangeRateWidgetDto[]) => void;
}

export const CrossExchangeInfo: FC<CrossExchangeInfoProps> = (props) => {
  const { providerId, userId, customOfferMode, updateCrossExchangeRates, crossExchangeRates } = props;
  const ratesForCalc = customOfferMode ? crossExchangeRates : undefined
  const { exchangeCalculationResponse, activeOption, isInitialization, inputTarget } =
    useExchangerStore(providerId, userId, undefined, ratesForCalc);

  useEffect(() => {
    if (activeOption !== 'Currency') {
      updateCrossExchangeRates([])
    }
  }, [activeOption]);

  if (isInitialization) {
    return <SkeletonWithWrapper containerClassName={styles.skeleton} />;
  }

  if (
    !exchangeCalculationResponse ||
    activeOption !== 'Currency'
  ) {
    return null;
  }

  return (
    <CrossExchange
      exchanges={exchangeCalculationResponse?.exchangeRateWidgets}
      exchange={exchangeCalculationResponse?.crossExchangeWidget}
      inputTarget={inputTarget}
      customOfferMode={customOfferMode}
      updateCrossExchangeRates={updateCrossExchangeRates}
    />
  );
};
