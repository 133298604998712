export enum RangeValue {
  max = 99,
  min = -99,
}

export const FEE_RATE_INCREMENT_VALUE: number = 5000;

export type FEE_RANGE_ENDPOINT = '/fees';
export type FEE_RANGE_DEFAULTS_ENDPOINT = '/fees/default';

export const FEE_RANGE_ENDPOINT: FEE_RANGE_ENDPOINT = '/fees';
export const FEE_RANGE_DEFAULTS_ENDPOINT: FEE_RANGE_DEFAULTS_ENDPOINT =
  '/fees/default';
