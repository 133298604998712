import { FC } from 'react';

import { UserDto } from 'dtos';

import { Avatar } from 'shared/ui/Avatar/Avatar';

export interface ProviderSlideProps extends UserDto {}

export const ProviderSlide: FC<ProviderSlideProps> = (props) => {
  return <Avatar {...props} />;
};
