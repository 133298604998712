import type { NavLinkProps } from 'react-router-dom';
import type { TFunction } from 'i18next';

import { FRIENDS, EXCHANGE_MAIN, PROFILE } from 'pages/paths';

import { IconName } from 'components/Icon/IconList';
import type { IconProps } from 'components/Icon/Icon';
import { FOCUS_TASK } from 'const';

export type Icon = Pick<IconProps, 'name' | 'width' | 'height'>;

export interface NavButton {
  path: NavLinkProps['to'];
  title: string;
  icon?: Icon;
  onClick?: () => void;
}

export const composeButtons = (isGuest: boolean, t: TFunction): NavButton[] => {
  const providerButtons: NavButton[] = [
    {
      path: EXCHANGE_MAIN,
      title: t('menu.exchange'),
      icon: {
        name: IconName.ConnectLogo,
        width: 27,
        height: 18,
      },
    },
  ];

  const focusTask = localStorage.getItem(FOCUS_TASK);

  return [
    ...(isGuest ? [] : providerButtons),
    {
      path: focusTask ? `/task/${focusTask}` : '/task',
      title: t('menu.task'),
      icon: {
        name: IconName.Ticket,
        width: 27,
        height: 18,
      },
    },
    {
      path: FRIENDS,
      title: t('menu.friends'),
      icon: {
        name: IconName.Contacts,
        width: 27,
        height: 18,
      },
    },
    {
      path: PROFILE,
      title: t('menu.profile'),
    },
  ];
};
