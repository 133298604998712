import { useEffect } from 'react';
import { NavigateOptions, To, useNavigate } from 'react-router-dom';

export const useRedirect = (
  condition: boolean,
  to: To,
  options?: NavigateOptions
): void => {
  const navigate = useNavigate();

  useEffect(() => {
    if (condition) {
      navigate(to, options);
    }
  }, [condition, navigate, to, options]);
};
