import { useTranslation } from 'react-i18next';

import Modal from 'components/Modal/Modal';
import Button, { Variant } from 'components/Button/Button';

import { useConfigurationModal } from 'entities1/configurationModal/services/useConfigurationModal';

interface IConfigurationModalProps {
  onChange: () => void;
  onRemove: () => void;
}

function ConfigurationModal({
  onChange,
  onRemove,
}: IConfigurationModalProps): JSX.Element | null {
  const { t } = useTranslation();
  const { state, closeModal } = useConfigurationModal();

  if (!state) {
    return null;
  }

  return (
    <Modal visible={state.visible} onClose={closeModal}>
      <Button
        variant={Variant.Outlined}
        onClick={onChange}
      >
        {t('button.edit')}
      </Button>
      <Button
        variant={Variant.Outlined}
        onClick={onRemove}
      >
        {t('button.delete')}
      </Button>
    </Modal>
  );
}

export default ConfigurationModal;
