import { ValueOf } from 'shared/types/types';
import {
  CurrencyDto,
  CurrencyInfoValuableType,
  PaymentFormDtoV5,
  PaymentMethodDto,
  UserDto,
} from 'dtos';

export type UserId = UserDto['id'] | null | undefined;

export const ExchangerScope = {
  Sell: 'sell',
  Buy: 'buy',
} as const;
export type ExchangerScope = ValueOf<typeof ExchangerScope>;

export type CurrencyMethodType = PaymentMethodDto['type'];

export interface ExchangerLocalStorageEntry {
  activeOption: CurrencyInfoValuableType;
  formDataByOption: Partial<Record<string, PaymentFormDtoV5 & {currentTab: string}>>;
}

export type InputTarget = 'buy' | 'sell';

export type FavouriteCurrencyPayments = Record<CurrencyDto['code'], PaymentMethodDto>;
