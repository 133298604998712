import styles from './Suggest.module.scss';

import React, { ChangeEventHandler, useCallback, useRef, useState } from 'react';
import cn from 'classnames';
import { Combobox } from '@headlessui/react';

import { useClickOutside } from 'shared/hooks/useClickOutside';

import { debounce } from 'utils';

const EMPTY: string = '';

export interface SuggestProps {
  value: string;
  values: string[];
  className?: string;
  inputClassName?: string;
  onChange?: (v: string) => void;
  placeholder?: string;
  disabled?: boolean;
}

function Suggest({
  value,
  values,
  className,
  inputClassName,
  onChange,
  placeholder,
  disabled,
}: SuggestProps): JSX.Element {
  const suggestRef = useRef<HTMLElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  useClickOutside({ ref: suggestRef, cb: () => setOpen(false) });

  const [selected, setSelected] = useState(value);
  const changeSelected = (v: string) => {
    setSelected(v);
    onChange && onChange(v);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.key === 'Done') {
      event.preventDefault();
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const debouncedChangeSelected = useCallback(
    debounce((v: string) => changeSelected(v)),
    [changeSelected]
  );

  const [query, setQuery] = useState(EMPTY);
  const changeQuery: ChangeEventHandler<HTMLInputElement> = (e): void => {
    const value = e.target.value;
    setQuery(value);
    debouncedChangeSelected(value);
  };

  const filteredValues: string[] =
    query === EMPTY
      ? values
      : values.filter((v) => v.toLowerCase().includes(query.toLowerCase()));

  const valueExists: boolean = values.some((v) => v === query);

  return (
    <Combobox
      ref={suggestRef}
      as="div"
      value={selected}
      onChange={changeSelected}
      className={cn(styles.wrap, className)}
      disabled={disabled}
    >
      <Combobox.Input
        onChange={changeQuery}
        displayValue={(v: string): string => v}
        placeholder={placeholder}
        autoComplete="off"
        onClick={() => setOpen(true)}
        onKeyDown={handleKeyPress}
        className={cn(styles.input, inputClassName)}
      />

      {open && (
        <Combobox.Options static className={styles.options}>
          {filteredValues.map((v) => (
            <Combobox.Option
              key={v}
              value={v}
              onClick={() => setOpen(false)}
              className={styles.option}
            >
              {v}
            </Combobox.Option>
          ))}
          {query.length > 0 && !valueExists && (
            <Combobox.Option
              value={query}
              onClick={() => setOpen(false)}
              className={styles.option}
            >
              + new "{query}"
            </Combobox.Option>
          )}
        </Combobox.Options>
      )}
    </Combobox>
  );
}

export default Suggest;
