import { useTranslation } from 'react-i18next';
import Button, { Variant } from 'components/Button/Button';

import styles from './AllTask.module.scss';
import { useCallback } from 'react';
import { TASKS } from 'pages/paths';
import { useNavigate } from 'react-router-dom';

type AllTaskProps = {
  isPrioritizing?: boolean
};

const AllTask = (props: AllTaskProps) => {
  const { isPrioritizing } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleClick = useCallback(() => {
    navigate(TASKS)
  }, [navigate, TASKS])

  return (
    <>
      <Button variant={Variant.Gray} className={styles.button} onClick={handleClick} disabled={isPrioritizing}>
        {t('page.task.nextTask')}
      </Button>
    </>
  );
};

export default AllTask;
