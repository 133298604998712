import { useInfiniteQuery } from '@tanstack/react-query';

import type { FriendsResponse } from 'api/balances/types';
import { PageUserDto } from 'dtos';

import { useLazyGetCustomersQuery, INITIAL_PAGE, INITIAL_LIMIT } from 'services/contacts';

export const useGetInfiniteCustomers = (searchString?: string) => {
  const [getCustomers] = useLazyGetCustomersQuery();
  return useInfiniteQuery({
    queryKey: ['customers', searchString],
    queryFn: async ({ pageParam = INITIAL_PAGE }) => {
      const res = await getCustomers({
        page: pageParam,
        limit: INITIAL_LIMIT,
        searchString
      }).unwrap() as PageUserDto;
      return { pageParam, ...res };
    },
    getNextPageParam: (lastPage) => {
      return lastPage.last
        ? undefined
        : (lastPage.pageParam || INITIAL_PAGE) + 1;
    },
    onSuccess: (data) => {
      const pages = data.pages;
      if (!Array.isArray(pages)) return;
    },
    keepPreviousData: true,
    staleTime: 1000,
  });
};
