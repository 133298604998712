import styles from './Status.module.scss';

import React, { HTMLAttributes } from 'react';

import Icon from 'components/Icon/Icon';
import { IconName } from 'components/Icon/IconList';

import { PaymentParticipantRole, PaymentStatus, PaymentType } from 'dtos';

import { LABEL } from 'components/Filter/label';

export interface StatusProps extends HTMLAttributes<HTMLLIElement> {
  title?: PaymentStatus | PaymentParticipantRole | PaymentType;
  onRemoveTag?: () => void;
}

function Tag({
  title,
  onRemoveTag,
  children,
  ...statusProps
}: StatusProps): JSX.Element {
  return (
    <li
      className={styles.status}
      onClick={title ? undefined : onRemoveTag}
      {...statusProps}
    >
      {title ? LABEL[title] : children}

      {title ? (
        <div className={styles.remove}>
          <Icon
            name={IconName.Close2}
            height={10}
            width={10}
            onClick={onRemoveTag}
            className={styles.icon}
          />
        </div>
      ) : null}
    </li>
  );
}

export default Tag;
