import { UserDto } from 'dtos';
import styles from './TaskParticipant.module.scss';
import TelegramIcon from 'components/icons/Telegram';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import checkIsWebApp from 'utils/checkIsWebApp';
import { getUserName, getUserPhoto } from 'utils';

interface TaskParticipantProps {
  user: UserDto;
}

function TaskParticipant({ user }: TaskParticipantProps): JSX.Element | null {
  const navigate = useNavigate();

  const tgChatLink = checkIsWebApp() ? user.contactUrl : user.contactTgDeepLink;
  const nameFromUser = getUserName(user) ?? '';
  const photoFromUser = getUserPhoto(user) ?? '';
  const providerPhoto = {
    backgroundImage: 'url(' + photoFromUser + ')',
  };
  const onContactClick = () => {
    navigate(`/friends/${user.id}`);
  };

  return (
    <div className={styles.userInfo}>
      <div className={styles.nameAndPhoto} onClick={onContactClick}>
        <div className={styles.userPhoto} style={providerPhoto}></div>
        <div className={styles.userNamePart}>
          <div className={styles.userName}>{nameFromUser}</div>
        </div>
      </div>
      {tgChatLink && (
        <a className={styles.goToTgBtn} href={tgChatLink}>
          <TelegramIcon className={styles.telegramIcon} />
        </a>
      )}
    </div>
  );
}

export default TaskParticipant;
