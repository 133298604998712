import Button, { Variant } from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import styles from './ExchangerEditOfferMode.module.scss';
import cn from 'classnames';
import { useResetUpdatingMethodMutation, useUpdatePriceMutation } from 'services/exchange';
import { useCallback } from 'react';
import { UseExchangerStoreReturn, useExchangerStore } from 'widgets/ExchangerWidget/store';
import { UserId } from 'widgets/ExchangerWidget/types';

export interface ExchangerEditOfferModeProps {
  providerId: UserId;
  userId?: UserId;
  switchEditOfferMode: (event: React.MouseEvent) => void;
}

export const ExchangerEditOfferMode = (props: ExchangerEditOfferModeProps) => {
  const { providerId, userId, switchEditOfferMode } = props;
  const { t } = useTranslation();

  const data: UseExchangerStoreReturn = useExchangerStore(providerId, userId);
  const { currentFormData } = data;

  const [updatePrice, { isLoading: isLoadingUpdate }] = useUpdatePriceMutation();
  const [reset, { isLoading: isLoadingReset }] = useResetUpdatingMethodMutation();

  const handleConfirmClick = useCallback((event: React.MouseEvent) => {
    event.preventDefault();

    if (
      currentFormData?.buyExchangeUnitAmount &&
      currentFormData?.buyExchangeUnit?.skuIdentifier &&
      currentFormData?.sellExchangeUnit?.code &&
      currentFormData?.sellExchangeUnit?.paymentMethod
    ) {
      updatePrice({
        skuIdentifier: currentFormData?.buyExchangeUnit?.skuIdentifier,
        currencyCode: currentFormData?.sellExchangeUnit?.code,
        paymentMethodDto: {
          name: currentFormData?.sellExchangeUnit?.paymentMethod?.name,
          type: currentFormData?.sellExchangeUnit?.paymentMethod?.type,
        },
        newPrice: currentFormData?.sellExchangeUnitAmount / currentFormData?.buyExchangeUnitAmount,
      });
      switchEditOfferMode(event);
    }
  }, [currentFormData, switchEditOfferMode]);

  const handleGetPriceClick = useCallback((event: React.MouseEvent) => {
    event.preventDefault();
    if (currentFormData?.buyExchangeUnit?.skuIdentifier) {
      reset(currentFormData?.buyExchangeUnit?.skuIdentifier);
    }
  }, []);

  return (
    <div className={styles.wrapper}>
      <Button
        className={cn(styles.button, styles.setupButton)}
        onClick={handleConfirmClick}
        variant={Variant.Contained}
        disabled={isLoadingUpdate || isLoadingReset}
      >
        {t('button.confirm')}
      </Button>
      <Button
        className={cn(styles.button, styles.cancelButton)}
        onClick={handleGetPriceClick}
        variant={Variant.Outlined}
        disabled={isLoadingUpdate || isLoadingReset}
      >
        {t('button.getPrice')}
      </Button>
    </div>
  );
};
