import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';

import { NOT_FOUND } from './paths';

import { useCurrentUserPermissions } from 'shared/hooks/useCurrentUserPermissions';
import { useRedirect } from 'shared/hooks/useRedirect';

function MaintenanceRoute() {
  const { user, canMaintain } = useCurrentUserPermissions();

  const outletContext = useMemo(() => ({ user }), [user]);

  useRedirect(!canMaintain, NOT_FOUND, { replace: true });

  return <Outlet context={outletContext} />;
}

export default MaintenanceRoute;
