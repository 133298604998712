import { useMemo } from 'react';
import { ContactRelationType, UserDto } from 'dtos';

export const useContactRelations = (contact?: UserDto) => {
  return useMemo(
    () =>
      ({
        customerRelation:
          contact?.contactRelationType === ContactRelationType.Customer,
        providerRelation:
          contact?.contactRelationType === ContactRelationType.Provider,
        customerProviderRelation:
          contact?.contactRelationType === ContactRelationType.CustomerProvider,
        friendRelation:
          contact?.contactRelationType === ContactRelationType.Friend,
      } as const),
    [contact]
  );
};
