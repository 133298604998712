import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ProviderExchangeRates, {
  ProviderRatesProps,
} from 'components/ProviderExchangeRates';
import ExchangeRatesPageLayout, { styles } from '../layout';

import ExchangeRatesFilter from 'widgets/ExchangeRatesFilter';

import { useGetExchangeRatesByProviderId } from 'entities1/exchange-rate/model/services/useGetExchangeRatesByProviderId';
import { useExchangeRateInheritanceUpdate } from 'entities1/exchange-rate/model/services/useExchangeRateInheritanceUpdate';

import type { ExchangeRateInheritedDto } from 'dtos';

import { debounce } from 'utils';
import { useGetContactProviderByIdQuery } from 'services/contacts';

export default function ExchangeRatesByProviderPage(): JSX.Element {
  const { t } = useTranslation();
  const { providerId } = useParams();
  const id = Number(providerId);

  const { data: provider } = useGetContactProviderByIdQuery(id);

  const {
    data: providerExchangeRates,
    isLoading,
    fetchNextPage,
  } = useGetExchangeRatesByProviderId(id);

  const { mutateAsync: inheritanceUpdate } =
    useExchangeRateInheritanceUpdate(id);

  const debouncedGetExchangeRates = useCallback(
    debounce(() => {
      return fetchNextPage && fetchNextPage({ pageParam: 1 });
    }),
    [fetchNextPage]
  );

  const rates = useMemo(() => {
    const pages = providerExchangeRates?.pages || [];
    return pages.map((p) => p.content).flat();
  }, [providerExchangeRates]);

  const toggle: ProviderRatesProps['toggle'] = async (r) => {
    const rate = r as ExchangeRateInheritedDto;
    if (!rate.exchangeRate.id) return;
    await inheritanceUpdate({
      requestParams: {
        exchangeRateId: rate.exchangeRate.id,
        providerId: id,
      },
      inherit: !rate.isInherited,
    });
  };

  return (
    <ExchangeRatesPageLayout
      actions={
        <ExchangeRatesFilter
          queryKey={['exchange-rates-by-provider', id]}
          onGetExchangeRates={debouncedGetExchangeRates}
        />
      }
    >
      {isLoading || !provider ? (
        <p className={styles.empty}>{t('page.exchangeRates.loading')}</p>
      ) : (
        <ProviderExchangeRates
          provider={provider}
          rates={rates}
          toggle={toggle}
          fetchNextPage={fetchNextPage}
        />
      )}
    </ExchangeRatesPageLayout>
  );
}
