import { useSearchParams } from 'react-router-dom';

import RedeemInvitationForm from 'features/invitation/RedeemInvitationForm';

const RedeemInvitationWidget = () => {
  const [searchParams] = useSearchParams();

  return <RedeemInvitationForm token={searchParams.get('share')} />;
};

export default RedeemInvitationWidget;
