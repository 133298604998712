import styles from './Thanks.module.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button/Button';
import cn from 'classnames';
import CloseIcon from 'components/icons/Close';
import Icon from 'components/Icon/Icon';
import { IconName } from 'components/Icon/IconList';
import { Language } from 'dtos';

export interface ThanksProps {
  language: Language;
  onClose: () => void;
}

function Thanks({ onClose, language }: ThanksProps): JSX.Element | null {
  const { t } = useTranslation();
  const xLink = 'https://twitter.com/connect_pwapp'
  const eng: boolean = language === Language.En;
  const instagramLink = `https://instagram.com/${ eng ? 'connect.rental' : 'tesla.connect' }`

  return (
    <div className={styles.success}>
      <h3 className={styles.title}>{t('modal.callMeBack.thanks')}</h3>

      <p className={styles.description}>{t('modal.callMeBack.thanksDescription')}</p>

      <div className={styles.buttons}>
        <a className={cn(styles.button, styles.instButton)} href={instagramLink} target={'_blank'} rel='noopener noreferrer'>
          <div className={styles.buttonIcon}>
            <Icon name={IconName.Instagram} width={18} height={18} />
          </div>
          Instagram
        </a>

        <a className={cn(styles.button, styles.xButton)} href={xLink} target={'_blank'} rel='noopener noreferrer'>
          <div className={styles.buttonIcon}>
            <Icon name={IconName.Twitter} width={18} height={18} />
          </div>
          X
        </a>

        <Button className={styles.closeButton} onClick={onClose}>
          <CloseIcon className={styles.closeIcon} />
        </Button>
      </div>
    </div>
  );
}

export default Thanks;
