import styles from './styles.module.scss';

import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import IconBell from 'components/icons/Bell';
import { useTranslation } from 'react-i18next';
import {
  useAddPushSubscriptionMutation,
  useGetPushSubscriptionsQuery,
  useLazyGetPushPublicKeyQuery,
} from 'services/subscriptions';
import Spinner from 'components/Spinner/Spinner';
import { toast } from 'react-hot-toast';

type Props = {
  className?: string;
};

const NotificationBanner = (props: Props) => {
  const { t } = useTranslation();
  const [available, setAvailable] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [getPublicKey] = useLazyGetPushPublicKeyQuery();
  const [addSubscription] = useAddPushSubscriptionMutation();
  const { data: userSubscriptions = [], isLoading } = useGetPushSubscriptionsQuery();

  useEffect(() => {
    const isAvailable = async () => {
      const isSupported = await isPushNotificationSupported();
      if (isSupported) {
        const registration = await navigator.serviceWorker.getRegistration();
        const subscription = await registration?.pushManager.getSubscription();
        if (subscription && !isLoading && await isPushSubscriptionExpired(subscription)) {
          setAvailable(true)
        } else {
          setAvailable(!subscription);
        }
        return;
      }

      setAvailable(false);
    };

    isAvailable();
  }, [userSubscriptions, isLoading]);

  const handleSubscribeButtonClick = () => {
    if (loading) {
      return;
    }

    toast.promise(subscribe(), {
      loading: t('status.adding'),
      success: () => {
        setAvailable(false);
        setLoading(false);
        return t('status.successSubscribed');
      },
      error: (error) => {
        console.log(error);
        return t('status.smthWrong');
      },
    });
  };

  async function isPushSubscriptionExpired(currentSubscription: PushSubscription): Promise<Boolean> {
    const existingSubscription = userSubscriptions.find((element) => element.endpoint === currentSubscription.endpoint)
    console.log("currentSubscription: " + JSON.stringify(currentSubscription))
    return !existingSubscription;
  }

  async function subscribe() {
    setLoading(true);
    await Notification.requestPermission();

    const publicKey = await getPublicKey().unwrap();

    const registration = await navigator.serviceWorker.getRegistration();
    const subscription = await registration?.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: publicKey,
    });

    if (subscription) {
      addSubscription(subscription);
    }

    setLoading(false);
  }

  if (!available) {
    return null;
  }

  return (
    <div
      className={cn(styles.root, props.className, {
        [styles.disable]: loading,
      })}
      onClick={handleSubscribeButtonClick}
    >
      {loading && <Spinner size={20} />}
      {!loading && (
        <>
          <IconBell className={styles.icon} />
          {t('notification.banner')}
        </>
      )}
    </div>
  );
};

async function isPushNotificationSupported() {
  const registration = await navigator.serviceWorker?.getRegistration();

  return (
    'serviceWorker' in navigator &&
    'PushManager' in window &&
    'Notification' in window &&
    typeof registration !== 'undefined'
  );
}

export default NotificationBanner;
