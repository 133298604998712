import Button, { Variant } from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import styles from './ExchangerCustomOfferModeSwitch.module.scss';
import cn from 'classnames';

export interface ExchangerCustomOfferModeSwitchProps {
  customOfferMode: boolean;
  switchCustomOfferMode: (event: React.MouseEvent) => void;
}

export const ExchangerCustomOfferModeSwitch = (props: ExchangerCustomOfferModeSwitchProps) => {
  const { customOfferMode, switchCustomOfferMode } = props;
  const { t } = useTranslation();
  return (
    <>
      {!customOfferMode ? (
        <Button
          className={cn(styles.button, styles.setupButton)}
          onClick={switchCustomOfferMode}
          variant={Variant.Contained}
        >
          {t('button.setupNewDeal')}
        </Button>
      ) : (
        <Button
          className={cn(styles.button, styles.cancelButton)}
          onClick={switchCustomOfferMode}
          variant={Variant.Outlined}
        >
          {t('button.cancel')}
        </Button>
      )}
    </>
  );
};
