import styles from './Form.module.scss';

import React, { ChangeEvent, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import CurrencyInput from 'components/Input/CurrencyInput/CurrencyInput';
import Suggest from 'components/Suggest';

import { useAutoFocus } from 'shared/hooks/useAutoFocus';

import { isResponseWithErrorMessage } from 'utils';
import { useObligationTransferMutation } from 'services/balances';
import { ObligationTransferData } from '../../index';

export interface FormProps {
  data: ObligationTransferData;
  onClose: () => void;
  onSetData: (data: ObligationTransferData) => void;
}

export type FormStateProps = Pick<ObligationTransferData, 'value' | 'code' | 'comment'>;

export interface FormState extends Required<FormStateProps> {}

const INITIAL_VALUE: string = '0';
const EMPTY: string = '';

function Form({ data, onClose, onSetData }: FormProps): JSX.Element | null {
  const { t } = useTranslation();
  const amountRef = useAutoFocus<HTMLInputElement>();

  const [form, setForm] = useState<FormState>({
    value: data.value,
    code: data.code,
    comment: EMPTY,
  });

  const [error, setError] = useState<string | null>(null);

  const changeFormValue = (value?: string): void => {
    if (Number(data.value) > 0) {
      if (Number(value) > Number(data.value)) {
        return setForm((prev) => ({ ...prev, value: data.value ?? INITIAL_VALUE }));
      } else if (Number(value) < 0 || value === '-' || value === '-0') {
        return setForm((prev) => ({ ...prev, value: INITIAL_VALUE }));
      }
    } else if (Number(data.value) < 0) {
      if (Number(value) < Number(data.value)) {
        return setForm((prev) => ({ ...prev, value: data.value ?? INITIAL_VALUE }));
      } else if (Number(value) > 0) {
        return setForm((prev) => ({ ...prev, value: INITIAL_VALUE }));
      } else if (value === '-0') {
        return setForm((prev) => ({ ...prev, value: '-' }));
      }
    }
    return setForm((prev) => ({ ...prev, value: value ?? INITIAL_VALUE }));
  };

  const changeComment = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    e.preventDefault();
    setForm((prev) => ({ ...prev, comment: e.target.value }));
  };

  const [obligationTransferMutation] = useObligationTransferMutation();

  const canSendRequest: boolean =
    parseFloat(form.value) !== 0 && Boolean(form.code.trim()) && !!form.comment.length;
  const sendingRequest: boolean = Boolean(data?.isSending);

  const obligationTransfer = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();

    if (parseFloat(form.value) === 0) {
      setError(t('error.value'));
    } else if (!Boolean(form.code.trim())) {
      setError(t('error.code'));
    } else if (!form.comment.length) {
      setError(t('error.comment'));
    } else {
      setError(null);
    }

    if (!canSendRequest || !data) return;
    if (data.fromBalance?.id === undefined || data.toBalance?.id === undefined) return;

    try {
      onSetData({ ...data, isSending: true });
      const { comment, code: bucketName, value } = form;
      const formattedValue: number = parseFloat(value.replace(',', '.'));
      await obligationTransferMutation({
        fromBalanceId: data.fromBalance.id,
        toBalanceId: data.toBalance.id,
        comment: comment,
        bucketName: bucketName,
        amount: formattedValue,
      }).unwrap();
      onSetData({ ...data, isSending: false, sent: true, ...form });
    } catch (e) {
      onSetData({ ...data, isSending: false });
      if (e instanceof Error) {
        const errorMessage = isResponseWithErrorMessage(e) ? e.response.data.message : e.message;
        setError(errorMessage);
      } else {
        // @ts-ignore
        const errorMessage = e?.data?.message ? e.data.message : t('error.unknown');
        setError(errorMessage);
      }
    }
  };

  const calmState: string = t(`button.transfer`);
  const loadingState: string = t(`button.transferring`);

  return (
    <form onSubmit={obligationTransfer} className={styles.form}>
      <div className={styles.values}>
        <CurrencyInput
          ref={amountRef}
          value={form.value}
          onValueChange={changeFormValue}
          className={styles.amountWrap}
          inputClassName={styles.amount}
          autoComplete="transaction-amount"
          allowNegativeValue={true}
        />

        <Suggest
          placeholder="USD"
          value={form.code}
          values={[]}
          className={styles.codeWrap}
          inputClassName={styles.code}
          disabled
        />
      </div>

      <textarea
        id="comment"
        value={form.comment}
        placeholder={t('placeholder.comment')}
        onChange={changeComment}
        className={styles.comment}
        autoComplete="off"
      />

      {!!error && <p className={styles.error}>{error}</p>}

      <div className={styles.buttons}>
        <button
          type="submit"
          disabled={sendingRequest}
          onClick={obligationTransfer}
          className={cn(styles.button, styles.submit)}
        >
          {!sendingRequest ? calmState : loadingState}
        </button>

        <button
          type="button"
          disabled={sendingRequest}
          onClick={onClose}
          className={cn(styles.button, styles.close)}
        >
          {t('button.close')}
        </button>
      </div>
    </form>
  );
}

export default Form;
