import instance from 'api';
import type {ExchangerInitializationDtoV5, ExchangerInitializeRequest} from 'dtos';

const endpoint: string = '/exchanger/v5/initialize';

export default class ExchangerService {
  static async getInitialization(
    params: ExchangerInitializeRequest,
  ): Promise<ExchangerInitializationDtoV5> {
    const { data } = await instance.post<ExchangerInitializationDtoV5>(endpoint, {
      ...params,
    });
    return data;
  }
}
