import { useTranslation } from 'react-i18next';

import ExchangeInfo from 'modules/ExchangeInfo/ExchangeInfo';

import { Layout } from 'components/Layout/Layout';

import styles from './Exchange.module.scss';


const Exchange = () => {
  const { t } = useTranslation();

  return (
    <Layout hasMenu={false}>
      <Layout.TopBar className={styles.headerTitle}>{t('page.exchangeInfo.goods.title')}</Layout.TopBar>
      <Layout.Content unsetBottomMargin unsetTopPadding>
        <ExchangeInfo />
      </Layout.Content>
    </Layout>
  );
};

export default Exchange;
