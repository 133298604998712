import React from 'react';
import cn from 'classnames';
import Skeleton, { SkeletonProps } from 'react-loading-skeleton';

import styles from './index.module.scss';

import SkeletonWrapper from 'components/SkeletonWrapper';

export interface SkeletonWithWrapperProps
  extends Omit<SkeletonProps, 'wrapper'> {}

function SkeletonWithWrapper({
  count = 1,
  ...props
}: SkeletonWithWrapperProps): JSX.Element {
  return (
    <Skeleton
      className={cn({
        [styles.gap]: count > 1,
      })}
      wrapper={SkeletonWrapper}
      count={count}
      {...props}
    />
  );
}

export default SkeletonWithWrapper;
