import React from 'react';
import { useTranslation } from 'react-i18next';

import { Profile } from 'modules/Profile/Profile';
import { useGetMeQuery } from 'services/users';

import { Layout } from 'components/Layout/Layout';
import { ApplicationInfo, SocialsList } from './components';

const ProfilePage = () => {
  const { t } = useTranslation();
  const { isLoading, isFetching } = useGetMeQuery();

  return (
    <Layout>
      <Layout.TopBar>
        <Layout.Title>{t('page.profile.title')}</Layout.Title>
      </Layout.TopBar>

      <Layout.Content>
        <Profile />

        {!isLoading && !isFetching ? (
          <>
            <ApplicationInfo />
            <SocialsList />
          </>
        ) : null}
      </Layout.Content>
    </Layout>
  );
};

export default ProfilePage;
