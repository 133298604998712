import { InfiniteData, useMutation } from '@tanstack/react-query';

import ExchangeRateInheritanceService from 'api/exchange-rate-inheritance';
import type {
  InheritedExchangeRateByIdParams,
  UploadedInheritanceExchangeRatesResponse,
} from 'api/exchange-rate-inheritance/types';

import { queryClient } from 'shared/config/reactQuery';

import { refetchInfiniteQueryPage } from 'utils';

interface ExchangeRateUploadedUpdateParams {
  id: InheritedExchangeRateByIdParams;
  enable: boolean;
}

export const useExchangeRateUploadedUpdate = () => {
  const queryKey = ['own-exchange-rates'];
  return useMutation({
    mutationKey: queryKey,
    mutationFn: ({ id, enable }: ExchangeRateUploadedUpdateParams) => {
      if (enable) {
        return ExchangeRateInheritanceService.enableInheritedExchangeRateById(
          id
        );
      }
      return ExchangeRateInheritanceService.disableInheritedExchangeRateById(
        id
      );
    },
    onSuccess: (_, { id }) => {
      const data =
        queryClient.getQueryData<
          InfiniteData<UploadedInheritanceExchangeRatesResponse>
        >(queryKey);

      data?.pages.forEach((p, idx) => {
        const rate = p.content.find((r) => r.id === id);

        if (rate) refetchInfiniteQueryPage(queryKey, idx);
      });
    },
  });
};
