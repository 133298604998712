import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import { useState, useCallback } from 'react';
import { RootState } from 'shared/redux-store';
import { hide, ModalTypes } from 'slices/modal';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import Modal from 'components/Modal/Modal';
import { UseExchangerStoreReturn, useExchangerStore } from '../ExchangerWidget';
import {
  PaymentCreationRequestDtoV3,
  PaymentFormDtoV5,
  TargetField,
} from 'dtos';
import { Layout } from 'components/Layout/Layout';
import { Variant } from 'components/Button/Button';
import cn from 'classnames';

import styles from './ChoosePaymentFriends.module.scss';
import { InfiniteCustomerList } from 'modules/InfiniteCustomerList';
import FloatingActionBar from 'components/FloatingActionBar/FloatingActionBar';
import { useCreateSessionMutation } from 'services/payments';
import { useProvidersStore } from 'shared/store/providers';
import { formatExchangeCurrencyAmount } from 'widgets/Exchanger/lib';
import upperCase from 'lodash/upperCase';
import { open } from 'slices/modal';
import Input from 'components/Input/Input';
import { getExchangeUnitDto } from 'dtos/dtoMapper';

function ChoosePaymentFriends(): JSX.Element | null {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchString, setSearchString] = useState<string | undefined>(undefined);
  const { visible } = useSelector(
    (state: RootState) => state.modal[ModalTypes.ChoosePaymentFriends]
  );
  const { currentFormData, tabs } = useExchangerStore();

  const debounceSetSearchString = useCallback(
    (searchString?: string) => setSearchString(searchString),
    [setSearchString]
  );

  const openModal = useCallback(
    (): void => {
      dispatch(
        open({
          id: ModalTypes.GeneratePaymentInvitation,
          data: {},
        })
      );
    },
    [dispatch]
  );

  const closeModal = (): void => {
    dispatch(hide({ id: ModalTypes.ChoosePaymentFriends }));
  };

  const [createSession, { isLoading }] = useCreateSessionMutation();
  const { primaryProvider } = useProvidersStore();
  const exchanger: UseExchangerStoreReturn = useExchangerStore(
    primaryProvider?.id,
    null,
    { cache: true }
  );
  const paymentForm: PaymentFormDtoV5 = exchanger?.currentFormData as PaymentFormDtoV5;

  const goToExchangePage = async (id: number) => {
    // e.preventDefault();

    const preparedForm: PaymentCreationRequestDtoV3 = {
      providerId: paymentForm.providerId,
      userId: id,

      buyExchangeUnit: getExchangeUnitDto(paymentForm.buyExchangeUnit, true),
      buyCurrencyAmount: formatExchangeCurrencyAmount(
        paymentForm.buyExchangeUnitAmount
      ),
      sellExchangeUnit: getExchangeUnitDto(paymentForm.sellExchangeUnit, true),
      sellCurrencyAmount: formatExchangeCurrencyAmount(
        paymentForm.sellExchangeUnitAmount
      ),
      targetField: `${upperCase(
        exchanger?.inputTarget || 'buy'
      )}_CURRENCY` as TargetField,
      utmTag: exchanger.utmTag
    };

    try {
      const { sessionKey } = await createSession(preparedForm).unwrap();
      navigate(`/exchange/${sessionKey}`, { state: { sessionKey } });
    } catch (e) {
      Sentry.captureException(e);
      if (!(e instanceof Error)) return;
      toast.error(e.message);
    }
  };

  if (!visible || !currentFormData || !tabs) {
    return null;
  }

  return (
    <Modal
      visible={visible}
      onClose={closeModal}
      className={styles.modalContent}
      modalClassName={styles.modal}
    >
      <Layout.Content
        className={styles.content}
        unsetBottomMargin
        unsetBottomPadding
        unsetTopPadding
        unsetLeftRightPadding
      >
        <Layout.TopBar className={styles.headerTitle}>
          {t('modal.createPaymentInvitation.chooseContact')}
        </Layout.TopBar>

        <Input
          name="search"
          onChange={(event) => {debounceSetSearchString(event.target.value)}}
          placeholder={t('page.friends.search')}
          inputClassName={styles.search}
          value={searchString}
        />

        <InfiniteCustomerList onClickItem={goToExchangePage} searchString={searchString}/>
        <FloatingActionBar
          className={styles.floating}
          actions={[
            {
              variant: Variant.Contained,
              text: t('modal.createPaymentInvitation.inviteWithLink'),
              onClick: openModal,
              className: styles.button,
              disabled: isLoading,
            },
            {
              variant: Variant.Outlined,
              text: t('button.cancel'),
              onClick: closeModal,
              className: cn(styles.button, styles.closeButton)
            },
          ]}
        />
      </Layout.Content>
    </Modal>
  );
}

export default ChoosePaymentFriends;
