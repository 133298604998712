import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from 'shared/redux-store';
import { hide, ModalTypes } from 'slices/modal';
import React, { useCallback, useState } from 'react';
import styles from './DelegateTaskModal.module.scss';
import { Layout } from 'components/Layout/Layout';
import Input from 'components/Input/Input';
import Modal from 'components/Modal/Modal';
import { InfiniteContactList } from 'modules/InfiniteContactList';
import { UserDto } from 'dtos';
import cn from 'classnames';
import Button, { Variant } from 'components/Button/Button';
import { Successfully } from 'modules/Successfully';
import { useDelegateTaskMutation } from 'services/tasks';
import * as Sentry from '@sentry/react';
import { toast } from 'react-hot-toast';

function DelegateTaskModal(): JSX.Element | null {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { visible, data: modalData } = useSelector(
    (state: RootState) => state.modal[ModalTypes.DelegateTask],
  );
  const [searchString, setSearchString] = useState<string>();
  const [selectedContact, setSelectedContact] = useState<UserDto>();
  const [ delegateTask, {isLoading: isDelegating} ] = useDelegateTaskMutation();

  const debounceSetSearchString = useCallback(
    (searchString?: string) => setSearchString(searchString),
    [setSearchString]
  );

  const closeModal = (): void => {
    setSelectedContact(undefined);
    setSearchString(undefined);
    dispatch(hide({ id: ModalTypes.DelegateTask }));
  };

  const onContactClick = async (userDto: UserDto) => {
    try {
      if (!isDelegating) {
        await delegateTask({ taskId: modalData.task.id, contactId: userDto.id }).unwrap();
        setSelectedContact(userDto);
      }
    } catch (e) {
      Sentry.captureException(e);
      if (!(e instanceof Error)) return;
      toast.error(e.message);
    }
  }

  return (
    <>
      <Modal
        visible={visible}
        onClose={closeModal}
        className={styles.modalContent}
        modalClassName={styles.modal}
      >
        <Layout.Content
          className={styles.content}
          unsetBottomMargin
          unsetBottomPadding
          unsetTopPadding
          unsetLeftRightPadding
        >
          {!selectedContact ? (
            <>
              <Layout.TopBar className={styles.header}>
                <div className={styles.title}>{t('modal.delegateTask.chooseContact')}</div>
                <Button
                  className={cn(styles.topButton)}
                  variant={Variant.Minimal}
                  onClick={closeModal}
                >
                  {t('modal.delegateTask.closeButton')}
                </Button>
              </Layout.TopBar>

              <Input
                name="search"
                onChange={(event) => {
                  debounceSetSearchString(event.target.value);
                }}
                placeholder={t('page.friends.search')}
                inputClassName={styles.search}
                value={searchString}
              />
              <div className={styles.infiniteList}>
                <InfiniteContactList searchString={searchString} onClickItem={onContactClick} withBalanceButtons={false} />
              </div>
            </>
          ) : (
            <>
              <Layout.TopBar className={cn(styles.header, styles.headerSuccess)}>
                <Button
                  className={cn(styles.topButton)}
                  variant={Variant.Minimal}
                  onClick={closeModal}
                >
                  {t('modal.delegateTask.closeButton')}
                </Button>
              </Layout.TopBar>

              <Successfully className={styles.success}>
                <h2 className={styles.successTitle}>{t('modal.delegateTask.success')}</h2>
              </Successfully>
            </>

          )}
        </Layout.Content>
      </Modal>
    </>
  );
}

export default DelegateTaskModal;
