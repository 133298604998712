import styles from './Checkbox.module.scss';

import React, {
  Dispatch,
  InputHTMLAttributes,
  PropsWithChildren,
  SetStateAction,
} from 'react';
import cn from 'classnames';

import CheckboxIcon from "../icons/Checkbox";

type StateAction<Data> = Dispatch<SetStateAction<Data>>;

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  onChangeValue: StateAction<HTMLInputElement['checked']>;
  labelClassName?: string;
}

const Checkbox = ({
  checked,
  onChangeValue,
  children,
  labelClassName,
}: PropsWithChildren<CheckboxProps>): JSX.Element => {
  const handleChange = () => onChangeValue(!checked);

  return (
    <label className={cn(styles.label, labelClassName)}>
      <input type="checkbox" checked={checked} onChange={handleChange} hidden />

      <span
        className={cn(
          styles.checkbox,
          { [styles.checked]: checked },
          { [styles.active]: checked }
        )}
      >
        {checked && (
          <CheckboxIcon />
        )}
      </span>
      {children}
    </label>
  );
};

export default Checkbox;
