import styles from './ContactRelationIndicator.module.scss';

import { ContactRelationType } from 'dtos';

const RELATION_CLASS_NAME = {
  [ContactRelationType.Friend]: undefined,
  [ContactRelationType.NotContact]: undefined,
  [ContactRelationType.Customer]: styles.customer,
  [ContactRelationType.Provider]: styles.provider,
  [ContactRelationType.CustomerProvider]: styles.customerAndProvider,
};

const RELATION_LABEL = {
  [ContactRelationType.Friend]: undefined,
  [ContactRelationType.NotContact]: undefined,
  [ContactRelationType.Customer]: 'C',
  [ContactRelationType.Provider]: 'P',
  [ContactRelationType.CustomerProvider]: 'PC',
};

export { RELATION_LABEL, RELATION_CLASS_NAME };
