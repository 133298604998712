import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../fetchBaseQueryWithAuth';
import {
  CreateTransactionalPaymentDto,
  PaymentConfirmationDto,
  PaymentCreationRequestDtoV3,
  PaymentCreationResponseDtoV4,
  PaymentDtoV4,
  PaymentInitializationResponse,
} from 'dtos';

export const paymentsApi = createApi({
  reducerPath: 'paymentsApi',
  baseQuery: baseQueryWithReauth(true),
  tagTypes: ['Payment'],
  endpoints: (builder) => ({
    getPaymentBySession: builder.query<PaymentCreationResponseDtoV4, PaymentCreationResponseDtoV4['sessionKey']>({
      query: (sessionKey) => ({
        url: `/payments/v4/session/${sessionKey}`,
      }),
    }),
    createSession: builder.mutation<PaymentCreationResponseDtoV4, PaymentCreationRequestDtoV3>({
      query: (body) => ({
        url: '/payments/v4/create-session',
        method: 'POST',
        body,
      }),
    }),
    createSessionTransaction: builder.mutation<PaymentCreationResponseDtoV4, CreateTransactionalPaymentDto>({
      query: (body) => ({
        url: '/payments/v4/create-session/transaction',
        method: 'POST',
        body,
      }),
    }),
    completeSession: builder.mutation<PaymentInitializationResponse, PaymentConfirmationDto>({
      query: (body) => ({
        url: '/payments/complete-session',
        method: 'POST',
        body,
      }),
    }),
    getPaymentHistory: builder.query<string, number>({
      query: (paymentId: number) => ({
        url: '/payment-chat/history',
        params: { paymentId },
        responseHandler: (response) => response.text(),
      }),
    }),
    getPaymentsTotalIncome: builder.query<number, void>({
      query: () => ({
        url: '/payments/total-income',
      }),
    }),
    getPaymentById: builder.query<PaymentDtoV4, number>({
      query: (id: number) => ({
        url: `/payments/v4/${id}`,
      }),
    }),
  }),
});

export const {
  useGetPaymentBySessionQuery,
  useCreateSessionMutation,
  useCompleteSessionMutation,
  useGetPaymentHistoryQuery,
  useCreateSessionTransactionMutation,
  useGetPaymentsTotalIncomeQuery,
  useGetPaymentByIdQuery,
} = paymentsApi;
