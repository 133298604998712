import styles from './AssistantCard.module.scss';

import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';

import cn from 'classnames';
import Icon from 'components/Icon/Icon';
import { IconName } from 'components/Icon/IconList';
import { UserCard } from 'entities1/user';
import type { BalanceDto, UserDto } from 'dtos';
import {
  useDeleteBalanceAssistantMutation,
  useAddBalanceAssistantMutation,
} from 'services/assistants';

export type AssistantCardProps = {
  assistant: UserDto;
  balanceId: BalanceDto['id'];
  isCurrent: boolean;
};

function AssistantCard({ assistant, balanceId, isCurrent }: AssistantCardProps): JSX.Element {
  const assistantId: UserDto['id'] = assistant.id;
  const { t } = useTranslation();
  const [addBalanceAssistant, { isLoading: isLoadingAddAssistant }] =
    useAddBalanceAssistantMutation();
  const [deleteBalanceAssitant, { isLoading: isLoadingDeleteAssistant }] =
    useDeleteBalanceAssistantMutation();
  const query = useQueryClient();

  const deleteAssistantHandler = async (): Promise<void> => {
    toast.promise(deleteBalanceAssitant({ assistantId, balanceId }).unwrap(), {
      loading: t('status.deleting'),
      success: () => {
        // Перезапрашиваем список контактов
        query.refetchQueries({ queryKey: ['BalanceContactsWithSearch'] });
        return <b>{t('status.successDeletedAssistant')}</b>;
      },
      error: (error) => {
        const errorMessage =
          error?.error || error?.data?.message || error?.errorMessage || t('status.smthWrong');
        return <b>{errorMessage}</b>;
      },
    });
  };

  const addAssistantHandler = () => {
    toast.promise(addBalanceAssistant({ assistantId, balanceId }).unwrap(), {
      loading: t('status.adding'),
      success: () => {
        // Перезапрашиваем список контактов
        query.refetchQueries({ queryKey: ['BalanceContactsWithSearch'] });
        return <b>{t('status.success')}</b>;
      },
      error: (error) => {
        const errorMessage =
          error?.error || error?.data?.message || error?.errorMessage || t('status.smthWrong');
        return <b>{errorMessage}</b>;
      },
    });
  };

  return (
    <div
      className={styles.assistant}
      onClick={isCurrent ? deleteAssistantHandler : addAssistantHandler}
    >
      <UserCard user={assistant} />

      {isCurrent && !isLoadingDeleteAssistant && (
        <div className={cn(styles.iconContainer, styles.removeAssistantIconContainer)}>
          <Icon
            name={IconName.Close2}
            className={styles.removeAssistantIcon}
            width={10}
            height={10}
            viewBox="0 0 10 10"
          />
        </div>
      )}

      {!isCurrent && !isLoadingAddAssistant && (
        <div className={cn(styles.iconContainer, styles.addAssistantIconContainer)}>
          <Icon
            name={IconName.BigPlus}
            className={styles.addAssistantIcon}
            width={11}
            height={11}
            viewBox="0 0 11 11"
          />
        </div>
      )}
    </div>
  );
}

export default AssistantCard;
