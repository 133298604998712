import styles from './ExchangerWidget.module.scss';

import {
  ExchangeSwapButton,
  ExchangeTabs,
  ExchangeErrorMessage,
  CrossExchangeInfo,
  ExchangeContinueButton,
  ExchangerBlock,
} from './components';
import { UserId, ExchangerScope } from './types';
import React, { useEffect, useState } from 'react';
import { ExchangerCustomOfferModeSwitch } from './components/ExchangerCustomOfferModeSwitch';
import { ExchangeRateWidgetDto } from 'dtos';
import { GeneratePaymentInvitation } from './components/GeneratePaymentInvitation';
import { ExchangerEditOfferMode } from './components/ExchangerEditOfferMode';

export interface ExchangerWidgetProps {
  providerId: UserId;
  userId?: UserId;
  isMyself?: boolean;
  isPublic?: boolean;
  customOfferMode?: boolean;
  editOfferMode?: boolean;
  crossExchangeRates?: ExchangeRateWidgetDto[];
}

export interface ExchangerWidgetScopeProps extends ExchangerWidgetProps {
  scope: ExchangerScope;
}

export function ExchangerWidget(props: ExchangerWidgetProps): JSX.Element | null {
  const { userId, isMyself, isPublic, providerId } = props;
  const [editOfferMode, setEditOfferMode] = useState(false);
  const [customOfferMode, setCustomOfferMode] = useState(false);
  const [crossExchangeRates, setCrossExchangeRates] = useState<ExchangeRateWidgetDto[]>([]);

  const switchCustomOfferMode = (event: React.MouseEvent) => {
    event.preventDefault();
    setCustomOfferMode(!customOfferMode);
  };

  const switchEditOfferMode = (event: React.MouseEvent) => {
    event.preventDefault();
    setEditOfferMode(!editOfferMode);
  };

  useEffect(() => {
    if (!isMyself) {
      setCustomOfferMode(false);
    }
  }, [isMyself]);

  const updateCrossExchangeRates = (rates: ExchangeRateWidgetDto[]) => {
    setCrossExchangeRates(rates);
  };

  const ratesForProps = customOfferMode ? crossExchangeRates : undefined;
  const updatedProps = { ...props, editOfferMode, customOfferMode, crossExchangeRates: ratesForProps };
  const crossExchangeInfoProps = {
    ...updatedProps,
    updateCrossExchangeRates: updateCrossExchangeRates,
  };

  return (
    <form className={styles.form}>
      <ExchangeTabs {...updatedProps} />

      <div className={styles.exchanger}>
        <ExchangerBlock scope={ExchangerScope.Buy} {...updatedProps} />
        <ExchangeSwapButton {...props} />
        <ExchangerBlock
          scope={ExchangerScope.Sell}
          {...updatedProps}
          switchEditOfferMode={switchEditOfferMode}
          editOfferMode={editOfferMode}
        />
      </div>

      <div className={styles.crossExchange}>
        <CrossExchangeInfo {...crossExchangeInfoProps} />
      </div>

      <ExchangeErrorMessage {...updatedProps} />

      {!userId && !isMyself && !isPublic && <ExchangeContinueButton {...updatedProps} />}

      {isMyself && customOfferMode && <GeneratePaymentInvitation />}

      {isMyself && editOfferMode && (
        <div className={styles.background} onClick={switchEditOfferMode} />
      )}

      {isMyself && !editOfferMode && (
        <ExchangerCustomOfferModeSwitch
          customOfferMode={customOfferMode}
          switchCustomOfferMode={switchCustomOfferMode}
        />
      )}

      {isMyself && editOfferMode && (
        <ExchangerEditOfferMode
          {...props}
          switchEditOfferMode={switchEditOfferMode}
        />
      )}
    </form>
  );
}
