import styles from './PaymentInfo.module.scss';

import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { useGetPaymentByIdQuery } from 'services/payments';
import Button, { Variant } from 'components/Button/Button';
import CrossExchange from 'components/CrossExchange/CrossExchange';

import { hasExchangeCalculations } from 'widgets/Exchanger/lib';

import { PaymentType, type ExchangeCalculationResponse, type PaymentForListDto } from 'dtos';

import { getFormattedFullDateTime } from 'utils';
import { PaymentExchange } from 'components/PaymentExchange';
import truncate from 'lodash/truncate';
import checkIsWebApp from 'utils/checkIsWebApp';
import { toast } from 'react-hot-toast';

function PaymentInfo() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: payment, isLoading } = useGetPaymentByIdQuery(Number(id));
  const linkToChat = checkIsWebApp() ? payment?.telegramInviteLink : payment?.telegramDeepLink;

  type ExchangeCalculationResponseWithoutAmount = Pick<
    Partial<ExchangeCalculationResponse>,
    'exchanges' | 'crossExchangeWidgetData'
  >;

  const exchangeCalculationResponse: ExchangeCalculationResponseWithoutAmount = useMemo(
    () => ({
      exchanges: payment?.exchangeRates,
      crossExchangeWidgetData: payment?.crossExchangeWidgetData,
    }),
    [payment]
  );

  if (!id || !payment || isLoading) {
    return <></>;
  }

  const paymentStatus: PaymentForListDto['paymentStatus'] =
    payment.paymentStatus.toLowerCase() as PaymentForListDto['paymentStatus'];
  const isTransaction = payment.type === PaymentType.Transaction;

  const goToTelegramChat = () => {
    linkToChat && (window.location.href = linkToChat);
  };

  const copyLink = () => {
    if (!payment.telegramInviteLink) return;

    navigator.clipboard.writeText(payment.telegramInviteLink).then(() => {
      payment.telegramInviteLink?.trim() && toast.success(t('status.copied'));
    });
  };

  const handlePaymentTelegramHistoryClick = (paymentId: number) => () => {
    navigate(`/payments/${paymentId}/chat-history`);
  };
  const title = truncate(payment.title, { length: 35, omission: '…' });

  return (
    <div>
      <div className={styles.inline}>
        <p>{t(`page.paymentInfo.${isTransaction ? 'titleId' : 'id'}`)}</p>

        <p>{isTransaction ? title : payment.id}</p>
      </div>

      <div className={styles.inline}>
        <p>{t('page.paymentInfo.status')}</p>

        <p className={cx(styles[paymentStatus], styles.text)}>{payment.paymentStatus}</p>
      </div>

      <div className={styles.inline}>
        <p>{t('page.paymentInfo.creationDate')}</p>

        <p className={cx(styles[paymentStatus], styles.text)}>
          {getFormattedFullDateTime(payment.createdAt)}
        </p>
      </div>

      {hasExchangeCalculations(exchangeCalculationResponse) && (
        <div className={styles.crossExchangeWrap}>
          <CrossExchange
            exchanges={exchangeCalculationResponse.exchanges}
            exchange={exchangeCalculationResponse.crossExchangeWidgetData}
            updateCrossExchangeRates={() => {}}
          />
        </div>
      )}

      <div className={styles.line} />

      {payment.intermediaryExchanges.map((exchange) => (
        <div key={exchange.id}>
          <PaymentExchange exchangeInfo={exchange} isTransaction={isTransaction} />
          <div className={styles.line} />
        </div>
      ))}

      <div className={styles.navigators}>
        {linkToChat && (
          <>
            <Button className={styles.telegram} onClick={goToTelegramChat}>
              {t('button.linkToTelegramChat')}
            </Button>
            <Button variant={Variant.Outlined} className={styles.copy} onClick={copyLink}>
              {t('button.copy')}
            </Button>
          </>
        )}

        {payment.chatHistoryKey && (
          <Button onClick={handlePaymentTelegramHistoryClick(payment.id)}>
            {t('page.paymentInfo.chatHistory')}
          </Button>
        )}
      </div>
    </div>
  );
}

export default PaymentInfo;
