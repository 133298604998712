import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import styles from './ExchangerBlock.module.scss';

import { ExchangeTypeAndMethodSelects } from '../ExchangeTypeAndMethodSelects';
import { ExchangeCurrencySelect } from '../ExchangeCurrencySelect';
import { ExchangerWidgetScopeProps } from '../../ExchangerWidget';
import { ExchangeAmountInput } from '../ExchangeAmountInput';
import { ExchangerSubcategories } from '../ExchangerSubcategories';
import { ExchangeSlider } from '../ExchangeSlider';
import { ExchangerSkuParams } from '../ExchangerSkuParams';
import { ExchangerMeasureUnits } from '../ExchangerMeasureUnits';

import { useExchangerStore, UseExchangerStoreReturn } from '../../store';
import { ExchangerScope } from '../../types';
import EditIcon from 'components/icons/Edit';
import { useCallback, useState } from 'react';

type Props = ExchangerWidgetScopeProps & {
  switchEditOfferMode?: (event: React.MouseEvent) => void;
};

export function ExchangerBlock(props: Props) {
  const { t } = useTranslation();
  const { providerId, userId, isPublic, customOfferMode, switchEditOfferMode, editOfferMode } = props;
  const { isInitialization, activeOption, currentFormData }: UseExchangerStoreReturn = useExchangerStore(
    providerId,
    userId
  );

  const canEdit = currentFormData?.isEditable && activeOption !== 'Currency' && props.scope === ExchangerScope.Sell ;

  const handleEditButtonClick = useCallback((event: React.MouseEvent) => {
    switchEditOfferMode?.(event);
  }, []);

  if (activeOption === 'Currency' || props.scope === ExchangerScope.Sell) {
    return (
      <div className={cn(styles.field, {[styles.fieldZ]: editOfferMode})}>
        {!isInitialization && (
          <div className={cn(styles.row, styles.titleWrap)}>
            <h3 className={styles.title}>
              {t(
                props.scope === ExchangerScope.Buy
                  ? (customOfferMode ? 'labels.clientGets' : 'labels.youGet')
                  : activeOption !== 'Currency'
                    ? 'labels.total'
                    : (customOfferMode ? 'labels.clientGives' : 'labels.youGive')
              )}
            </h3>
            {canEdit && (
              <div
                className={cn(styles.iconContainer, styles.changeIconContainer)}
                onClick={handleEditButtonClick}
              >
                <EditIcon />
              </div>
            )}
          </div>
        )}
        <div className={styles.col}>
          <div className={styles.row}>
            <ExchangeAmountInput {...props} editOfferMode={editOfferMode} />
            <ExchangeCurrencySelect {...props} editOfferMode={editOfferMode} />
            <ExchangeTypeAndMethodSelects {...props} editOfferMode={editOfferMode} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={styles.col}>
        {!isPublic && (
          <div className={styles.row}>
            <ExchangeTypeAndMethodSelects {...props} />
          </div>
        )}
        <ExchangerSubcategories {...props} />
        <ExchangeSlider {...props} />
        <ExchangerSkuParams {...props} />
        <ExchangerMeasureUnits {...props} />
      </div>
    </>
  );
}
