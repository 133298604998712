import React, { PropsWithChildren } from 'react';

export interface TitleProps extends PropsWithChildren {
  className?: string;
}

function Title({ children, className }: TitleProps): JSX.Element | null {
  if (!children) return null;

  return <h1 className={className}>{children}</h1>;
}

export default Title;
