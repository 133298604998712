import { IconProps } from 'components/Icon/Icon';
import { IconName } from 'components/Icon/IconList';
import { Language, UserDto } from 'dtos';

type Social = {
  icon: IconProps;
  href: string;
};

export const getSocialsByLanguage = (lang: UserDto['language']): Social[] => {
  const eng: boolean = lang === Language.En;

  return [
    {
      href: 'https://twitter.com/connectai_app',
      icon: { name: IconName.Twitter, width: 36, height: 36 },
    },
    {
      href: `https://instagram.com/${eng ? 'connectaiapp' : 'tesla.connect'}`,
      icon: { name: IconName.Instagram, width: 36, height: 36 },
    },
    {
      href: `https://t.me/${eng ? 'connect_en' : 'connbot_ru'}`,
      icon: { name: IconName.Telegram, width: 36, height: 36 },
    },
  ];
};
