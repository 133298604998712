import styles from './Title.module.scss';

import { useTranslation } from 'react-i18next';
import { UserDto } from 'dtos';
import { useMemo } from 'react';
import { getUserName } from 'utils';

interface TitleProps {
  provider: UserDto | null;
  selectedProviderId: UserDto['id'] | null;
  isMyself?: boolean;
}

export function Title({
  provider,
  selectedProviderId,
  isMyself = false,
}: TitleProps): JSX.Element {
  const { t } = useTranslation();
  const userName = useMemo(() => provider && getUserName(provider), [provider]);

  if (selectedProviderId == null) {
    return (
      <div className={styles.wrap}>
        <h2 className={styles.rideTitle}>
          {t('component.providersSlider.rideTitle')}&nbsp;
          <span className={styles.inner}>
            {t('component.providersSlider.ride')}
          </span>
        </h2>
      </div>
    );
  }

  return (
    <div className={styles.wrap}>
      <h2 className={styles.title}>{userName}</h2>
      <p className={styles.text}>
        {isMyself ? t('component.providersSlider.yourOffer') : t('component.providersSlider.text')}
      </p>
    </div>
  );
}
