import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../fetchBaseQueryWithAuth';
import { setTokens } from 'shared/lib/auth';
import { JwtTokenResponse } from 'dtos';
import { toast } from 'react-hot-toast';

// const memo: Record<string, number> = {};

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getTgSession: builder.query<boolean, string>({
      /**
       * Инструкция:
       * Мы хотим зарегистрировать пользователя через нашего бота, для этого мы даём ему возможность открыть ссылку на бота,
       * а бот уже общается с бэком, который понимает кто пришёл (по уникальному ключу uuid) и отдаёт флаг true
       *
       * С этим ключом (uuid) мы идём в другую ручку и получаем JWT токены, которые устанавливаем и перезагружаем приложение
       *
       * Мемоизацию временно отключу, пусть постоянно стучится.
       * Почему: боюсь, что в pwa нельзя будет обновить ключ и тогда невозможно будет зайти,
       * так как мы перестанем стучать в бэк с новым ключом, а на старом у нас будет false всегда
       */
      queryFn: (key, _api, _extraOptions, baseQuery) => {
        // if (!memo[key]) {
        //   memo[key] = 0;
        // }

        // if (memo[key] < 30) {
          return baseQuery(`/tg-session/${key}`)
            // @ts-ignore
            .then((data) => {
              if (data?.data) {
                return baseQuery({ url: '/login-tg/tg-session', method: 'POST', params: { key } })
                // @ts-ignore
                  .then((refreshResult) => {
                    if (refreshResult.data) {
                      const { jwt, refreshToken, userId } = (refreshResult.data as JwtTokenResponse);
                      setTokens({ jwt, refreshToken, userId });
                    }

                    return data;
                  });
              }

              // memo[key]++

              return data;
            })
            // @ts-ignore
            .catch((data) => {
              toast.error(data);
            });
        // }

        // return false;
      },
    }),
  }),
});

export const { useGetTgSessionQuery, useLazyGetTgSessionQuery } = authApi;
