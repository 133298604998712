import { useInfiniteQuery } from '@tanstack/react-query';
import type { FriendsResponse } from 'api/balances/types';
import { INITIAL_LIMIT, INITIAL_PAGE, useLazyGetContactsQuery } from 'services/contacts';

export const useGetInfiniteFriends = (searchString?: string) => {
  const [getContacts] = useLazyGetContactsQuery();

  return useInfiniteQuery({
    queryKey: ['friends', searchString],
    queryFn: async ({ pageParam = INITIAL_PAGE }) => {
      const res = await getContacts({
        page: pageParam,
        limit: INITIAL_LIMIT,
        searchString
      }).unwrap() as FriendsResponse;
      return { pageParam, ...res };
    },
    getNextPageParam: (lastPage) => {
      return lastPage.last
        ? undefined
        : (lastPage.pageParam || INITIAL_PAGE) + 1;
    },
    onSuccess: (data) => {
      const pages = data.pages;
      if (!Array.isArray(pages)) return;
    },
    keepPreviousData: true,
  });
};
