import React from 'react';
import { useTranslation } from 'react-i18next';

import RedeemInvitationWidget from 'widgets/RedeemInvitation';

import { Layout } from 'components/Layout/Layout';

const RedeemInvitationPage = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Layout.TopBar>
        <Layout.Back />

        <Layout.Title>{t('page.redeemInvitation.title')}</Layout.Title>
      </Layout.TopBar>

      <Layout.Content>
        <RedeemInvitationWidget />
      </Layout.Content>
    </Layout>
  );
};

export default RedeemInvitationPage;
