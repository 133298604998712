import { useEffect, useMemo, useState } from 'react';

const SECOND = 1000;
const MINUTE = SECOND * 60;

export const Timer = ({
  deadline,
  onExpired,
}: {
  deadline: string;
  onExpired?: () => void;
}) => {
  const parsedDeadline = useMemo(() => +new Date(deadline), [deadline]);
  const delta = parsedDeadline - Date.now();
  const [time, setTime] = useState(delta < 0 ? 0 : delta);

  useEffect(() => {
    const interval = setInterval(() => {
      const delta = parsedDeadline - Date.now();
      setTime(delta < 0 ? 0 : delta);

      if (delta <= 0) {
        if (onExpired) {
          onExpired();
        }
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [parsedDeadline, onExpired]);

  return (
    <div>
      {[(time / MINUTE) % 60, (time / SECOND) % 60]
        .map((time) => `${Math.floor(time)}`.padStart(2, '0'))
        .join(':')}
    </div>
  );
};
