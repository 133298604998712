import { retrieveAccessToken, retrieveRefreshToken } from "shared/lib/auth";

export const AUTH_FIELD = 'auth';
export const AUTH_REFRESH = 'auth-refresh';
export const USER_ID = 'userId';

export default function checkIsAuth() {
  return Boolean(
    retrieveAccessToken() || retrieveRefreshToken()
  );
}
