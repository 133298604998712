import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';

import InvitationService from 'api/invitation';

import { PROFILE } from 'pages/paths';

const TIMEOUT: number = 3000;

const useRedeemInvitationMutation = () => {
  const navigate = useNavigate();

  return useMutation(
    (shareToken: string) => {
      return InvitationService.redeemInvitationByShareToken(shareToken);
    },
    {
      onSuccess: () => {
        setTimeout(() => navigate(PROFILE), TIMEOUT);
      },
    }
  );
};

export default useRedeemInvitationMutation;
