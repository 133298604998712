import { ExchangerFormData, Language, TargetField } from 'dtos';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyGetWhatsAppMessageQuery } from 'services/callback';
import { useExchangerStore } from 'widgets/ExchangerWidget';
import { UserId } from 'widgets/ExchangerWidget/types';

import styles from './styles.module.scss';
import { getUserId } from 'shared/lib/auth';
import { Select } from 'components/Select';
import { ModalTypes, open } from 'slices/modal';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import CallMeBackModal from 'widgets/CallMeBackModal';

type Props = {
  providerId: UserId;
};

const PublicSelect = (props: Props) => {
  const { t, i18n } = useTranslation();
  const { currentFormData, activeOption, isInitialization } = useExchangerStore(props.providerId);
  const [getMessage] = useLazyGetWhatsAppMessageQuery();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const language = i18n.language as Language;
  const utm = searchParams.get('utm') as string | 'none';

  const openCallMeBackModal = useCallback((): void => {
    dispatch(
      open({
        id: ModalTypes.CallMeBack,
        data: { providerId: props.providerId, language: language, utm: utm, policyPrivacy: true },
      })
    );
  }, [dispatch]);

  const value = useMemo(() => 'other', [])
  const values = useMemo(() => ['wa', 'cm', 'cancel'], []);
  const labels = useMemo(
    () => ({
      wa: t('button.whatsapp'),
      cm: t('button.contactMe'),
      cancel: t('button.cancel'),
      other: t('button.other'),
    }),
    []
  );
  const optionsStyles = useMemo(
    () => ({
      wa: styles.wa,
      cm: styles.cm,
    }),
    []
  );

  const form = useMemo(() => {
    if (!currentFormData) {
      return null;
    }
    const { sellExchangeUnit, buyExchangeUnit, sellExchangeUnitAmount, buyExchangeUnitAmount } =
      currentFormData;
    return {
      sellExchangeUnitDto: sellExchangeUnit,
      buyExchangeUnitDto: buyExchangeUnit,
      sellExchangeUnitAmount,
      buyExchangeUnitAmount,

      currentTab: activeOption,
      targetField: `BUY_CURRENCY` as TargetField,
    } as ExchangerFormData;
  }, [currentFormData, activeOption]);

  const waClick = async () => {
    if (!form) {
      return;
    }

    try {
      // @ts-ignore
      fbq('track', 'WAButtonClick', { isAuth: Boolean(getUserId()) });
    } catch (e) {
      console.error('Failed tracking fb');
    }

    const windowReference = window.open();

    await getMessage({
      providerId: Number(props.providerId),
      language: i18n.language as Language,
      form,
    })
      .unwrap()
      .then((url) => {
        if (typeof url === 'string' && windowReference) {
          windowReference.location = url;
        }
      });
  };

  const handleChange = (v: string) => {
    if (v === 'wa') {
      waClick();
    } else if (v === 'cm') {
      openCallMeBackModal();
    }
  };

  if (!currentFormData || isInitialization) {
    return null;
  }

  return (
    <>
      <Select
        value={value}
        values={values}
        labels={labels}
        onChange={handleChange}
        buttonClassName={styles.selectBtn}
        optionsClassName={styles.selectOptions}
        unchanging
        optionsStyles={optionsStyles}
      />

      <CallMeBackModal />
    </>
  );
};

export default PublicSelect;
