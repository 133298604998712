import styles from "./AIPrioritized.module.scss";
import AIPrioritizedIcon from "../icons/AIPrioritized";
import {useTranslation} from "react-i18next";
import cn from "classnames";

export interface AIPrioritizedProps {
  description: string;
  darkTheme?: boolean;
}

function AIPrioritized({ description, darkTheme }: AIPrioritizedProps): JSX.Element | null {
  const { t } = useTranslation();
  return (
    <div className={styles.aiPrioritized}>
      <AIPrioritizedIcon className={styles.aiPrioritizedIcon}/>
      <div>
        <div className={styles.aiPrioritizedTitle}>
          {t('page.task.mainTask.aiPrioritizedTitle')}
        </div>
        <div className={cn(styles.aiPrioritizedDescription, darkTheme && styles.darkTheme)}>
          {description}
        </div>
      </div>
    </div>
  )
}

export default AIPrioritized;
