import { useCallback } from 'react';
import { QueryKey, useQuery } from '@tanstack/react-query';

import { PaymentType } from 'dtos';

import { queryClient } from 'shared/config/reactQuery';

const QUERY_KEY: QueryKey = ['payments', 'types'];
export const STORAGE_KEY: string = 'types';

export const usePersistedPaymentsFilterByType = () => {
  const { data } = useQuery({
    queryKey: QUERY_KEY,
    queryFn: () => {
      const stringifyStatuses = localStorage.getItem(STORAGE_KEY);
      if (stringifyStatuses) {
        return JSON.parse(stringifyStatuses);
      } else {
        localStorage.setItem(STORAGE_KEY, JSON.stringify([]));
        return [];
      }
    },
  });

  const selectedTypes = data ?? [];

  const persistSelectedTypes = useCallback(
    (roles: PaymentType[]): void => {
      queryClient.removeQueries({ queryKey: ['payments'], exact: true });
      localStorage.setItem(STORAGE_KEY, JSON.stringify(roles));
      queryClient.setQueryData(QUERY_KEY, () => roles);
    },
    []
  );

  const removeSelectedTypes = useCallback(
    (role: PaymentType) => {
      const roles =
        queryClient.getQueryData<PaymentType[]>(QUERY_KEY);
      if (roles) {
        const updatedRoles = roles.filter((r) => r !== role);
        persistSelectedTypes(updatedRoles);
      }
    },
    [persistSelectedTypes]
  );

  const clearSelectedTypes = useCallback(() => {
    localStorage.removeItem(STORAGE_KEY);
    persistSelectedTypes([]);
  }, [persistSelectedTypes]);

  return {
    selectedTypes,
    persistSelectedTypes,
    removeSelectedTypes,
    clearSelectedTypes,
  };
};
