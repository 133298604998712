import styles from './Payment.module.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tab } from '@headlessui/react';

import PaymentInfo from 'widgets/PaymentInfo';
import PaymentHistory from 'widgets/PaymentHistory';

import { Layout } from 'components/Layout/Layout';

import { StyledTab } from 'shared/ui/Tab/Tab';

const PaymentPage = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Layout.TopBar>
        <Layout.Back />

        <Layout.Title>{t('page.paymentInfo.title')}</Layout.Title>
      </Layout.TopBar>

      <Layout.Content>
        <Tab.Group>
          <Tab.List className={styles.tabsContainer}>
            <StyledTab>{t('page.paymentInfo.infoTab')}</StyledTab>

            <StyledTab>{t('page.paymentInfo.historyTab')}</StyledTab>
          </Tab.List>

          <Tab.Panels>
            <Tab.Panel>
              <PaymentInfo />
            </Tab.Panel>

            <Tab.Panel>
              <PaymentHistory />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </Layout.Content>
    </Layout>
  );
};

export default PaymentPage;
