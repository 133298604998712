import { Layout } from 'components/Layout/Layout';
import { Installation } from 'modules/Installation';

const InstallPage = () => {
  return (
    <Layout hasMenu={false}>
      <Layout.Content unsetBottomMargin>
        <Installation />
      </Layout.Content>
    </Layout>
  );
};

export default InstallPage;
