import styles from './UserCard.module.scss';

import React, { useMemo, useEffect, useState, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import toast from 'react-hot-toast';

import type { UserDto } from 'dtos';

import { Avatar, AvatarSize } from 'shared/ui/Avatar/Avatar';
import SkeletonWithWrapper from 'shared/ui/SkeletonWithWrapper';
import UserBadge from 'shared/ui/UserBadge';

import { getFormattedDays, getUserName, getUserPhoto } from 'utils';
import { useAutoFocus } from 'shared/hooks/useAutoFocus';
import { queryClient } from 'shared/config/reactQuery';
import { useUpdateContactByIdMutation } from 'services/contacts';
import EditIcon from "components/icons/Edit";
import checkIsWebApp from '../../../../utils/checkIsWebApp';

export interface UserCardProps {
  user?: UserDto;
  loading?: boolean;
  avatarSize?: AvatarSize;
  hideRegisterDays?: boolean;
  hideBadge?: boolean;
  reversedContent?: boolean;
  className?: string;
  withEditing?: boolean;
}

function UserCard({
  user,
  loading,
  avatarSize = AvatarSize.Default,
  hideRegisterDays,
  hideBadge,
  reversedContent,
  className,
  withEditing = false,
}: UserCardProps): JSX.Element | null {
  const { t } = useTranslation();
  const inputRef = useAutoFocus<HTMLInputElement>();
  const [name, setName] = useState<string>('');
  const [inputVisibility, setInputVisibility] = useState(false);

  const userName = useMemo(
    () => (user ? name || getUserName(user) : ''),
    [user, name]
  );
  const userPhoto = useMemo(() => user && getUserPhoto(user), [user]);
  const linkToChat = checkIsWebApp() ? user?.contactUrl : user?.contactTgDeepLink;

  useEffect(() => {
    if (user) {
      setName(getUserName(user));
    }
  }, [user]);

  useEffect(() => {
    if (inputVisibility && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputVisibility, inputRef]);

  const userRegisterDays = useMemo(
    () => (user && getFormattedDays(user.accountRegistrationDate)) || 0,
    [user]
  );

  const [updateContactById] = useUpdateContactByIdMutation();

  const changeCustomName = async () => {
    toast.promise(
      updateContactById({
        contactId: Number(user?.id),
        details: {
          customName: inputRef.current?.value,
        },
      }).unwrap(),
      {
        loading: t('status.saving'),
        success: () => {
          return <b>{t('status.settingsSaved')}</b>
        },
        error: (error) => {
          setName(getUserName(user));

          return <b>{error.message}</b>
        },
      },
    );
  }

  const showNameChangeField = () => setInputVisibility(true);

  const onChangeValueHandler = (event: ChangeEvent<HTMLInputElement>): void =>
    setName(event.target.value);

  const onFormSubmit = async () => {
    try {
      await changeCustomName();
      setInputVisibility(false);

      queryClient.refetchQueries({
        queryKey: ['payments', user?.id, 'history'],
        exact: true,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const onBlurHandler = async (): Promise<void> => {
    onFormSubmit();
  };

  const onSubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onFormSubmit();
  };

  if (loading) {
    return <SkeletonWithWrapper height={64} />;
  }

  if (!user) {
    return null;
  }

  return (
    <div
      className={cn(
        styles.container,
        styles[`${avatarSize}Gap`],
        reversedContent && styles.reverse,
        className
      )}
    >
      <Avatar
        className={styles.avatar}
        username={userName}
        profilePictureUrl={userPhoto}
        size={avatarSize}
        tgChatLink={linkToChat}
      />

      <div className={styles.infoWrap}>
        <div className={styles.usernameWrap}>
          {inputVisibility ? (
            <form onSubmit={onSubmitHandler}>
              <input
                className={styles.nameField}
                type="text"
                value={name}
                ref={inputRef}
                onChange={onChangeValueHandler}
                onBlur={onBlurHandler}
              />
            </form>
          ) : (
            <NameDisplay
              userName={userName}
              showNameChangeField={
                withEditing ? showNameChangeField : undefined
              }
              withEditing={withEditing}
            />
          )}
        </div>

        <div className={styles.info}>
          {!hideBadge && <UserBadge user={user} />}

          {!hideRegisterDays && (
            <span className={styles.registerDays}>
              {Math.round(userRegisterDays) + ' ' + t('date.days')}
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

interface NameDisplayProps {
  userName: string,
  showNameChangeField?: () => void;
  withEditing?: boolean,
}

const NameDisplay = ({ userName, showNameChangeField, withEditing }: NameDisplayProps) => (
  <>
    <span className={styles.name} onClick={showNameChangeField}>
      {userName}
    </span>

    {withEditing && (
      <button className={styles.changeNameBtn} onClick={showNameChangeField}>
        <EditIcon className={styles.icon}/>
      </button>
    )}
  </>
);

export { UserCard };
