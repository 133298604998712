import {
  CurrencyInformationResponseDto,
  CurrencyPaymentMethodInfo, PaymentFormDtoV5,
  PaymentMethodsResponseDto,
} from 'dtos';
import type { ExchangeCalculationResponse } from 'dtos';
import type { UserIdParameter } from 'api/payments/types';

export const hasExchangeCalculations = (
  calc?:
    | {
        crossExchangeWidgetData?: {
          sellCurrency: {
            amount: unknown;
          };
        };
        exchanges?: Array<unknown>;
      }
    | undefined
): calc is ExchangeCalculationResponse => {
  if (!(calc instanceof Object)) {
    return false;
  }
  return (
    (!!calc?.crossExchangeWidgetData?.sellCurrency?.amount ?? false) &&
    !!calc?.exchanges?.map
  );
};

export const formatExchangeCurrencyAmount = (
  value: PaymentFormDtoV5['sellExchangeUnitAmount' | 'buyExchangeUnitAmount']
): number => {
  if (!value) return 0;

  return parseFloat(String(value).replaceAll(',', '.'));
};

// TODO: add lazy migration to eliminate _v3 suffix later
// i.e. remove `paymentForm-${userId}` if exists
export const paymentFormLocalStorageKey = (
  id: UserIdParameter,
  providerId: UserIdParameter
) => {
  if (id !== null) {
    return `paymentForm_v5-${id}`;
  }
  return `paymentForm_v5_pid_${providerId}`;
};

export const zeroIfNotNumber = (f: unknown) => (typeof f !== 'number' ? 0 : f);

type PaymentMethods = CurrencyInformationResponseDto['paymentMethods'];

export const findPaymentMethodByType = (
  paymentMethods: PaymentMethods,
  type: PaymentMethodsResponseDto['id']
): PaymentMethodsResponseDto | undefined =>
  paymentMethods.find((m) => m.id === type);

export const findCurrencyPaymentMethodInfoByTypeAndName = (
  paymentMethods: PaymentMethods,
  type: PaymentMethodsResponseDto['id'],
  name: CurrencyPaymentMethodInfo['methodName']
): CurrencyPaymentMethodInfo | undefined =>
  findPaymentMethodByType(paymentMethods, type)?.methods.find(
    (m) => m.methodName === name
  );
