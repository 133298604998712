import styles from './styles.module.scss';

import { useEffect, useState } from 'react';

import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import {
  useAddPushSubscriptionMutation,
  useGetPushSubscriptionsQuery,
  useLazyGetPushPublicKeyQuery,
  useRemovePushSubscriptionMutation,
} from 'services/subscriptions';
import Spinner from 'components/Spinner/Spinner';

const CustomNotification = () => {
  const { t } = useTranslation();
  const [currentSubscription, setCurrentSubscription] = useState<
    string | undefined
  >();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [getPublicKey, {isLoading: isLoadingPublicKey}] = useLazyGetPushPublicKeyQuery();
  const [addSubscription, {isLoading: isLoadingSubscription}] = useAddPushSubscriptionMutation();
  const [removeSubscription, {isLoading: isRemovingSubscription}] = useRemovePushSubscriptionMutation();
  const { data = [], isLoading } = useGetPushSubscriptionsQuery();

  useEffect(() => {
    const isAvailable = async () => {
      try {
        if (!isPushNotificationSupported()) {
          setError(t('notification.notSupported'));
          return;
        }

        const registration = await navigator.serviceWorker.getRegistration();
        const subscription = await registration?.pushManager.getSubscription();
        setCurrentSubscription(subscription?.endpoint);
      } catch (error) {
        if (!(error instanceof Error)) return;

        setError(error.message);
        console.error(error);
      }
    };

    isAvailable();
    setLoading(false);
  }, [data]);

  const handleSubscribeButtonClick = () => {
    subscribe();
  };

  const handleUnsubscribeButtonClick = () => {
    unsubscribe();
  };

  const handleRemoveSubscription = (id: string) => () => {
    removeSubscription(id);
  };

  async function unsubscribe() {
    const registration = await navigator.serviceWorker.ready;
    const subscription = await registration.pushManager.getSubscription();

    try {
      await subscription?.unsubscribe();
      await removeSubscription(String(currentSubscription));
    } catch (error) {
      console.error(error);
    }
  }

  async function subscribe() {
    await Notification.requestPermission();

    // if (notificationPermission === 'granted') {
      const publicKey = await getPublicKey().unwrap();

      const registration = await navigator.serviceWorker.ready;
      console.log('publicKey, registration', publicKey, registration);
      const subscription = await registration?.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: publicKey,
        // "BKgZAQKjnTOewocgh5Q0oV5-rmlHbdCdFi3MAE30kphD2H3tkWLZM0W6WmpM3lquIR-hrsqvquck7nvb5oY2Z1g"
        // "BNLMtkjPnXdGE6boM2Mit2wjZkQp5AAWf5ASb5aeZ_anqoM-z4SdjBvma7HdPNWMWYU7LZSTOFaDDiFPOzuBh20"
      });
      console.log('subscription--', subscription);
      if (subscription) {
        await addSubscription(subscription);
      }

    // } else {
    //   setError(t('notification.denied'));
    // }
  }

  if (loading || isLoading) {
    return <Spinner className={styles.spinner} />;
  }

  const hasSub = data.find((sub) => sub.endpoint === currentSubscription);

  return (
    <div>
      {error && <p>{error}</p>}
      {!error && hasSub && (
        <Button
          onClick={handleUnsubscribeButtonClick}
          isLoading={isRemovingSubscription}
          disabled={isRemovingSubscription}
        >
          {t('notification.unsubscribe')}
        </Button>
      )}

      {!error && !hasSub && (
        <Button
          onClick={handleSubscribeButtonClick}
          isLoading={isLoadingPublicKey || isLoadingSubscription}
          disabled={isLoadingPublicKey || isLoadingSubscription}
        >
          {t('notification.subscribe')}
        </Button>
      )}

      {data.map((subscription, index) => (
        <div
          className={styles.subscriptionWrapper}
          key={index + subscription.auth}
          onClick={handleRemoveSubscription(subscription.endpoint)}
        >
          <p>
            {currentSubscription === subscription.endpoint ? '+' : '-'}
            {subscription.auth}
          </p>
        </div>
      ))}
    </div>
  );
};

// function urlB64ToUint8Array(base64String: string) {
//   const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
//   const base64 = (base64String + padding)
//     .replace(/\-/g, '+')
//     .replace(/_/g, '/');
//   const rawData = window.atob(base64);
//   const outputArray = new Uint8Array(rawData.length);
//   for (let i = 0; i < rawData.length; ++i) {
//     outputArray[i] = rawData.charCodeAt(i);
//   }
//   return outputArray;
// }

function isPushNotificationSupported() {
  return "serviceWorker" in navigator && "PushManager" in window;
}

export default CustomNotification;
