import React, { ReactNode } from 'react';

import styles from './Cell.module.scss';

type CellProps = {
  children: ReactNode;
};

export const Cell = ({ children }: CellProps) => {
  return <div className={styles.cell}>{children}</div>;
};
