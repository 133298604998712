import { useCallback } from 'react';
import { QueryKey, useQuery } from '@tanstack/react-query';

import { queryClient } from 'shared/config/reactQuery';
import {BalanceTransactionProps} from 'components/BalancesHistory/BalanceTransaction';

const queryKey: QueryKey = ['transaction-info'];

export interface TransactionInfoModalState {
  visible: boolean;
  data: BalanceTransactionProps | null;
}

export const useTransactionInfoModal = () => {
  const { data: state } = useQuery<TransactionInfoModalState>({
    queryKey,
    queryFn: () => ({ visible: false, data: null }),
    staleTime: Infinity,
  });

  const openModal = useCallback((data: BalanceTransactionProps) => {
    queryClient.setQueryData<TransactionInfoModalState>(queryKey, (prev) => {
      if (prev)
        return {
          ...prev,
          visible: true,
          data: {
            ...prev.data,
            ...data,
          },
        };
    });
  }, []);

  const closeModal = useCallback(() => {
    queryClient.setQueryData<TransactionInfoModalState>(queryKey, (prev) => {
      if (prev)
        return {
          ...prev,
          visible: false,
          data: null,
        };
    });
  }, []);

  return {
    state,
    openModal,
    closeModal,
  };
};
