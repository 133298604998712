import styles from './index.module.scss';
import cx from 'classnames';
import { Link } from 'react-router-dom';

const ProfileCircleLink = ({ link, children, onClick, text }: any) => {
  return link ? (
    <Link to={link} className={styles.root}>
      <div className={cx(styles.icon)}>{children}</div>
      {text && <span className={styles.text}>{text}</span>}
    </Link>
  ) : (
    <div className={cx(styles.root)} onClick={onClick}>
      <div className={cx(styles.icon)}>{children}</div>
      {text && <span className={styles.text}>{text}</span>}
    </div>
  );
};

export default ProfileCircleLink;
