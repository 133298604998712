import { useTranslation } from 'react-i18next';
import { UserDto } from 'dtos';
import { getUserName, getUserPhoto } from 'utils';

import styles from './PaymentUserInfo.module.scss';

interface PaymentUserInfoProps {
  user?: UserDto;
  isInvitation?: boolean;
  isCustomer?: boolean;
  isTransaction?: boolean;
}

export default function PaymentUserInfo(props: PaymentUserInfoProps): JSX.Element {
  const { user, isInvitation, isCustomer, isTransaction } = props;
  const { t } = useTranslation();

  const nameFromUser = user ? getUserName(user) : '';
  const photoFromUser = user ? getUserPhoto(user) : '';

  const userName = !isInvitation ? nameFromUser : t('page.exchangeInfo.inviteLink');
  const userPhoto = !isInvitation ? photoFromUser : '/logo180.png';
  const userRole = (isInvitation || isTransaction)? t('page.exchangeInfo.contact')
    : isCustomer ? t('page.exchangeInfo.customer') : t('page.exchangeInfo.provider');

  const providerPhoto = {
    backgroundImage: 'url(' + userPhoto + ')',
  };

  return (
    <>
      <div className={styles.userInfo}>
        <div className={styles.userPhoto} style={providerPhoto}></div>
        <div className={styles.userNamePart}>
          <div className={styles.userRole}>{userRole}</div>
          <div className={styles.userName}>{userName}</div>
        </div>
      </div>
    </>
  );
}
