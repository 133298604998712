import styles from './Header.module.scss';

import React, { PropsWithChildren } from 'react';
import cn from 'classnames';

export interface HeaderProps extends PropsWithChildren {
  className?: string;
  unsetRowGap?: boolean;
  unsetBottomPadding?: boolean;
}

function Header({ children, className, unsetRowGap, unsetBottomPadding }: HeaderProps): JSX.Element | null {
  if (!children) return null;

  return <header className={cn(styles.header, className, unsetRowGap && styles.unsetRowGap, unsetBottomPadding && styles.unsetBottomPadding)}>{children}</header>;
}

export default Header;
