import { useMutation, useQueryClient } from '@tanstack/react-query';

import instance from 'api';
import { FeeRangeServiceSettings } from '../types/feeRate';
import type { FeeRateRangeDto } from 'dtos';
import { exchangerQueryKey } from 'widgets/ExchangerWidget';

const useChangeFeeRangeMutation = ({
  userId,
  queryKey,
  zeroBoundQueryKey,
  apiEndpoint,
  shouldUpdateExchanger,
}: FeeRangeServiceSettings) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (newFeeRange) => instance.put(apiEndpoint, newFeeRange),
    onMutate: async (newFeeRange: FeeRateRangeDto) => {
      await queryClient.cancelQueries(queryKey);
      const previousFeeRates =
        queryClient.getQueryData<FeeRateRangeDto[]>(queryKey);
      const previousZeroBound =
        queryClient.getQueryData<number>(zeroBoundQueryKey);

      return { previousZeroBound, previousFeeRates };
    },
    onSuccess: () => {
      if (shouldUpdateExchanger) {
        queryClient.refetchQueries(exchangerQueryKey(null, userId));
      }
    },
    onSettled: (data, error, newFeeRange, context) => {
      if (context?.previousZeroBound) {
        queryClient.setQueryData(zeroBoundQueryKey, newFeeRange.feeRate);
      }
      queryClient.invalidateQueries(zeroBoundQueryKey);
      queryClient.refetchQueries(['contact', userId]);

      if (context?.previousFeeRates) {
        const updatedFeeRangeIndex = context.previousFeeRates
          .map((rate) => rate.id)
          .indexOf(newFeeRange.id);
        queryClient.setQueryData<FeeRateRangeDto[]>(queryKey, [
          ...context.previousFeeRates.slice(0, updatedFeeRangeIndex),
          newFeeRange,
          ...context.previousFeeRates.slice(updatedFeeRangeIndex + 1),
        ]);
      }

      queryClient.refetchQueries(['contacts']);
    },
  });
};

export { useChangeFeeRangeMutation };
