import isEmpty from 'lodash/isEmpty';

export default function checkIsWebApp() {
  // For SSR
  if (typeof window === 'undefined') {
    return false;
  }

  const webApp = window?.Telegram?.WebApp;
  return !isEmpty(webApp?.initData) && webApp?.platform !== 'unknown';
}
