import styles from './ExchangeContinueButton.module.scss';

import { FC, MouseEventHandler } from 'react';
import { useNavigate } from 'react-router-dom';
import isNull from 'lodash/isNull';
import upperCase from 'lodash/upperCase';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';

import Button, { Variant } from 'components/Button/Button';

import { ExchangerWidgetProps } from '../../ExchangerWidget';
import { UseExchangerStoreReturn, useExchangerStore } from '../../store';
import SkeletonWithWrapper from 'shared/ui/SkeletonWithWrapper';
import { useCreateSessionMutation } from 'services/payments';
import { useProvidersStore } from 'shared/store/providers';
import { PaymentCreationRequestDtoV3, PaymentFormDtoV5, TargetField } from 'dtos';
import { formatExchangeCurrencyAmount } from 'widgets/Exchanger/lib';
import { toast } from 'react-hot-toast';
import { getExchangeUnitDto } from 'dtos/dtoMapper';

export const ExchangeContinueButton: FC<ExchangerWidgetProps> = (
  props
) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { providerId, userId } = props;
  const { errorData, isCalculating, isInitialization } = useExchangerStore(
    providerId,
    userId
  );
  const [createSession, { isLoading }] = useCreateSessionMutation();
  const { primaryProvider } = useProvidersStore();
  const exchanger: UseExchangerStoreReturn = useExchangerStore(
    primaryProvider?.id,
    null,
    { cache: true }
  );
  const paymentForm: PaymentFormDtoV5 =
    exchanger?.currentFormData as PaymentFormDtoV5;

  if (isInitialization) {
    return (
      <div className={styles.wrap}>
        <SkeletonWithWrapper height={56} />
      </div>
    );
  }

  const goToExchangePage: MouseEventHandler<HTMLButtonElement> = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const preparedForm: PaymentCreationRequestDtoV3 = {
      providerId: paymentForm.providerId,
      userId: paymentForm.customerId,

      buyExchangeUnit: getExchangeUnitDto(paymentForm.buyExchangeUnit, true),
      buyCurrencyAmount: formatExchangeCurrencyAmount(
        paymentForm.buyExchangeUnitAmount
      ),
      sellExchangeUnit: getExchangeUnitDto(paymentForm.sellExchangeUnit, true),
      sellCurrencyAmount: formatExchangeCurrencyAmount(
        paymentForm.sellExchangeUnitAmount
      ),
      targetField: `${upperCase(
        exchanger?.inputTarget || 'buy'
      )}_CURRENCY` as TargetField,
      utmTag: exchanger.utmTag
    };

    try {
      const { sessionKey } = await createSession(preparedForm).unwrap();
      navigate(`/exchange/${sessionKey}`, { state: { sessionKey } });
    } catch (e) {
      Sentry.captureException(e);
      if (!(e instanceof Error)) return;
      toast.error(e.message);
    }
  };

  if (isNull(userId) || providerId === userId) {
    return null;
  }

  const hasError: boolean = Boolean(
    errorData?.isErrorOnInit || errorData?.isErrorOnUpdate
  );
  const errorText: string = `button.${
    hasError ? 'notValidExchange' : 'continue'
  }`;
  const buttonText: string = t(
    isCalculating || isLoading ? 'button.loading' : errorText
  );

  return (
    <div className={styles.wrap}>
      <Button
        type="button"
        variant={Variant.Exchanger}
        onClick={goToExchangePage}
        isLoading={isCalculating || isLoading}
        disabled={hasError || isCalculating || isLoading || !paymentForm}
      >
        {buttonText}
      </Button>
    </div>
  );
};
