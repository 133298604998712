import type { QueryKey, UseInfiniteQueryResult } from '@tanstack/react-query';
import { useInfiniteQuery } from '@tanstack/react-query';

import PaymentsService from 'api/payments';
import type { PaymentsResponse } from 'api/payments/types';

import { PaymentStatus, UserDto } from 'dtos';

type UseGetInfinitePaymentsOptions = {
  queryKey: QueryKey;
  limit?: number;
  filterable?: boolean;
  participantId?: UserDto['id'];
  filters?: {[key: string]: string[]}
};

type InfiniteQueryData = {
  pageParam?: number;
} & PaymentsResponse;

const INITIAL_PAGE: number = 1;
const INITIAL_LIMIT: number = 20;

const defaultStatuses = [PaymentStatus.Initialized, PaymentStatus.InProgress];

export const useGetInfinitePayments = ({
  queryKey,
  limit,
  filterable,
  participantId,
  filters,
}: UseGetInfinitePaymentsOptions): UseInfiniteQueryResult<InfiniteQueryData> => {
  return useInfiniteQuery<InfiniteQueryData>({
    queryKey,
    queryFn: async ({ pageParam = INITIAL_PAGE }) => {
      let paymentStatuses, paymentTypes, participantRoles;

      if (!filters) {
      const LSPaymentStatuses = localStorage.getItem('statuses');
      paymentStatuses = LSPaymentStatuses
        ? JSON.parse(LSPaymentStatuses)
        : defaultStatuses;
      const LSPaymentTypes = localStorage.getItem('types');
      paymentTypes = LSPaymentTypes
        ? JSON.parse(LSPaymentTypes)
        : [];

      const LSParticipantsRoles = localStorage.getItem('roles');
      participantRoles = LSParticipantsRoles
        ? JSON.parse(LSParticipantsRoles)
        : [];
      } else {
        paymentStatuses = filters.paymentStatuses ?? [];
        paymentTypes = filters.paymentTypes ?? [];
        participantRoles = filters.participantRoles ?? [];
      }

      const res = await PaymentsService.getPayments({
        participantId,
        limit: limit || INITIAL_LIMIT,
        page: pageParam || INITIAL_PAGE,
        paymentStatuses: filterable ? paymentStatuses : undefined,
        participantRoles: filterable ? participantRoles : undefined,
        paymentTypes: filterable ? paymentTypes : undefined,
      });
      return { pageParam, ...res };
    },
    getNextPageParam: (lastPage) => {
      return lastPage.last
        ? undefined
        : (lastPage.pageParam || INITIAL_PAGE) + 1;
    },
  });
};
