import React from 'react';

import { InfiniteContactList } from 'modules/InfiniteContactList';

import { Layout } from 'components/Layout/Layout';
import CreateBalanceTransactionModal from 'widgets/CreateBalanceTransactionModal';

type FriendsContentProps = {
  searchString?: string;
};

export function FriendsContent(props: FriendsContentProps): JSX.Element {
  const { searchString } = props;

  return (
    <Layout.Content unsetBottomPadding>
      <InfiniteContactList searchString={searchString} />

      <CreateBalanceTransactionModal />

    </Layout.Content>
  );
}
