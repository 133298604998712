import { useQuery } from '@tanstack/react-query';

import instance from 'api';
import { FEE_RANGE_DEFAULTS_ENDPOINT } from '../constants';
import type { FeeRangeServiceSettings } from '../types/feeRate';

const useGetFeeRatesQueryByUserId = ({
  userId,
  queryKey,
  apiEndpoint,
}: FeeRangeServiceSettings) => {
  const defaultFees: boolean = apiEndpoint === FEE_RANGE_DEFAULTS_ENDPOINT;
  return useQuery({
    queryKey,
    queryFn: async () => {
      const { data } = await instance.get(
        apiEndpoint,
        defaultFees ? {} : { params: { userId } }
      );
      return data;
    },
    enabled: defaultFees || Boolean(userId),
  });
};

export { useGetFeeRatesQueryByUserId };
