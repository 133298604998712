import instance from 'api';
import type {
  BalanceTransactionsResponse,
  BalanceTransactionsDto,
} from './types';

const BASE_ENDPOINT: string = '/balances';

export default class BalancesService {
  static async getBalancesTransactionsByContactId({
    signal,
    ...params
  }: BalanceTransactionsDto<BalanceTransactionsResponse>): Promise<BalanceTransactionsResponse> {
    const { data } = await instance.get<BalanceTransactionsResponse>(
      BASE_ENDPOINT + '/v3/transactions',
      { params, signal }
    );
    return data;
  }

  static async getBucketNameSuggestions(balanceId: number): Promise<string[]> {
    const {data} = await instance.get<string[]>(
      BASE_ENDPOINT + '/v2/buckets',
      {params: {balanceId}}
    );
    return data;
  }
}
