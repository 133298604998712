import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { register as registerServiceWorker } from '../serviceWorkerRegistration';
import { ModalTypes, open } from '../slices/modal';

// wrapper component to add in service worker details.
export const withSwRegistration = (WrappedComp: FC<{}>) => () => {
  const dispatch = useDispatch();

  const openUpdateModal = () => {
    dispatch(
      open({
        id: ModalTypes.Update,
        data: {},
      })
    );
  };

  if (typeof window !== 'undefined') {
    registerServiceWorker({ openUpdateModal: openUpdateModal });
  }

  return <WrappedComp />;
};
