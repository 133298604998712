import styles from './PaymentsHistoryWidget.module.scss';

import { useEffect, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import type { UseInfiniteQueryResult } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import flattenDeep from 'lodash/flattenDeep';
import isObject from 'lodash/isObject';
import findLastIndex from 'lodash/findLastIndex';
import isEqual from 'lodash/isEqual';

import { InfiniteList } from 'components/InfiniteList';
import Payment from 'components/Payment';
import PaymentsTotalIncome from 'entities1/payments/ui/PaymentsTotalIncome';
import SkeletonWithWrapper from 'shared/ui/SkeletonWithWrapper';
import type { PaymentForListDtoV4 } from 'dtos';
import { groupListByCreatedDate, sortListByCreatedDate } from 'utils';

type InfiniteQueryProps = Pick<
  UseInfiniteQueryResult<{ content: PaymentForListDtoV4[] }>,
  'data' | 'isLoading' | 'fetchNextPage' | 'isFetchingNextPage'
>;

type PaymentsProps = Partial<InfiniteQueryProps> & {onlyTransactions?: boolean};

function PaymentsHistoryWidget({
  data,
  isLoading,
  fetchNextPage,
  isFetchingNextPage,
  onlyTransactions,
}: PaymentsProps): JSX.Element {
  const { ref, inView } = useInView();
  const { t } = useTranslation();

  const groupedPaymentsByCreatedDate = useMemo(() => {
    const contents = map(data?.pages, (p) => p.content);
    const payments = flattenDeep(contents);
    const sortedPayments = sortListByCreatedDate(payments);
    return onlyTransactions ? sortedPayments : groupListByCreatedDate(sortedPayments);
  }, [data?.pages]);

  useEffect(() => {
    if (inView) {
      fetchNextPage && fetchNextPage();
    }
  }, [inView, fetchNextPage]);

  const indexOfLastElement = findLastIndex(groupedPaymentsByCreatedDate);

  return (
    <>
      {!onlyTransactions && <PaymentsTotalIncome />}
      <InfiniteList
        limit={20}
        loading={isLoading}
        loadingNextPage={isFetchingNextPage}
        loaderItem={<SkeletonWithWrapper height={66} />}
        emptyListPlaceholder={t('page.payments.empty')}
        listClassName={styles.payments}
      >
        {groupedPaymentsByCreatedDate.map((paymentOrDate, idx) => {
          if (isObject(paymentOrDate)) {
            return (
              <Payment
                key={paymentOrDate.id}
                paymentRef={isEqual(indexOfLastElement, idx) ? ref : undefined}
                payment={paymentOrDate}
              />
            );
          }
          return (
            <h3 key={paymentOrDate} className={styles.date}>
              {paymentOrDate}
            </h3>
          );
        })}
      </InfiniteList>
    </>
  );
}

export default PaymentsHistoryWidget;
