import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../fetchBaseQueryWithAuth';
import { PageTaskResponseDto, TaskResponseDto, TaskStatus, TaskStillInProgressDto } from 'dtos';
import { delayedPromiseRetry } from 'delayed-promise-retry';
import isEmpty from 'lodash/isEmpty';

const retryDelay = (retryNumber: number) => {
  let n: number;

  switch (retryNumber) {
    case 1: {
      n = 2;
      break;
    }
    case 2: {
      n = 3;
      break;
    }
    case 3: {
      n = 5;
      break;
    }
    default: {
      n = 7;
    }
  }

  return n * 1000;
};
const retryTask = (baseQuery: any, key: string) => {
  return delayedPromiseRetry(async () => {
    const result = await baseQuery({
      url: '/task/v2/check-request',
      params: { requestKey: key },
    });

    if (result?.data.isCompleted === true) {
      return result;
    }

    if (isEmpty(result?.data)) {
      throw new Error('Error Null');
    }

    throw new Error('Error Waiting');
  }, 20, retryDelay);
};

export const tasksApi = createApi({
  reducerPath: 'tasksApi',
  baseQuery: baseQueryWithReauth(true),
  tagTypes: ['Task'],
  endpoints: (builder) => ({
    getTaskById: builder.query<TaskResponseDto, number>({
      query: (taskId) => ({
        url: `/task/${taskId}`
      }),
      providesTags: (_result, _error, taskId) => [{ type: 'Task', id: taskId }]
    }),
    createTask: builder.mutation<TaskResponseDto, { task?: string, audio?: File }>({
      queryFn: (params, _api, _extraOptions, baseQuery) => {
        const formData = new FormData();

        if (params.audio) {
          formData.append("audio", params.audio, params.audio.name);
        }
        return baseQuery({
          url: '/task',
          method: 'POST',
          params: { task: params.task },
          body: formData,
        })
          // @ts-ignore
          .then((data) => {
            return data;
          });
      },
      invalidatesTags: ['Task'],
    }),
    createTaskWithAssessment: builder.mutation<{ task: TaskResponseDto }, { task?: string, audio?: File }>({
      queryFn: (params, _api, _extraOptions, baseQuery) => {
        const formData = new FormData();

        if (params.audio) {
          formData.append("audio", params.audio, params.audio.name);
        }
        return baseQuery({
          url: '/task/v2/create-with-assessment',
          method: 'POST',
          params: { task: params.task },
          body: formData,
          responseHandler: 'text',
        })
          // @ts-ignore
          .then((data) => {
            if (typeof data?.data === 'string') {
              const key = data.data;
              return retryTask(baseQuery, key)
            }
          })
      },
      invalidatesTags: ['Task'],
    }),
    initialAssessment: builder.mutation<{ task: TaskResponseDto }, { taskId: number, audio?: File }>({
      queryFn: (params, _api, _extraOptions, baseQuery) => {
        return baseQuery({
          url: '/task/v2/initial-assessment',
          method: 'POST',
          params: { taskId: params.taskId },
          body: { audio: params.audio },
          responseHandler: 'text',
        })
          // @ts-ignore
          .then((data) => {
            if (typeof data?.data === 'string') {
              const key = data.data;
              return retryTask(baseQuery, key)
            }
          })
      },
      invalidatesTags: (_result, _error, arg) => [{ type: 'Task', id: arg.taskId }],
    }),
    taskAssessmentWithAnswer: builder.mutation<{ task: TaskResponseDto }, { taskId: number; answer?: string, audio?: File }>({
      queryFn: (params, _api, _extraOptions, baseQuery) => {
        const formData = new FormData();

        if (params.audio) {
          formData.append("audio", params.audio, params.audio.name);
        }

        return baseQuery({
          url: '/task/v2/answer',
          method: 'POST',
          params: { taskId: params.taskId, answer: params.answer },
          body: formData,
          responseHandler: 'text',
        })
          // @ts-ignore
          .then((data) => {
            if (typeof data?.data === 'string') {
              const key = data.data;
              return retryTask(baseQuery, key)
            }
          })
      },
      invalidatesTags: (_result, _error, arg) => [{ type: 'Task', id: arg.taskId }],
    }),
    prioritizeTasks: builder.mutation<TaskResponseDto, void>({
      queryFn: (_, _api, _extraOptions, baseQuery) => {
        return baseQuery({
          url: '/task/v2/prioritize',
          method: 'POST',
          responseHandler: 'text',
        })
          // @ts-ignore
          .then((data) => {
            if (typeof data?.data === 'string') {
              const key = data.data;
              return retryTask(baseQuery, key)
            }
          })
      },
      invalidatesTags: ['Task'],
    }),
    getTaskList: builder.query<PageTaskResponseDto, { statuses?: TaskStatus[]; page: number; limit: number }>({
      query: (params) => ({
        url: '/task/list',
        params: params,
      }),
      providesTags: (result) =>
        result?.content
          ? [...result.content.map(({ id }) => ({ type: 'Task' as const, id })), 'Task']
          : ['Task'],
    }),
    startWorkingOnTask: builder.mutation<TaskResponseDto, number>({
      query: (taskId) => ({
        url: '/task/start-working',
        method: 'POST',
        params: { taskId },
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'Task', id: arg }],
    }),
    completeTask: builder.mutation<TaskResponseDto, number>({
      query: (taskId) => ({
        url: '/task/complete',
        method: 'POST',
        params: { taskId },
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'Task', id: arg }],
    }),
    postponeTask: builder.mutation<TaskResponseDto, { taskId: number, comment?: string }>({
      query: (params,) => ({
        url: '/task/postpone',
        method: 'POST',
        params: params,
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'Task', id: arg.taskId }],
    }),
    changePostponedToReadyForWorkStatus: builder.mutation<TaskResponseDto, number>({
      query: (taskId) => ({
        url: '/task/ready-for-work',
        method: 'POST',
        params: { taskId },
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'Task', id: arg }],
    }),
    deleteTask: builder.mutation<TaskResponseDto, number>({
      query: (taskId) => ({
        url: '/task',
        method: 'DELETE',
        params: { taskId },
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'Task', id: arg }],
    }),
    checkRequest: builder.query<TaskResponseDto, number>({
      query: (reqKey) => ({
        url: `/task/check-request?reqKey=${reqKey}`
      }),
      // providesTags: (_result, _error, taskId) => [{ type: 'Task', id: taskId }]
    }),
    changeTaskNotificationTime: builder.mutation<void, number>({
      query: (taskId) => ({
        url: `/task/notification-time`,
        method: 'POST',
        params: { taskId: taskId }
      }),
    }),
    stillInProgressAnswer: builder.mutation<{ taskStillInProgressDto: TaskStillInProgressDto }, { taskId: number, question?: string, answer?: string, audio?: File }>({
      queryFn: (params, _api, _extraOptions, baseQuery) => {
        const formData = new FormData();

        if (params.audio) {
          formData.append("audio", params.audio, params.audio.name);
        }

        return baseQuery({
          url: `/task/v2/still-in-progress`,
          method: 'POST',
          params: { taskId: params.taskId, currentQuestion: params.question, answer: params.answer },
          body: formData,
          responseHandler: 'text',
        })
          // @ts-ignore
          .then((data) => {
            if (typeof data?.data === 'string') {
              const key = data.data;
              return retryTask(baseQuery, key)
            }
          })
      },
    }),
    takeOnTask: builder.mutation<TaskResponseDto, number>({
      query: (taskId) => ({
        url: `/task/undelegate`,
        method: 'POST',
        params: { taskId: taskId }
      }),
      invalidatesTags: ['Task']
    }),
    delegateTask: builder.mutation<TaskResponseDto, { taskId: number, contactId: number }>({
      query: (params) => ({
        url: `/task/delegate`,
        method: 'POST',
        params: { taskId: params.taskId, contactId: params.contactId }
      }),
      invalidatesTags: ['Task']
    }),
  }),

});

export const {
  useGetTaskByIdQuery,
  useCreateTaskMutation,
  useCreateTaskWithAssessmentMutation,
  useInitialAssessmentMutation,
  useTaskAssessmentWithAnswerMutation,
  usePrioritizeTasksMutation,
  useGetTaskListQuery,
  useLazyGetTaskListQuery,
  useStartWorkingOnTaskMutation,
  useCompleteTaskMutation,
  usePostponeTaskMutation,
  useChangePostponedToReadyForWorkStatusMutation,
  useDeleteTaskMutation,
  useLazyCheckRequestQuery,
  useChangeTaskNotificationTimeMutation,
  useStillInProgressAnswerMutation,
  useTakeOnTaskMutation,
  useDelegateTaskMutation
} = tasksApi;
