import { BaseIconProps } from '../types';

const EditIcon = (props: BaseIconProps) => (
  <svg
    width={20}
    height={20}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      d="M9.5 13H14M11.75 5.29031C11.9489 5.10443 12.2187 5 12.5 5C12.6393 5 12.7772 5.02564 12.9059 5.07545C13.0346 5.12526 13.1515 5.19827 13.25 5.29031C13.3485 5.38236 13.4266 5.49162 13.4799 5.61188C13.5332 5.73214 13.5607 5.86103 13.5607 5.99119C13.5607 6.12136 13.5332 6.25025 13.4799 6.37051C13.4266 6.49077 13.3485 6.60003 13.25 6.69208L7 12.5327L5 13L5.5 11.131L11.75 5.29031Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default EditIcon;
