import { Layout } from 'components/Layout/Layout';
import Assistants from 'components/Assistants';

function AssistantsPage() {
  return (
    <Layout>
      <Layout.TopBar>
        <Layout.Back />
      </Layout.TopBar>

      <Layout.Content>
        <Assistants />
      </Layout.Content>
    </Layout>
  );
}

export default AssistantsPage;
