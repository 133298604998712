import styles from './index.module.scss';

import { FC } from 'react';

export const EnumSlide: FC<any> = (props) => {
  const { localizedValue } = props;
  return (
    <div className={styles.enumSlide}>
      <span className={styles.title}>{localizedValue}</span>
    </div>
  );
};
