import styles from './index.module.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { UseQueryResult } from '@tanstack/react-query';

import FeeRateRow from '../FeeRateRow';

import SkeletonWithWrapper from 'shared/ui/SkeletonWithWrapper';

import type { FeeRateRangeDto } from 'dtos';

type FeeRatesTableProps = {
  ratesQueryResult: UseQueryResult<FeeRateRangeDto[]>;
  onRateUpdate: (feeRateRangeDto: FeeRateRangeDto) => void;
  onRateRemove: (feeRateRangeId: FeeRateRangeDto['id']) => void;
};

function FeeRatesTable({
  ratesQueryResult,
  onRateUpdate,
  onRateRemove,
}: FeeRatesTableProps) {
  const { t } = useTranslation();

  const { isSuccess, isLoading, data: feeRates } = ratesQueryResult;

  if (isLoading) {
    return <SkeletonWithWrapper height={322} style={{ margin: '32px 0' }} />;
  }

  const tableText = (text: string): string =>
    t(`page.contactProfile.feeRanges.feeRatesTable.${text}`);

  return (
    <div className={styles.container}>
      <span className={styles.caption}>{tableText('caption')}</span>

      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.header}>{tableText('thead.lowerBound')}</div>
          <div className={styles.header}>{tableText('thead.feeRate')}</div>
        </div>

        {isSuccess
          ? feeRates.map((rate) => (
              <FeeRateRow
                key={rate.id}
                rate={rate}
                ratesQueryResult={ratesQueryResult}
                onRateUpdate={onRateUpdate}
                onRateRemove={() => onRateRemove(rate.id)}
              />
            ))
          : null}
      </div>
    </div>
  );
}

export default FeeRatesTable;
