import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { HOME } from 'pages/paths';
import Button from 'components/Button/Button';
import { Layout } from 'components/Layout/Layout';

import styles from './NotFound.module.scss';

const NotFoundPage = (): JSX.Element => {
  const navigate = useNavigate();
  const handleClick = () => navigate(HOME);

  return (
    <Layout hasMenu={false}>
      <Layout.Content>
        <div className={styles.container}>
          <h1 className={styles.title}>404</h1>
          <h2 className={styles.subtitle}>Page not found</h2>
          <p className={styles.description}>
            The link you followed may be broken or the page has been removed.
          </p>
          <p>
            Go back to&nbsp;
            <Link className={styles.link} to="/">
              main menu
            </Link>
          </p>
          <Button className={styles.button} onClick={handleClick}>
            Main Menu
          </Button>
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default NotFoundPage;
