import styles from './RedeemInvitationForm.module.scss';

import React, { ChangeEvent, FormEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import Button from 'components/Button/Button';

import useRedeemInvitationMutation from 'entities1/invitation/model/services/useRedeemInvitationMutation';

import InputWithCharUnderline from 'shared/ui/InputWithCharUnderline';

import { isResponseWithErrorMessage } from 'utils';

const EMPTY: string = '';

type RedeemInvitationFormProps = {
  token: string | null;
};

function RedeemInvitationForm({
  token,
}: RedeemInvitationFormProps): JSX.Element {
  const { t } = useTranslation();
  const [inviteToken, setInviteToken] = useState(token || EMPTY);

  const changeInviteToken = (e: ChangeEvent<HTMLInputElement>) => {
    setInviteToken(e.target.value.toUpperCase());
  }

  const { mutate, error, isLoading, isSuccess } = useRedeemInvitationMutation();
  const redeemInviteToken = (e: FormEvent) => {
    e.preventDefault();
    mutate(inviteToken);
  };

  const errorMessage = useMemo(() => {
    if (error instanceof Error) {
      return isResponseWithErrorMessage(error)
        ? error.response.data.message
        : error.message;
    }
  }, [error]);
  const empty: boolean = inviteToken === EMPTY;
  const disabled: boolean =
    empty || inviteToken.length < 5 || isSuccess || isLoading;

  return (
    <form onSubmit={redeemInviteToken} className={styles.form}>
      <InputWithCharUnderline
        name="inviteToken"
        value={inviteToken}
        onChange={changeInviteToken}
        disabled={isLoading || isSuccess}
        error={errorMessage}
      />

      <Button
        className={cn(styles.button, isSuccess && styles.success)}
        onClick={redeemInviteToken}
        disabled={disabled}
      >
        {t(isLoading ? 'button.loading' : 'page.redeemInvitation.button')}
      </Button>
    </form>
  );
}

export default RedeemInvitationForm;
