import { MouseEventHandler } from 'react';
import Icon from 'components/Icon/Icon';
import { IconName } from 'components/Icon/IconList';

import styles from './index.module.scss';

const DeleteFeeRangeButton = ({ onClick }: { onClick: MouseEventHandler }) => {
  return (
    <div className={styles.container} onClick={onClick}>
      <Icon
        width={48.8}
        height={48.8}
        viewBox={'-15 -15 40 40'}
        name={IconName.Close2}
        className={styles.svg}
      />
    </div>
  );
};

export default DeleteFeeRangeButton;
