import styles from './layout.module.scss';

import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { Layout } from 'components/Layout/Layout';

export interface ExchangeRatesPageLayoutProps extends PropsWithChildren {
  actions?: JSX.Element;
}

export default function ExchangeRatesPageLayout({
  actions,
  children,
}: ExchangeRatesPageLayoutProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Layout>
      <Layout.TopBar>
        <Layout.Back />

        <div className={styles.topBar}>
          <Layout.Title>{t('page.subscriptions.title')}</Layout.Title>

          {actions && actions}
        </div>
      </Layout.TopBar>

      <Layout.Content>{children}</Layout.Content>
    </Layout>
  );
}

export { styles };
