import styles from './Select.module.scss';

import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import Modal from 'components/Modal/Modal';

const MAX_SHOWN_VALUES: number = 4;
const MAX_HEIGHT: number = 260;

export interface SelectProps<Value> {
  value: Value;
  values: Value[];
  readonly labels?: Record<string, string>;
  onChange: (v: Value) => void;
  className?: string;
  buttonClassName?: string;
  optionsClassName?: string;
  optionClassName?: string;
  unchanging?: boolean;
  readonly optionsStyles?: Record<string, string>;
}

export function Select<Value>({
  value,
  values,
  labels,
  onChange,
  className,
  buttonClassName,
  optionsClassName,
  optionClassName,
  unchanging = false,
  optionsStyles = {},
}: SelectProps<Value>): JSX.Element {
  const [selected, setSelected] = useState<Value>(value);
  const [visible, setVisible] = useState<boolean>(false);
  const [style, setStyle] = useState<any>({});
  const ref = useRef<null | HTMLUListElement>(null);
  const height = window.innerHeight;

  const changeSelected = (v: Value) => {
    if (!unchanging) {
      setSelected(v);
    }

    setVisible(false);

    if (typeof onChange === 'function') {
      onChange(v);
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!e.target) {
      return;
    }

    const position = (e.target as HTMLDivElement).getBoundingClientRect();

    if (height - position.top < MAX_HEIGHT) {
      setStyle({ bottom: height - (position.top + position.height) });
    } else {
      setStyle({ top: position.top });
    }

    changeVisible();
  };

  const changeVisible = () => {
    setVisible((state) => !state);
  };

  useEffect(() => {
    if (value) {
      setSelected(value);
    }
  }, [value]);

  const scrollable = values.length > MAX_SHOWN_VALUES;
  const typeSort = style.top || unchanging ? [-1, 1] : [1, -1];

  useEffect(() => {
    if (ref.current && style.bottom) {
      ref.current.scrollTo(0, 10000);
    }
  }, [ref.current, style]);

  return (
    <>
      <div className={cn(styles.btn, buttonClassName)} onClick={handleClick}>
        <>{labels ? labels[String(selected)] : selected}</>
      </div>
      <Modal
        visible={visible}
        className={styles.modalContent}
        onClose={changeVisible}
        style={style}
      >
        <div className={cn(styles.select, className)}>
          <ul
            className={cn(styles.options, optionsClassName, scrollable && styles.scrollable)}
            ref={ref}
          >
            {values
              .sort((a) => (a === value ? typeSort[0] : typeSort[1]))
              .map((v) => (
                <li
                  key={String(v)}
                  className={cn(styles.option, optionClassName, optionsStyles[String(v)], {
                    [styles.selectedOption]: selected === v && !unchanging,
                  })}
                  onClick={() => changeSelected(v)}
                >
                  {labels ? labels[String(v)] : String(v)}
                </li>
              ))}
          </ul>
        </div>
      </Modal>
    </>
  );
}
