import styles from './Profile.module.scss';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import * as PATH from 'pages/paths';

import User from 'components/User/User';
import { IconName } from 'components/Icon/IconList';
import ProfileButton from 'components/ProfileButton/ProfileButton';
import DeleteUserButton from 'components/DeleteUserButton';

import { clearStore } from 'shared/lib/auth';

import SkeletonWithWrapper from 'shared/ui/SkeletonWithWrapper';
import { useCurrentUserPermissions } from 'shared/hooks/useCurrentUserPermissions';
import { queryClient } from 'shared/config/reactQuery';

import { Language } from 'dtos';

import ProfileCircleLink from 'components/ProfileCircleLink';
import IconList from 'components/icons/List';
import IconPercent from 'components/icons/Percent';
import IconAssistants from 'components/icons/Assistants';
import { useGetAssistedBalancesQuery } from 'services/balances';
import BalancesSummary from 'components/BalancesSummary';
import Icon from '../../components/Icon/Icon';
import { useBecomeProviderMutation, useChangeLanguageMutation, useGetMeQuery, usersApi } from 'services/users';
import { useDispatch } from 'react-redux';
import IconBell from 'components/icons/Bell';

const IS_DEVTOOLS_ENABLED: boolean = Boolean(
  Number(process.env.REACT_APP_ENABLE_DEVTOOLS)
);

export const Profile = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { data: user, isLoading } = useGetMeQuery(undefined, { refetchOnMountOrArgChange: true });
  const { isProvider, isGuest, canMaintain } = useCurrentUserPermissions();
  const dispatch = useDispatch();

  const { data: balancesInfo = [], isLoading: isLoadingBalances } =
    useGetAssistedBalancesQuery(undefined, { skip: false, refetchOnMountOrArgChange: true });

  // Второе условие для работы детектора
  const isEngLanguage: boolean = i18n.language === Language.En;
  const locale: Language = isEngLanguage ? Language.Ru : Language.En;

  const [changeLanguageMutation, {isError}] = useChangeLanguageMutation();
  const [upgradeToProvider] = useBecomeProviderMutation();

  const logout = (): void => {
    // @ts-ignore
    window.__ONLY_BUTTON = false;
    clearStore();
    // И очистить кэш RTK Query
    dispatch(usersApi.util.resetApiState());
    navigate(PATH.HOME);
  };

  const switchLanguage = async (): Promise<void> => {
    await changeLanguageMutation(locale)
      .unwrap()
      .then(() => {
        return i18n.changeLanguage(locale);
      });
  };

  const onHistoryButtonClick = () => {
    queryClient.removeQueries({ queryKey: ['payments'], exact: true });
  }

  if (isLoading || !user) {
    return (
      <div className={styles.empty}>
        <SkeletonWithWrapper height={64} containerClassName={styles.skeleton} />

        <SkeletonWithWrapper
          height={318}
          containerClassName={styles.skeleton}
        />
      </div>
    );
  }

  return (
    <>
      <User user={user} withEditing />

      {isProvider && (
        <div className={styles.iconsList}>
          <>
            <ProfileCircleLink link={PATH.EXCHANGE_RATES} text={t('page.profile.menu.myOffers')}>
              <IconList />
            </ProfileCircleLink>
            <ProfileCircleLink link={PATH.FEE_RANGE_DEFAULTS} text={t('page.profile.menu.myFees')}>
              <IconPercent className={styles.iconPercent} />
            </ProfileCircleLink>
            <ProfileCircleLink link={PATH.ASSISTANTS} text={t('page.profile.menu.assistants')}>
              <IconAssistants />
            </ProfileCircleLink>
            <ProfileCircleLink
              link={PATH.PAYMENTS}
              text={t('menu.history')}
              onClick={onHistoryButtonClick}
            >
              <Icon name={IconName.History} className={styles.iconHistory} width={35} height={35} />
            </ProfileCircleLink>
          </>
        </div>
      )}

      <BalancesSummary
        balances={balancesInfo}
        isLoading={isLoadingBalances}
        className={balancesInfo.length > 0 || isLoadingBalances ? styles.balanceSummary : undefined}
        showContact
        enableSort
      />

      {balancesInfo.length === 0 && !isLoadingBalances && (
        <div className={styles.noProject}>{t('page.profile.noProjects')}</div>
      )}

      <div className={styles.capabilities}>
        {!isProvider && !isGuest && (
          <Profile.Button
            icon={{ name: IconName.Star, width: 25, height: 25 }}
            onClick={upgradeToProvider}
          >
            {t('page.profile.menu.upgradeToProvider')}
          </Profile.Button>
        )}

        {canMaintain && (
          <Profile.Button
            link={PATH.MAINTENANCE}
            icon={{
              name: IconName.Settings,
              width: 20,
              height: 20,
              fill: 'var(--blue)',
            }}
          >
            {t('page.profile.menu.maintenance')}
          </Profile.Button>
        )}

        {IS_DEVTOOLS_ENABLED && (
          <Profile.Button
            link={PATH.SUBSCRIPTIONS}
            // icon={{
            //   name: IconName.Delete,
            //   width: 19,
            //   height: 20,
            //   viewBox: '0 1 20 20',
            //   fill: 'var(--blue)',
            // }}
          >
            {t('page.profile.menu.subscriptions')}
          </Profile.Button>
        )}

        <Profile.Button
          icon={{
            name: IconName.Language,
            width: 19,
            height: 20,
            fill: 'none',
          }}
          onClick={switchLanguage}
        >
          <span style={{ color: isError ? 'red' : 'none' }}>
            {t('page.profile.menu.changeLanguage')}
          </span>
        </Profile.Button>

        {IS_DEVTOOLS_ENABLED && (
          <DeleteUserButton logout={logout}>
            <Profile.Button
              icon={{
                name: IconName.Delete,
                width: 19,
                height: 20,
                viewBox: '0 1 20 20',
                fill: 'var(--blue)',
              }}
            >
              {t('page.profile.menu.deleteAccount')}
            </Profile.Button>
          </DeleteUserButton>
        )}

        <Profile.Button
          link={PATH.NOTIFICATIONS}
        >
          <IconBell className={styles.iconBell} />
          {t('page.profile.menu.notifications')}
        </Profile.Button>

        <Profile.Button
          icon={{ name: IconName.Man, width: 19, height: 20, fill: 'none' }}
          color="red"
          onClick={logout}
        >
          {t('page.profile.menu.logout')}
        </Profile.Button>
      </div>
    </>
  );
};

Profile.Button = ProfileButton;
