import { InputHTMLAttributes } from 'react';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  label?: string;
  error?: string;
  type?: InputType;
  variant?: InputVariant;
}

export enum InputType {
  TEXT = 'text',
  NUMBER = 'number',
  PASSWORD = 'password',
  EMAIL = 'email',
  TELEPHONE = 'tel',
  RANGE = 'range',
}

export enum InputVariant {
  OUTLINED = 'outlined',
  FILLED = 'filled',
}
