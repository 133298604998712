import { Authority, UserDto } from 'dtos';

export const hasGuestPermission = (user?: UserDto) =>
  !user?.roleInfo.authorities.includes(Authority.Exchange) ?? true;

export const hasAnyPermission = (user?: UserDto): boolean =>
  user?.roleInfo?.authorities ? user.roleInfo.authorities.length > 0 : false;

export const hasProvidePermission = (user?: UserDto): boolean =>
  Boolean(user?.roleInfo.authorities.includes(Authority.Provide));

export const hasMaintainPermission = (user?: UserDto): boolean =>
  Boolean(user?.roleInfo.authorities.includes(Authority.Maintain));

export const hasModifyRatesPermission = (user?: UserDto): boolean =>
  Boolean(user?.roleInfo.authorities.includes(Authority.ModifyRates));
