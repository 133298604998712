import cn from 'classnames';

import { InputProps, InputType, InputVariant } from './InputTypes';

import styles from './Input.module.scss';

const Input = ({
  className,
  labelClassName,
  inputClassName,
  label,
  error,
  type = InputType.TEXT,
  variant = InputVariant.OUTLINED,
  ...inputProps
}: InputProps) => {
  return (
    <div className={cn(styles.field, { [styles.error]: error }, className)}>
      {label && <div className={cn(labelClassName, styles.label)}>{label}</div>}

      <input
        className={cn(styles.input, styles[variant], inputClassName, {
          [styles.disabled]: inputProps.disabled,
        })}
        type={type}
        {...inputProps}
      />

      {error && (
        <p className={cn(styles.error, styles.errorMessage)}>{error}</p>
      )}
    </div>
  );
};

export default Input;
