import styles from './ProviderExchangeRates.module.scss';

import React, { useEffect } from 'react';
import type { UseInfiniteQueryResult } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';

import User from 'components/User/User';
import ExchangeRatesTable from 'components/ExchangeRatesTable';

import type { UserDto, ExchangeRateInheritedDto, ExchangeRateDto } from 'dtos';

import { AvatarSize } from 'shared/ui/Avatar/Avatar';

export interface ProviderRatesProps {
  id?: 'me' | 'provider';
  provider: UserDto;
  rates: ExchangeRateInheritedDto[] | ExchangeRateDto[];
  toggle: (rateItem: ExchangeRateInheritedDto | ExchangeRateDto) => void;
  fetchNextPage: UseInfiniteQueryResult['fetchNextPage'];
}

function ProviderRates({
  provider,
  rates,
  fetchNextPage,
  ...props
}: ProviderRatesProps): JSX.Element | null {
  const { t } = useTranslation();
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage]);

  if (rates.length === 0) {
    return (
      <p className={styles.empty}>{t('page.exchangeRates.provider.empty')}</p>
    );
  }

  return (
    <>
      <div className={styles.wrap}>
        <User user={provider} size={AvatarSize.Payment} filled />

        <ExchangeRatesTable rates={rates} {...props} />
      </div>

      <div ref={ref} className={styles.pagination} />
    </>
  );
}

export default ProviderRates;
