import { createApi } from '@reduxjs/toolkit/query/react';
import { BalanceAssistantsDto, BalanceDto, PageUserDto, UserDto } from 'dtos';
import { baseQueryWithReauth } from '../fetchBaseQueryWithAuth';

export const assistantsApi = createApi({
  reducerPath: 'assistantsApi',
  baseQuery: baseQueryWithReauth(true),
  tagTypes: ['Assistant'],
  endpoints: (builder) => ({
    getAssistants: builder.query<UserDto[], void>({
      query: () => '/assistants',
      providesTags: [{ type: 'Assistant' }],
      keepUnusedDataFor: 300,
    }),

    addAssistant: builder.mutation<UserDto, UserDto['id']>({
      query: (assistantUserId) => ({
        url: '/assistants',
        method: 'POST',
        body: { assistantUserId },
      }),
      async onQueryStarted(assistantUserId, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            assistantsApi.util.updateQueryData('getAssistants', undefined, draft => {
              const hasAssistant = draft.find(assistant => assistant.id === assistantUserId);

              if (!hasAssistant) {
                draft.push(data);
              }
            })
          );
        } catch { }
      },
    }),

    deleteAssistant: builder.mutation<void, UserDto['id']>({
      query: (assistantUserId) => ({
        url: '/assistants',
        method: 'DELETE',
        params: { assistantUserId },
      }),
      async onQueryStarted(assistantUserId, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            assistantsApi.util.updateQueryData('getAssistants', undefined, draft => {
              return draft.filter(assistant => assistant.id !== assistantUserId);
            })
          );
        } catch { }
      },
    }),

    getBalanceContacts: builder.query<UserDto[], BalanceDto['id']>({
      query: (balanceId) => ({
        url: `/v1/contacts/balance`,
        params: { balanceId },
      }),
      providesTags: ['Assistant'],
    }),

    getBalanceContactsWithSearch: builder.query<PageUserDto, { balanceId: number, searchString?: string, page: number, limit: number }>({
      query: (params) => ({
        url: '/v2/contacts/balance',
        params,
      }),
      // providesTags: (result) =>
      //   result?.content
      //     ? [...result.content.map(({ id }) => ({ type: 'Assistant' as const, id: id })), 'Assistant']
      //     : ['Assistant'],
    }),

    getAllBalanceAssistants: builder.query<BalanceAssistantsDto[], void>({
      query: () => ({
        url: `/balances/assistants`,
      }),
      providesTags: ['Assistant'],
    }),

    addBalanceAssistant: builder.mutation<void, {balanceId: BalanceDto['id'], assistantId: UserDto['id']}>({
      query: (params) => ({
        url: '/balances/assistant',
        method: 'POST',
        params,
      }),
      invalidatesTags: ['Assistant'],
    }),

    deleteBalanceAssistant: builder.mutation<void, {balanceId: BalanceDto['id'], assistantId: UserDto['id']}>({
      query: (params) => ({
        url: '/balances/assistant',
        method: 'DELETE',
        params,
      }),
      invalidatesTags: ['Assistant'],
    }),

    getBalanceAssistants: builder.query<BalanceAssistantsDto, BalanceDto['id']>({
      query: (balanceId) => ({
        url: `/balances/${balanceId}/assistants`,
      }),
      providesTags: ['Assistant'],
    }),
  }),
});

export const {
  // all assistants
  useGetAssistantsQuery,
  useAddAssistantMutation,
  useDeleteAssistantMutation,

  // balance assistants
  useGetBalanceAssistantsQuery,
  useGetBalanceContactsQuery,
  useLazyGetBalanceContactsWithSearchQuery,
  useAddBalanceAssistantMutation,
  useDeleteBalanceAssistantMutation,

  useGetAllBalanceAssistantsQuery,
} = assistantsApi;
