import styles from './Form.module.scss';

import React, { ChangeEvent, useState } from 'react';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { Tab } from '@headlessui/react';
import { useDispatch } from 'react-redux';

import BalancesService from 'api/balances';
import { useCreateBalanceTransactionMutation } from 'services/balances';
import CurrencyInput from 'components/Input/CurrencyInput/CurrencyInput';
import Suggest from 'components/Suggest';
import { ModalTypes, hide } from 'slices/modal';
import { useAutoFocus } from 'shared/hooks/useAutoFocus';

import { isResponseWithErrorMessage } from 'utils';

import { BalanceType, TransactionData } from '../../index';
import { useCreateSessionTransactionMutation } from 'services/payments';

export interface FormProps {
  data: TransactionData;
  onClose: () => void;
  onSetData: (data: TransactionData) => void;
}

export interface FormState extends Required<Pick<TransactionData, 'value' | 'code' | 'comment'>> {}

const INITIAL_VALUE: FormState['value'] = '0';
const EMPTY: string = '';

function Form({ data, onClose, onSetData }: FormProps): JSX.Element | null {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const TABS = [t('tab.alreadyReceived'), t('tab.wantToReceive')];
  const amountRef = useAutoFocus<HTMLInputElement>();

  const [tab, setTab] = useState(0);
  const isReceiveTab = tab === 1;
  const [form, setForm] = useState<FormState>({
    value: INITIAL_VALUE,
    code: data?.code || EMPTY,
    comment: EMPTY,
  });

  const [error, setError] = useState<string | null>(null);

  const changeFormValue = (value?: string): void => {
    return setForm((prev) => ({ ...prev, value: value ?? INITIAL_VALUE }));
  };

  const changeFormCode = (code: string): void => {
    return setForm((prev) => ({ ...prev, code }));
  };

  const changeComment = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    e.preventDefault();
    setForm((prev) => ({ ...prev, comment: e.target.value }));
  };

  const { data: bucketNames } = useQuery({
    queryKey: ['bucket-names', data.balance?.id],
    placeholderData: [],
    queryFn: async () => {
      const balanceId = data.balance?.id;
      if (balanceId) {
        return await BalancesService.getBucketNameSuggestions(balanceId);
      } else {
        return Promise.resolve([]);
      }
    },
  });

  const [createBalance] = useCreateBalanceTransactionMutation();
  const [createSessionTransaction] = useCreateSessionTransactionMutation();
  const canSendRequest: boolean =
    parseFloat(form.value) !== 0 && Boolean(form.code.trim()) && !!form.comment.length;
  const sendingRequest: boolean = Boolean(data?.isSending);

  const createBalanceTransaction = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();

    if (parseFloat(form.value) === 0) {
      setError(t('error.value'));
    } else if (!Boolean(form.code.trim())) {
      setError(t('error.code'));
    } else if (!form.comment.length) {
      setError(t('error.comment'));
    } else {
      setError(null);
    }

    if (!canSendRequest || !data) return;

    const contact = data.contact;
    try {
      onSetData({ ...data, contact, isSending: true });
      const { comment, code: bucketName, value } = form;
      const formattedValue: number = parseFloat(value.replace(',', '.'));

      if (!isReceiveTab) {
        await createBalance({
          value: data.type === BalanceType.Send ? formattedValue : -formattedValue,
          contactId: contact.id,
          bucketName,
          comment,
          balanceId: data.balance?.id,
        }).unwrap();
        onSetData({ ...data, contact, isSending: false, sent: true, ...form });
      } else {
        const { sessionKey } = await createSessionTransaction({
          contactId: contact.id,
          fromBalanceId: Number(data.balance?.id),
          exchangeUnitName: bucketName,
          amount: formattedValue,
          comment,
        }).unwrap();
        dispatch(hide({ id: ModalTypes.CreateBalanceTransaction }));
        navigate(`/exchange/${sessionKey}`, { state: { sessionKey } });
      }
    } catch (e) {
      onSetData({ ...data, contact, isSending: false });
      if (!(e instanceof Error)) return;
      alert(isResponseWithErrorMessage(e) ? e.response.data.message : e.message);
    }
  };

  if (data?.type == null) {
    return null;
  }

  const calmState: string = !isReceiveTab ? t(`button.${data.type === BalanceType.Send ? 'send' : 'receive'}`) : t('button.request');

  const loadingState: string = !isReceiveTab ? t(
    `button.${data.type === BalanceType.Send ? 'sending' : 'receiving'}`
  ) : t('button.requesting');

  return (
    <form onSubmit={createBalanceTransaction} className={styles.form} data-form-type={data.type}>
      {data.balance?.id && data.type === BalanceType.Receive && (
        <Tab.Group as="div" className={styles.wrap} defaultIndex={tab} onChange={setTab}>
          <div className={styles.tabsWrap}>
            <Tab.List className={styles.tabs}>
              {TABS.map((tabName) => {
                return (
                  <Tab as={React.Fragment} key={tabName}>
                    {({ selected }) => (
                      <button
                        className={cn(styles.tab, selected && styles.selected)}
                        children={t(tabName)}
                      />
                    )}
                  </Tab>
                );
              })}
            </Tab.List>
          </div>
        </Tab.Group>
      )}

      <div className={styles.values}>
        <CurrencyInput
          ref={amountRef}
          value={form.value}
          onValueChange={changeFormValue}
          className={styles.amountWrap}
          inputClassName={styles.amount}
          autoComplete="transaction-amount"
          allowNegativeValue={false}
          autoFocus
        />

        <Suggest
          placeholder="USD"
          value={form.code}
          values={bucketNames ? bucketNames : []}
          onChange={changeFormCode}
          className={styles.codeWrap}
          inputClassName={styles.code}
          disabled={!!data.code}
        />
      </div>

      <textarea
        id="comment"
        value={form.comment}
        placeholder={t('placeholder.comment')}
        onChange={changeComment}
        className={styles.comment}
        autoComplete="off"
      />

      {!!error && <p className={styles.error}>{error}</p>}

      <div className={styles.buttons}>
        <button
          type="submit"
          disabled={sendingRequest}
          onClick={createBalanceTransaction}
          className={cn(styles.button, styles.submit, {[styles.request]: tab === 1})}
        >
          {!sendingRequest ? calmState : loadingState}
        </button>

        <button
          type="button"
          disabled={sendingRequest}
          onClick={onClose}
          className={cn(styles.button, styles.close)}
        >
          {t('button.close')}
        </button>
      </div>
    </form>
  );
}

export default Form;
