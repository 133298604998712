import styles from './Navigation.module.scss';

import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import Icon from 'components/Icon/Icon';

import { composeButtons } from './buttons';

import { useCurrentUserPermissions } from 'shared/hooks/useCurrentUserPermissions';
import { Avatar, AvatarSize } from 'shared/ui/Avatar/Avatar';

function Navigation(): JSX.Element {
  const { t } = useTranslation();

  const { user, isGuest } = useCurrentUserPermissions();
  const navButtons = useMemo(() => composeButtons(isGuest, t), [isGuest, t]);

  return (
    <nav className={styles.nav}>
      <ul className={styles.links}>
        {navButtons.map(({ path, title, icon, onClick }) => (
          <li key={title} onClick={onClick}>
            <NavLink
              to={path}
              caseSensitive
              className={({ isActive }) =>
                cn(styles.link, isActive && styles.active)
              }
            >
              {icon ? (
                <Icon {...icon} className={styles.icon} />
              ) : (
                <Avatar {...user} size={AvatarSize.Small} />
              )}
              <h3 className={styles.title}>{title}</h3>
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default Navigation;
