import { PROFILE, REDEEM_INVITATION } from 'pages/paths';

import {
  hasAnyPermission,
  hasGuestPermission,
} from 'entities1/user/model/roles';
import type { UserDto } from 'dtos';

const privateRoutesForGuest = new Set([PROFILE, REDEEM_INVITATION]);

function isUserAuthorizedByPath(user: UserDto, path: string) {
  const validRouteForGuest: boolean =
    hasGuestPermission(user) && privateRoutesForGuest.has(path);
  return hasAnyPermission(user) || validRouteForGuest;
}

export default isUserAuthorizedByPath;
