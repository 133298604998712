import instance from 'api';

import type { UserDto } from 'dtos';
import { InvitationTokenParams, InvitationTokenResponse } from './types';
const USER_URL: string = '/users/me';

export default class UserService {
  static async getMe(): Promise<UserDto> {
    const { data } = await instance.get<UserDto>(USER_URL);
    return data;
  }

  static async getInvitations(
    params: InvitationTokenParams
  ): Promise<InvitationTokenResponse> {
    const { data } = await instance.get<InvitationTokenResponse>(
      USER_URL + '/invitations',
      { params }
    );
    return data;
  }

  static async deleteUser(userId?: UserDto['id']): Promise<void> {
    await instance.delete<void>(USER_URL, { params: { userId } });
  }

  static async uploadCustomPhoto(file: FormData): Promise<string> {
    const { data } = await instance.post(
      USER_URL + '/custom-photo',
      file
    );

    return data;
  }

  static async deleteCustomPhoto(): Promise<void> {
    await instance.delete(
      USER_URL + '/custom-photo'
    );
  }
}
