// @see https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
function detectIOS() {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(typeof window !== 'undefined' ? window.navigator.platform : '') ||
    // iPad on iOS 13 detection
    (typeof window !== 'undefined' ? (window.navigator.userAgent.includes('Mac') && 'ontouchend' in document) : false)
  );
}

const MOBILE_PATTERN: RegExp = /mobi/i;
const userAgent = typeof window !== 'undefined' ? window.navigator.userAgent.toLowerCase() : '';

const isMobile: boolean = MOBILE_PATTERN.test(userAgent);
const isDesktop = !isMobile;

const isIOS: boolean = detectIOS();

// @see https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browsers
const isSafari = typeof window !== 'undefined' && !!(
  navigator.vendor &&
  navigator.vendor.indexOf('Apple') > -1 &&
  navigator.userAgent &&
  navigator.userAgent.indexOf('CriOS') === -1 &&
  navigator.userAgent.indexOf('FxiOS') === -1
);

const isPWAInstalled: boolean =
  (typeof window !== 'undefined' && window.matchMedia('(display-mode: standalone)')?.matches) ?? false;

export interface DeviceInfo {
  isMobile: boolean;
  isDesktop: boolean;
  isIOS: boolean;
  // isAndroid: boolean;

  isSafari: boolean;
  isPWAInstalled: boolean;
}

export default function useDeviceInfo(): DeviceInfo {
  return { isMobile, isDesktop, isIOS, isSafari, isPWAInstalled };
}
