import { useCallback } from 'react';
import { QueryKey } from '@tanstack/react-query';
import assign from 'lodash/assign';
import omit from 'lodash/omit';
import isObject from 'lodash/isObject';

import {
  CrossExchangeWidgetDto,
  CurrencyDto,
  ExchangeRateWidgetDto,
  PaymentFormDtoV5,
} from 'dtos';
import { queryClient } from 'shared/config/reactQuery';
import { LocalStorage } from 'shared/localStorage';

import { UseExchangerStore } from './useExchangerStore';
import { getFavouriteCurrenciesPaymentsLSKey, getPaymentFormLSKey, isValidLocalStorageEntry } from '../utils';
import { ValueOf } from 'shared/types/types';
import { UserId, ExchangerScope } from '../types';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';

type UseExchangerStoreActionsParams = {
  queryKey: QueryKey;
  providerId: UserId;
  userId?: UserId;
};

export const useExchangerStoreActions = ({
  queryKey,
  providerId,
  userId = null,
}: UseExchangerStoreActionsParams) => {
  const { id: currentUserId } = useCurrentUser();
  const storageKey = getPaymentFormLSKey(providerId || currentUserId, userId || currentUserId);
  const favouriteLSKey = getFavouriteCurrenciesPaymentsLSKey(providerId || currentUserId, userId || currentUserId);

  const setPaymentForm = useCallback(
    (payload: Partial<PaymentFormDtoV5 & { currentTab: string }>): PaymentFormDtoV5 & { currentTab: string } | undefined => {
      const store = queryClient.setQueryData<UseExchangerStore>(
        queryKey,
        // CNTFRONT-2 ошибка из-за Partial
        // @ts-ignore
        (prev) => {
          if (!prev) {
            return;
          }

          const { activeOption, inputTarget } = prev;
          const prevForm = prev.exchangerByOption?.[activeOption]?.form ?? {};

          const nextForm = assign({ currentTab: activeOption }, prevForm, payload);

          const nextStore = {
            ...prev,
            exchangerByOption: {
              ...prev.exchangerByOption,
              [activeOption]: {
                ...prev.exchangerByOption[activeOption],
                form: nextForm,
              },
            },
          };

          const prevLSFrom = LocalStorage.getItem(storageKey);

          if (isValidLocalStorageEntry(prevLSFrom)) {
            LocalStorage.setItem(storageKey, {
              ...prevLSFrom,
              formDataByOption: {
                ...prevLSFrom.formDataByOption,
                [activeOption]: nextForm,
              },
            });
          } else {
            LocalStorage.setItem(storageKey, {
              inputTarget,
              activeOption,
              formDataByOption: {
                [activeOption]: nextForm,
              },
            });
          }
          return nextStore;
        }
      );
      const activeOption = store?.activeOption || 'Currency';
      return store?.exchangerByOption?.[activeOption]?.form;
    },
    [queryKey, storageKey]
  );

  /**
   * Используется для обновления состояния ошибки
   * Удаляет данные из локального хранилища браузера, если в метод прокидывается значение ошибки
   */
  const setErrorData = useCallback(
    (payload: Partial<UseExchangerStore['errorData']>) => {
      const { isErrorOnUpdate, message, status } = payload;

      const store = queryClient.setQueryData<UseExchangerStore>(
        queryKey,
        (prev) => {
          const errorData = assign({}, prev?.errorData, payload);
          if (prev) {
            return { ...(prev), errorData };
          }

          return { errorData } as UseExchangerStore;
        }
      );

      if ((status && (status >= 400 && status < 500)) || (isErrorOnUpdate && message)) {
        const prevLSFrom = LocalStorage.getItem(storageKey);

        if (isValidLocalStorageEntry(prevLSFrom)) {
          const formDataByOption = omit(prevLSFrom.formDataByOption, [prevLSFrom.activeOption]);

          LocalStorage.setItem(storageKey, {
            ...prevLSFrom,
            formDataByOption: {
              ...formDataByOption,
            },
          });
        } else {
          LocalStorage.removeItem(storageKey);
        }
      }

      return store?.errorData;
    },
    [queryKey, storageKey]
  );

  const setCrossExchange = useCallback(
    (payload: {
      exchangeRateWidgets?: ExchangeRateWidgetDto[];
      crossExchangeWidget?: CrossExchangeWidgetDto;
    }) => {
      queryClient.setQueryData<UseExchangerStore>(queryKey, (prev) => {
        if (!prev) {
          return;
        }
        const { activeOption } = prev;
        return {
          ...prev,
          exchangerByOption: {
            ...prev.exchangerByOption,
            [activeOption]: {
              ...prev.exchangerByOption[activeOption],
              exchangeCalculationResponse: payload,
            },
          },
        };
      });
    },
    [queryKey]
  );

  const setExchangeOption = useCallback(
    (nextOption: string) => {
      queryClient.setQueryData<UseExchangerStore>(queryKey, (prev) => {
        if (!prev) {
          return;
        }
        const prevLSFrom = LocalStorage.getItem(storageKey);

        if (isValidLocalStorageEntry(prevLSFrom)) {
          LocalStorage.setItem(storageKey, {
            ...prevLSFrom,
            activeOption: nextOption,
          });
        } else {
          LocalStorage.setItem(storageKey, {
            activeOption: nextOption,
            formDataByOption: {
              [nextOption]: {},
            },
          });
        }

        return {
          ...prev,
          activeOption: nextOption,
        };
      });
    },
    [queryKey, storageKey]
  );

  const setInputTargetSelect = useCallback(
    (nextInputTarget: ValueOf<typeof ExchangerScope>) => {
      queryClient.setQueryData<UseExchangerStore>(queryKey, (prev) => {
        if (!prev) {
          return;
        }
        const prevLSFrom = LocalStorage.getItem(storageKey);

        if (isValidLocalStorageEntry(prevLSFrom)) {
          LocalStorage.setItem(storageKey, {
            ...prevLSFrom,
            inputTarget: nextInputTarget,
          });
        }

        return {
          ...prev,
          inputTarget: nextInputTarget,
        };
      });
    },
    [queryKey, storageKey]
  );

  const setFavouriteCurrencyPayments = useCallback(
    (value: CurrencyDto) => {
      queryClient.setQueryData<UseExchangerStore>(queryKey, (prev) => {
        if (!prev) {
          return;
        }
        const currentValue = { [value.code]: { ...value.paymentMethod } };
        const prevLSFrom = LocalStorage.getItem(favouriteLSKey);

        if (!isObject(prevLSFrom)) {
          LocalStorage.setItem(favouriteLSKey, currentValue);
        } else {
          LocalStorage.setItem(favouriteLSKey, {
            ...prevLSFrom,
            ...currentValue,
          });
        }

        return {
          ...prev,
          favouriteCurrenciesPayments: { ...prev.favouriteCurrenciesPayments, ...currentValue },
        };
      });
    },
    [queryKey, favouriteLSKey]
  );

  return {
    setPaymentForm,
    setErrorData,
    setCrossExchange,
    setExchangeOption,
    setInputTargetSelect,
    setFavouriteCurrencyPayments,
  };
};
