import styles from './SocialsList.module.scss';

import { useMemo } from 'react';

import Icon from 'components/Icon/Icon';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { getSocialsByLanguage } from './utils';

export function SocialsList(): JSX.Element {
  const { language } = useCurrentUser();

  const socials = useMemo(() => getSocialsByLanguage(language), [language]);

  return (
    <ul className={styles.socials}>
      {socials.map((s) => (
        <li key={s.href} className={styles.social}>
          <a
            href={s.href}
            className={styles.link}
            target="_blank"
            rel="noreferrer"
          >
            <Icon {...s.icon} className={styles.icon} />
          </a>
        </li>
      ))}
    </ul>
  );
}
