import styles from './index.module.scss';

import { useTranslation } from 'react-i18next';

import { useGetPaymentsTotalIncomeQuery } from 'services/payments';
import { hasProvidePermission } from 'entities1/user/model/roles';

import { useCurrentUser } from 'shared/hooks/useCurrentUser';

function PaymentsTotalIncome(): JSX.Element | null {
  const { t } = useTranslation();
  const user = useCurrentUser();

  const isProvider = hasProvidePermission(user);

  const { data: totalIncome, isSuccess } = useGetPaymentsTotalIncomeQuery();

  if (!isSuccess || !isProvider) return null;

  return (
    <h3 className={styles.title}>
      {t('labels.totalIncome')}: {totalIncome} USDT
    </h3>
  );
}

export default PaymentsTotalIncome;
