import {
  CrossExchangeWidgetDto,
  ExchangeRateWidgetDto,
  PaymentFormDtoV5,
  TargetField,
} from 'dtos';

export const calculatePaymentFormData = (
  exchangerFormData: Omit<PaymentFormDtoV5, 'targetField' | 'providerId' | 'customerId'> & {
    currentTab: string;
  },
  userId: number,
  providerId: number,
  targetField: TargetField,
  customRates: ExchangeRateWidgetDto[],
  exchangeCalculationResponse: Pick<
    PaymentFormDtoV5,
    'exchangeRateWidgets' | 'crossExchangeWidget'
  > | null
) => {
  let sellAmount: number;
  let buyAmount: number;
  let crossExchangeWidget: CrossExchangeWidgetDto;
  if (exchangerFormData.buyExchangeUnit.name !== undefined) {
    sellAmount = exchangerFormData.sellExchangeUnitAmount;
    buyAmount = exchangerFormData.buyExchangeUnitAmount;
    crossExchangeWidget = {
      sellCurrency: {
        amount: Number((sellAmount / buyAmount).toFixed(4) ?? 0),
        code: exchangerFormData.crossExchangeWidget?.sellCurrency.code || '',
        canBeChanged: true
      },
      buyCurrency: {
        amount: 1,
        code: exchangerFormData.crossExchangeWidget?.buyCurrency.code || '',
        canBeChanged: false
      },
    };
  } else {
    let sellRate: CrossExchangeWidgetDto | undefined;
    let buyRate: CrossExchangeWidgetDto | undefined;
    const formDataSellUnitCode =
      exchangerFormData.sellExchangeUnit.code || exchangerFormData.sellExchangeUnit.name;
    const formDataBuyUnitCode = exchangerFormData.buyExchangeUnit.code;
    if (targetField === TargetField.SellCurrency) {
      sellAmount = exchangerFormData.sellExchangeUnitAmount;
      sellRate = customRates.find((rate) => rate.sellCurrency.code === formDataSellUnitCode);
      const usdtAmount = sellRate?.sellCurrency.amount
        ? sellAmount / sellRate.sellCurrency.amount
        : 0;
      buyRate = customRates.find((rate) => rate.buyCurrency.code === formDataBuyUnitCode);
      buyAmount = Number((usdtAmount * buyRate?.buyCurrency.amount!!).toFixed(2) ?? 0);
    } else {
      buyAmount = exchangerFormData.buyExchangeUnitAmount;
      buyRate = customRates.find((rate) => rate.buyCurrency.code === formDataBuyUnitCode);
      const usdtAmount = buyRate?.buyCurrency.amount ? buyAmount / buyRate.buyCurrency.amount : 0;
      sellRate = customRates.find((rate) => rate.sellCurrency.code === formDataSellUnitCode);
      sellAmount = Number((usdtAmount * (sellRate?.sellCurrency.amount ?? 0)).toFixed(2) ?? 0);
    }
    if (customRates.length === 1) {
      crossExchangeWidget = { ...customRates[0] };
    } else {
      let crossExchangerWidgetSellAmount: number;
      let crossExchangerWidgetBuyAmount: number;
      if (exchangeCalculationResponse?.crossExchangeWidget?.buyCurrency.canBeChanged) {
        crossExchangerWidgetSellAmount = 1;
        crossExchangerWidgetBuyAmount = Number(
          (buyRate?.buyCurrency.amount!! / sellRate?.sellCurrency.amount!!).toFixed(4)
        );
      } else {
        crossExchangerWidgetBuyAmount = 1;
        crossExchangerWidgetSellAmount = Number(
          (sellRate?.sellCurrency.amount!! / buyRate?.buyCurrency.amount!!).toFixed(4)
        );
      }
      crossExchangeWidget = {
        sellCurrency: {
          amount: crossExchangerWidgetSellAmount,
          code: formDataSellUnitCode || '',
          canBeChanged: crossExchangerWidgetSellAmount !== 1
        },
        buyCurrency: {
          amount: crossExchangerWidgetBuyAmount,
          code: formDataBuyUnitCode || '',
          canBeChanged: crossExchangerWidgetBuyAmount !== 1
        },
      };
    }
  }
  return {
    paymentForm: {
      buyExchangeUnit: exchangerFormData.buyExchangeUnit,
      buyExchangeUnitAmount: buyAmount,
      crossExchangeWidget: crossExchangeWidget,
      customerId: userId,
      exchangeRateWidgets: customRates,
      intermediaryExchanges: customRates,
      providerId: providerId,
      sellExchangeUnit: exchangerFormData.sellExchangeUnit,
      sellExchangeUnitAmount: sellAmount,
      subcategory: exchangerFormData.subcategory,
      targetField: targetField,
    },
  };
};
