import React, { SVGAttributes } from 'react';

import IconList, { IconName } from './IconList';

export interface IconProps extends SVGAttributes<SVGElement> {
  name: IconName;
}

function Icon({
  name,
  width,
  height,
  viewBox = `0 0 ${width} ${height}`,
  fill = 'currentColor',
  ...props
}: IconProps): JSX.Element {
  return (
    <svg width={width} height={height} viewBox={viewBox} fill={fill} {...props}>
      {IconList[name]}
    </svg>
  );
}

export default Icon;
