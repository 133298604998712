import { BaseIconProps } from '../types';

const History = (props: BaseIconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.3 18.3" fill="currentColor" fillRule="evenodd" {...props}>
    <path d="M9.1 16.987a7.887 7.887 0 1 0 0-15.774 7.887 7.887 0 0 0 0 15.774Zm0 1.213A9.1 9.1 0 1 0 9.1 0a9.1 9.1 0 0 0 0 18.2Z" />
    <path d="M12.677 12.442a.91.91 0 0 1-1.286.062L7.96 9.388V3.337a.91.91 0 0 1 1.82 0v5.245l2.835 2.574a.91.91 0 0 1 .062 1.286Z" />
  </svg>
);

export default History;
