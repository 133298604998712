import {
  CurrencyResponseDto,
  GoodsDto,
  GoodsInCityResponseDto, GoodsResponseDto,
  SkuModelDto, SkuParameterResponseDto,
  SubcategoryDtoV4,
} from 'dtos';

type UseGoodsInfo = (
  args: {
    good?: GoodsDto;
    cities?: GoodsInCityResponseDto[];
    subcategory?: string;
  },
) => {
  currentCity?: GoodsInCityResponseDto,
  currentSubcategory?: SubcategoryDtoV4,
  currentGood?: SkuModelDto,
  goodsResponseDto?: Partial<CurrencyResponseDto & GoodsResponseDto>
};

export const useGoodsInfo: UseGoodsInfo = ({
  good,
  cities,
  subcategory,
}) => {
  const cityName = good?.city;

  const currentCity = cities?.find(c => c.cityName === cityName);
  const currentSubcategory = currentCity?.subcategories.find(sc => sc.subcategoryName === subcategory);
  const currentGood = currentSubcategory?.skuModels.find(sm => sm.modelName === good?.name);


  const measureUnit = currentGood?.measureUnits.find((mu) => mu.name === good?.measureUnit);
  const availableSkuParameters = currentGood?.availableSkuParameters.filter((skuParam) => (good?.skuParameters?.map((param) => param.name) || []).includes(skuParam.name));
  const skuParams = good?.skuParameters?.map((p) => {
    const paramWithName = availableSkuParameters?.find((asp) => asp.name === p.name);
    const paramWithValue = paramWithName?.availableValues.find((av) => av.value === p.value);
    return {name: paramWithName?.name, localizedName: paramWithName?.localizedName, value: paramWithValue?.value, localizedValue: paramWithValue?.localizedValue, type: paramWithName?.type} as SkuParameterResponseDto;
  })
  const goodsResponseDto = {
    ...good,
    picture: currentGood?.picture,
    localizedCity: currentCity?.localizedCityName,
    localizedMeasureUnit: measureUnit?.localizedName,
    skuParameters: skuParams
  } as GoodsResponseDto;

  return {
    currentCity,
    currentSubcategory,
    currentGood,
    goodsResponseDto
  };
};
