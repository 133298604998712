import styles from './index.module.scss';

import React, { MouseEventHandler } from 'react';
import { UseQueryResult } from '@tanstack/react-query/build/lib/types';

import RateInput from 'features/feeRate/RateInput';
import Rate from 'components/Rate/Rate';
import DeleteFeeRangeButton from 'features/feeRate/DeleteFeeRangeButton';

import type { FeeRateRangeDto } from 'dtos';

export type FeeRateRowProps = {
  rate: FeeRateRangeDto;
  ratesQueryResult: UseQueryResult<FeeRateRangeDto[]>;
  onRateUpdate: (feeRangeRangeDto: FeeRateRangeDto) => void;
  onRateRemove: MouseEventHandler;
};

function FeeRateRow({
  rate,
  ratesQueryResult,
  onRateUpdate,
  onRateRemove,
}: FeeRateRowProps) {
  const isFirstRange: boolean = rate.lowerBound === 0;

  const updateFeeRate = (feeRate: number) => {
    onRateUpdate({
      ...rate,
      feeRate,
    });
  };

  return (
    <div className={styles.row}>
      <RateInput
        disabled={isFirstRange}
        ratesQueryResult={ratesQueryResult}
        onRateUpdate={onRateUpdate}
        suffix=" USDT"
        name="lowerBound"
        rate={rate}
      />
      <Rate value={rate.feeRate} onChange={updateFeeRate} withInput />

      {!isFirstRange ? <DeleteFeeRangeButton onClick={onRateRemove} /> : null}
    </div>
  );
}

export default FeeRateRow;
