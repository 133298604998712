import { BaseIconProps } from '../types';

const Icon = (props: BaseIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="9"
    viewBox="0 0 19 9"
    fill="currentColor"
    {...props}
  >
    <path
      d="M18.3536 4.87797C18.5488 4.68271 18.5488 4.36612 18.3536 4.17086L15.1716 0.988881C14.9763 0.793619 14.6597 0.793619 14.4645 0.988881C14.2692 1.18414 14.2692 1.50073 14.4645 1.69599L17.2929 4.52442L14.4645 7.35284C14.2692 7.5481 14.2692 7.86469 14.4645 8.05995C14.6597 8.25521 14.9763 8.25521 15.1716 8.05995L18.3536 4.87797ZM-4.37114e-08 5.02441L18 5.02442L18 4.02442L4.37114e-08 4.02441L-4.37114e-08 5.02441Z"
    />
  </svg>
);

export default Icon;
