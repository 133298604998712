import { useInfiniteQuery } from '@tanstack/react-query';

import { BalanceContactsWithSearchResponse, FriendsParams } from 'api/balances/types';
import { useLazyGetBalanceContactsWithSearchQuery } from 'services/assistants';

const INITIAL_PAGE: FriendsParams['page'] = 1;
const INITIAL_LIMIT: FriendsParams['limit'] = 10;
export const useLazyGetBalanceContactsWithSearch = (
  balanceId: number,
  searchString?: string,
  enabled: boolean = true
) => {
  const [getBalanceContactsWithSearch] = useLazyGetBalanceContactsWithSearchQuery();
  return useInfiniteQuery({
    queryKey: ['BalanceContactsWithSearch', balanceId, searchString],
    queryFn: async ({ pageParam = INITIAL_PAGE }) => {
      const res = (await getBalanceContactsWithSearch({
        balanceId,
        searchString,
        page: pageParam,
        limit: INITIAL_LIMIT,
      }).unwrap()) as BalanceContactsWithSearchResponse;
      return { pageParam, ...res };
    },
    getNextPageParam: (lastPage) => {
      return lastPage.last ? undefined : (lastPage.pageParam || INITIAL_PAGE) + 1;
    },
    onSuccess: (data) => {
      const pages = data.pages;
      if (!Array.isArray(pages)) return;
    },
    staleTime: 1000,
    enabled,
  });
};
