export class LocalStorage {
  private static ls: Storage = globalThis.localStorage;

  private static parse(value: string): unknown {
    try {
      return JSON.parse(value);
    } catch (e) {
      throw new Error('Local storage JSON.parse error occurred');
    }
  }

  static getItem(key: string): unknown {
    const candidate: string | null = this.ls.getItem(key);
    if (!candidate) return null;
    return this.parse(candidate);
  }

  static setItem(key: string, value: any): void {
    try {
      if (value === undefined) return;
      const jsonValue = JSON.stringify(value);
      this.ls.setItem(key, jsonValue);
    } catch (e) {
      throw new Error('Local storage setItem error occurred');
    }
  }

  static removeItem(key: string): void {
    this.ls.removeItem(key);
  }

  static clearAll(): void {
    this.ls.clear();
  }
}
