import styles from './ExchangeRatesFilter.module.scss';

import React, { useMemo } from 'react';
import type { QueryKey } from '@tanstack/react-query';

import Filter from 'components/Filter';

import { useExchangeRatesFilter } from 'entities1/exchange-rate/model/services/useExchangeRatesFilter';
import { useGetCurrencies } from 'entities1/currencies/model/services/useGetCurrencies';

import type { CurrencyDto } from 'dtos';

export interface ExchangeRatesFilterProps {
  queryKey: QueryKey;
  onGetExchangeRates: () => void;
}

function ExchangeRatesFilter({
  queryKey,
  onGetExchangeRates,
}: ExchangeRatesFilterProps): JSX.Element | null {
  const { selectedCurrencyCode, persistSelectedCurrencyCodes } =
    useExchangeRatesFilter(queryKey);

  const selectCurrencyCodeAndGetExchangeRates = (code: string) => {
    persistSelectedCurrencyCodes([code]);
    onGetExchangeRates();
  };

  const { data, isLoading } = useGetCurrencies();
  const currencyCodes = useMemo(() => {
    const currencies: CurrencyDto[] = data || [];
    return currencies.map((c) => c.code);
  }, [data]);

  const active: boolean = Boolean(selectedCurrencyCode);

  if (isLoading) return null;

  return (
    <Filter
      value={selectedCurrencyCode}
      onChange={selectCurrencyCodeAndGetExchangeRates}
      values={currencyCodes}
      title="FILTER"
      active={active}
      className={styles.filter}
    />
  );
}

export default ExchangeRatesFilter;
