import React, { PropsWithChildren } from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { queryClient } from 'shared/config/reactQuery';

export const QueryProvider = ({ children }: PropsWithChildren) => (
  <QueryClientProvider client={queryClient}>
    {children}

    <ReactQueryDevtools
      initialIsOpen={false}
      position={'bottom-left'}
      panelPosition={'right'}
    />
  </QueryClientProvider>
);
