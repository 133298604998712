import styles from './PostponeTaskModal.module.scss';

import React, { ChangeEvent, SetStateAction, useState } from 'react';
import { RootState } from 'shared/redux-store';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { hide, ModalTypes } from 'slices/modal';
import Modal from 'components/Modal/Modal';
import Button, { Variant } from 'components/Button/Button';
import isEmpty from 'lodash/isEmpty';
import { toast } from 'react-hot-toast';
import { usePostponeTaskMutation } from '../../services/tasks';

export interface PostponeTaskModalProps {
  setPrioritize: (value:  SetStateAction<boolean>) => void;
}

function PostponeTaskModal({setPrioritize}: PostponeTaskModalProps): JSX.Element | null {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [postponeTask, { isLoading: isPostponing }] = usePostponeTaskMutation();
  const { visible, data } = useSelector((state: RootState) => state.modal[ModalTypes.PostponeTaskModal]);

  const [comment, setComment] = useState<string>();
  const [error, setError] = useState<string>();

  const closeModal = (): void => {
    setComment(undefined);
    setError(undefined);
    dispatch(hide({ id: ModalTypes.PostponeTaskModal }));
  };

  const changeComment = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    if (!isEmpty(e.target.value) && !isEmpty(error)) {
      setError(undefined);
    }
    setComment(e.target.value);
  };

  const onConfirmButton = async () => {
    if (!isEmpty(comment)) {
      await toast.promise(postponeTask({ taskId: data.task.id, comment: comment }), {
        loading: t('status.processing'),
        success: () => {
          if (data.task.priority === 1) {
            setPrioritize(true);
          }
          return <b>{t('status.success')}</b>;
        },
        error: () => <b>{t('status.smthWrong')}</b>,
      });
      closeModal();
      setComment(undefined);
    } else {
      setError(t('modal.postponeTask.errorMessage'));
    }
  }

  if (!visible) {
    return null;
  }

  return (
    <Modal visible={visible} onClose={closeModal} className={styles.modalContent} modalClassName={styles.modal}>

      <div>
        <Button className={styles.topButton} onClick={closeModal} variant={Variant.Minimal}>
          {t('modal.postponeTask.cancelButton')}
        </Button>
        <div className={styles.description}>{t('modal.postponeTask.description')}</div>
      </div>


      <textarea
        id="comment"
        value={comment}
        placeholder={t('placeholder.comment')}
        onChange={changeComment}
        className={styles.comment}
        autoComplete="off"
      />

      {!isEmpty(error) && (
        <span className={styles.error}>{error}</span>
      )}

      <Button className={styles.confirmButton} onClick={onConfirmButton} variant={Variant.BlackWhite}>
        {t('modal.postponeTask.confirmButton')}
      </Button>
    </Modal>
  );
}

export default PostponeTaskModal;
