import styles from './AssistantCard.module.scss';

import Icon from 'components/Icon/Icon';
import { IconName } from 'components/Icon/IconList';

import { UserCard } from 'entities1/user';

import type { UserDto } from 'dtos';
import { AvatarSize } from 'shared/ui/Avatar/Avatar';

export type AssistantCardProps = {
  assistant: UserDto;
  onClick?: () => void;
};

function AssistantCard({
  assistant,
  onClick,
}: AssistantCardProps): JSX.Element {
  return (
    <div className={styles.assistant}>
      <UserCard user={assistant} avatarSize={AvatarSize.Payment} />

      {typeof onClick === 'function' && (
        <div className={styles.removeAssistantIconContainer}>
          <Icon
            name={IconName.Close2}
            className={styles.removeAssistantIcon}
            width={10}
            height={10}
            viewBox="0 0 10 10"
            onClick={onClick}
          />
        </div>
      )}
    </div>
  );
}

export default AssistantCard;
