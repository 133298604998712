import cn from 'classnames';
import { useMemo } from 'react';

import { RoleDto } from 'dtos';

import avatarStyles from 'shared/ui/Avatar/Avatar.module.scss';
import styles from './PaymentRoleIndicator.module.scss';

interface PaymentRoleIndicatorProps {
  role: Lowercase<RoleDto['name']>;
}

export default function PaymentRoleIndicator({
  role,
}: PaymentRoleIndicatorProps) {
  const roleFirstChar = useMemo<string | undefined>(
    () => role?.slice(0, 1).toUpperCase(),
    [role]
  );
  return (
    <div className={cn(avatarStyles.dotIndicator, role && styles[role])}>
      {roleFirstChar}
    </div>
  );
}
