import cn from 'classnames';
import { useTranslation, Trans } from 'react-i18next';

import styles from './index.module.scss';

type InstructionScreenProps = {
  next: () => void;
};

const InstructionScreen = ({ next }: InstructionScreenProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <img className={styles.logo} src="/logo180.png" alt="Connect App Logo" />
      <h2 className={styles.heading}>
        <Trans
          i18nKey="install.instruction"
          components={{ strong: <strong className={styles.strong} /> }}
        />
      </h2>
      <hr className={styles.line} />
      <ul className={styles.steps}>
        <li>
          {t('install.step1')}
          <BrowserIcon />
        </li>
        <li>
          {t('install.step2')}
          <ShareIcon />
        </li>
        <li>
          <Trans
            i18nKey="install.step3"
            components={{ strong: <strong className={styles.strong} /> }}
          />
        </li>
        <li>
          <Trans
            i18nKey="install.step4"
            components={{
              strong: <strong className={cn(styles.strong, styles.blue)} />,
            }}
          />
        </li>
      </ul>
      <span onClick={next} className={styles.link}>
        {t('install.video')}
      </span>
    </div>
  );
};

const BrowserIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    className={styles.icon}
  >
    <rect width="24" height="24" fill="#fff" rx="5" />
    <path
      fill="#4692FD"
      d="M12 3.273c-4.809 0-8.727 3.918-8.727 8.728s3.918 8.727 8.728 8.727 8.727-3.918 8.727-8.727c0-4.81-3.918-8.728-8.727-8.728Zm0 1.343a7.375 7.375 0 0 1 7.385 7.385 7.375 7.375 0 0 1-7.384 7.384 7.375 7.375 0 0 1-7.385-7.384 7.375 7.375 0 0 1 7.385-7.385Zm4.7 2.685-6.042 3.357L7.301 16.7l6.042-3.357L16.7 7.301ZM12 11.33a.67.67 0 0 1 .672.672.67.67 0 0 1-.671.671.67.67 0 0 1-.672-.671.67.67 0 0 1 .672-.672Z"
    />
  </svg>
);

const ShareIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    className={styles.icon}
  >
    <rect width="24" height="24" fill="#fff" rx="5" />
    <path
      fill="#4692FD"
      fillRule="evenodd"
      d="M8.429 7.685H7.182C5.977 7.685 5 8.675 5 9.895v9.114c0 1.221.977 2.21 2.182 2.21h9.171c1.205 0 2.182-.989 2.182-2.21V9.895c0-1.22-.977-2.21-2.181-2.21h-1.157v1.173h1.157c.565 0 1.024.464 1.024 1.037v9.114c0 .573-.459 1.038-1.024 1.038H7.181a1.03 1.03 0 0 1-1.024-1.038V9.895a1.03 1.03 0 0 1 1.024-1.037h1.247V7.685Z"
      clipRule="evenodd"
    />
    <path
      fill="#4692FD"
      fillRule="evenodd"
      d="m8.577 5.003 2.872-2.87a.451.451 0 0 1 .638 0l2.871 2.87a.451.451 0 0 1-.638.638l-2.101-2.1v8.385h-.902V3.54L9.216 5.641a.451.451 0 1 1-.639-.638Z"
      clipRule="evenodd"
    />
  </svg>
);

export default InstructionScreen;
