import styles from './Avatar.module.scss';

import React, { MouseEventHandler, ReactNode, useMemo } from 'react';
import cn from 'classnames';

import type { UserDto } from 'dtos';
import { getUserPhoto } from 'utils';
import EditIcon from "components/icons/Edit";
import TelegramIcon from "components/icons/Telegram";

const USER_PATTERN: RegExp = /\b(\w)/g;

export enum AvatarSize {
  Default = 'default',
  Big = 'big',
  Small = 'small',
  Payment = 'payment',
  SuperSmall = 'superSmall'
}

export type UserProps = Pick<UserDto, 'username' | 'profilePictureUrl' | 'customPhotoUrl'>;

export interface AvatarProps extends UserProps {
  size?: AvatarSize;
  className?: string;
  dotIndicator?: ReactNode;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onChangePhoto?: () => void;
  tgChatLink?: string;
}

export const Avatar = ({
  username,
  customPhotoUrl,
  profilePictureUrl,
  dotIndicator,
  className,
  size = AvatarSize.Default,
  onClick,
  onChangePhoto,
  tgChatLink
}: AvatarProps) => {
  const userPhoto = useMemo(() => getUserPhoto({
    customPhotoUrl,
    profilePictureUrl,
  }), [customPhotoUrl, profilePictureUrl]);
  const userInitials = useMemo(() => {
    const initials: RegExpMatchArray | null = username
      ? username.match(USER_PATTERN)
      : null;

    if (!initials) {
      return '';
    }

    return initials.slice(0, 2).join('');
  }, [username]);

  if (!username) {
    return <></>;
  }

  return (
    <div
      onClick={onClick}
      className={cn(
        styles.avatar,
        styles[size],
        { [styles.withDotIndicator]: dotIndicator },
        className
      )}
    >
      {userPhoto ? (
        <img className={styles.img} src={userPhoto} alt={username} />
      ) : (
        userInitials
      )}

      {dotIndicator}

      {onChangePhoto && (
        <button
          className={styles.changePhotoBtn}
          onClick={onChangePhoto}
        >
          <EditIcon className={styles.icon} />

        </button>
      )}

      {tgChatLink && (
        <a className={styles.goToTgBtn} href={tgChatLink}>
          <TelegramIcon className={styles.telegramIcon} />
        </a>
      )}
    </div>
  );
};
