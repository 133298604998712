import styles from './Exchange.module.scss';

import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import { IconName } from 'components/Icon/IconList';
import Icon from 'components/Icon/Icon';
import { ExchangeRateWidgetDto, ExchangeWidgetDto } from 'dtos';
import CurrencyInput from 'components/Input/CurrencyInput/CurrencyInput';
import { CurrencyInputProps } from 'react-currency-input-field';

export type ExchangeProps = {
  currency: ExchangeWidgetDto;
  className?: string;
  disableInputs: boolean;
  setExchange: (exchange: ExchangeRateWidgetDto) => void;
};

function Exchange(props: ExchangeProps): JSX.Element {
  const { className, currency, disableInputs, setExchange } = props;
  const sellAmount = currency.sellCurrency.amount;
  const buyAmount = currency.buyCurrency.amount;

  const [sellAmountInput, setSellAmountInput] = useState(`${sellAmount}`);
  const [buyAmountInput, setBuyAmountInput] = useState(`${buyAmount}`);

  useEffect(() => {
    sellAmount ? setSellAmountInput(`${sellAmount}`) : setSellAmountInput('0');
    buyAmount ? setBuyAmountInput(`${buyAmount}`) : setBuyAmountInput('0');
  }, [currency]);

  const setSellInput: CurrencyInputProps['onValueChange'] = (v, _, values) => {
    const value = String(v ?? 0);
    setSellAmountInput(value);
    setExchangeFromInput(value, true);
  };

  const setBuyInput: CurrencyInputProps['onValueChange'] = (v, _, values) => {
    const value = String(v ?? 0);
    setBuyAmountInput(value);
    setExchangeFromInput(value, false);
  };

  const setExchangeFromInput = (value: string, isSelling: boolean) => {
    if (value.endsWith('.')) {
      return;
    }
    if (value.endsWith('0') && value.includes('.')) {
      return;
    }
    if (isSelling) {
      setExchange({
        sellCurrency: { ...currency.sellCurrency, amount: Number(value) },
        buyCurrency: { ...currency.buyCurrency },
      });
    } else {
      setExchange({
        sellCurrency: { ...currency.sellCurrency },
        buyCurrency: { ...currency.buyCurrency, amount: Number(value) },
      });
    }
  };

  return (
    <div className={cn(className, styles.container)}>
      <div className={styles.currencyContainer}>
        <CurrencyInput
          className={styles.currency}
          disabled={!currency.sellCurrency.canBeChanged || disableInputs}
          value={sellAmountInput}
          inputClassName={styles.input}
          style={{ width: sellAmountInput.toString().length * 9 }}
          onValueChange={setSellInput}
          autoComplete="off"
          decimalsLimit={8}
        />

        <p>{currency.sellCurrency.code}</p>
      </div>

      <Icon name={IconName.ExchangeRate} width={14} height={11} className={styles.icon} />

      <div className={styles.currencyContainer}>
        <CurrencyInput
          className={styles.currency}
          disabled={!currency.buyCurrency.canBeChanged || disableInputs}
          value={buyAmountInput}
          inputClassName={styles.input}
          style={{ width: buyAmountInput.toString().length * 9, minWidth: 9 }}
          onValueChange={setBuyInput}
          autoComplete="off"
          decimalsLimit={8}
        />

        <p>{currency.buyCurrency.code}</p>
      </div>
    </div>
  );
}

export default Exchange;
