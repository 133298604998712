import styles from './FriendsHeader.module.scss';

import React, { ChangeEvent, useState } from 'react';
import { queryClient } from 'shared/config/reactQuery';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { INVITATIONS } from 'pages/paths';
import { ContactsCounter } from 'modules/InfiniteContactList';
import { Layout } from 'components/Layout/Layout';
import Input from 'components/Input/Input';
import IconSearch from 'components/icons/Search';
import IconPlusBold from 'components/icons/PlusBold';

type FriendsHeaderProps = {
  debounceSetSearchString: (searchString?: string) => Promise<void>;
};

export function FriendsHeader(props: FriendsHeaderProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { debounceSetSearchString } = props;

  const goToInvitations = () => {
    queryClient.invalidateQueries(['friends']);
    navigate(INVITATIONS);
  };

  const searchPlaceholder = t('page.friends.search');
  const [searchValue, setSearchValue] = useState<string | undefined>('');
  const [showSearch, setShowSearch] = useState(false);
  const changeSelected = (event: ChangeEvent<HTMLInputElement>) => {
    if (showSearch) {
      setSearchValue(event.target.value);
      debounceSetSearchString(event.target.value);
    } else {
      setSearchValue('');
      debounceSetSearchString(undefined);
    }
  };
  const handleShowSearch = () => {
    setShowSearch(!showSearch);
    if (showSearch) {
      setSearchValue('');
      debounceSetSearchString(undefined);
    }
  };

  return (
    <Layout.TopBar unsetRowGap>
      <Layout.Title className={styles.title}>
        {t('page.friends.title')} (<ContactsCounter />)
        <div className={styles.buttonsBlock}>
          <button className={styles.button} onClick={handleShowSearch}>
            <IconSearch className={styles.icon} />
          </button>
          <button className={styles.button} onClick={goToInvitations}>
            <IconPlusBold className={styles.icon} />
          </button>
        </div>
      </Layout.Title>
      <Input
        name="search"
        onChange={changeSelected}
        placeholder={searchPlaceholder}
        inputClassName={cn(styles.search, { [styles.displayNone]: !showSearch })}
        value={searchValue}
      />
    </Layout.TopBar>
  );
}
