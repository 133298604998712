import styles from './Button.module.scss';

import React, { useState, useEffect, useRef, ButtonHTMLAttributes, ForwardedRef } from 'react';
import cn from 'classnames';
import omit from 'lodash/omit';
import Spinner from 'components/Spinner/Spinner';

export enum Variant {
  Contained = 'contained',
  ContainedDark = 'containedDark',
  Outlined = 'outlined',
  Exchanger = 'exchanger',
  Rounded = 'rounded',
  Success = 'success',
  White = 'white',
  Minimal = 'minimal',
  Gray = 'gray',
  Telegram = 'telegram',
  WhatsApp = 'whatsapp',
  BlackWhite = 'blackWhite',
}

export interface DefaultButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {}

export interface ButtonProps extends DefaultButtonProps {
  variant?: Variant;
  active?: boolean;
  danger?: boolean;
  isLoading?: boolean;
}

function Button(
  { children, variant = Variant.Contained, active, danger, ...props }: ButtonProps,
  ref: ForwardedRef<HTMLButtonElement>
): JSX.Element {
  const computedClasses = {
    [styles.active]: active,
    [styles.danger]: danger,
  };

  const [height, setHeight] = useState(0);
  const contentRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    setHeight(contentRef?.current?.clientHeight || 0);
  }, []);

  return (
    <button
      ref={ref}
      {...omit(props, ['isLoading'])}
      className={cn(styles.button, styles[variant], computedClasses, props.className, {
        [styles.disabled]: props.disabled,
      })}
    >
      {!props.isLoading ? (
        <>
          {variant === Variant.Telegram && <i className={styles.icon} />}
          <span ref={contentRef}>{children}</span>
        </>
      ) : (
        <Spinner size={height} />
      )}
    </button>
  );
}

export default React.forwardRef(Button);
