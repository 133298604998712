import styles from './InfiniteCustomerList.module.scss';

import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';

import { InfiniteList } from 'components/InfiniteList';
import { UserCard } from 'entities1/user';

import { useGetInfiniteCustomers } from './api/useGetInfiniteCustomers';

import SkeletonWithWrapper from 'shared/ui/SkeletonWithWrapper';
import { getIndexOfLastListElement } from 'shared/lib/arrays';

type InfiniteContactListProps = {
  onClickItem?: (id: number) => void;
  searchString?: string;
};

export function InfiniteCustomerList(props: InfiniteContactListProps): JSX.Element {
  const { ref, inView } = useInView();
  const { t } = useTranslation();
  const { searchString, onClickItem } = props;

  const { data, fetchNextPage, isLoading, isFetchingNextPage } = useGetInfiniteCustomers(searchString);

  const contacts = useMemo(() => {
    const allContacts = data?.pages.map((p) => p.content) || [];
    return allContacts.flat();
  }, [data?.pages]);

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage]);

  const indexOfLastListElement = getIndexOfLastListElement(contacts);

  return (
    <InfiniteList
      limit={20}
      loading={isLoading}
      loadingNextPage={isFetchingNextPage}
      listClassName={styles.list}
      loaderItem={<SkeletonWithWrapper height={64} />}
      emptyListPlaceholder={t('modal.createPaymentInvitation.empty')}
    >
      {contacts.map((c, idx) => (
        // <span>{JSON.stringify(c)}</span>
        <li key={c?.id} ref={indexOfLastListElement === idx ? ref : undefined} className={styles.customer} onClick={() => {onClickItem && onClickItem(Number(c?.id))}}>
          <UserCard user={c} />
        </li>
      ))}
    </InfiniteList>
  );
}
