import { BaseIconProps } from '../types';

const Telegram = (props: BaseIconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 0 24 24" width="16" {...props}>
    <path
      d="m1.95617055 11.392196c5.77764656-2.42328736 9.63031585-4.02086673 11.55800785-4.79273807 5.5039525-2.20384954 6.6476266-2.5866818 7.3930574-2.59932314.1639507-.00278035.5305319.0363352.7679878.22182361.2005031.15662277.2556695.36819788.2820684.51669348.026399.1484956.0592719.48677234.0331404.75109194-.2982611 3.0169019-1.5888322 10.33812718-2.2454015 13.71710898-.2778191 1.4297738-.8288514 1.7357846-1.3584441 1.7826999-1.1509274.1019576-2.0208916-.5588425-3.1356211-1.2622918-1.7443316-1.1007592-2.3854935-1.3972358-4.0786694-2.4713734-1.95675765-1.2413519-.8891962-1.8911034.2259543-3.0061212.2918402-.2918054 5.3989024-4.83750096 5.497052-5.24030969.0122753-.05037796-.1557336-.55407742-.2716182-.65323489-.1158847-.09915747-.2869204-.06524947-.4103446-.03828214-.17495.03822537-2.9615423 1.81132342-8.35977698 5.31929412-.79096496.5228681-1.50739646.7776269-2.1492945.7642766-.70764107-.0147176-2.06885864-.3851791-3.08078398-.7018404-1.24116762-.388398-1.69932554-.5713149-1.61342745-1.2309348.04474105-.3435709.36011227-.7024173.94611366-1.0765391z"
      fill="#fff"
      fillRule="evenodd"
    />
  </svg>
);

export default Telegram;
