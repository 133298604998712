import { useTranslation } from 'react-i18next';

import { CurrencyResponseDto, ExchangerType, GoodsResponseDto } from 'dtos';
import { formatNumber } from 'utils';
import styles from './ExchangeUnitInfo.module.scss';

interface ExchangeUnitInfoProps {
  exchangeUnit?: Partial<CurrencyResponseDto & GoodsResponseDto>;
  amount?: number;
  isSelling: boolean;
  isCurrentUserProvider?: boolean;
}

export default function ExchangeUnitInfo(props: ExchangeUnitInfoProps): JSX.Element {
  const { exchangeUnit, amount, isSelling, isCurrentUserProvider } = props;
  const { t } = useTranslation();
  const type = exchangeUnit?.paymentMethod ? ExchangerType.Currency : ExchangerType.Goods;

  if (type === ExchangerType.Currency) {
    const title = isCurrentUserProvider ? t(isSelling ? 'labels.clientGives' : 'labels.clientGets') : t(isSelling ? 'labels.youGive' : 'labels.youGet');
    const currency = exchangeUnit as CurrencyResponseDto;
    const currencyInfo = `${currency.code}, ${currency.paymentMethod?.localizedType ?? currency.paymentMethod.type}, ${currency.paymentMethod?.name}`;

    return (
      <div className={styles.currencyInfo}>
        <div className={styles.currencyLabel}>
          <div className={styles.currencyLabelTitle}>{title}</div>
        </div>

        {amount && <div className={styles.currencyAmount}>{formatNumber(amount)}</div>}

        <div className={styles.currencyPaymentMethod}>{currencyInfo}</div>
      </div>
    );
  } else {
    const sku = exchangeUnit as GoodsResponseDto;
    const skuName = sku.localizedName || sku.name;
    const city = t('page.exchangeInfo.goods.city');
    const cityName = sku.localizedCity || sku.city;
    const measureUnit = sku.localizedMeasureUnit || sku.measureUnit;
    const sortedSkuParams = [...sku.skuParameters].sort((a, b) => (b.type === 'COLOR' ? -1 : 0));

    const skuPicture = {
      backgroundImage: 'url(' + sku.picture + ')',
    };

    return (
      <div className={styles.skuInfo}>
        <div className={styles.skuCard} style={skuPicture}>
          <div className={styles.skuInfoTitle}>
            <div className={styles.skuName}>{skuName}</div>
          </div>
        </div>

        <div className={styles.skuParamTab}>
          <div className={styles.skuParamName}>{city}</div>
          <div className={styles.skuParamValue}>{cityName}</div>
        </div>

        <div className={styles.skuParamTab}>
          <div className={styles.skuParamName}>{measureUnit}</div>
          <div className={styles.skuParamValue}>{amount}</div>
        </div>

        {sortedSkuParams.map((p) => (
          <div className={styles.skuParamTab} key={p.name}>
            <div className={styles.skuParamName}>{p.localizedName ?? p.name}</div>
            <div className={styles.skuParamValue}>
              {p.type === 'COLOR' ? p.value.split('|')[1] : (p.localizedValue ?? p.value)}
            </div>
          </div>
        ))}
      </div>
    );
  }
}
