import styles from './ExchangeRates.module.scss';

import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import * as PATH from 'pages/paths';
import {Select} from 'components/Select';
import User from 'components/User/User';
import Providers from './Providers';

import { AvatarSize } from 'shared/ui/Avatar/Avatar';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { hasProvidePermission } from 'entities1/user/model/roles';
import { ResellType } from 'dtos';
import { useResellStrategyMutation } from 'services/users';

function ExchangeRates(): JSX.Element | null {
  const { t } = useTranslation();
  const user = useCurrentUser();
  const [updateResellStrategy] = useResellStrategyMutation();

  const isProvider = hasProvidePermission(user);

  return (
    <div className={styles.wrap}>
      <h3 className={styles.title}>{t('page.exchangeRates.title.you')}</h3>

      <Link to={PATH.EXCHANGE_RATES_MY}>
        <User
          user={user}
          size={AvatarSize.Payment}
          filled
          className={styles.user}
        />
      </Link>

      {isProvider &&
        <div className={styles.resellStrategy}>
          {t('page.exchangeRates.resellStrategy')}

          <Select
            value={user.resellStrategy}
            values={[ResellType.Default, ResellType.ChainPayments, ResellType.SquashPayments]}
            onChange={updateResellStrategy}
            buttonClassName={styles.selectButton}
            optionsClassName={styles.selectOptions}
            labels={{
              [ResellType.Default]: t('resellStrategy.default'),
              [ResellType.ChainPayments]: t('resellStrategy.chainPayments'),
              [ResellType.SquashPayments]: t('resellStrategy.squashPayments'),
            }}
          />
        </div>
      }

      <h3 className={styles.title}>
        {t('page.exchangeRates.title.availableProviders')}
      </h3>

      <Providers />
    </div>
  );
}

export default ExchangeRates;
