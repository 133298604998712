import styles from './Toggle.module.scss'

interface ToggleProps {
  isToggled: boolean;
  setIsToggled: (value: boolean) => void;
}

const Toggle = ({ isToggled, setIsToggled }:ToggleProps) => {

  const toggle = () => {
    setIsToggled(!isToggled);
  };

  return (
    <div className={styles.toggleContainer}>
      <label className={styles.guiSwitch}>
        <input type="checkbox" checked={isToggled} onChange={toggle} />
        <span className={styles.slider}></span>
      </label>
    </div>
  );
};

export default Toggle;
