import styles from './BalancesSummary.module.scss';

import React, { useCallback, useEffect } from 'react';
import cn from 'classnames';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import find from 'lodash/find';
import { useInView } from 'react-intersection-observer';
import { Disclosure } from '@headlessui/react';
import { useTranslation } from 'react-i18next';

import BalanceCardInfo from './BalanceCardInfo';

import type { BalanceParticipantResponseDto, BalanceResponseDto, UserDto } from 'dtos';
import { BalanceParticipantRole } from 'dtos';

import SkeletonWithWrapper from 'shared/ui/SkeletonWithWrapper';
import { getIndexOfLastListElement } from 'shared/lib/arrays';

import { getUserName, isBalanceType } from 'utils';

import { useArchiveBalanceMutation, useUpdateBalancePriorityMutation } from 'services/balances';
import BalanceCard from 'components/BalanceCard';
import { ModalTypes, open } from 'slices/modal';
import BalanceInfoModal from 'widgets/BalanceInfoModal';

import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import CreateBalanceTransactionModal, { BalanceType } from 'widgets/CreateBalanceTransactionModal';
import ObligationTransferModal from 'widgets/ObligationTransferModal';

export interface BalancesSummaryProps {
  balances: BalanceResponseDto[];
  isLoading: boolean;
  className?: string;
  showContact?: boolean;
  enableSort?: boolean;
}

function BalancesSummary({
  balances,
  isLoading,
  className,
  showContact,
  enableSort,
}: BalancesSummaryProps): JSX.Element | null {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { ref } = useInView();
  const { t } = useTranslation();
  const { id: currentUserId } = useCurrentUser();
  const [removeBalance, { isLoading: isRemoving }] = useArchiveBalanceMutation();
  const [updateBalancePriority, { isLoading: isUpdatingPriority }] =
    useUpdateBalancePriorityMutation();

  useEffect(() => {
    const lastClickedBalanceId = sessionStorage.getItem('lastClickedBalanceId');

    if (lastClickedBalanceId) {
      setTimeout(() => {
        const element = document.getElementById(`balance-${lastClickedBalanceId}`);
        if (element) {
          element.scrollIntoView({ behavior: 'instant' });
        }
        sessionStorage.removeItem('lastClickedBalanceId');
      }, 0);
    }
  }, []);

  const openTransactionModal = useCallback(
    (friend: UserDto) =>
      (evt: React.MouseEvent<HTMLButtonElement>): void => {
        evt.preventDefault();
        const type: string | undefined = evt.currentTarget.dataset.balanceType;
        if (isBalanceType(type)) {
          const code: string | undefined = evt.currentTarget.dataset.code;
          const balanceId = Number(evt.currentTarget.dataset.balanceId) || 0;
          const balance = balances.find((balance) => balance.id === balanceId);

          dispatch(
            open({
              id: ModalTypes.CreateBalanceTransaction,
              data: { contact: friend, type, code, balance },
            })
          );
        }
      },
    [dispatch, balances]
  );

  const openObligationTransferModal = useCallback(
    (friend: UserDto) =>
      (evt: React.MouseEvent<HTMLButtonElement>): void => {
        evt.preventDefault();
        const code: string = evt.currentTarget.dataset.code!!;
        const balanceId = Number(evt.currentTarget.dataset.balanceId) || 0;
        const balance = balances.find((balance) => balance.id === balanceId);
        const amount = evt.currentTarget.dataset.value!!;
        dispatch(
          open({
            id: ModalTypes.ObligationTransfer,
            data: { contact: friend, fromBalance: balance, code: code, value: amount },
          })
        );
      },
    [dispatch, balances]
  );

  const onAssistantClick = (data: { balanceId: BalanceResponseDto['id'] }) => () => {
    sessionStorage.setItem('lastClickedBalanceId', data.balanceId.toString());
    navigate(`/balance/${data.balanceId}/assistants`);
  };

  const onChangeBalance =
    (data: { contactId: UserDto['id']; balance: BalanceResponseDto }) => () => {
      dispatch(
        open({
          id: ModalTypes.BalanceInfo,
          data: { balance: data.balance, contactId: data.contactId },
        })
      );
    };

  const onArchiveBalance =
    (data: { balanceId: BalanceResponseDto['id']; contactId: UserDto['id'] }) => () => {
      toast.promise(removeBalance(data).unwrap(), {
        loading: t('status.archiving'),
        success: () => {
          return <b>{t('status.successArchivedBalance')}</b>;
        },
        error: (error) => {
          const errorMessage =
            error?.error || error?.data?.message || error?.errorMessage || t('status.smthWrong');
          return <b>{errorMessage}</b>;
        },
      });
    };

  const onDeleteBalance =
    (data: { balanceId: BalanceResponseDto['id']; contactId: UserDto['id'] }) => () => {
      toast.promise(removeBalance(data).unwrap(), {
        loading: t('status.deleting'),
        success: () => {
          return <b>{t('status.successDeletedBalance')}</b>;
        },
        error: (error) => {
          const errorMessage =
            error?.error || error?.data?.message || error?.errorMessage || t('status.smthWrong');
          return <b>{errorMessage}</b>;
        },
      });
    };

  const onSortBalance = (balanceId: BalanceResponseDto['id']) => () => {
    toast.promise(updateBalancePriority(balanceId).unwrap(), {
      loading: t('status.processing'),
      success: () => {
        return <b>{t('status.successChangeBalance')}</b>;
      },
      error: (error) => {
        const errorMessage =
          error?.error || error?.data?.message || error?.errorMessage || t('status.smthWrong');
        return <b>{errorMessage}</b>;
      },
    });
  };

  const handleHistoryClick = (balanceId: number) => () => {
    sessionStorage.setItem('lastClickedBalanceId', balanceId.toString());
    navigate(`/balance/${balanceId}/history`);
  };

  if (isLoading) {
    return <SkeletonWithWrapper height={85} count={2} className={className}/>;
  }

  return (
    <div className={cn(styles.wrap, className)}>
      {balances.map((balance, index) => {
        const { buckets, id, participants, canTransfer } = balance;
        const { friend, master, isAssistant } = getParticipantsInformation(
          participants,
          currentUserId
        );
        return (
          <div id={`balance-${id}`} className={styles.balanceWrap} key={id}>
            <Disclosure defaultOpen>
              <Disclosure.Button as="div" disabled className={styles.title}>
                <BalanceCard
                  balance={balance}
                  titleClassName={styles.balanceTitle}
                  onAssistantClick={!isAssistant ? onAssistantClick({ balanceId: id }) : undefined}
                  onChange={
                    !isAssistant
                      ? onChangeBalance({
                          contactId: friend.id,
                          balance,
                        })
                      : undefined
                  }
                  onDelete={
                    !isAssistant
                      ? onDeleteBalance({
                          balanceId: id,
                          contactId: friend.id,
                        })
                      : undefined
                  }
                  onArchive={
                    !isAssistant
                      ? onArchiveBalance({
                          balanceId: id,
                          contactId: friend.id,
                        })
                      : undefined
                  }
                  onSort={onSortBalance(id)}
                  onHistoryClick={handleHistoryClick(id)}
                  isLoading={isLoading || isRemoving || isUpdatingPriority}
                  friend={isAssistant || showContact ? friend : undefined}
                  enableSort={index === 0 ? undefined : enableSort}
                />
              </Disclosure.Button>

              <Disclosure.Panel as="div" key={id}>
                <ul className={styles.balances}>
                  {buckets.map((b, idx) => (
                    <li
                      key={b.shortname}
                      ref={getIndexOfLastListElement(buckets) === idx ? ref : undefined}
                      className={styles.balance}
                    >
                      <BalanceCardInfo
                        {...b}
                        balanceId={id}
                        onCreateBalanceTransaction={openTransactionModal(friend)}
                        onObligationTransfer={openObligationTransferModal(friend)}
                        canTransfer={canTransfer}
                      />
                    </li>
                  ))}
                </ul>
              </Disclosure.Panel>

              {isAssistant && (
                <p className={styles.deligated}>
                  {t('component.balanceSummary.deligated', { name: getUserName(master) })}
                </p>
              )}

              <div className={styles.btns}>
                <button
                  type="button"
                  data-balance-id={id}
                  data-balance-type={BalanceType.Receive}
                  onClick={openTransactionModal(friend)}
                  className={styles.btn}
                >
                  {t('button.receive')}
                </button>
                <button
                  type="button"
                  data-balance-id={id}
                  data-balance-type={BalanceType.Send}
                  onClick={openTransactionModal(friend)}
                  className={styles.btn}
                >
                  {t('button.send')}
                </button>
              </div>
            </Disclosure>
          </div>
        );
      })}

      <CreateBalanceTransactionModal />
      <ObligationTransferModal />
      <BalanceInfoModal />
    </div>
  );
}

export function getParticipantsInformation(
  participants: BalanceParticipantResponseDto[],
  currentUserId: number
) {
  const mySelf = find(participants, (p) => p.user.id === currentUserId);
  const myRole = mySelf?.role.split('_')[0];
  const isAssistant = mySelf?.role.indexOf('ASSISTANT') !== -1;
  const friendRole = myRole?.slice(-1) === '1' ? 'USER2' : 'USER1';
  const friend = find(participants, (p) => p.role === friendRole)?.user;
  const master = find(participants, (p) => p.role === myRole)?.user;

  return { isAssistant, friend, master } as {
    isAssistant: boolean;
    friend: UserDto;
    master: UserDto;
  };
}

export function getOtherBalanceOwner(
  participants: BalanceParticipantResponseDto[],
  currentUserId: number
): UserDto | undefined {
  const currentParticipant = participants.find((it) => it.user.id === currentUserId);

  if (!currentParticipant) {
    return;
  }

  switch (currentParticipant.role) {
    case BalanceParticipantRole.User1:
    case BalanceParticipantRole.User1Assistant:
      return participants.find((it) => it.role === BalanceParticipantRole.User2)?.user;
    case BalanceParticipantRole.User2:
    case BalanceParticipantRole.User2Assistant:
      return participants.find((it) => it.role === BalanceParticipantRole.User1)?.user;
  }
}

export default BalancesSummary;
