import { RefObject, useEffect } from 'react';

export interface UseClickOutsideOptions<T extends HTMLElement = HTMLElement> {
  ref: RefObject<T>;
  cb: (event: Event) => void;
}

export const useClickOutside = ({ ref, cb }: UseClickOutsideOptions): void => {
  useEffect(() => {
    const handleClick = (evt: MouseEvent) => {
      const elem = ref?.current;
      if (!elem || elem.contains((evt?.target as Node) || null)) {
        return;
      }
      cb(evt);
    };

    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [ref, cb]);
};
