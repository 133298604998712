import { useTranslation } from 'react-i18next';

import styles from './TextInfo.module.scss';
import Input from 'components/Input/Input';

type ExchangeUnitInfoProps = {
  text: string;
  onChange: (text: string) => void;
  type: 'title' | 'comment';
};

export default function TextInfo(props: ExchangeUnitInfoProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className={styles.wrap}>
      <div className={styles.label}>
        <div className={styles.labelTitle}>{t(`labels.${props.type}`)}</div>
      </div>

      <div className={styles.inputWrap}>
        <Input
          value={props.text}
          onChange={(event) => {
            props.onChange(event.target.value);
          }}
          inputClassName={styles.input}
        />
      </div>
    </div>
  );
}
