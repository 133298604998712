import cn from 'classnames';
import styles from './InputWithFloatingPlaceholder.module.scss';
import React, { InputHTMLAttributes } from 'react';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  label?: string;
  placeholder?: string;
  placeholderClassName?: string;
  error?: string;
  ref?: React.LegacyRef<HTMLInputElement>;
}

const InputWithFloatingPlaceholder = ({
  className,
  labelClassName,
  inputClassName,
  label,
  placeholder,
  placeholderClassName,
  error,
  ref,
  ...inputProps
}: InputProps) => {
  const placeholderStyles = !inputProps.value ? styles.bigPlaceholder : styles.smallPlaceholder;

  return (
    <div className={cn(styles.container, { [styles.error]: error }, className)}>
      {label && <div className={cn(labelClassName, styles.label)}>{label}</div>}

      <input
        className={cn(styles.input, inputClassName, {
          [styles.disabled]: inputProps.disabled,
        })}
        ref={ref}
        {...inputProps}
      />

      {placeholder && (
        <span className={cn(placeholderClassName, styles.placeholder, placeholderStyles)}>
          {placeholder}
        </span>
      )}

      {error && <p className={cn(styles.error, styles.errorMessage)}>{error}</p>}
    </div>
  );
};

export default InputWithFloatingPlaceholder;
