import React, { Children, ReactNode } from 'react';

import styles from './Row.module.scss';

type RowProps = {
  children: ReactNode;
};

export const Row = ({ children }: RowProps) => {
  return (
    <div
      className={styles.row}
      style={{
        gridTemplateColumns: `repeat(${
          Children.toArray(children).length
        }, 1fr)`,
      }}
    >
      {children}
    </div>
  );
};
