import styles from './Invitation.module.scss';

import React, { ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { Avatar, AvatarSize } from 'shared/ui/Avatar/Avatar';

import { InvitationTokenDto } from 'dtos';

import { formatDate, getUserName, getUserPhoto } from 'utils';

export type InvitationProps = Partial<{
  invitation: InvitationTokenDto;
  className: string;
}>;

const ONE_AND_HALF_SEC: number = 500;

function Invitation ({ invitation, className }: InvitationProps) {
  const { t } = useTranslation();
  const [linkCopied, setLinkCopied] = useState<boolean>(false);

  const userName = useMemo(() => invitation && invitation.usedBy ? getUserName(invitation.usedBy) : '', [invitation]);
  const userPhoto = useMemo(() => invitation && invitation.usedBy && getUserPhoto(invitation.usedBy), [invitation]);

  if (!invitation) return <></>;

  const createdAt = formatDate(new Date(invitation.createdAt), {
    month: '2-digit',
    year: 'numeric',
  }).replaceAll('/', '.').replaceAll(',', '');

  function getRemainingTime(date: Date, offset: number): string | false {
    var currentDate = new Date();
    var expiredDate = new Date(date.getTime() + (offset * 1000));

    var time = expiredDate.getTime() - currentDate.getTime();
    var totalSeconds = (time / 1000);
    const totalMinutes = Math.floor(totalSeconds / 60);

    const minutes = String(totalMinutes % 60).padStart(2, '0');
    const hours = Math.floor(totalMinutes / 60);

    if (time < 0) {
      return false;
    }

    return `${hours}${t('time.short.hour')} ${minutes}${t('time.short.minute')}`;
  }

  const remainingTime = getRemainingTime(new Date(invitation.createdAt), invitation.ttlSec);

  const copyShareLink = (): void => {
    const { botLink } = invitation;

    if (!botLink) return;

    navigator.clipboard.writeText(botLink.trim()).then(() => {
        setLinkCopied(true);
        setTimeout(() => setLinkCopied(false), ONE_AND_HALF_SEC);
    });
  };

  const InvitationWrapper = ({ children }: { children: ReactNode}) => {
    return (
      <div
        className={cn(
          styles.invitation,
          className
        )}
        onClick={copyShareLink}
      >
        {linkCopied && (
          <div className={styles.notification}>{t('component.invitation.copied')}</div>
        )}
        {children}
      </div>
    )
  }

  // Accepted invitation
  if (invitation.usedBy) {

    return (
      <InvitationWrapper>
        <Avatar
          size={AvatarSize.Default}
          username={userName}
          profilePictureUrl={userPhoto}
        />
        <div className={styles.invitationGeneral}>
          <div className={styles.invitationUsername}>{userName}</div>
          <div className={styles.invitationCreatedAt}>{t('component.invitation.createdAt')}: {createdAt.split(', ')[0]}</div>
        </div>
        <div className={styles.invitationStatus}>
          <div className={styles.invitationCode}>{t('component.invitation.inviteCode')}: {invitation.token}</div>
          <div className={cn(styles.invitationStatusValue, styles.accepted)}>{t('component.invitation.accepted')}</div>
        </div>
      </InvitationWrapper>
    )
  }

  // Default invitation
  return (
    <InvitationWrapper>
      <div className={styles.invitationGeneral}>
        <div className={styles.invitationCode}>{t('component.invitation.inviteCode')}: {invitation.token}</div>
        <div className={styles.invitationCreatedAt}>{t('component.invitation.createdAt')}: {createdAt}</div>
        {/* { false && (<div className={styles.invitationRecommendation}>{t('component.invitation.recommendation')}: Kirill S</div>)} */}
      </div>
      { remainingTime ? (
        <div className={styles.invitationStatus}>
          <div className={styles.invitationStatusLabel}>{t('component.invitation.remainingTime')}</div>
          <div className={cn(styles.invitationStatusValue, styles.default)}>{remainingTime}</div>
        </div>
      ) : (
        <div className={styles.invitationStatus}>
          <div className={cn(styles.invitationStatusValue, styles.expired)}>{t('component.invitation.expired')}</div>
        </div>
      )}
    </InvitationWrapper>
  );
}

export default Invitation;
