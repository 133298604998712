import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Layout } from 'components/Layout/Layout';
import BalanceAssistants from 'components/BalanceAssistants';

import styles from './index.module.scss';

function BalanceAssistantsPage(): JSX.Element {
  const { t } = useTranslation();
  const { id } = useParams();
  const balanceId = Number(id);

  return (
    <Layout>
      <Layout.TopBar>
        <div className={styles.wrap}>
          <Layout.Back />
        </div>
        <Layout.Title>
          {t('page.balanceAssistants.currentAssistants')}
        </Layout.Title>
      </Layout.TopBar>
      <Layout.Content>
        <BalanceAssistants balanceId={balanceId} />
      </Layout.Content>
    </Layout>
  );
}

export default BalanceAssistantsPage;
