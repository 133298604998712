import styles from './InfiniteList.module.scss';

import React, { PropsWithChildren, useMemo } from 'react';
import cn from 'classnames';

import SkeletonWithWrapper from 'shared/ui/SkeletonWithWrapper';

export interface InfiniteListProps extends PropsWithChildren {
  listClassName?: string;
  itemClassName?: string;
  limit?: number;
  loading?: boolean;
  loadingNextPage?: boolean;
  loaderItem?: JSX.Element;
  emptyListPlaceholder?: string;
}

export function InfiniteList({
  children,
  loaderItem,
  limit = 10,
  loading,
  loadingNextPage,
  listClassName,
  itemClassName,
  emptyListPlaceholder,
}: InfiniteListProps): JSX.Element {
  const loaderList = useMemo(() => {
    return new Array<JSX.Element>(limit).fill(
      loaderItem || <SkeletonWithWrapper height={50} />
    );
  }, [limit, loaderItem]);

  const listClassNames = listClassName
    ? cn(styles.list, listClassName)
    : undefined;
  const itemClassNames = itemClassName
    ? cn(styles.item, itemClassName)
    : undefined;

  if (loading) {
    return (
      <ul className={listClassNames}>
        {loaderList.map((item, idx) => (
          <li key={idx} className={itemClassNames}>
            {item}
          </li>
        ))}
      </ul>
    );
  }

  if (Array.isArray(children) && children.length === 0) {
    return (
      <>
        {emptyListPlaceholder && (
          <ul className={listClassName}>
            <li data-empty={true} className={styles.empty}>
              {emptyListPlaceholder}
            </li>
          </ul>
        )}
      </>
    );
  }

  return (
    <ul className={listClassNames}>
      {children}

      {loadingNextPage &&
        loaderList.map((item, idx) => (
          <li key={idx} className={itemClassNames}>
            {item}
          </li>
        ))}
    </ul>
  );
}
