import instance from 'api';
import type {ExchangerCalculateRequest, ExchangeCalculationResponseV5} from 'dtos';
import type { PaymentsParams, PaymentsResponse } from 'api/payments/types';

import { setPaymentStatuses } from 'utils';

const PAYMENTS_URL: string = '/payments';
const CALCULATE_AMOUNT_URL: string = '/exchanger/v5/calculate';

export default class PaymentsService {
  static async getPayments({
    limit = 20,
    page = 1,
    paymentStatuses = [],
    participantRoles = [],
    paymentTypes = [],
    ...params
  }: PaymentsParams): Promise<PaymentsResponse> {
    const statuses: PaymentsParams['paymentStatuses'] = setPaymentStatuses(paymentStatuses);

    const { data } = await instance.get<PaymentsResponse>(`${PAYMENTS_URL}/v4`, {
      params: {
        limit,
        page,
        paymentStatuses: statuses.length > 0 ? statuses.join(',') : undefined,
        participantRoles: participantRoles.length > 0 ? participantRoles?.join(',') : undefined,
        paymentTypes: paymentTypes.length > 0 ? paymentTypes.join(',') : undefined,
        ...params,
      },
    });
    return data;
  }

  static async getPaymentsCalculateAmount(
    body: ExchangerCalculateRequest,
    signal?: AbortSignal
  ): Promise<ExchangeCalculationResponseV5> {
    const { data } = await instance.post<ExchangeCalculationResponseV5>(
      CALCULATE_AMOUNT_URL,
      body,
      { signal }
    );
    return data;
  }
}
