import styles from './FloatingActionBar.module.scss';

import React, { ButtonHTMLAttributes } from 'react';
import cn from 'classnames';

import Button, { Variant } from 'components/Button/Button';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: Variant.Contained | Variant.Outlined;
  bottomPadding?: boolean;
  containerClassName?: string;
  active?: boolean;
}

export interface IActionOfFloatingActionBar {
  text?: string;
  onClick?: () => void;
  variant: Variant;
  active?: boolean;
  className?: string;
  disabled?: boolean;
}

export interface IFloatingActionBar {
  actions?: IActionOfFloatingActionBar[];
  bottomPadding?: boolean;
  className?: string
}

const ActionOfFloatingActionBar = ({
  text,
  variant,
  active = false,
  className,
  ...props
}: IActionOfFloatingActionBar) => {
  const computedClassName = cn(
    // styles.button,
    // styles[variant],
    { [styles.active]: active },
    className
  );

  return (
    <Button {...props} variant={variant} className={computedClassName}>
      {text}
    </Button>
  )
}

const FloatingActionBar = ({
  actions,
  bottomPadding,
  className,
  ...props
}: IFloatingActionBar) => {
  return (
    <div
      className={cn(
        styles.sticky,
        bottomPadding && styles.bottomPadding,
        className
      )}
      {...props}
    >

      {!!(actions?.length) && (
        <div className={styles.actionList}>
          {actions.map((button, index) => (
            <ActionOfFloatingActionBar key={index} {...button} />
          ))}
        </div>
      )}
    </div>
  );
};

export default FloatingActionBar;
