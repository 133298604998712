import React from 'react';

import Spinner from 'components/Spinner/Spinner';

import styles from './ContentLoader.module.scss';

function ContentLoader({ refNode }: { refNode?: (node?: Element | null) => void }) {

    return (
        <div ref={refNode} className={styles.contentLoader}>
            <Spinner />
        </div>
    )
}

export default ContentLoader;
