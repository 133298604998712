import styles from './CallMeBackModal.module.scss';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import { RootState } from 'shared/redux-store';

import { hide, ModalTypes } from 'slices/modal';
import isEmpty from 'lodash/isEmpty';
import Modal from 'components/Modal/Modal';
import Form from './components/Form';
import Thanks from './components/Thanks';
import { ExchangerFormData, Language, TargetField } from 'dtos';
import { useExchangerStore } from '../ExchangerWidget';

export interface CallMeBackData {
  providerId: number;
  name?: string;
  phone?: string;
  language: Language;
  utm: string;
  policyPrivacy: boolean;
  exchangerFormData: ExchangerFormData;
  sent?: boolean;
  isSending?: boolean;
}

function CallMeBackModal(): JSX.Element | null {
  const dispatch = useDispatch();
  const { visible, data: modalData } = useSelector(
    (state: RootState) => state.modal[ModalTypes.CallMeBack]
  );

  const [callMeBackData, setCallMeBackData] = useState({
    ...modalData,
  } as CallMeBackData);

  const { currentFormData: currentExchangerFormData, activeOption } = useExchangerStore(
    modalData.providerId,
    null,
    { cache: true }
  );

  useEffect(() => {
    if (!currentExchangerFormData) {
      return;
    }
    const { sellExchangeUnit, buyExchangeUnit, sellExchangeUnitAmount, buyExchangeUnitAmount } =
      currentExchangerFormData;
    const exchangerData = {
      sellExchangeUnitDto: sellExchangeUnit,
      buyExchangeUnitDto: buyExchangeUnit,
      sellExchangeUnitAmount,
      buyExchangeUnitAmount,
      currentTab: activeOption,
      targetField: `BUY_CURRENCY` as TargetField,
    } as ExchangerFormData;
    setCallMeBackData({ ...modalData, exchangerFormData: exchangerData });
  }, [modalData]);

  const closeModal = (): void => {
    setCallMeBackData({} as CallMeBackData);
    dispatch(hide({ id: ModalTypes.CallMeBack }));
  };

  const setData = (data: CallMeBackData) => {
    setCallMeBackData(data);
  };

  if (!visible || isEmpty(modalData)) {
    return null;
  }

  return (
    <Modal visible={visible} onClose={closeModal} className={styles.modal}>
      {!callMeBackData.sent ? (
        <>
          <div className={styles.title}>
            <Trans i18nKey="modal.callMeBack.title" />
          </div>

          <Form data={callMeBackData} onSetData={setData} onClose={closeModal} />
        </>
      ) : (
        <Thanks onClose={closeModal} language={callMeBackData.language} />
      )}
    </Modal>
  );
}

export default CallMeBackModal;
