import styles from './AddNewProviderButton.module.scss';

import React from 'react';
import { Link } from 'react-router-dom';

import { REDEEM_INVITATION } from 'pages/paths';

import Icon from 'components/Icon/Icon';
import { IconName } from 'components/Icon/IconList';

export function AddNewProviderButton(): JSX.Element {
  return (
    <Link to={REDEEM_INVITATION} className={styles.btn}>
      <Icon
        name={IconName.BigPlus}
        width={11}
        height={11}
        className={styles.icon}
      />
    </Link>
  );
}
