import instance from 'api';

import type { CurrencyInformationResponseDto } from 'dtos';

export default class CurrencyService {
  static async getCurrencies(): Promise<CurrencyInformationResponseDto> {
    const { data } = await instance.get<CurrencyInformationResponseDto>(
      '/currencies'
    );
    return data;
  }
  //TODO: add other methods
}
