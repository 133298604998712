import styles from './Amount.module.scss';

import React, { useMemo } from 'react';
import cn from 'classnames';

export interface AmountProps {
  value: number;
  options?: Intl.NumberFormatOptions;
  locale?: string;
  sign?: boolean;
  colored?: boolean;
  compact?: boolean;
  code?: string;
  className?: string;
}

function Amount({
  value,
  options,
  locale = 'us-EN',
  compact,
  sign,
  colored,
  code,
  className,
}: AmountProps): JSX.Element {
  const formattedValue = useMemo(() => {
    return new Intl.NumberFormat(locale, {
      style: 'decimal',
      useGrouping: true,
      ...options,
      signDisplay: sign ? 'exceptZero' : undefined,
      notation: compact ? 'compact' : undefined,
    }).format(value);
  }, [locale, sign, compact, value, options]);

  const colorStyles: Record<string, boolean> | undefined = colored
    ? {
        [styles.green]: value > 0,
        [styles.red]: value < 0,
      }
    : undefined;

  return (
    <div className={cn(colorStyles, className)}>
      {formattedValue} {code}
    </div>
  );
}

export default Amount;
