import { useTranslation } from 'react-i18next';

import Icon from 'components/Icon/Icon';
import { IconName } from 'components/Icon/IconList';

import videoUrl from '../../assets/add-to-home-page-ios.mp4';

import styles from './AddToHomeScreen.module.scss';

const AddToHomeScreen = ({back}: {back?: () => void}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{t('install.pwa')}</h2>
      <video
        className={styles.video}
        src={videoUrl}
        playsInline
        autoPlay
        muted
        loop
      />
      <div className={styles.description}>
        <p className={styles.tapOnShare}>{t('install.tapOnShareButton')}</p>
        <Icon
          name={IconName.SafariShareIcon}
          width={24}
          height={24}
          fill="var(--blue)"
        />
      </div>
      <p onClick={back} className={styles.back}>{t('menu.back')}</p>
    </div>
  );
};

export default AddToHomeScreen;
