import { useTranslation } from 'react-i18next';
import React, { useLayoutEffect, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  CurrencyInformationResponseDto,
  CurrencyResponseDto,
  PaymentCreationResponseDtoV4,
  PaymentMethodType,
} from 'dtos';

import { isPaymentDetailsParams } from 'utils';
import AccountPaymentDetailsService from 'api/account-payment-details';

import styles from './DetailsForm.module.scss';
import Details from '../Details';
import isEmpty from 'lodash/isEmpty';

interface DetailsFormProps {
  paymentForm: PaymentCreationResponseDtoV4;
  currencies?: CurrencyInformationResponseDto[];
  updateDetails: React.Dispatch<React.SetStateAction<string | undefined>>;
  value: string | undefined;
}

export default function DetailsForm(props: DetailsFormProps): JSX.Element {
  const { paymentForm, updateDetails, value } = props;
  const { t } = useTranslation();
  const hasTransferMethod: boolean =
    (paymentForm.buyExchangeUnit as CurrencyResponseDto).paymentMethod.type ===
    PaymentMethodType.Transfer;
  const method = (paymentForm.buyExchangeUnit as CurrencyResponseDto).paymentMethod.name
  const code = (paymentForm.buyExchangeUnit as CurrencyResponseDto).code

  const { data: details, isLoading } = useQuery({
    queryKey: [
      'exchanger',
      'details',
      {
        method: method,
        code: code,
      },
    ],
    queryFn: async ({ queryKey }) => {
      const params = isPaymentDetailsParams(queryKey[2]) ? queryKey[2] : null;

      if (!params) {
        return null;
      }

      return await AccountPaymentDetailsService.getDetails(params);
    },
    enabled: hasTransferMethod,
  });

  const changeActiveReception = (value: string): void => {
    updateDetails(value);
  };

  const handleDetailSelect = (text: string) => {
    updateDetails(text);
  };

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useLayoutEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "inherit";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [value]);
  const rows = isEmpty(value) ? undefined : value!!.split('\n').length

  return (
    <>
      {hasTransferMethod && (
        <div className={styles.box}>
          <div className={styles.titleContainer}>
            <div className={styles.title}>{t(`labels.comment`)}</div>
            {!isLoading && details?.length !== 0 && (
              <Details details={details!!} onSelect={handleDetailSelect} method={method} code={code}/>
            )}
          </div>

          {!isLoading && (
            <div className={styles.inputWrap}>
              <textarea
                rows={rows}
                ref={textareaRef}
                value={value}
                onChange={(event) => {
                  changeActiveReception(event.target.value);
                }}
                className={styles.input}
                placeholder={t('page.exchangeInfo.receptionAccount.placeholder')}
                autoComplete="cc-number"
              />
            </div>
          )}
        </div>
      )}
    </>
  );
}
