import styles from '../styles.module.scss';
import AIPrioritizedIcon from 'components/icons/AIPrioritized';
import Task from 'components/Task';
import isEmpty from 'lodash/isEmpty';
import { TaskResponseDto, TaskStatus } from 'dtos';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

interface DelegatedProps {
  delegatedTasks: TaskResponseDto[];
}

const Delegated = ({ delegatedTasks }: DelegatedProps) => {
  const { t } = useTranslation();
  const location = useLocation().pathname;
  const prioritizedDelegatedTasks = delegatedTasks.filter(
    (task) =>
      (task.status === TaskStatus.ReadyForWork && task.isPrioritized) ||
      task.status === TaskStatus.InProgress,
  );
  const unprioritizedDelegatedTasks = delegatedTasks.filter(
    (task) => task.status === TaskStatus.ReadyForWork && !task.isPrioritized,
  );

  const toBeFormulatedDelegatedTasks = delegatedTasks.filter(
    (task) => task.status === TaskStatus.ToBeFormulated,
  );
  const blockedDelegatedTasks = delegatedTasks.filter(
    (task) => task.status === TaskStatus.Postponed,
  );

  const isDelegatedTasksEmpty = isEmpty(delegatedTasks);
  const isPrioritizedDelegatedTasksEmpty = isEmpty(prioritizedDelegatedTasks);
  const isUnprioritizedDelegatedTasksEmpty = isEmpty(unprioritizedDelegatedTasks);
  const isBlockedDelegatedTasksEmpty = isEmpty(blockedDelegatedTasks);
  const isToBeFormulatedDelegatedTasksEmpty = isEmpty(toBeFormulatedDelegatedTasks);

  return (
    <>
      { isDelegatedTasksEmpty ? (
        <div className={styles.placeholder}>{t('page.tasks.emptyDelegatedTasks')}</div>
      ) : (
        <>
          {!isPrioritizedDelegatedTasksEmpty && (
            <>
              <div className={styles.blockTitle}>
                <div className={styles.aiPrioritized}>
                  <AIPrioritizedIcon />
                  <div className={styles.aiPrioritizedText}>
                    {t('page.tasks.aiPrioritized')}
                  </div>
                </div>
              </div>
              <ul className={styles.tasks}>
                {prioritizedDelegatedTasks.map((task) => (
                  <li key={task.id}>
                    <Task task={task} isOpacity={task.priority === 1} backTo={location} />
                  </li>
                ))}
              </ul>
            </>
          )}

          {!isUnprioritizedDelegatedTasksEmpty && (
            <>
              <div className={styles.blockTitle}>{t('page.tasks.unprioritized')}</div>
              <ul className={styles.tasks}>
                {unprioritizedDelegatedTasks.map((task) => (
                  <li key={task.id}>
                    <Task task={task} backTo={location} />
                  </li>
                ))}
              </ul>
            </>
          )}

          {!isBlockedDelegatedTasksEmpty && (
            <>
              <div className={styles.blockTitle}>{t('page.tasks.blocked')}</div>
              <ul className={styles.tasks}>
                {blockedDelegatedTasks.map((task) => (
                  <li key={task.id}>
                    <Task task={task} backTo={location} />
                  </li>
                ))}
              </ul>
            </>
          )}

          {!isToBeFormulatedDelegatedTasksEmpty && (
            <>
              <div className={styles.blockTitle}>{t('page.tasks.toBeFormulated')}</div>
              <ul className={styles.tasks}>
                {toBeFormulatedDelegatedTasks.map((task) => (
                  <li key={task.id}>
                    <Task task={task} backTo={location} />
                  </li>
                ))}
              </ul>
            </>
          )}
        </>
      )}
    </>

  )
}
export default Delegated;
