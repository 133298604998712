import { BaseIconProps } from '../types';

const Assistants = (props: BaseIconProps) => (
  <svg width="30" height="25" viewBox="0 0 29 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="15" cy="11" r="4" fill="currentColor"/>
    <circle cx="9.5" cy="3.5" r="3.5" fill="currentColor"/>
    <circle cx="20.5" cy="3.5" r="3.5" fill="currentColor"/>
    <path d="M25.4998 5.49998L27.341 6.46513C27.4438 6.51902 27.5241 6.60769 27.5676 6.71532V6.71532C28.4545 8.91106 26.7174 11.2715 24.3573 11.0777L24.0002 11.0484L21.5 11.0484" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
    <path d="M4.49999 5.50006L2.25057 6.39983C2.09291 6.46289 1.97437 6.59681 1.93092 6.76096V6.76096C1.33772 9.00174 3.05344 11.1876 5.371 11.1436L6.00046 11.1317L8.50105 11.1316" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
    <path d="M20.5088 16.0672L23.0055 17.376C23.128 17.4402 23.226 17.543 23.2845 17.6684V17.6684C24.5503 20.3867 22.5657 23.5 19.5671 23.5L18.5005 23.5L13.501 23.4999" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
    <path d="M9.31123 16.0671L6.23075 17.2993C6.05547 17.3694 5.91909 17.512 5.85676 17.6902V17.6902C4.92587 20.3518 6.80033 23.1648 9.61522 23.3304L12.4997 23.5L16.5 23.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
  </svg>
);

export default Assistants;
