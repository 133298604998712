import { useCallback, useMemo } from 'react';
import { QueryKey, useQuery } from '@tanstack/react-query';

import { UserDto } from 'dtos';
import { queryClient } from 'shared/config/reactQuery';
import { useGetContactProvidersQuery } from 'services/contacts';

const LS_KEY: string = 'primaryProvider';
const QUERY_KEY: QueryKey = [LS_KEY];

type Provider = UserDto;
type ProviderId = Provider['id'] | null;

export const useProvidersStore = () => {
  const {
    data,
    isLoading: isListLoading,
    isFetching: isListFetching,
  } = useGetContactProvidersQuery();

  const providersList = useMemo(() => [...(data?.special || []), ...(data?.contacts || [])], [data]);

  const changePrimaryProvider = useCallback((providerId?: ProviderId) => {
    if (!providerId) {
      return;
    }

    localStorage.setItem(LS_KEY, String(providerId));

    const currentProviderId = queryClient.getQueryData(QUERY_KEY);
    if (currentProviderId !== providerId) {
      queryClient.setQueryData<ProviderId>(QUERY_KEY, providerId);
    }
  }, []);

  const findProviderByIdOrGetFirst = useCallback(
    (id: ProviderId = null) => {
      if (providersList == null || providersList.length === 0) {
        return null;
      }
      if (id == null) {
        return providersList[0];
      }
      const candidate = providersList.find((p) => p.id === id);
      return candidate ?? providersList[0];
    },
    [providersList]
  );

  const { data: primaryProviderId, isLoading: isPrimaryProviderLoading } =
    useQuery<ProviderId | null>({
      queryKey: QUERY_KEY,
      queryFn: () => {
        const lsPrimaryProviderId = localStorage.getItem(LS_KEY);
        return (
          findProviderByIdOrGetFirst(
            lsPrimaryProviderId ? JSON.parse(lsPrimaryProviderId) : null
          )?.id ?? null
        );
      },
      onError: () => {
        localStorage.removeItem(LS_KEY)
      },
      staleTime: Infinity,
      retry: 1,
      enabled: !isListLoading && !isListFetching,
    });

  const isLoading = isListLoading || isPrimaryProviderLoading;

  const primaryProvider = useMemo(
    () => (isLoading ? null : findProviderByIdOrGetFirst(primaryProviderId)),
    [findProviderByIdOrGetFirst, primaryProviderId, isLoading]
  );

  return {
    isLoading,
    providers: data,
    primaryProvider,
    changePrimaryProvider,
  };
};
