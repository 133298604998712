import { useOutletContext } from 'react-router-dom';

import type { UserDto } from 'dtos';

interface OutletContext {
  user: UserDto;
}

export const useCurrentUser = (): UserDto => {
  const context = useOutletContext<OutletContext>();
  return context?.user || {};
};
