import cn from 'classnames';
import truncate from 'lodash/truncate';

import { BalanceInfoDto, BalanceResponseDto, UserDto } from 'dtos';

import { Avatar, AvatarSize } from 'shared/ui/Avatar/Avatar';
import { IconName } from 'components/Icon/IconList';
import Icon from 'components/Icon/Icon';
import IconHistory from 'components/icons/History';
import ArrowUpIcon from 'components/icons/ArrowUp';
import ArchiveIcon from 'components/icons/Archive';
import IconAssistance from 'components/icons/Assistants';
import EditIcon from 'components/icons/Edit';

import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { getUserName } from 'utils';
import checkIsWebApp from '../../utils/checkIsWebApp';

type Props = {
  className?: string;
  balance?: Partial<BalanceResponseDto & BalanceInfoDto>;
  pictureSize?: AvatarSize;
  titleClassName?: string;
  isLoading?: boolean;
  onChange?: () => void;
  onDelete?: () => void;
  onArchive?: () => void;
  onHistoryClick?: () => void;
  onAssistantClick?: () => void;
  friend?: UserDto;
  enableSort?: boolean;
  onSort?: () => void;
};

function BalanceCard(props: Props): JSX.Element | null {
  const { t } = useTranslation();

  if (!props.balance) {
    return null;
  }

  const {
    balanceName,
    balancePhotoUrl,
    canEdit = false,
    canDelete = false,
    canArchive = false,
    canAssist = false,
    tgLink,
    tgDeepLink
  } = props.balance;

  const { enableSort } = props;
  const link = checkIsWebApp() ? tgLink : tgDeepLink;

  return (
    <div className={cn(styles.row, props.className)}>
      <Avatar
        username={String(balanceName)}
        profilePictureUrl={balancePhotoUrl}
        size={props.pictureSize || AvatarSize.Small}
      />
      <div className={styles.titleWrap}>
        <a className={cn(styles.title, props.titleClassName)} href={link}>
          {truncate(balanceName, { length: 25 })}
        </a>
        {props.friend && (
          <p className={styles.with}>
            {t('component.balanceSummary.with', {
              name: getUserName(props.friend),
            })}
          </p>
        )}
      </div>

      {canEdit && typeof props.onChange === 'function' && !props.isLoading && (
        <div
          className={cn(styles.iconContainer, styles.changeIconContainer)}
          onClick={props.onChange}
        >
          <EditIcon />
        </div>
      )}

      {canAssist && typeof props.onAssistantClick === 'function' && (
        <div
          className={cn(styles.iconContainer, styles.changeIconContainer)}
          onClick={props.onAssistantClick}
        >
          <IconAssistance className={styles.assistantIcon} />
        </div>
      )}

      {typeof props.onHistoryClick === 'function' && (
        <div
          className={cn(styles.iconContainer, styles.changeIconContainer)}
          onClick={props.onHistoryClick}
        >
          <IconHistory className={styles.historyIcon} />
        </div>
      )}

      {canDelete && !canArchive && typeof props.onDelete === 'function' && !props.isLoading && (
        <div
          className={cn(styles.iconContainer, styles.removeIconContainer)}
          onClick={props.onDelete}
        >
          <Icon name={IconName.Close2} width={20} height={20} viewBox="-5 -5 20 20" />
        </div>
      )}

      {canArchive && typeof props.onArchive === 'function' && !props.isLoading && (
        <div
          onClick={props.onArchive}
          className={cn(styles.iconContainer, styles.archiveIconContainer)}
        >
          <ArchiveIcon className={styles.archiveIcon} />
        </div>
      )}

      {enableSort && typeof props.onSort === 'function' && !props.isLoading && (
        <div
          onClick={props.onSort}
          className={cn(styles.iconContainer, styles.updatePriorityIconContainer)}
        >
          <ArrowUpIcon className={styles.arrowUpIcon} />
        </div>
      )}
    </div>
  );
}

export default BalanceCard;
