import styles from '../../ExchangerWidget.module.scss';

import { useEffect, useRef, useState } from 'react';
import { CurrencyInputProps } from 'react-currency-input-field';

import CurrencyInput from 'components/Input/CurrencyInput/CurrencyInput';
import Icon from 'components/Icon/Icon';
import { IconName } from 'components/Icon/IconList';
import { PaymentFormDtoV5 } from 'dtos';
import { ExchangerWidgetScopeProps } from '../../ExchangerWidget';
import { useExchangerStore, useExchangerStoreActions } from '../../store';
import { isBuyScope } from '../../utils';

import SkeletonWithWrapper from 'shared/ui/SkeletonWithWrapper';

export function ExchangeAmountInput(props: ExchangerWidgetScopeProps): JSX.Element | null {
  const { providerId, userId, scope, crossExchangeRates, customOfferMode, editOfferMode } = props;
  const inputRef = useRef<HTMLInputElement | null>(null);
  const {
    queryKey,
    currentFormData,
    calculateExchangePaymentForm,
    activeOption,
    isInitialization,
  } = useExchangerStore(providerId, userId, undefined, crossExchangeRates, customOfferMode, editOfferMode);
  const { setPaymentForm, setInputTargetSelect } = useExchangerStoreActions({
    queryKey,
    providerId,
    userId,
  });

  const buyScope = isBuyScope(scope);
  const buyOrSellExchangeUnitProp = buyScope ? 'buyExchangeUnitAmount' : 'sellExchangeUnitAmount';
  const outerAmount: number = currentFormData?.[buyOrSellExchangeUnitProp] ?? 0;
  const [amount, setAmount] = useState<string>(String(outerAmount));

  const handleFocusEvent = () => {
    setInputTargetSelect(scope);
  };

  const changeExchangeAmount: CurrencyInputProps['onValueChange'] = (v, _, values) => {
    if (amount === v) {
      return;
    }

    setAmount(String(v ?? 0));
    const nextAmount: PaymentFormDtoV5[typeof buyOrSellExchangeUnitProp] =
      values?.float == null ? 0 : values.float;

    calculateExchangePaymentForm({
      form: { [buyOrSellExchangeUnitProp]: nextAmount },
      target: buyOrSellExchangeUnitProp,
      editOfferMode,
    });
  };

  const clearExchangeAmount = () => {
    const emptyInputs: {buyExchangeUnitAmount?:number, sellExchangeUnitAmount: number} = {
      sellExchangeUnitAmount: 0,
    };

    if (!editOfferMode) {
      emptyInputs.buyExchangeUnitAmount = 0;
    }
    setPaymentForm(emptyInputs);
    calculateExchangePaymentForm({form: emptyInputs});
    inputRef.current?.focus();
  };

  useEffect(() => {
    if (Number(amount) !== outerAmount) {
      setAmount(String(outerAmount));
    }
  }, [amount, outerAmount, currentFormData]);

  useEffect(() => {
    calculateExchangePaymentForm({form: { ...currentFormData }, customOfferMode, editOfferMode});
  }, [crossExchangeRates, editOfferMode]);

  useEffect(() => {
    if (activeOption !== 'Currency') {
      calculateExchangePaymentForm({
        form: { ...currentFormData },
        target: 'buyExchangeUnitAmount',
        customOfferMode,
        editOfferMode
      });
    }
  }, [activeOption]);

  if (isInitialization) {
    return (
      <div className={styles.inputWrap}>
        <SkeletonWithWrapper height={68} style={{ margin: '8px 8px 8px 0' }} />
      </div>
    );
  }

  const readOnly = activeOption !== 'Currency' && !customOfferMode && !editOfferMode;

  return (
    <div className={styles.inputWrap}>
      <CurrencyInput
        ref={inputRef}
        name={buyOrSellExchangeUnitProp}
        value={amount}
        onValueChange={changeExchangeAmount}
        allowNegativeValue={false}
        inputClassName={styles.input}
        autoComplete="off"
        readOnly={readOnly}
        disabled={readOnly}
        onFocus={handleFocusEvent}
      />

      {!readOnly && (
        <div className={styles.clearIconWrap}>
          <Icon
            name={IconName.Close2}
            width={10}
            height={10}
            onClick={clearExchangeAmount}
            className={styles.clearIcon}
          />
        </div>
      )}
    </div>
  );
}
