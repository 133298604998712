import { useMutation, useQueryClient } from '@tanstack/react-query';

import instance from 'api';
import type { FeeRangeServiceSettings } from '../types/feeRate';

import type { FeeRateRangeDto, SetFeeRequest } from 'dtos';

const useAddNewFeeRangeMutation = ({
  queryKey,
  apiEndpoint,
  userId,
  shouldUpdateExchanger,
}: FeeRangeServiceSettings) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (newFeeRange): Promise<number> => {
      const { data } = await instance.post(apiEndpoint, newFeeRange);
      return data;
    },
    onMutate: async (newFeeRange: Required<SetFeeRequest>) => {
      await queryClient.cancelQueries({
        queryKey,
      });

      const previousFeeRates =
        queryClient.getQueryData<FeeRateRangeDto[]>(queryKey);
      return { previousFeeRates };
    },
    onSuccess: async (data, newFeeRange, context) => {
      if (context?.previousFeeRates && data) {
        const rates = context.previousFeeRates;
        const lastFeeRange = rates.indexOf(rates[rates.length - 1]);
        queryClient.setQueryData<FeeRateRangeDto[]>(queryKey, (prev) => {
          if (!prev) return;

          const newFeeRateRange: FeeRateRangeDto = {
            id: data,
            lowerBound: newFeeRange.lowerBound,
            feeRate: rates[lastFeeRange].feeRate,
          };

          return [...rates, newFeeRateRange];
        });

        if (shouldUpdateExchanger) {
          queryClient.refetchQueries(['exchanger', userId]);
        }
      }
    },
    onError: (err, newFeeRange, context) => {
      if (context?.previousFeeRates) {
        queryClient.setQueryData<FeeRateRangeDto[]>(queryKey, [
          ...context.previousFeeRates,
        ]);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey,
      });
    },
  });
};

export { useAddNewFeeRangeMutation };
