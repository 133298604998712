import checkIsWebApp from '../../utils/checkIsWebApp';
import * as Sentry from '@sentry/react';
import { toast } from 'react-hot-toast';
import Button from '../Button/Button';

interface ShareButtonProps {
  url: string;
  text?: string;
  className?: string;
  buttonText: string;
}

function ShareButton({ url, text, className, buttonText }: ShareButtonProps) {
  const isWebApp = checkIsWebApp();
  const canShare = typeof navigator.share === 'function';

  const createUrlWithShareParams = () => {
    const baseUrl = isWebApp ? 'https://t.me/share/url?url=' : 'tg://msg_url?url=';
    return `${baseUrl}${encodeURIComponent(url)}${text ? `&text=${encodeURIComponent(text)}` : ''}`;
  };

  const handleShare = async () => {
    if (canShare) {
      try {
        let preparedText = `${url}${text ? `\n\n${text}`: ''}`;
        await navigator.share({
          text: preparedText
        });
      } catch (error) {
        Sentry.captureException(error);
      }
    } else {
      toast.error('Sharing is not supported in your browser')
    }
  };
  const preparedLink = createUrlWithShareParams();

  return (
    <>
      {!isWebApp && canShare ? (
        <Button className={className} onClick={handleShare}>{buttonText}</Button>
      ) : !isWebApp && !canShare ? (
        <a href={preparedLink}>
          <Button className={className}>{buttonText}</Button>
        </a>
      ) : isWebApp ? (
        <a href={preparedLink}>
          <Button className={className}>{buttonText}</Button>
        </a>
      ) : null
      }
    </>
  );
}

export default ShareButton;
