import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../fetchBaseQueryWithAuth';
import { UserSubscriptionDto } from 'dtos';

export const notificationsApi = createApi({
  reducerPath: 'notificationsApi',
  baseQuery: baseQueryWithReauth(true),
  tagTypes: ['Notification'],
  endpoints: (builder) => ({
    getUserSubscriptions: builder.query<UserSubscriptionDto[], void>({
      query: () => ({
        url: '/notification/subscription',
      }),
      providesTags: (result) =>
        result ? result.map(({ userId, notificationEvent }) => ({ type: 'Notification', id: userId + notificationEvent })) : ['Notification'],
    }),

    getUserMuteMode: builder.query<boolean, void>({
      query: () => ({
        url: '/notification/subscription/mute-mode',
      }),
      providesTags: (result) => ['Notification'],
    }),

    changeUserSubscription: builder.mutation<void, UserSubscriptionDto>({
      query: (body) => ({
        url: '/notification/subscription',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Notification' }],
    }),

    changeAllUserSubscriptions: builder.mutation<void, UserSubscriptionDto[]>({
      query: (body) => ({
        url: '/notification/subscription/all',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Notification' }],
    }),

    changeUserMuteModeSubscriptions: builder.mutation<void, boolean>({
      query: (param) => ({
        url: '/notification/subscription/mute-mode',
        method: 'POST',
        params: { muted: param }
      }),
      invalidatesTags: [{ type: 'Notification' }],
    }),
  }),
});

export const {
  useGetUserSubscriptionsQuery,
  useGetUserMuteModeQuery,
  useChangeUserSubscriptionMutation,
  useChangeAllUserSubscriptionsMutation,
  useChangeUserMuteModeSubscriptionsMutation
} = notificationsApi;
