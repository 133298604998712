import { useEffect, KeyboardEvent, useCallback } from 'react';

export const useKeyDown = (key: string, callback: () => void) => {
  const onKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key === key) {
      event.preventDefault();
      callback();
    }
  }, [key, callback]);

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown as any);
    return () => {
      document.removeEventListener('keydown', onKeyDown as any);
    };
  }, [onKeyDown]);
};
