import React from 'react';
import { useTranslation } from 'react-i18next';

import Invitations from 'modules/Invitations/Invitations';

import { Layout } from 'components/Layout/Layout';

function InvitationsPage(): JSX.Element | null {
  const { t } = useTranslation();

  return (
    <Layout>
      <Layout.TopBar>
        <Layout.Back />

        <Layout.Title>{t('page.invitations.title')}</Layout.Title>
      </Layout.TopBar>

      <Layout.Content unsetBottomPadding>
        <Invitations />
      </Layout.Content>
    </Layout>
  );
}

export default InvitationsPage;
