import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import ProviderExchangeRates, {
  ProviderRatesProps,
} from 'components/ProviderExchangeRates';
import ExchangeRatesPageLayout, { styles } from '../layout';

import ExchangeRatesFilter from 'widgets/ExchangeRatesFilter';

import { useGetExchangeRatesUploaded } from 'entities1/exchange-rate/model/services/useGetExchangeRatesUploaded';
import { useExchangeRateUploadedUpdate } from 'entities1/exchange-rate/model/services/useExchangeRateUploadedUpdate';

import { useCurrentUser } from 'shared/hooks/useCurrentUser';

import type { ExchangeRateDto } from 'dtos';

import { debounce } from 'utils';

export default function ExchangeRatesMyPage(): JSX.Element {
  const { t } = useTranslation();
  const user = useCurrentUser();

  const {
    data: myExchangeRates,
    isLoading,
    fetchNextPage,
  } = useGetExchangeRatesUploaded();

  const { mutate: updateOwnRate } = useExchangeRateUploadedUpdate();

  const debouncedGetUploadedExchangeRates = useCallback(
    debounce(() => {
      return fetchNextPage && fetchNextPage({ pageParam: 1 });
    }),
    [fetchNextPage]
  );

  const rates = useMemo(() => {
    const pages = myExchangeRates?.pages || [];
    return pages.map((p) => p.content).flat();
  }, [myExchangeRates]);

  const toggle: ProviderRatesProps['toggle'] = (r) => {
    const rate = r as ExchangeRateDto;
    if (!rate.id) return;
    updateOwnRate({
      id: rate.id,
      enable: !rate.isActive,
    });
  };

  return (
    <ExchangeRatesPageLayout
      actions={
        <ExchangeRatesFilter
          queryKey={['own-exchange-rates']}
          onGetExchangeRates={debouncedGetUploadedExchangeRates}
        />
      }
    >
      {isLoading || !user ? (
        <p className={styles.empty}>{t('page.exchangeRates.loading')}</p>
      ) : (
        <ProviderExchangeRates
          id="me"
          provider={user}
          rates={rates}
          toggle={toggle}
          fetchNextPage={fetchNextPage}
        />
      )}
    </ExchangeRatesPageLayout>
  );
}
