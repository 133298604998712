import styles from './index.module.scss';
import { Layout } from 'components/Layout/Layout';
import { useParams } from 'react-router-dom';
import { useGetTaskByIdQuery } from 'services/tasks';
import SkeletonWithWrapper from 'shared/ui/SkeletonWithWrapper';
import { Cell, Row, Table } from 'shared/ui';
import React, { useEffect } from 'react';
import { getFormattedDate } from 'utils';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';

const TaskHistoryPage = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const taskId = Number(id);
  const { data: task, isLoading, refetch } = useGetTaskByIdQuery(taskId);

  useEffect(() => {
    refetch();
  }, []);

  if (isLoading) {
    return (
      <Layout.Content>
        <Layout.TopBar className={styles.header}>
          <Layout.Back />
          <Layout.Title>
            {t('page.taskHistory.title')}
          </Layout.Title>
        </Layout.TopBar>
        <SkeletonWithWrapper count={3} height={110} />
      </Layout.Content>
    );
  }

  if (!task) {
    return <></>
  }

  return (
    <Layout>
      <Layout.TopBar className={styles.header}>
        <Layout.Back />
        <Layout.Title>
          {t('page.taskHistory.title')}
        </Layout.Title>
      </Layout.TopBar>
      <Layout.Content>
        <div className={styles.content}>
          {!isEmpty(task.history) ? (
            <Table className={styles.table}>
              {task.history?.map((historyData) => (
                <React.Fragment
                  key={`${historyData.createdAt}-${historyData.event}`}
                >
                  <Row>
                    <Cell>
                      {getFormattedDate(historyData.createdAt, '.')}
                      &nbsp;
                      {new Date(historyData.createdAt).toLocaleTimeString('ru-RU')}
                    </Cell>
                  </Row>

                  <Row>
                    <Cell>{historyData.description}</Cell>
                  </Row>
                </React.Fragment>
              ))}
            </Table>
          ) : (
            <div className={styles.emptyPlaceholder}>{t('page.taskHistory.empty')}</div>
          )}
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default TaskHistoryPage;
