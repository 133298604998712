import { useCallback } from 'react';
import { QueryKey, useQuery } from '@tanstack/react-query';

import { PaymentStatus } from 'dtos';

import { queryClient } from 'shared/config/reactQuery';

const QUERY_KEY: QueryKey = ['payments', 'statuses'];
export const STORAGE_KEY: string = 'statuses';

const defaultStatuses = [PaymentStatus.Initialized, PaymentStatus.InProgress];

export const usePersistedPaymentsByStatus = () => {
  const { data } = useQuery({
    queryKey: QUERY_KEY,
    queryFn: () => {
      const stringifyStatuses = localStorage.getItem(STORAGE_KEY);
      if (stringifyStatuses) {
        return JSON.parse(stringifyStatuses);
      } else {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(defaultStatuses));
        return defaultStatuses;
      }
    },
  });

  const selectedStatuses = data ?? [];

  const persistSelectedStatuses = useCallback(
    (statuses: PaymentStatus[]): void => {
      queryClient.removeQueries({ queryKey: ['payments'], exact: true });
      localStorage.setItem(STORAGE_KEY, JSON.stringify(statuses));
      queryClient.setQueryData(QUERY_KEY, () => statuses);
    },
    []
  );

  const removeSelectedStatus = useCallback(
    (status: PaymentStatus) => {
      const statuses = queryClient.getQueryData<PaymentStatus[]>(QUERY_KEY);
      if (statuses) {
        const updatedStatuses = statuses.filter((s) => s !== status);
        persistSelectedStatuses(updatedStatuses);
      }
    },
    [persistSelectedStatuses]
  );

  const clearSelectedStatuses = useCallback(() => {
    localStorage.removeItem(STORAGE_KEY);
    persistSelectedStatuses([]);
  }, [persistSelectedStatuses]);

  return {
    selectedStatuses,
    persistSelectedStatuses,
    removeSelectedStatus,
    clearSelectedStatuses,
  };
};
