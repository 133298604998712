import styles from './Form.module.scss';

import React, { ChangeEvent, SetStateAction, useState } from 'react';
import cn from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
// import 'react-international-phone/style.css';
import parsePhoneNumber from 'libphonenumber-js';
import { defaultCountries, usePhoneInput } from 'react-international-phone';

import { isResponseWithErrorMessage } from 'utils';
import { CallMeBackData } from '../../index';
import { useCallMeBackMutation } from 'services/callback';
import Checkbox from 'components/Checkbox/Checkbox';
import CloseIcon from 'components/icons/Close';
import InputWithFloatingPlaceholder from 'components/InputWithFloatingPlaceholder';

export interface FormProps {
  data: CallMeBackData;
  onClose: () => void;
  onSetData: (data: CallMeBackData) => void;
}

export type FormStateProps = Pick<CallMeBackData, 'name' | 'phone' | 'policyPrivacy'>;

export interface FormState extends Required<FormStateProps> {}

const INITIAL_VALUE: string = '';

function Form({ data, onClose, onSetData }: FormProps): JSX.Element | null {
  const { t } = useTranslation();

  const [form, setForm] = useState<FormState>({
    name: data.name || INITIAL_VALUE,
    phone: data.phone || INITIAL_VALUE,
    policyPrivacy: data.policyPrivacy || true,
  });

  const [error, setError] = useState<string | null>(null);

  const changeName = (e: ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault();
    setForm((prev) => ({ ...prev, name: e.target.value }));
  };

  const changePhone = (phone: string): void => {
    setForm((prev) => ({ ...prev, phone: phone }));
  };

  const changePolicyPrivacy = (event: SetStateAction<boolean>) => {
    setForm((prev) => ({ ...prev, policyPrivacy: event as boolean }));
  };

  const [callMeBack] = useCallMeBackMutation();

  const phoneNumber = parsePhoneNumber(form.phone);
  const isValidPhoneNumber = phoneNumber?.isValid();
  const canSendRequest = !!form.name.length && isValidPhoneNumber;
  const sendingRequest: boolean = Boolean(data?.isSending);

  const sendCallback = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();

    if (!canSendRequest || !data || !data.exchangerFormData) return;

    try {
      onSetData({ ...data, isSending: true });
      const { name, phone } = form;
      await callMeBack({
        providerId: data.providerId,
        name: name,
        phone: phone,
        language: data.language,
        utm: data.utm,
        exchangerFormData: data.exchangerFormData,
      }).unwrap();
      onSetData({ ...data, isSending: false, sent: true, ...form });
    } catch (e) {
      onSetData({ ...data, isSending: false });
      if (e instanceof Error) {
        const errorMessage = isResponseWithErrorMessage(e) ? e.response.data.message : e.message;
        setError(errorMessage);
      } else {
        // @ts-ignore
        const errorMessage = e?.data?.message ? e.data.message : t('error.unknown');
        setError(errorMessage);
      }
    }
  };

  const { inputValue, handlePhoneValueChange, inputRef } = usePhoneInput({
    value: form.phone,
    onChange: (data) => {
      changePhone(data.phone);
    },
    countries: defaultCountries,
    disableDialCodePrefill: true,
  });

  const onPhoneInputFocus = () => {
    if (form.phone === undefined || form.phone === '') {
      changePhone('+');
    }
  };
  const onPhoneInputBlur = () => {
    if (form.phone === '+') {
      changePhone('');
    }
  };

  const calmState: string = t('modal.callMeBack.button');
  const loadingState: string = t('modal.callMeBack.loadingButton');
  const submitDisabled =
    isEmpty(form.name) || isEmpty(form.phone) || !form.policyPrivacy || !isValidPhoneNumber;

  return (
    <form onSubmit={sendCallback} className={styles.form}>
      <div className={styles.values}>
        <InputWithFloatingPlaceholder
          value={form.name}
          onChange={changeName}
          placeholder={t('modal.callMeBack.namePlaceholder')}
        />

        <InputWithFloatingPlaceholder
          value={inputValue}
          onChange={handlePhoneValueChange}
          onFocus={onPhoneInputFocus}
          onBlur={onPhoneInputBlur}
          ref={inputRef}
          placeholder={t('modal.callMeBack.phonePlaceholder')}
        />

        {/* <Checkbox
          checked={form.policyPrivacy}
          onChangeValue={changePolicyPrivacy}
          labelClassName={styles.checkbox}
        >
          <span className={styles.checkboxContent}>
            <Trans
              i18nKey='modal.callMeBack.policyPrivacy'
              components={{ a: <a href={''} target='_blank' rel='noopener noreferrer' /> }}
            />
          </span>
        </Checkbox> */}
      </div>

      {!!error && <p className={styles.error}>{error}</p>}

      <div className={styles.buttons}>
        <button
          type="submit"
          disabled={submitDisabled || sendingRequest}
          onClick={sendCallback}
          className={cn(styles.button, styles.submit)}
        >
          {!sendingRequest ? calmState : loadingState}
        </button>

        <button
          type="button"
          disabled={sendingRequest}
          onClick={onClose}
          className={cn(styles.button, styles.close)}
        >
          <CloseIcon className={styles.closeIcon} />
        </button>
      </div>
    </form>
  );
}

export default Form;
