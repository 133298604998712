import styles from './ObligationTransferModal.module.scss';

import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from 'shared/redux-store';
import { hide, ModalTypes } from 'slices/modal';
import isEmpty from 'lodash/isEmpty';
import Modal from 'components/Modal/Modal';

import { BalanceResponseDto, UserDto } from 'dtos';
import Form from './components/Form';
import Success from './components/Success';
import Input from 'components/Input/Input';
import { debounce } from 'utils';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { getIndexOfLastListElement } from 'shared/lib/arrays';
import { useInView } from 'react-intersection-observer';
import { useGetInfiniteBalancesForObligationTransfer } from './api/useLazyGetBalancesForObligationTransferQuery';
import { InfiniteList } from 'components/InfiniteList';
import SkeletonWithWrapper from 'shared/ui/SkeletonWithWrapper';
import { getOtherBalanceOwner } from 'components/BalancesSummary';
import { UserWithBalanceInfo } from 'components/UserWithBalanceInfo';

export interface ObligationTransferData {
  value: string;
  code: string;
  comment?: string;
  isSending?: boolean;
  sent?: boolean;
  fromBalance?: BalanceResponseDto;
  fromContact: UserDto;
  toBalance?: BalanceResponseDto;
  toContact?: UserDto;
}

function ObligationTransferModal(): JSX.Element | null {
  const dispatch = useDispatch();
  const { ref, inView } = useInView();
  const { t } = useTranslation();
  const { visible, data: modalData } = useSelector(
    (state: RootState) => state.modal[ModalTypes.ObligationTransfer]
  );
  const { id: currentUserId } = useCurrentUser();
  const [obligationTransferData, setObligationTransferDataData] = useState({
    ...modalData,
  } as ObligationTransferData);
  const [searchString, setSearchString] = useState<string>('');

  useEffect(() => {
    setObligationTransferDataData(modalData);
  }, [modalData]);

  const { data, fetchNextPage, isLoading, isFetchingNextPage } =
    useGetInfiniteBalancesForObligationTransfer(
      modalData.fromBalance?.id,
      searchString,
      !isEmpty(modalData)
    );

  const balances = visible ? data?.pages.map((p) => p.content).flat() || [] : [];

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage]);

  const closeModal = (): void => {
    setSearchString('');
    setObligationTransferDataData({} as ObligationTransferData);
    dispatch(hide({ id: ModalTypes.ObligationTransfer }));
  };

  const onBalanceClick = (evt: React.MouseEvent<HTMLElement>): void => {
    evt.preventDefault();
    const toBalanceId = Number(evt.currentTarget.dataset.toBalanceId);
    const toContactId = Number(evt.currentTarget.dataset.contactId);
    const toBalance = balances.find((balance) => balance.id === toBalanceId);
    const toContact = toBalance?.participants.find(
      (participant) => participant.user.id === toContactId
    )?.user;
    setObligationTransferDataData({
      ...obligationTransferData,
      toBalance: toBalance,
      toContact: toContact,
    } as ObligationTransferData);
  };

  const setData = (data: ObligationTransferData) => {
    setObligationTransferDataData(data);
  };

  const debounceSetSearchString = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => setSearchString(event.target.value), 500),
    [setSearchString]
  );

  if (!visible || isEmpty(modalData)) {
    return null;
  }

  const indexOfLastListElement = getIndexOfLastListElement(balances);

  return (
    <Modal visible={visible} onClose={closeModal} className={styles.modal}>
      {obligationTransferData.toBalance !== undefined ? (
        !obligationTransferData.sent ? (
          <>
            <UserWithBalanceInfo
              user={obligationTransferData.toContact}
              balance={obligationTransferData.toBalance}
            />

            <Form data={obligationTransferData} onSetData={setData} onClose={closeModal} />
          </>
        ) : (
          <Success data={obligationTransferData} onSuccess={closeModal} />
        )
      ) : (
        <>
          <div>{t('modal.obligationTransfer.selectRecipient')}</div>
          <Input onChange={debounceSetSearchString} autoFocus />
          <InfiniteList
            limit={20}
            loading={isLoading}
            loadingNextPage={isFetchingNextPage}
            listClassName={styles.balances}
            loaderItem={<SkeletonWithWrapper height={64} />}
            emptyListPlaceholder={t('modal.obligationTransfer.empty')}
          >
            {balances?.map((balance, index) => {
              const { id, participants } = balance;
              const otherOwner = getOtherBalanceOwner(participants, currentUserId);

              if (!otherOwner) {
                return (
                  <li
                    key={id}
                    ref={indexOfLastListElement === index ? ref : undefined}
                  >
                    <div className={styles.error}>
                      {t('modal.obligationTransfer.error', {
                        balanceName: balance.balanceName,
                        id: balance.id,
                      })}
                    </div>
                  </li>
                );
              }

              return (
                <li
                  key={id}
                  ref={indexOfLastListElement === index ? ref : undefined}
                  onClick={onBalanceClick}
                  data-to-balance-id={id}
                  data-contact-id={otherOwner.id}
                >
                  <UserWithBalanceInfo user={otherOwner} balance={balance} />
                </li>
              );
            })}
          </InfiniteList>
        </>
      )}
    </Modal>
  );
}

export default ObligationTransferModal;
