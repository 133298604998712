import { useState } from 'react';

import InstructionScreen from './components/InstructionScreen';
import AddToHomeScreen from './components/AddToHomeScreen/AddToHomeScreen';

export const Installation = () => {
  const [lastStep, setLastStep] = useState(false);
  if (lastStep) {
    return <AddToHomeScreen back={() => setLastStep(false)}/>;
  }
  return <InstructionScreen next={() => setLastStep(true)} />;
};
