import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import cn from 'classnames';

import { useGetTgSessionQuery } from 'services/auth';
import Button, { Variant } from 'components/Button/Button';

import styles from './styles.module.scss';


const ONE_MINUTE = 60 * 1000;

function TelegramBotAuth(props: { keyId: string, className?: string, onSuccess?: () => void, text?: string }) {
  const { keyId, onSuccess } = props;
  const { t } = useTranslation();
  const [stop, setStop] = useState<boolean>(true);

  const TG_DEEPLINK = `tg://resolve?domain=${process.env.REACT_APP_TELEGRAM_BOT_NAME}&start=auth_DELIM_${keyId}`;

  const data = useGetTgSessionQuery(keyId, {
    skip: stop,
    pollingInterval: 500,
  });

  useEffect(() => {
    if (data?.data === true && typeof onSuccess === 'function' && !stop) {
      onSuccess();
      toast.dismiss();
      setStop(true);
    }
  }, [data, onSuccess, stop]);

  const onTelegramSessionAuth = useCallback(async (): Promise<void> => {
    setStop(false);
    toast.dismiss();
    toast.loading(t('status.authorizing'));

    // Перестаём ходить в бэк и убираем всплывашку
    setTimeout(() => {
      setStop(true);
      toast.dismiss();
    }, ONE_MINUTE);

  }, [keyId]);

  return (
    <a
      href={TG_DEEPLINK}
      onClick={onTelegramSessionAuth}
      className={cn(props.className, styles.link)}
    >
      <Button className={styles.button} variant={Variant.Telegram}>
        {props.text || t('button.loginViaBot')}
      </Button>
    </a>
  );
}

export default React.memo(TelegramBotAuth);
