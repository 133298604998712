import styles from '../ExchangeRates.module.scss';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import User from 'components/User/User';

import { AvatarSize } from 'shared/ui/Avatar/Avatar';
import { useGetContactProvidersQuery } from 'services/contacts';

function Providers(): JSX.Element {
  const { t } = useTranslation();

  const {data: providers, isLoading} = useGetContactProvidersQuery();

  if (isLoading) {
    return <p className={styles.empty}>{t('page.exchangeRates.loading')}</p>;
  }

  if (providers?.contacts?.length === 0) {
    return <p className={styles.empty}>{t('page.exchangeRates.empty')}</p>;
  }

  return (
    <ul className={styles.providers}>
      {providers?.contacts?.map((p) => (
        <li key={p.id}>
          <Link to={`/profile/exchange-rates/${p.id}`}>
            <User user={p} size={AvatarSize.Payment} filled />
          </Link>
        </li>
      ))}
    </ul>
  );
}

export default Providers;
