import styles from './Content.module.scss';

import React, { PropsWithChildren } from 'react';
import cn from 'classnames';
import UpdateModal from "widgets/UpdateModal";

export interface ContentProps extends PropsWithChildren {
  unsetBottomPadding?: boolean;
  unsetBottomMargin?: boolean;
  unsetTopPadding?: boolean;
  unsetLeftRightPadding?: boolean;
  className?: string;
}

function Content({
  children,
  unsetBottomPadding,
  unsetBottomMargin,
  unsetTopPadding,
  unsetLeftRightPadding,
  className,
}: ContentProps): JSX.Element | null {
  if (!children) return null;

  return (
    <main
      className={cn(
        styles.content,
        unsetBottomPadding && styles.unsetBP,
        unsetBottomMargin && styles.unsetBM,
        unsetTopPadding && styles.unsetTP,
        unsetLeftRightPadding && styles.unsetLRP,
        className
      )}
    >
      {children}
      <UpdateModal />
    </main>
  );
}

export default Content;
