import { Fragment, useMemo, useState } from 'react';
import { Tab } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { Layout } from 'components/Layout/Layout';
import cn from 'classnames';
import Button, { Variant } from 'components/Button/Button';
import AIPrioritizedIcon from 'components/icons/AIPrioritized';
import { TaskStatus } from 'dtos';
import Task from 'components/Task';
import TaskFormulatingModal from 'widgets/TaskFormulatingModal';
import { ModalTypes, open } from 'slices/modal';
import SkeletonWithWrapper from 'shared/ui/SkeletonWithWrapper';
import { useGetTaskListQuery } from 'services/tasks';
import styles from './styles.module.scss';
import checkIfTaskDelegatedByUser from 'utils/checkIfTaskDelegatedByUser';
import Delegated from './Delegated';
import { useLocation } from 'react-router-dom';
import { TASK_MAIN } from '../paths';

const statuses = [
  TaskStatus.ReadyForWork,
  TaskStatus.ToBeFormulated,
  TaskStatus.Postponed,
  TaskStatus.InProgress,
  TaskStatus.Done,
];

const TasksPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const pathname = useLocation().pathname;
  const { data, isLoading } = useGetTaskListQuery({ statuses, page: 1, limit: 1000 });
  const tasks = useMemo(() => {
    return data?.content || [];
  }, [data?.content]);

  const usersTasks = tasks.filter(
    (task) => !checkIfTaskDelegatedByUser(task)
  )

  const delegatedTasks = tasks.filter(
    (task) => checkIfTaskDelegatedByUser(task)
  )

  const prioritizedTasks = usersTasks.filter(
    (task) =>
      (task.status === TaskStatus.ReadyForWork && task.isPrioritized) ||
      task.status === TaskStatus.InProgress
  );
  const unprioritizedTasks = usersTasks.filter(
    (task) => task.status === TaskStatus.ReadyForWork && !task.isPrioritized
  );

  const ownCompleted = usersTasks.filter((task) => task.status === TaskStatus.Done)
  const delegatedCompleted = delegatedTasks.filter((task) => task.status === TaskStatus.Done)

  const tabList = [
    {
      id: 0,
      title: t('page.tasks.tabNextTasks'),
    },
    {
      id: 1,
      title: t('page.tasks.tabDelegatedTasks'),
    },
    {
      id: 2,
      title: t('page.tasks.tabCompleted'),
    },
  ];
  const [activeTab, setActiveTab] = useState<number>(0);

  const openTaskFormulationModal = () => {
    dispatch(
      open({
        id: ModalTypes.TaskFormulating,
        data: {},
      })
    );
  };

  const isPrioritizedTasksEmpty = isEmpty(prioritizedTasks);
  const isUnprioritizedTasksEmpty = isEmpty(unprioritizedTasks);
  const isUsersCompletedTasksEmpty = isEmpty(ownCompleted);
  const isDelegatedCompletedTasksEmpty = isEmpty(delegatedCompleted);

  if (isLoading) {
    return (
      <Layout>
        <Layout.TopBar unsetBottomPadding>
          <Layout.BackNew to={TASK_MAIN} />
        </Layout.TopBar>
        <Layout.Content>
          <SkeletonWithWrapper count={5} height={70} />
        </Layout.Content>
      </Layout>
    );
  }

  return (
    <Layout>
      <Layout.TopBar unsetBottomPadding>
        <Layout.BackNew to={TASK_MAIN} />
      </Layout.TopBar>
      <Layout.Content>
        <Tab.Group selectedIndex={activeTab} onChange={setActiveTab}>
          <Tab.List className={styles.navTabsList}>
            {tabList.map(({ id, title }) => (
              <Tab key={id} as={Fragment}>
                {({ selected }) => (
                  <button className={cn(styles.navTabsListItem, { [styles.active]: selected })}>
                    {title}
                  </button>
                )}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel className={styles.navTabsPanel}>
              {isPrioritizedTasksEmpty && isUnprioritizedTasksEmpty ? (
                <div className={styles.placeholder}>{t('page.tasks.emptyNextTasks')}</div>
              ) : (
                <>
                  {!isPrioritizedTasksEmpty && (
                    <>
                      <div className={styles.blockTitle}>
                        <div className={styles.aiPrioritized}>
                          <AIPrioritizedIcon />
                          <div className={styles.aiPrioritizedText}>
                            {t('page.tasks.aiPrioritized')}
                          </div>
                        </div>
                        <Button
                          variant={Variant.Minimal}
                          className={styles.button}
                          onClick={openTaskFormulationModal}
                        >
                          {t('page.tasks.addTaskButton')}
                        </Button>
                      </div>
                      <ul className={styles.tasks}>
                        {prioritizedTasks.map((task) => (
                          <li key={task.id}>
                            <Task task={task} isOpacity={task.priority === 1} backTo={pathname} />
                          </li>
                        ))}
                      </ul>
                    </>
                  )}

                  {!isUnprioritizedTasksEmpty && (
                    <>
                      <div className={styles.blockTitle}>{t('page.tasks.unprioritized')}</div>
                      <ul className={styles.tasks}>
                        {unprioritizedTasks.map((task) => (
                          <li key={task.id}>
                            <Task task={task} backTo={pathname} />
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </>
              )}
            </Tab.Panel>

            <Tab.Panel className={styles.navTabsPanel}>
              <Delegated delegatedTasks={delegatedTasks} />
            </Tab.Panel>

            <Tab.Panel className={styles.navTabsPanel}>
              {isUsersCompletedTasksEmpty ? (
                <div className={styles.placeholder}>{t('page.tasks.emptyCompletedTasks')}</div>
              ) : (
                <>
                  <ul className={cn(styles.tasks, styles.topPadding)}>
                    {ownCompleted.map((task) => (
                      <li key={task.id}>
                        <Task task={task} isOpacity={true} isCompleted={true} backTo={pathname} />
                      </li>
                    ))}
                  </ul>
                  {!isDelegatedCompletedTasksEmpty && (
                    <>
                      <div className={styles.blockTitle}>{t('page.task.list.delegated')}</div>
                      <ul className={styles.tasks}>
                        {delegatedCompleted.map((task) => (
                          <li key={task.id}>
                            <Task task={task} isOpacity={true} isCompleted={true} backTo={pathname} />
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </>
                )}
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
        <TaskFormulatingModal />
      </Layout.Content>
    </Layout>
  );
};

export default TasksPage;
