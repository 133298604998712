import styles from './BalancesHistory.module.scss';

import React, { useEffect, useMemo } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useInView } from 'react-intersection-observer';

import TransactionInfoModal from 'components/TransactionInfoModal';
import { InfiniteList } from 'components/InfiniteList';
import BalanceTransaction, { BalanceTransactionProps } from './BalanceTransaction';

import BalancesService from 'api/balances';
import type { FriendsParams } from 'api/balances/types';

import type { UserDto } from 'dtos';
import { useTransactionInfoModal } from 'entities1/transactionInfoModal/services/useTransactionInfoModal';
import { getIndexOfLastListElement } from 'shared/lib/arrays';
import { groupListByCreatedDate } from 'utils';
import {useTranslation} from "react-i18next";

const INITIAL_PAGE: FriendsParams['page'] = 1;
const INITIAL_LIMIT: FriendsParams['limit'] = 20;

export interface BalancesHistoryProps {
  friendId?: UserDto['id'];
}

function BalancesHistory({
  friendId,
}: BalancesHistoryProps): JSX.Element | null {
  const { ref, inView } = useInView();
  const { openModal } = useTransactionInfoModal();
  const { t } = useTranslation();

  const openTransactionInfoModal =
    (b: BalanceTransactionProps) =>
    (e: React.MouseEvent<HTMLLIElement>) => {
      e.preventDefault();
      openModal(b);
    };

  const { data, isLoading, fetchNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: ['balances', friendId, 'history'],
      queryFn: async ({ pageParam = INITIAL_PAGE, signal }) => {
        const res = await BalancesService.getBalancesTransactionsByContactId({
          contactId: friendId,
          page: pageParam,
          limit: INITIAL_LIMIT,
          signal,
        });
        return { pageParam, ...res };
      },
      getNextPageParam: (lastPage) => {
        return lastPage.last
          ? undefined
          : (lastPage.pageParam || INITIAL_PAGE) + 1;
      },
      enabled: Boolean(friendId),
      staleTime: 1000,
      keepPreviousData: true,
    });

  const groupedHistoryByCreatedDate = useMemo(() => {
    const contents = data?.pages.map((p) => p.content) || [];

    const history = contents.flat().map(c => ({
      ...c,
      createdAt: c.transaction.createdAt,
    }));
    return groupListByCreatedDate(history);
  }, [data?.pages]);

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage]);

  const indexOfLastElement = getIndexOfLastListElement(
    groupedHistoryByCreatedDate
  );

  return (
    <>
      <InfiniteList
        limit={INITIAL_LIMIT}
        loading={isLoading}
        loadingNextPage={isFetchingNextPage}
        listClassName={styles.history}
        emptyListPlaceholder={t('page.contactProfile.balancesHistory.emptyListPlaceholder')}
      >
        {groupedHistoryByCreatedDate.map((b, idx) => {
          if (typeof b === 'string') {
            return <h3 key={b}>{b}</h3>;
          }

          const transactionProps: BalanceTransactionProps = {
            balanceName: b.balance.balanceName,
            balancePhotoUrl: b.balance.balancePhotoUrl,
            bucketName: b.bucket.shortname,
            transactionComment: b.transaction.comment,
            transactionValue: b.transaction.value,
            transactionCreatedAt: b.transaction.createdAt,
            authorName: b.transaction.authorUser.customName ?? b.transaction.authorUser.username,
            authorId: b.transaction.authorUser.id,
            id: b.transaction.id,
            type: b.transaction.type,
            canBeCanceled: b.transaction.canBeCancelled,
            contactId: friendId,
            balanceId: b.balance.id
          };

          return (
            <li
              key={b.transaction.id}
              ref={indexOfLastElement === idx ? ref : undefined}
              onClick={openTransactionInfoModal(transactionProps)}
            >
              <BalanceTransaction {...transactionProps} />
            </li>
          );
        })}
      </InfiniteList>

      <TransactionInfoModal />
    </>
  );
}

export default BalancesHistory;
