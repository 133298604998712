import React from 'react';
import cn from 'classnames';

import styles from './Spinner.module.scss';

type Props = {
  size?: number;
  className?: string,
};

const Spinner = (props: Props) => {
  return <div className={cn(props.className, styles.spinner)} style={{height: props.size, width: props.size}}/>;
};

export default Spinner;
