import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import Button, { Variant } from 'components/Button/Button';
import { ModalTypes, open } from 'slices/modal';
import GeneratePaymentInvitationModal from 'widgets/GeneratePaymentInvitationModal';

import styles from './GeneratePaymentInvitation.module.scss';

export const GeneratePaymentInvitation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const openModal = useCallback(
    (evt: React.MouseEvent<HTMLButtonElement>): void => {
      evt.preventDefault();
      dispatch(
        open({
          id: ModalTypes.ChoosePaymentFriends,
          data: {},
        })
      );
    },
    [dispatch]
  );
  return (
    <>
      <Button variant={Variant.Contained} className={cn(styles.button)} onClick={openModal}>
        {t('modal.createPaymentInvitation.createInvite')}
      </Button>
      <GeneratePaymentInvitationModal />
    </>
  );
};
