import { Link, useParams } from 'react-router-dom';

import { Layout } from 'components/Layout/Layout';
import { ExchangerWidget } from 'widgets/ExchangerWidget';
import Logo from 'components/Logo';
import ButtonAuthorization from 'components/ButtonAuthorization';
import { ExchangerScope } from 'widgets/ExchangerWidget/types';
import { ExchangeTypeAndMethodSelects } from 'widgets/ExchangerWidget/components';
import PublicSelect from 'components/PublicSelect';

import { HOME } from 'pages/paths';

import styles from './styles.module.scss';

const PublicPage = () => {
  const { id } = useParams();

  const providerId: number | null = Number(id) ?? null;

  return (
    <Layout hasMenu={false}>
      <Layout.TopBar className={styles.header}>
        <Link to={HOME}>
          <Logo />
        </Link>
        <ExchangeTypeAndMethodSelects scope={ExchangerScope.Buy} providerId={providerId} isPublic />
      </Layout.TopBar>
      <Layout.Content unsetBottomMargin>
        <ExchangerWidget providerId={providerId} isPublic />
        <ButtonAuthorization providerId={providerId} />
        <PublicSelect providerId={providerId} />
      </Layout.Content>
    </Layout>
  );
};

export default PublicPage;
