import React, { useMemo } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import isUserAuthorizedByPath from 'processes/auth/isUserAuthorizedByPath';

import { useGetMeQuery } from 'services/users';

import { HOME, NOT_FOUND } from './paths';


export default function PrivateRoute() {
  const { isLoading, isError, data: user } = useGetMeQuery();

  const outletContext = useMemo(() => ({ user }), [user]);

  if (isLoading) return <></>;

  if (
    !isLoading &&
    !isError &&
    user &&
    !isUserAuthorizedByPath(user, document.location.pathname)
  ) {
    return <Navigate to={NOT_FOUND} />;
  }

  if (!isLoading && isError) return <Navigate to={HOME} />;

  return <Outlet context={outletContext} />;
}
