import cookiejs from 'js-cookie';
import { AUTH_FIELD, AUTH_REFRESH, USER_ID } from 'utils/checkIsAuth';

import { queryClient } from 'shared/config/reactQuery';

export const retrieveAccessToken = () => cookiejs.get(AUTH_FIELD);

export const retrieveRefreshToken = () => cookiejs.get(AUTH_REFRESH);

export const getUserId = () => cookiejs.get(USER_ID);

export const clearStore = (): void => {
  removeTokens();
  queryClient.clear();
};

export const removeTokens = (): void => {
  cookiejs.remove(AUTH_FIELD);
  cookiejs.remove(AUTH_REFRESH);
  cookiejs.remove(USER_ID);
};

export const setTokens = ({
  jwt,
  refreshToken,
  userId,
}: Partial<{
  jwt: string;
  refreshToken: string;
  userId: number;
}>): void => {
  if (jwt) {
    cookiejs.set(AUTH_FIELD, jwt, { expires: 7 });
  }

  if (refreshToken) {
    cookiejs.set(AUTH_REFRESH, refreshToken, { expires: 30 });
  }

  if (userId) {
    cookiejs.set(USER_ID, String(userId), { expires: 30 });
  }
};
