import React, { useState } from 'react';
import cn from 'classnames';
import QRCode from 'qrcode';
import { useTranslation } from 'react-i18next';

import InvitationService from 'api/invitation';

import { getShareLink } from 'utils';

import Icon from 'components/Icon/Icon';
import { IconName } from 'components/Icon/IconList';
import Input from 'components/Input/Input';
import { InputVariant } from 'components/Input/InputTypes';
import Button, { Variant } from 'components/Button/Button';

import type { IInvitationGenerationFormProps } from './InvitationGenerationFormTypes';

import styles from './InvitationGenerationForm.module.scss';
import ShareButton from '../ShareButton';

const ONE_AND_HALF_SEC: number = 1500;

function InvitationGenerationForm({
    invitationLanguage,
    userRelationType,
    setQRCode,
    setIsShowQRCode,
    reusable,
    refetchInvitationList
}: IInvitationGenerationFormProps) {
    const { t } = useTranslation();
    const [shareLink, setShareLink] = useState<string | null>(null);
    const [invitationCode, setInvitationCode] = useState<string | null>(null);
    const [botLink, setBotLink] = useState<string | null>(null);
    const [deepLink, setDeepLink] = useState<string | null>(null);
    const [linkCopied, setLinkCopied] = useState<boolean>(false);
    const [codeCopied, setCodeCopied] = useState<boolean>(false);

    const generateInviteCode = async () => {
        const { invitationToken, botLink, deepLink } = await InvitationService.generateInvitation({
          language: invitationLanguage.value,
          userRelationType,
          reusable: reusable
        });
        const shareLink = getShareLink(invitationToken);
        setShareLink(shareLink);
        setInvitationCode(invitationToken);
        setBotLink(botLink);
        setDeepLink(deepLink);

        QRCode.toDataURL(
          deepLink,
          {
            width: 245,
            margin: 2,
          },
          (err, url) => {
            if (err) return;
            setQRCode(url);
          }
        );
      refetchInvitationList && refetchInvitationList();
    };

    const copyBotLink = (): void => {
        if (!botLink) return;

        navigator.clipboard.writeText(botLink.trim()).then(() => {
            setLinkCopied(true);
            setTimeout(() => setLinkCopied(false), ONE_AND_HALF_SEC);
        });
    };

    const copyInvitationCode = (): void => {
        if (!invitationCode) return;

        setTimeout(() => {navigator.clipboard.writeText(invitationCode.trim()).then(() => {
            setCodeCopied(true);
            setTimeout(() => setCodeCopied(false), ONE_AND_HALF_SEC);
        })}, 0);
    };

    const buttonText = t('page.invitations.shareButton');
    const shareText = t('page.invitations.shareText');

    return (
      <div className={styles.form}>
        {shareLink && invitationCode && (
          <div className={styles.controls}>
            <Input
              name="shareLink"
              variant={InputVariant.FILLED}
              value={invitationCode || 'XXXXX'}
              onClick={copyInvitationCode}
              contentEditable
              readOnly
              className={styles.input}
              inputClassName={cn(
                styles.inputField,
                {
                  [styles.active]: codeCopied,
                }
              )}
            />

            <Button
              variant={Variant.Outlined}
              onClick={copyBotLink}
              active={linkCopied}
              className={cn(styles.copy)}
            >
              {t(`button.${linkCopied ? 'copied' : 'copy'}`)}
            </Button>
          </div>
        )}

        {botLink &&
          <ShareButton url={botLink} text={shareText} buttonText={buttonText}/>
        }

        <div className={styles.actions}>
          <Button className={styles.button} onClick={generateInviteCode}>
            {t('button.generateInviteLink')}
          </Button>

          {deepLink && (
            <Button
              onClick={() => setIsShowQRCode(true)}
              className={styles.qr}
              variant={Variant.Outlined}
            >
              <Icon name={IconName.QRCode} width={23} height={23} />
            </Button>
          )}
        </div>
      </div>
    )
  }

  export default InvitationGenerationForm;
