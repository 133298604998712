import React from 'react';
import { useGetContactsQuery, INITIAL_LIMIT, INITIAL_PAGE } from 'services/contacts';

export interface ContactsCounter {
  counter: number | null;
}

export function ContactsCounter(): JSX.Element | null {
  const {data, isLoading} = useGetContactsQuery({page: INITIAL_PAGE, limit: INITIAL_LIMIT});

  if (isLoading || !data) {
    return null;
  }

  return <>{data.totalElements}</>;
}
