import styles from './ExchangeRatesRow.module.scss';

import React, { useState } from 'react';

import Icon from 'components/Icon/Icon';
import { IconName } from 'components/Icon/IconList';
import CheckMark from 'components/Filter/CheckMark';

import {
  CurrencyDto,
  ExchangeRateDto,
  ExchangeRateInheritedDto,
  GoodsDto,
} from 'dtos';

import {
  isExchangeRateInheritedDto,
  formatExchangeRate,
  isResponseWithErrorMessage,
} from 'utils';

export interface ExchangeRateCardProps {
  rate: ExchangeRateInheritedDto | ExchangeRateDto;
  onClick: () => void;
}

function ExchangeRatesRow({
  rate,
  onClick,
}: ExchangeRateCardProps): JSX.Element {
  const [disabled, setDisabled] = useState<boolean>(false);

  const toggleInheritance = async (): Promise<void> => {
    try {
      setDisabled(true);
      await onClick();
    } catch (e) {
      if (e instanceof Error) {
        alert(
          isResponseWithErrorMessage(e) ? e.response.data.message : e.message
        );
      }
    } finally {
      setDisabled(false);
    }
  };

  if (isExchangeRateInheritedDto(rate)) {
    const { exchangeRate, buyCurrency, sellCurrency } = rate.exchangeRate;
    return (
      <tr className={styles.row}>
        <ExchangeCurrencyCell {...sellCurrency} />

        <ExchangeCell />

        <ExchangeCurrencyCell {...buyCurrency} />

        <td className={styles.rate}>{formatExchangeRate(exchangeRate)}</td>

        <td className={styles.checkCell}>
          <button
            onClick={toggleInheritance}
            disabled={disabled}
            className={styles.btn}
          >
            <CheckMark selected={rate.isInherited} disabled={disabled} />
          </button>
        </td>
      </tr>
    );
  }

  const { exchangeRate, buyCurrency, isActive, sellCurrency } = rate;
  return (
    <tr className={styles.row}>
      <ExchangeCurrencyCell {...sellCurrency} />

      <ExchangeCell />

      <ExchangeCurrencyCell {...buyCurrency} />

      <td className={styles.rate}>{formatExchangeRate(exchangeRate)}</td>

      <td className={styles.checkCell}>
        <button onClick={onClick} disabled={disabled} className={styles.btn}>
          <CheckMark selected={isActive} disabled={disabled} />
        </button>
      </td>
    </tr>
  );
}

export default React.memo(ExchangeRatesRow);

const ExchangeCell = (): JSX.Element => {
  return (
    <td className={styles.exchange}>
      <Icon name={IconName.PaymentExchange} width={15} height={15} />
    </td>
  );
};

const ExchangeCurrencyCell = (params: Partial<CurrencyDto & GoodsDto>) => {
  const isCashType = 'code' in params;

  return (
    <td>
      <div className={styles.code}>
        {isCashType ? params.code : params.name}
      </div>

      <div className={styles.method}>
        <Icon
          name={isCashType ? IconName.Cash : IconName.Transfer}
          width={isCashType ? 14 : 12}
          height={isCashType ? 9 : 11}
          className={styles.methodIcon}
        />
        {isCashType ? params.paymentMethod?.name : params.city}
      </div>
    </td>
  );
};
