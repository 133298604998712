import React from 'react';
import { RouterProvider } from './RouterProvider';
import SkeletonProvider from './SkeletonProvider';
import { QueryProvider } from './QueryProvider';

import { store } from 'shared/redux-store';
import { Provider } from 'react-redux';

export const withProviders =
  <P extends object>(Component: React.ComponentType<P>) =>
  (props: P) =>
    (
      // @ts-ignore
      <Provider store={store}>
        <RouterProvider>
          <QueryProvider>
            <SkeletonProvider>
              {/* @ts-ignore */}
              <Component {...props} />
            </SkeletonProvider>
          </QueryProvider>
        </RouterProvider>
      </Provider>
    );
