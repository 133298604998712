import type { AxiosResponse } from 'axios';

import instance from 'api';

import type {
  InvitationInfoDto,
  GenerateInvitationRequest,
  GenerateInvitationResponse,
} from 'dtos';

const INVITATION_URL: string = '/invitation';

export default class InvitationService {
  static async checkInvitationByShareToken(
    shareToken: string | null
  ): Promise<InvitationInfoDto> {
    const { data } = await instance.get<InvitationInfoDto>(
      INVITATION_URL + '/check',
      { params: { shareToken } }
    );
    return data;
  }

  static async generateInvitation(
    generateInvitationDto?: GenerateInvitationRequest
  ): Promise<GenerateInvitationResponse> {
    const { data } = await instance.post<
      GenerateInvitationResponse,
      AxiosResponse<GenerateInvitationResponse>,
      GenerateInvitationRequest
    >(INVITATION_URL + '/generate', generateInvitationDto);
    return data;
  }
  //TODO:
  // 1) change to PUT request method
  // 2) add shareToken to body, not query
  static async redeemInvitationByShareToken(shareToken: string): Promise<void> {
    await instance.post<void>(INVITATION_URL + '/redeem', null, {
      params: { shareToken },
    });
  }
}
