import styles from './UserBadge.module.scss';

import React from 'react';
import cn from 'classnames';

import type { UserDto } from 'dtos';

import { hasProvidePermission } from 'entities1/user/model/roles';

export type UserBadgeProps = {
  user?: UserDto;
};

function UserBadge({ user }: UserBadgeProps): JSX.Element {
  const isProvider = hasProvidePermission(user);

  if (!user) return <></>;

  return (
    <div
      className={cn(styles.role, isProvider ? styles.provider : styles.default)}
    >
      {user.roleInfo.displayName}
    </div>
  );
}

export default React.memo(UserBadge);
