import React, { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button/Button';

type AddNewFeeRangeButtonProps = {
  onClick: MouseEventHandler;
};

const AddNewFeeRangeButton = ({ onClick }: AddNewFeeRangeButtonProps) => {
  const { t } = useTranslation();

  return <Button onClick={onClick}>{t('button.addNewFeeRange')}</Button>;
};

export default AddNewFeeRangeButton;
