import styles from './ExchangeInfo.module.scss';

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button, { Variant } from 'components/Button/Button';
import { queryClient } from 'shared/config/reactQuery';

import { exchangerQueryKey } from 'widgets/ExchangerWidget';

import { useCompleteSessionMutation, useGetPaymentBySessionQuery } from 'services/payments';
import { Timer } from 'components/Timer';
import Spinner from 'components/Spinner/Spinner';
import ExchangeUnitInfo from './components/ExchangeUnitInfo';
import PaymentUserInfo from './components/PaymentUserInfo';
import TextInfo from './components/TextInfo';
import { CurrencyResponseDto, GoodsResponseDto, PaymentStatus, PaymentType, UserDto } from 'dtos';
import { getUserId } from '../../shared/lib/auth';
import DetailsForm from './components/DetailsForm';
import checkIsWebApp from '../../utils/checkIsWebApp';

enum PaymentSessionState {
  Confirmed = 'CONFIRMED',
  Unconfirmed = 'UNCONFIRMED',
  Expired = 'EXPIRED',
  Loading = 'LOADING',
}

function ExchangeInfo(): JSX.Element {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();

  const [paymentSessionState, setPaymentSessionState] = useState<PaymentSessionState>(
    PaymentSessionState.Loading
  );
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [telegramChatLink, setTelegramChatLink] = useState<string>('');

  const {
    data: paymentForm,
    isLoading
  } = useGetPaymentBySessionQuery(String(id));
  const [title, setTitle] = useState<string>(paymentForm?.title || '');
  const [comment, setComment] = useState<string>(paymentForm?.comment || '');
  const [details, setDetails] = useState<string>();
  const [completeSession] = useCompleteSessionMutation();

  useEffect(() => {
    if (!paymentForm || isLoading) {
      setPaymentSessionState(PaymentSessionState.Loading);
    } else {
      if (paymentForm.paymentStatus === PaymentStatus.Unconfirmed) {
        setTitle(paymentForm?.title || '');
        setComment(paymentForm?.comment || '');
        const isExpired = +new Date(paymentForm.paymentExpirationTime) - Date.now() < 0;
        if (isExpired) {
          setPaymentSessionState(PaymentSessionState.Expired);
        } else {
          setPaymentSessionState(PaymentSessionState.Unconfirmed);
        }
      } else {
        if (paymentForm.telegramInviteLink) {
          setTelegramChatLink(paymentForm.telegramInviteLink);
        }
        setPaymentSessionState(PaymentSessionState.Confirmed);
      }
    }
  }, [paymentForm, isLoading]);

  const goBack = () => {
    navigate(-1);
  };

  const createPayment = async () => {
    try {
      setPaymentSessionState(PaymentSessionState.Loading);

      const { telegramInviteLink, telegramDeepLink } = await completeSession({
        sessionKey: String(id),
        moneyReady: true,
        title,
        comment,
        details: details
      }).unwrap();

      queryClient.removeQueries(exchangerQueryKey(paymentForm?.provider?.id));
      const linkToChat = checkIsWebApp() ? telegramInviteLink : telegramDeepLink;
      if (linkToChat) {
        window.location.href = linkToChat;
        setTelegramChatLink(linkToChat);
        setPaymentSessionState(PaymentSessionState.Confirmed);
      }
    } catch (e) {
      e instanceof Error && setErrorMessage(e.message);
      setPaymentSessionState(PaymentSessionState.Loading);
    }
  };

  const goToTelegramChat = () => {
    window.location.href = telegramChatLink;
  };

  if (!paymentForm) {
    return <Spinner className={styles.spinner} />;
  }
  const userId = getUserId();

  let isCurrentUserProvider: boolean;
  let relativeUser: UserDto;
  if (userId === paymentForm.provider.id.toString()) {
    isCurrentUserProvider = true;
    relativeUser = paymentForm.customer;
  } else {
    isCurrentUserProvider = false;
    relativeUser = paymentForm.provider;
  }

  function renderButtonBasedOnState(state: PaymentSessionState): JSX.Element {
    switch (state) {
      case PaymentSessionState.Confirmed:
        if (telegramChatLink) {
          return (
            <Button variant={Variant.Telegram} onClick={goToTelegramChat}>
              {t('button.offerAccepted')}
            </Button>
          );
        } else {
          return <Button variant={Variant.Telegram}>{t('button.noLink')}</Button>;
        }
      case PaymentSessionState.Unconfirmed:
        return (
          <Button variant={Variant.Telegram} onClick={createPayment}>
            {t('button.confirmAndCreateChat')}
          </Button>
        );
      case PaymentSessionState.Expired:
        return (
          <Button variant={Variant.Telegram} disabled={true}>
            {t('button.offerNotAccepted')}
          </Button>
        );
      case PaymentSessionState.Loading:
        return (
          <Button variant={Variant.Telegram} disabled={true}>
            <Spinner className={styles.spinner} size={20} />
          </Button>
        );
    }
  }

  const isTransaction = paymentForm.type === PaymentType.Transaction;
  const isCurrencyResponseDto = (obj: Partial<CurrencyResponseDto & GoodsResponseDto> | undefined) => {
    return obj ? 'code' in obj : false;
  };

  return (
    <div className={styles.container}>
      <ExchangeUnitInfo
        exchangeUnit={paymentForm.buyExchangeUnit}
        amount={paymentForm.buyExchangeUnitAmount}
        isSelling={false}
        isCurrentUserProvider={isCurrentUserProvider}
      />
      {isTransaction && (
        <TextInfo text={title} onChange={setTitle} type="title" />
      )}
      {isTransaction && (
        <TextInfo text={comment} onChange={setComment} type="comment" />
      )}
      <PaymentUserInfo user={relativeUser} isCustomer={isCurrentUserProvider} isTransaction={isTransaction} />
      {!isTransaction && (
        <ExchangeUnitInfo
          exchangeUnit={paymentForm.sellExchangeUnit}
          amount={paymentForm.sellExchangeUnitAmount}
          isSelling={true}
          isCurrentUserProvider={isCurrentUserProvider}
        />
      )}
      {!isTransaction && isCurrencyResponseDto(paymentForm.buyExchangeUnit) && (
        <DetailsForm paymentForm={paymentForm} updateDetails={setDetails} value={details}/>
      )}
      {paymentSessionState !== PaymentSessionState.Confirmed ? (
        <div className={styles.timerTab}>
          <div className={styles.timerText}>{t('page.exchangeInfo.timer')}</div>
          <div className={styles.timer}>
            {paymentForm.paymentExpirationTime && (
              <Timer
                deadline={paymentForm.paymentExpirationTime}
                onExpired={() => {
                  setPaymentSessionState(PaymentSessionState.Expired);
                }}
              />
            )}
          </div>
        </div>
      ) : (
        <div className={styles.timerTab}>
          <div className={styles.timerText}>{t('page.exchangeInfo.alreadyCreated')}</div>
        </div>
      )}

      <div className={styles.actions}>
        {renderButtonBasedOnState(paymentSessionState)}

        <Button onClick={goBack} variant={Variant.BlackWhite}>
          {t('button.cancel')}
        </Button>
      </div>

      {errorMessage && <p className={styles.error}>{errorMessage}</p>}
    </div>
  );
}

export default ExchangeInfo;
