import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import FeeRanges from 'widgets/FeeRanges';
import { ExchangerWidget } from 'widgets/ExchangerWidget';
import { Layout } from 'components/Layout/Layout';
import { UserCard } from 'entities1/user';
import { useGetContactByIdQuery } from 'services/contacts';
import { AvatarSize } from 'shared/ui/Avatar/Avatar';

import styles from './styles.module.scss';

function FeeRatesPage() {
  const { t } = useTranslation();
  const { id } = useParams();
  const contactId = Number(id);

  const {data, isLoading} = useGetContactByIdQuery(contactId);

  return (
    <Layout>
      <Layout.TopBar>
        <Layout.Back />

        <Layout.Title>{t('page.contactProfile.feeRanges.title')}</Layout.Title>
      </Layout.TopBar>

      <Layout.Content unsetBottomPadding>
        <div className={styles.userContent}>
          <UserCard
            user={data}
            loading={isLoading}
            avatarSize={AvatarSize.Default}
            hideRegisterDays
            reversedContent
          />
        </div>
        <ExchangerWidget providerId={null} userId={contactId} />
        <FeeRanges />
      </Layout.Content>
    </Layout>
  );
}

export default FeeRatesPage;
