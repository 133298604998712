import { BaseIconProps } from '../types';

const Icon = (props: BaseIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="currentColor"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.4765 -0.000198856L11.9766 -0.000198519L0.726712 11.2497L8.22661 18.7496L19.4765 7.4997L19.4765 -0.000198856ZM14.9765 4.49982C15.3907 4.91403 16.0623 4.91403 16.4765 4.49982C16.8907 4.08561 16.8907 3.41405 16.4765 2.99984C16.0623 2.58563 15.3907 2.58563 14.9765 2.99984C14.5623 3.41405 14.5623 4.08561 14.9765 4.49982Z"
    />
  </svg>
);

export default Icon;
