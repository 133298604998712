import styles from './index.module.scss';

import { FC } from 'react';

import { ExchangerWidgetProps } from '../../ExchangerWidget';
import { useExchangerStore } from '../../store';
import SkeletonWithWrapper from 'shared/ui/SkeletonWithWrapper';
import { Slider } from 'shared/ui/Slider';
import { EnumSlide } from './EnumSlide';
import { SkuParameterType } from 'dtos';
import { ColorSlide } from './ColorSlide';

export const ExchangerSkuParams: FC<ExchangerWidgetProps> = (props) => {
  const { providerId, userId } = props;
  const {
    currentFormData,
    calculateExchangePaymentForm,
    isInitialization,
    tabs,
    activeOption,
  } = useExchangerStore(providerId, userId);
  const skuParameters = currentFormData?.buyExchangeUnit?.skuParameters;
  const name = currentFormData?.buyExchangeUnit?.name;
  const city = currentFormData?.buyExchangeUnit?.city;
  const subcategory = currentFormData?.subcategory;
  const goods = tabs?.find(t => t.name === activeOption)?.goods;

  const currentCity = goods?.find((g) => g.cityName === city);
  const currentSubcategory = currentCity?.subcategories.find(s => s.subcategoryName === subcategory);
  const currentModel = currentSubcategory?.skuModels.find(sm => sm.modelName === name);
  const availableSkuParameters = currentModel?.availableSkuParameters || [];

  if (isInitialization) {
    // FIXME: add skeleton
    return <SkeletonWithWrapper className={styles.list} height={37} />;
  }

  if (!skuParameters || availableSkuParameters.length === 0) {
    return <></>;
  }

  return (
    <div className={styles.root}>
      {availableSkuParameters.map((param, index) => {
        const slides = param.availableValues.map((v, idx) => ({
          ...v,
          id: idx,
        }));
        const slide = slides.find((s) =>
          skuParameters.find(
            (p) => p.name === param.name && p.value === s.value
          )
        );
        const changeSlide = (slide: { value: string }) => {
          calculateExchangePaymentForm({
            form: {
              buyExchangeUnit: {
                ...currentFormData?.buyExchangeUnit,
                skuParameters: skuParameters.map((sp) => ({
                  ...sp,
                  value: sp.name === param.name ? slide.value : sp.value,
                })),
              },
            }
          });
        };

        return (
          <Slider
            key={param.name}
            sliderClassName={styles.slider}
            slideClassName={styles.slide}
            slides={slides}
            slide={slide}
            slideRenderer={getComponentByType(param.type)}
            onSlideChange={changeSlide}
          >
            <div className={styles.paramName} key={index}>
              {param.localizedName}:
            </div>
          </Slider>
        );
      })}
    </div>
  );
};

function getComponentByType(type: SkuParameterType) {
  return type === 'ENUM' ? EnumSlide : ColorSlide;
}
