import React from 'react';

import ExchangeRates from 'components/ExchangeRates';
import ExchangeRatesPageLayout from './layout';

function ExchangeRatesPage(): JSX.Element {
  return (
    <ExchangeRatesPageLayout>
      <ExchangeRates />
    </ExchangeRatesPageLayout>
  );
}

export default ExchangeRatesPage;
