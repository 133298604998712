import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { INVITATION } from 'pages/paths';

import Button, { Variant } from 'components/Button/Button';

import InvitationService from 'api/invitation';

import { GenerateInvitationRequest } from 'dtos';

import { isResponseWithErrorMessage } from 'utils';

const ONE_AND_HALF_SEC: number = 1500;
const INVITATION_URL: string = typeof window !== 'undefined' ? window.location.origin + INVITATION : '';

type ShareProviderButtonProps = {
  providerId: GenerateInvitationRequest['providerId'],
  userRelationType: GenerateInvitationRequest['userRelationType'],
};

function ShareProviderButton({
  providerId,
  userRelationType,
}: ShareProviderButtonProps): JSX.Element {
  const [disabled, setDisabled] = useState<boolean>(false);
  const [copied, setCopied] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const { t } = useTranslation();

  const copyToClipboard = async (link: string): Promise<void> => {
    await navigator.clipboard.writeText(link);
    setCopied(true);
    setTimeout(() => setCopied(false), ONE_AND_HALF_SEC);
  };

  const handleError = (): void => {
    setError(true);
    setTimeout(() => setError(false), ONE_AND_HALF_SEC);
  };

  const shareProvider = async (e: React.MouseEvent): Promise<void> => {
    e.preventDefault();
    if (!providerId) return;
    try {
      setDisabled(true);
      const { invitationToken } = await InvitationService.generateInvitation({
        providerId,
        userRelationType,
      });
      const shareLink: string = `${INVITATION_URL}?share=${invitationToken}`;
      await copyToClipboard(shareLink);
    } catch (e) {
      if (!(e instanceof Error)) return;
      handleError();
      alert(
        isResponseWithErrorMessage(e) ? e.response.data.message : e.message
      );
    } finally {
      setDisabled(false);
    }
  };

  return (
    <Button
      variant={Variant.Outlined}
      onClick={shareProvider}
      disabled={disabled}
      active={copied}
      danger={error}
    >
      {t(copied ? 'button.copied' : 'button.shareProvider')}
    </Button>
  );
}

export { ShareProviderButton };
