import styles from '../../ExchangerWidget.module.scss';

import { useMemo } from 'react';
import isEqual from 'lodash/isEqual';
import find from 'lodash/find';
import head from 'lodash/head';

import { Select } from 'components/Select';
import {
  CurrencyInformationResponseDtoV3,
  GoodsInCityResponseDto,
  GoodsInformationResponseDto,
  PaymentFormDtoV5,
  PaymentMethodDto, PaymentMethodResponseDto,
} from 'dtos';
import { ExchangerScope } from '../../types';
import { ExchangerWidgetScopeProps } from '../../ExchangerWidget';
import { useExchangerStore } from '../../store';
import { getBuyOrSellExchangeUnitProp, isBuyScope } from '../../utils';
import SkeletonWithWrapper from 'shared/ui/SkeletonWithWrapper';

export interface ExchangeMethodSelectProps extends ExchangerWidgetScopeProps {
  foundCurrency?:
    | CurrencyInformationResponseDtoV3
    | GoodsInformationResponseDto;
  paymentMethod?: PaymentMethodDto;
  city?: Omit<GoodsInCityResponseDto, 'subcategories'>;
  isPublic?: boolean;
}

export function ExchangeMethodSelect(
  props: ExchangeMethodSelectProps
): JSX.Element | null {
  const {
    providerId,
    userId,
    foundCurrency,
    paymentMethod,
    city,
    scope = ExchangerScope.Buy,
  } = props;
  const {
    currentFormData,
    calculateExchangePaymentForm,
    activeOption,
    isInitialization,
    tabs,
  } = useExchangerStore(providerId, userId);

  const name: string | undefined = paymentMethod?.name || city?.localizedCityName;
  const type: PaymentMethodDto['type'] | undefined = paymentMethod?.type;
  const goods = tabs?.find(t => t.name === activeOption)?.goods;

  const names = useMemo(() => {
    if (isInitialization) {
      return [];
    }

    if (paymentMethod) {
      let methodNames: string[] = [];
      if (foundCurrency && type) {
        if ('paymentMethods' in foundCurrency) {
          const exchangeTypeMethods = find(foundCurrency.paymentMethods, (m) =>
            isEqual(m.paymentMethodType, type)
          );
          methodNames = exchangeTypeMethods?.methods ?? [];
        }
      }
      return methodNames;
    } else {
      return (goods || []).map((city) => city.localizedCityName);
    }
  }, [foundCurrency, isInitialization, goods, type]);

  const changeExchangeMethod = (exchangeName: string) => {
    const buyOrSellExchangeUnitProp = getBuyOrSellExchangeUnitProp(scope);
    if (currentFormData) {
      let formData: PaymentFormDtoV5[typeof buyOrSellExchangeUnitProp] | null =
        null;
      let newSubcategory: string | undefined = currentFormData.subcategory;
      if (currentFormData[buyOrSellExchangeUnitProp]?.paymentMethod) {
        formData = {
          ...currentFormData[buyOrSellExchangeUnitProp],
          paymentMethod: {
            ...(currentFormData[buyOrSellExchangeUnitProp].paymentMethod as PaymentMethodResponseDto),
            name: exchangeName,
          },
        };
      } else {
        const city = goods?.find(g => g.localizedCityName === exchangeName);
        const subcategoryName = currentFormData.subcategory;
        const subcategory = city?.subcategories.find(sc => sc.subcategoryName === subcategoryName) || head(city?.subcategories);
        const model = subcategory?.skuModels.find(m => m.modelName === currentFormData[buyOrSellExchangeUnitProp].name) || head(subcategory?.skuModels);

        if (!model || !city) {
          return;
        }

        formData = {
          name: model.modelName,
          city: city.cityName,
          measureUnit: model.measureUnits[0].name,
          skuParameters: model.availableSkuParameters.map(p => ({
            name: p.name,
            type: p.type,
            value: p.availableValues[0].value,
            localizedName: p.localizedName,
            localizedValue: p.availableValues[0].localizedValue
          })),
        };

        newSubcategory = subcategory?.subcategoryName;
      }

      calculateExchangePaymentForm({
        form: {
          [buyOrSellExchangeUnitProp]: formData,
          subcategory: newSubcategory,
        }
      });
    }
  };

  if (isInitialization) {
    // FIXME: add skeleton
    return <SkeletonWithWrapper height={22} />;
  }

  if (!name) {
    return null;
  }

  return (
    <Select
      value={name}
      values={names}
      onChange={changeExchangeMethod}
      className={styles.select}
      buttonClassName={styles.btn}
    />
  );
}
