import { InfiniteData, QueryKey, useMutation } from '@tanstack/react-query';

import type { UserDto, SwitchInheritanceRequest } from 'dtos';
import ExchangeRateInheritanceService from 'api/exchange-rate-inheritance';
import type { InheritanceExchangeRatesByProviderIdResponse } from 'api/exchange-rate-inheritance/types';

import { queryClient } from 'shared/config/reactQuery';

interface ExchangeRateInheritanceUpdateParams {
  requestParams: SwitchInheritanceRequest;
  inherit: boolean;
}

export const useExchangeRateInheritanceUpdate = (providerId: UserDto['id']) => {
  const queryKey: QueryKey = ['exchange-rates-by-provider', providerId];
  return useMutation({
    mutationKey: queryKey,
    mutationFn: ({
      requestParams,
      inherit,
    }: ExchangeRateInheritanceUpdateParams) => {
      if (inherit) {
        return ExchangeRateInheritanceService.inheritProviderExchangeRate(
          requestParams
        );
      }
      return ExchangeRateInheritanceService.unInheritProviderExchangeRate(
        requestParams
      );
    },
    onSuccess: async (_, { requestParams }) => {
      queryClient.setQueryData<
        InfiniteData<InheritanceExchangeRatesByProviderIdResponse>
      >(queryKey, (prev) => {
        if (!prev) return;

        return {
          ...prev,
          pages: prev.pages.map((p) => {
            const rate = p.content.find(
              (r) => r.exchangeRate.id === requestParams.exchangeRateId
            );
            if (!rate) return p;

            return {
              ...p,
              content: p.content.map((r) => ({
                ...r,
                isInherited:
                  r.exchangeRate.id === rate.exchangeRate.id
                    ? !r.isInherited
                    : r.isInherited,
              })),
            };
          }),
        };
      });
    },
  });
};
