import styles from './GoBackButton.module.scss';

import React from 'react';
import { To, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import Icon from 'components/Icon/Icon';
import { IconName } from 'components/Icon/IconList';
import checkIsWebApp from 'utils/checkIsWebApp';

const PREVIOUS_PATH: number = -1;

export interface GoBackButtonProps {
  to?: To;
  className?: string;
}

function GoBackButton({ to, className }: GoBackButtonProps): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isWebApp = checkIsWebApp();

  const goTo = (): void => {
    to ? navigate(to) : navigate(PREVIOUS_PATH);
  };

  return (
    !isWebApp ? (
      <button
        type="button"
        onClick={goTo}
        className={cn(styles.backButton, className)}
      >
        <Icon
          name={IconName.Back}
          width={12}
          height={20}
          className={styles.icon}
        />
        {t('menu.back')}
      </button>
    ) : (<div></div>)
  );
}

export default GoBackButton;
