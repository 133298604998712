import ConnectAIIcon from 'components/icons/ConnectAI';
import Button, { Variant } from 'components/Button/Button';
import cn from 'classnames';
import styles from './TaskPageHeader.module.scss';
import { useTranslation } from 'react-i18next';

export interface TaskPageProps {
  openTaskFormulationModal: () => void;
  isLoading: boolean;
}

const TaskPageHeader = (props: TaskPageProps) => {
  const { openTaskFormulationModal, isLoading } = props;
  const { t } = useTranslation();
  return (
    <div className={styles.content}>
      <ConnectAIIcon className={cn({[styles.icon]: isLoading})}/>
      <Button className={styles.button} variant={Variant.Minimal} onClick={openTaskFormulationModal} disabled={isLoading}>
        {t('page.task.header.button')}
      </Button>
    </div>
  );
};

export default TaskPageHeader;
