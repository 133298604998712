import styles from './ContactRelationIndicator.module.scss';

import cn from 'classnames';

import { ContactRelationType } from 'dtos';

import { RELATION_LABEL, RELATION_CLASS_NAME } from './constants';

import avatarStyles from 'shared/ui/Avatar/Avatar.module.scss';

interface ContactRelationIndicatorProps {
  relationType: ContactRelationType;
}

function ContactRelationIndicator({
  relationType,
}: ContactRelationIndicatorProps): JSX.Element | null {
  if (relationType === ContactRelationType.Friend) {
    return null;
  }
  return (
    <div
      className={cn(
        avatarStyles.dotIndicator,
        styles.dotIndicator,
        RELATION_CLASS_NAME[relationType]
      )}
    >
      {RELATION_LABEL[relationType]}
    </div>
  );
}

export default ContactRelationIndicator;
