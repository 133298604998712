import styles from './UpdateModal.module.scss';

import React from 'react';
import { RootState } from 'shared/redux-store';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { hide, ModalTypes } from 'slices/modal';
import Modal from 'components/Modal/Modal';
import Button from 'components/Button/Button';
import Title from 'components/Layout/Title';

function CallMeBackModal(): JSX.Element | null {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { visible } = useSelector((state: RootState) => state.modal[ModalTypes.Update]);

  const closeModal = (): void => {
    dispatch(hide({ id: ModalTypes.Update }));
  };

  const updateAction = (): void => {
    window.location.reload();
  };

  if (!visible) {
    return null;
  }

  return (
    <Modal visible={visible} onClose={closeModal} className={styles.modal}>
      <Title className={styles.title}>{t('modal.update.title')}</Title>

      <div className={styles.description}>{t('modal.update.description')}</div>

      <div className={styles.buttons}>
        <Button className={styles.updateButton} onClick={updateAction}>
          {t('modal.update.updateButton')}
        </Button>
        <Button className={styles.closeButton} onClick={closeModal}>
          {t('modal.update.closeButton')}
        </Button>
      </div>
    </Modal>
  );
}

export default CallMeBackModal;
