import type { AxiosRequestHeaders, AxiosResponse } from 'axios';

import instance from 'api';

import type { SwitchInheritanceRequest } from 'dtos';
import type {
  InheritanceExchangeRatesByProviderIdParams,
  InheritanceExchangeRatesByProviderIdResponse,
  UploadedInheritanceExchangeRatesParams,
  UploadedInheritanceExchangeRatesResponse,
  InheritedExchangeRateByIdParams,
} from './types';

const EXCHANGE_RATES_URL: string = '/inheritance/exchange-rates';
const headers: AxiosRequestHeaders = { 'Content-Type': 'application/json' };

export default class ExchangeRateInheritanceService {
  static async getExchangeRatesByProviderId({
    id,
    ...params
  }: InheritanceExchangeRatesByProviderIdParams): Promise<InheritanceExchangeRatesByProviderIdResponse> {
    const { data } =
      await instance.get<InheritanceExchangeRatesByProviderIdResponse>(
        EXCHANGE_RATES_URL + `/${id}`,
        { params }
      );
    return data;
  }

  static async getUploadedExchangeRates(
    params: UploadedInheritanceExchangeRatesParams
  ): Promise<UploadedInheritanceExchangeRatesResponse> {
    const { data } =
      await instance.get<UploadedInheritanceExchangeRatesResponse>(
        EXCHANGE_RATES_URL + '/uploaded',
        { params }
      );
    return data;
  }

  static async inheritProviderExchangeRate(
    switchInheritanceDto: SwitchInheritanceRequest
  ): Promise<void> {
    await instance.post<void, AxiosResponse<void>, SwitchInheritanceRequest>(
      EXCHANGE_RATES_URL + '/inherit',
      switchInheritanceDto
    );
  }

  static async unInheritProviderExchangeRate(
    switchInheritanceDto: SwitchInheritanceRequest
  ): Promise<void> {
    await instance.post<void, AxiosResponse<void>, SwitchInheritanceRequest>(
      EXCHANGE_RATES_URL + '/uninherit',
      switchInheritanceDto
    );
  }

  static async enableInheritedExchangeRateById(
    id: InheritedExchangeRateByIdParams
  ): Promise<void> {
    await instance.post<
      void,
      AxiosResponse<void>,
      InheritedExchangeRateByIdParams
    >(EXCHANGE_RATES_URL + '/mine/enable', id, { headers });
  }

  static async disableInheritedExchangeRateById(
    id: InheritedExchangeRateByIdParams
  ): Promise<void> {
    await instance.post<
      void,
      AxiosResponse<void>,
      InheritedExchangeRateByIdParams
    >(EXCHANGE_RATES_URL + '/mine/disable', id, { headers });
  }
}
