import styles from './index.module.scss';

import { FC } from 'react';

export const ColorSlide: FC<any> = (props) => {
  const { localizedValue } = props;
  const [
    color,
    // text,
  ] = localizedValue.split('|');
  return (
    <div className={styles.colorSlide} style={{backgroundColor: color}}>
      {/* <span className={styles.title}>{text}</span> */}
    </div>
  );
};
