import { AxiosError } from 'axios';
import { getI18n } from 'react-i18next';
import { QueryKey } from '@tanstack/react-query';
import orderBy from 'lodash/orderBy';
import groupBy from 'lodash/groupBy';

import type { AccountPaymentDetailsParams } from 'api/account-payment-details/types';

import {
  PaymentStatus,
  PaymentParticipantRole,
  PaymentForListDto,
  ExchangeRateInheritedDto,
  UserDto,
  PaymentType,
  PaymentForListDtoV4,
} from 'dtos';

import { queryClient } from 'shared/config/reactQuery';
import { BalanceType } from '../widgets/CreateBalanceTransactionModal';

const EMPTY: string = '';

export function setAttributes(
  el: HTMLElement,
  attrs: { [key: string]: string }
) {
  Object.keys(attrs).forEach((key) => el.setAttribute(key, attrs[key]));
}

export function debounce<T extends unknown[], U>(
  callback: (...args: T) => PromiseLike<U> | U,
  wait: number = 300
) {
  let timeoutId: ReturnType<typeof setTimeout>;

  return (...args: T): Promise<U> => {
    clearTimeout(timeoutId);
    return new Promise((resolve) => {
      timeoutId = setTimeout(() => resolve(callback(...args)), wait);
    });
  };
}

const MILLISECONDS_IN_DAY: number = 1000 * 60 * 60 * 24;
export const getFormattedDays = (date: string): number => {
  const currentDay: number = Date.now();
  const registerDate: Date = new Date(date);
  return (currentDay - registerDate.getTime()) / MILLISECONDS_IN_DAY;
};

export const isToday = (date: Date) => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

export const isMoreThenYear = (date: Date): boolean => {
  const today = new Date();
  const yearDifference = today.getFullYear() - date.getFullYear();

  return (
    yearDifference > 1 ||
    (yearDifference === 1 && (date.getMonth() < today.getMonth() ||
      (date.getMonth() === today.getMonth() && date.getDate() <= today.getDate())))
  );
};

export const nth = function (date: number) {
  if (date > 3 && date < 21) return 'th';
  switch (date % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

export const formattedDate = (dateString: string, needToday = true) => {
  const { t, language } = getI18n();
  const date = new Date(dateString);
  const day = date.getDate();

  if (isToday(date) && needToday) {
    return t('date.today');
  }

  if (isMoreThenYear(date)) {
    return new Date(Date.now()).toLocaleDateString(language);
  }

  const resultDate = date.toLocaleString(language, {
    month: 'long',
    day: 'numeric',
  });

  return language === 'en' ? `${resultDate}${nth(day)}` : resultDate;
};

export const getFormattedDate = (dateString: string, separate = '.') => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (1 + date.getMonth()).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  return day + separate + month + separate + year;
};

export const getFormattedFullDateTime = (dt: string) => {
  return new Intl.DateTimeFormat('ru-RU', {
    year: 'numeric', month: 'numeric', day: 'numeric',
    hour: 'numeric', minute: 'numeric',
    hour12: false,
  }).format(new Date(dt));
}

type PaymentsGroupedByDate = Record<string, PaymentForListDto[]>;
export const groupPaymentsByDate = (
  payments: PaymentForListDto[]
): PaymentsGroupedByDate => {
  const formatter = (p: PaymentForListDto) => formattedDate(p.createdAt);
  return groupBy(payments, formatter);
};

export const isPaymentDetailsParams = (
  obj: unknown
): obj is AccountPaymentDetailsParams => {
  if (!(obj instanceof Object)) return false;

  const hasMethodProp: boolean =
    'method' in obj && typeof obj['method'] === 'string';
  const hasCodeProp: boolean = 'code' in obj && typeof obj['code'] === 'string';

  return hasMethodProp && hasCodeProp;
};

export const isResponseWithErrorMessage = (
  obj: unknown
): obj is Required<AxiosError<{ message: string }>> => {
  if (!(obj instanceof AxiosError)) return false;

  return Boolean(
    obj?.response?.data?.message &&
      typeof obj.response.data.message === 'string'
  );
};

export const formatPaymentStatus = (
  payment: PaymentForListDtoV4,
  role?: Lowercase<PaymentParticipantRole>
): string => {
  switch (payment.paymentStatus) {
    case PaymentStatus.Success:
      if (role && role === PaymentParticipantRole.Customer.toLowerCase()) {
        return payment.feeAmount + ' ' + (payment.feeExchangeUnit?.code || payment.feeExchangeUnit?.name);
      }
      return 'Success';
    case PaymentStatus.Cancelled:
      return 'Cancelled';
    case PaymentStatus.Initialized:
      return 'Initialized';
    default:
      return 'In progress';
  }
};

export const setPaymentStatuses = (statuses: PaymentStatus[]) => {
  if (!statuses.includes(PaymentStatus.InProgress)) {
    return statuses;
  }

  const allInProgressStatuses: PaymentStatus[] = Object.values(
    PaymentStatus
  ).filter((s) => {
    switch (s) {
      case PaymentStatus.Initialized:
      case PaymentStatus.Success:
      case PaymentStatus.Cancelled:
        return false;
      default:
        return true;
    }
  });

  return [
    ...statuses.filter((s) => s !== PaymentStatus.InProgress),
    ...allInProgressStatuses,
  ];
};

export const sortListByCreatedDate = <T extends { createdAt: string }>(
  list: T[]
): T[] => {
  return orderBy(list, (i) => i.createdAt, ['desc']);
};

export const formatPercent = (v: string | number): string =>
  `${v.toLocaleString('en-US', {
    minimumFractionDigits: 2,
  })} %`;

export const isExchangeRateInheritedDto = (
  obj: unknown
): obj is ExchangeRateInheritedDto => {
  if (!(obj instanceof Object)) return false;
  return 'isInherited' in obj && typeof obj['isInherited'] === 'boolean';
};

export const formatExchangeRate = (rate: number): string => {
  return new Intl.NumberFormat('us-US', {
    maximumFractionDigits: 6,
  }).format(rate);
};

export const refetchInfiniteQueryPage = (
  queryKey: QueryKey,
  page: number
): void => {
  queryClient.refetchQueries({
    queryKey,
    refetchPage: (_, idx) => idx === page,
  });
};

export const formatNumber = (
  value: number,
  options: Intl.NumberFormatOptions = {}
): string => {
  return new Intl.NumberFormat('ru-RU', {
    style: 'decimal',
    useGrouping: true,
    minimumFractionDigits: 2,
    ...options,
  }).format(value).replace(',', '.');
};

export const formatDate = (date: Date | number, options?: Partial<Intl.DateTimeFormatPartTypesRegistry>): string => {
  const defaultOptions = {
    day: '2-digit',
    month: 'short',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  }

  return new Intl.DateTimeFormat('ru', {
    ...defaultOptions,
    ...options,
  }).format(date);
};

export const isPaymentStatus = (name: unknown): name is PaymentStatus => {
  return Object.values(PaymentStatus).includes(name as PaymentStatus);
};

export const isPaymentType = (name: unknown): name is PaymentType => {
  return Object.values(PaymentType).includes(name as PaymentType);
};

export type GroupedByCreatedDate<Item> = { [P in string]: Item[] };

export const groupListByCreatedDate = <Item extends { createdAt: string }>(
  list: Item[]
): (string | Item)[] => {
  const sortedList = [...list].sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );
  const groupedByCreatedDate = sortedList.reduce(
    (groupedItems: GroupedByCreatedDate<Item>, item) => {
      const date: keyof GroupedByCreatedDate<Item> = formattedDate(
        item.createdAt,
        false
      );
      return {
        ...groupedItems,
        [date]: [...(groupedItems[date] ?? []), item],
      };
    },
    {}
  );

  return Object.entries(groupedByCreatedDate).flat(2);
};

export const isBalanceType = (type: unknown): type is BalanceType => {
  return type === BalanceType.Send || type === BalanceType.Receive;
};

/**
 * Метод генерации URL-адреса приглашения
 * @param {string} invitationToken - Код приглашения
 * @returns {string} - URL-адреса приглашения
 */
export const getShareLink = (invitationToken: string): string => {
  return window.location.origin + `/invitation?share=${invitationToken}`;
}

/**
 * Возвращает имя пользователя в зависимости от наличия соответствующих значений
 * @param {UserDto} user - Объект данных пользователя
 * @returns {string} - Имя пользователя
 */
export const getUserName = (user?: UserDto): string => {
  return user?.customName || user?.username || EMPTY;
}

/**
 * Возвращает фото пользователя в зависимости от наличия соответствующих значений
 * @param {UserDto} user - Объект данных пользователя
 * @returns {string} - Фото пользователя с добавленным timestamp как get-параметром
 */
export const getUserPhoto = (user: UserDto | {
  customPhotoUrl?: string;
  profilePictureUrl?: string;
}): string => {
  let photoUrl = EMPTY;

  if (user.customPhotoUrl) {
    photoUrl = user.customPhotoUrl;
  } else if (user.profilePictureUrl) {
    photoUrl = user.profilePictureUrl;
  }

  return photoUrl;
}


/**
 * Из строки получаем числовой идентификатор
 * https://stackoverflow.com/questions/6122571/simple-non-secure-hash-function-for-javascript
 */
export const hashCode = (text: string): number => {
  let hash = 0;

  for (var i = 0; i < text.length; i++) {
      let char = text.charCodeAt(i);
      hash = ((hash << 5) - hash) + char;
      hash = hash & hash;
  }

  return hash;
}
