import styles from './FailScreen.module.scss';

import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Button from 'components/Button/Button';
import { Layout } from 'components/Layout/Layout';
import Icon from 'components/Icon/Icon';
import { IconName } from 'components/Icon/IconList';

import { HOME } from '../paths';

const BACK: number = -2;

const FailScreenPage = (): JSX.Element => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const error = searchParams.get('error');
  const link = searchParams.get('link');

  const goBack = () => {
    const navTo: string | number = link || BACK;
    typeof navTo === 'string'
      ? navigate(navTo, { replace: true })
      : navigate(navTo);
  };

  const goHome = () => navigate(HOME);

  return (
    <Layout hasMenu={false}>
      <Layout.Content>
        <div className={styles.container}>
          <h1 className={styles.title}>
            <Icon name={IconName.Error} width={50} height={50} />
          </h1>

          <h2 className={styles.subtitle}>Something went wrong</h2>

          <p className={styles.description}>
            {error || ' Sorry, something went wrong there. Try again.'}
          </p>

          <p>
            <button className={styles.link} onClick={goHome}>
              Go home
            </button>
          </p>

          <Button className={styles.button} onClick={goBack}>
            Go back
          </Button>
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default FailScreenPage;
