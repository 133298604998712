import { formatDate } from 'utils';

import styles from './ApplicationInfo.module.scss';
import releaseInfoData from '../../../../release-info';

const logoUrl: string = process.env.PUBLIC_URL + '/logo180.png';

export function ApplicationInfo(): JSX.Element {
  const getFormattedDate = (date: string) => {
    return formatDate(new Date(date), {
      month: '2-digit',
      year: 'numeric',
    }).replaceAll('/', '.').replaceAll(',', '');
  }

  return (
    <div className={styles.info}>
      <div className={styles.imgWrap}>
        <img src={logoUrl} alt="logo" className={styles.img} />
      </div>
      <h4 className={styles.title}>Connect</h4>

      {releaseInfoData && (
        <div className={styles.releaseInfo}>
          {/* TODO: Добавить в release-info.json название версии (beta) */}
          <div className={styles.releaseVersion}>Version {releaseInfoData.version} (beta)</div>
          <div className={styles.releaseDate}>{getFormattedDate(releaseInfoData.date)}</div>
        </div>
      )}
    </div>
  );
}
