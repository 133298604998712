import styles from './PaymentHistory.module.scss';

import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGetPaymentByIdQuery } from 'services/payments';

import { Table, Row, Cell } from 'shared/ui';

import { getFormattedDate, sortListByCreatedDate } from 'utils';

function PaymentHistory() {
  const { id } = useParams();

  const { data: payment, isLoading } = useGetPaymentByIdQuery(Number(id));

  const sortedPaymentByCreatedDate = useMemo(() => {
    if (!payment) return;
    return sortListByCreatedDate(payment?.paymentProcessingHistory);
  }, [payment]);

  if (!id || !payment || isLoading) {
    return <></>;
  }

  return (
    <div className={styles.content}>
      <Table className={styles.table}>
        {sortedPaymentByCreatedDate?.map((paymentHistoryData) => (
          <React.Fragment
            key={`${paymentHistoryData.createdAt}-${paymentHistoryData.paymentEvent}`}
          >
            <Row>
              <Cell>
                {getFormattedDate(paymentHistoryData.createdAt, '.')}
                &nbsp;
                {new Date(paymentHistoryData.createdAt).toLocaleTimeString('ru-RU')}
              </Cell>
            </Row>

            <Row>
              <Cell>{paymentHistoryData.paymentEvent}</Cell>
            </Row>

            <Row>
              <Cell>{paymentHistoryData.description}</Cell>
            </Row>
          </React.Fragment>
        ))}
      </Table>
    </div>
  );
}

export default PaymentHistory;
