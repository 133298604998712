import { QAndADto } from 'dtos';
import isEmpty from 'lodash/isEmpty';
import styles from './QAndAChat.module.scss';
import cn from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface QAndAChatProps {
  qAndA?: QAndADto[];
}

function QAndAChat({ qAndA }: QAndAChatProps): JSX.Element | null {
  const { t } = useTranslation();
  const [qAndAVisibility, setQAndAVisibility] = useState(false);

  const onQAndAClick = () => {
    setQAndAVisibility(!qAndAVisibility);
  };

  if (isEmpty(qAndA)) {
    return null;
  }

  return (
    <div className={styles.questionsAndAnswers}>
      <div className={styles.qAndATitle} onClick={onQAndAClick}>
        {t('modal.taskFormulation.qAndA')}
      </div>
      <ul className={cn(styles.questionsAndAnswersList, !qAndAVisibility && styles.hidden)}>
        {qAndA!!.map((qAndA) => {
          return (
            <>
              <li key={Math.random()} className={cn(styles.qAndAItem)}>
                {qAndA.question}
              </li>
              <li key={Math.random()} className={cn(styles.qAndAItem, styles.answerItem)}>
                {qAndA.answer}
              </li>
            </>
          );
        })}
      </ul>
    </div>
  );
}

export default QAndAChat;
