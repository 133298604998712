import React, { useCallback, useState } from 'react';

import { Layout } from 'components/Layout/Layout';
import { FriendsHeader, FriendsContent } from './components';
import { debounce } from 'utils';

function FriendsPage(): JSX.Element {
  const [searchString, setSearchString] = useState<string | undefined>(undefined);
  const debounceSetSearchString = useCallback(
    debounce((searchString?: string) => setSearchString(searchString), 500),
    [setSearchString]
  );

  return (
    <Layout>
      <FriendsHeader debounceSetSearchString={debounceSetSearchString} />

      <FriendsContent searchString={searchString} />
    </Layout>
  );
}

export default FriendsPage;
