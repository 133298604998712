import { PaymentParticipantRole, PaymentStatus, PaymentType } from 'dtos';

export type Label = {
  [K in PaymentStatus | PaymentParticipantRole | PaymentType]?: string;
};

export const LABEL: Label = {
  [PaymentStatus.Initialized]: 'Initialized',
  [PaymentStatus.InProgress]: 'In progress',
  [PaymentStatus.Success]: 'Success',
  [PaymentStatus.Cancelled]: 'Cancelled',
  [PaymentParticipantRole.Provider]: 'Provider',
  [PaymentParticipantRole.Customer]: 'Customer',
  [PaymentParticipantRole.ProviderAssistant]: 'Provider assistant',
  [PaymentParticipantRole.CustomerAssistant]: 'Customer assistant',
  [PaymentType.Exchange]: 'Exchange',
  [PaymentType.Transaction]: 'Transaction',
};

export const isLabelKey = (v: unknown): v is keyof Label => {
  return Object.keys(LABEL).includes(String(v));
};
