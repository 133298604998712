import React from 'react';

import Modal from 'components/Modal/Modal';
import Info from './Info';

import { useTransactionInfoModal } from 'entities1/transactionInfoModal/services/useTransactionInfoModal';

function TransactionInfoModal() {
  const { state, closeModal } = useTransactionInfoModal();

  if (!state || !state.data) return null;

  const { visible, data } = state;

  return (
    <Modal visible={visible} onClose={closeModal}>
      <Info {...data} />
    </Modal>
  );
}

export default TransactionInfoModal;
