import styles from './Rate.module.scss';

import { useEffect, useState } from 'react';
import cx from 'classnames';

import Icon from 'components/Icon/Icon';
import { IconName } from 'components/Icon/IconList';
import CurrencyInput, { CurrencyInputProps } from 'components/Input/CurrencyInput/CurrencyInput';
import { RangeValue } from 'entities1/feeRate/model/constants';
import { InputType } from 'components/Input/InputTypes';

type RateProps = {
  value: number;
  onChange: (value: number) => void;
  step?: number;
  suffix?: string;
  decimalsLimit?: number;
  withInput?: boolean;
  className?: string;
  range?: { min: number; max: number };
  withoutDecimals?: boolean;
  inputType?: InputType;
};

function Rate({
  value,
  onChange,
  step = 0.01,
  suffix = '%',
  decimalsLimit = 2,
  withInput = false,
  className,
  range = RangeValue,
  withoutDecimals,
  inputType = InputType.TEXT,
}: RateProps): JSX.Element {
  const [inputValue, setInputValue] = useState<string | number>(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const updateInputValue = (value: string | number) => {
    const nextValue = Number(value);

    if (nextValue > range.max || nextValue < range.min) {
      return;
    }

    // тут оставляем строку, потому что хотим сохранить такую строку "0."
    setInputValue(value);

    onChange(nextValue);
  };

  const increaseValue = () => {
    if (inputValue == null) return;
    const next = Number((Number(inputValue) + step).toFixed(decimalsLimit));
    updateInputValue(next > range.max ? range.max : next);
  };

  const decreaseValue = () => {
    if (inputValue == null) return;
    const next = Number((Number(inputValue) - step).toFixed(decimalsLimit));
    updateInputValue(next < range.min ? range.min : next);
  };

  const onValueChange: CurrencyInputProps['onValueChange'] = (_v, _n, values) => {
    let value = !values || values.value === '' ? 0 : values.value;
    if (withoutDecimals) {
      value = String(value).split('.')[0];
    }
    updateInputValue(value);
  };

  return (
    <div className={cx(styles.rateInput, className)}>
      <Icon
        onClick={decreaseValue}
        name={IconName.Minus}
        className={styles.icon}
        height={24}
        width={24}
      />
      {withInput ? (
        <CurrencyInput
          // иначе не можем вставить минус, так как клавиатура ios не показывает такой знак
          type={inputType}
          value={inputValue}
          onValueChange={onValueChange}
          className={styles.inputWrapper}
          inputClassName={styles.input}
          suffix={suffix}
          decimalsLimit={decimalsLimit}
          allowNegativeValue
        />
      ) : (
        <span className={styles.input}>
          {inputValue}
          {suffix}
        </span>
      )}
      <Icon
        onClick={increaseValue}
        name={IconName.Plus}
        className={styles.icon}
        height={24}
        width={24}
      />
    </div>
  );
}

export default Rate;
