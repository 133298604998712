import { QueryKey, useMutation } from '@tanstack/react-query';
import isError from 'lodash/isError';

import { debounce, isResponseWithErrorMessage } from 'utils';

import PaymentsService from 'api/payments';
import {
  ExchangeCalculationResponseV5,
  ExchangeRateWidgetDto,
  ExchangerCalculateRequest,
  PaymentFormDtoV5,
  TargetField,
  UserDto,
} from 'dtos';
import { useExchangerStoreActions, UseExchangerStoreReturn } from '../store';
import { useCallback } from 'react';
import { calculatePaymentFormData } from '../utils/calculatePaymentFormData';

const INPUT_TIMEOUT: number = 300;

type UseExchangeMutationOptions = {
  providerId: UserDto['id'];
  userId: UserDto['id'];
  mutationKey: QueryKey;
  errorData: UseExchangerStoreReturn['errorData'];
  customRates?: ExchangeRateWidgetDto[];
  customOfferMode?: boolean;
  editOfferMode?: boolean;
  exchangeCalculationResponse: Pick<PaymentFormDtoV5, "exchangeRateWidgets" | "crossExchangeWidget"> | null
};

type UseExchangeMutationVariables = {
  exchangerFormData: Omit<PaymentFormDtoV5, 'targetField' | 'providerId' | 'customerId'> & {currentTab: string};
  currentTab?: string;
  targetField: TargetField;
  signal?: AbortSignal,
  nextInputTarget?: 'sell' | 'buy',
  customOfferMode?: boolean,
  editOfferMode?: boolean,
};

export const useExchangeMutation = (options: UseExchangeMutationOptions) => {
  const { mutationKey, errorData, providerId, userId, customRates, customOfferMode, editOfferMode, exchangeCalculationResponse } = options;

  const { setPaymentForm, setErrorData, setCrossExchange, setInputTargetSelect, setFavouriteCurrencyPayments } =
    useExchangerStoreActions({
      queryKey: mutationKey,
      providerId,
      userId,
    });

  const { mutate, isLoading } = useMutation<
    ExchangeCalculationResponseV5,
    unknown,
    UseExchangeMutationVariables
  >({
    // CNTFRONT-2
    // @ts-ignore
    mutationKey,
    mutationFn: async ({ exchangerFormData, targetField, currentTab, signal }) => {
      if (editOfferMode) {
        return;
      }

      if (customOfferMode) {
        return calculatePaymentFormData(
          exchangerFormData,
          userId,
          providerId,
          targetField,
          customRates ?? [],
          exchangeCalculationResponse
        );
      }

      const dto: ExchangerCalculateRequest = {
        providerId,
        customerId: userId,

        exchangerFormData: {
          buyExchangeUnitDto: exchangerFormData.buyExchangeUnit,
          sellExchangeUnitDto: exchangerFormData.sellExchangeUnit,
          buyExchangeUnitAmount: exchangerFormData.buyExchangeUnitAmount,
          sellExchangeUnitAmount: exchangerFormData.sellExchangeUnitAmount,
          targetField,
          currentTab: exchangerFormData.currentTab,
        },
      };
      return await PaymentsService.getPaymentsCalculateAmount(dto, signal);
    },
    onSuccess: (res: ExchangeCalculationResponseV5, { exchangerFormData, nextInputTarget, currentTab }) => {
      if (editOfferMode) {
        return;
      }

      setCrossExchange({
        exchangeRateWidgets: res.paymentForm.exchangeRateWidgets,
        crossExchangeWidget: res.paymentForm.crossExchangeWidget,
      });
      setPaymentForm({
        buyExchangeUnit: res.paymentForm.buyExchangeUnit ?? exchangerFormData.buyExchangeUnit,
        sellExchangeUnit: res.paymentForm.sellExchangeUnit ?? exchangerFormData.sellExchangeUnit,
        buyExchangeUnitAmount: res.paymentForm.buyExchangeUnitAmount ?? exchangerFormData.buyExchangeUnitAmount,
        sellExchangeUnitAmount: res.paymentForm.sellExchangeUnitAmount ?? exchangerFormData.sellExchangeUnitAmount,
      });
      if (errorData?.isErrorOnUpdate) {
        setErrorData({ isErrorOnUpdate: false, message: null });
      }
      if (nextInputTarget) {
        setInputTargetSelect(nextInputTarget);
      }
      // Тут не запоминаем на вкладке Goods из-за того, что выбираем постоянно машины
      // CNTFRONT-2
      // @ts-ignore
      setFavouriteCurrencyPayments(currentTab !== 'Currency' ? exchangerFormData.sellExchangeUnit : exchangerFormData.buyExchangeUnit);
    },
    onError: (e) => {
      if (!isError(e)) {
        return;
      }
      // AbortController error
      if (e.name === 'CanceledError') {
        return;
      }

      setErrorData({
        isErrorOnUpdate: true,
        message: isResponseWithErrorMessage(e)
          ? e.response.data.message
          : e.message,
        status: isResponseWithErrorMessage(e) ? e.response.status : null,
      });
    },
  });

  const getExchangeCalculation = useCallback(debounce(mutate, INPUT_TIMEOUT), [
    mutate,
    providerId,
    userId,
  ]);

  return {
    getExchangeCalculation,
    isCalculating: isLoading,
  };
};
