import React from 'react';
import { useTranslation } from 'react-i18next';

import FeeRanges from 'widgets/FeeRanges';

import { Layout } from 'components/Layout/Layout';

const FeeRatesPage = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Layout.TopBar>
        <Layout.Back />

        <Layout.Title>{t('page.feeRangesDefault.title')}</Layout.Title>
      </Layout.TopBar>

      <Layout.Content unsetBottomPadding>
        <FeeRanges defaults />
      </Layout.Content>
    </Layout>
  );
};

export default FeeRatesPage;
