import styles from './index.module.scss';

import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { Navigate } from 'react-router-dom';

import { BalanceDto } from 'dtos';
import { NOT_FOUND } from 'pages/paths';
import AssistantCard from './AssistantCard';
import { useGetBalanceAssistantsQuery } from 'services/assistants';
import SkeletonWithWrapper from 'shared/ui/SkeletonWithWrapper';
import BalanceCard from 'components/BalanceCard';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { debounce } from 'utils';
import Input from 'components/Input/Input';
import { useLazyGetBalanceContactsWithSearch } from './api/useLazyGetBalanceContactsWithSearchQuery';
import { InfiniteList } from 'components/InfiniteList';
import { getIndexOfLastListElement } from 'shared/lib/arrays';

function Assistants({ balanceId }: { balanceId: BalanceDto['id'] }): JSX.Element {
  const { t } = useTranslation();
  const { ref, inView } = useInView();

  const [searchInput, setSearchInput] = useState<string | undefined>(undefined);
  const [searchString, setSearchString] = useState<string | undefined>(undefined);

  const debounceSetSearchString = useCallback(
    debounce((searchString?: string) => setSearchString(searchString), 500),
    [setSearchString]
  );
  const changeSelected = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
    debounceSetSearchString(event.target.value);
  };

  const { data, fetchNextPage, isLoading, isFetchingNextPage } =
    useLazyGetBalanceContactsWithSearch(balanceId, searchString);
  const contacts = data?.pages.map((p) => p.content).flat() || [];

  const {
    data: balanceAssistants,
    isLoading: isLoadingBalanceAsistants,
    isError,
  } = useGetBalanceAssistantsQuery(balanceId);

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage]);

  const indexOfLastListElement = getIndexOfLastListElement(contacts);

  if (isLoadingBalanceAsistants) {
    return (
      <div className={styles.loading}>
        <SkeletonWithWrapper circle height={64} width={64} />
        <SkeletonWithWrapper height={64} width={200} />
      </div>
    );
  }

  if (isError) {
    return <Navigate to={NOT_FOUND} />;
  }

  if (!balanceAssistants) {
    return <></>;
  }

  return (
    <>
      <div className={styles.balance}>
        <BalanceCard balance={balanceAssistants.balanceInfo} />
      </div>
      <div className={styles.wrap}>
        <h2 className={styles.header}>{t('page.balanceAssistants.currentAssistants')}</h2>
        {balanceAssistants?.assistants.length === 0 && (
          <p>{t('page.balanceAssistants.currentAssistantsEmpty')}</p>
        )}
        {balanceAssistants?.assistants.length > 0 && (
          <>
            <ul className={styles.assistants}>
              {balanceAssistants?.assistants.map(({ user }) => (
                <li key={user.id}>
                  <AssistantCard assistant={user} balanceId={balanceId} isCurrent={true} />
                </li>
              ))}
            </ul>
          </>
        )}
      </div>

      <h2 className={styles.header}>{t('page.balanceAssistants.otherContacts')}</h2>
      <Input
        name="search"
        onChange={changeSelected}
        placeholder={t('page.balanceAssistants.search')}
        className={styles.search}
        value={searchInput}
      />
      <InfiniteList
        limit={20}
        loading={isLoading}
        loadingNextPage={isFetchingNextPage}
        loaderItem={<SkeletonWithWrapper height={64} />}
        emptyListPlaceholder={t('page.balanceAssistants.contactsNotFound')}
        listClassName={styles.assistants}
      >
        {contacts.map((contact, index) => (
          <li key={contact.id} ref={indexOfLastListElement === index ? ref : undefined}>
            <AssistantCard assistant={contact} balanceId={balanceId} isCurrent={false} />
          </li>
        ))}
      </InfiniteList>
    </>
  );
}

export default Assistants;
