import { useParams } from 'react-router-dom';

import { Layout } from 'components/Layout/Layout';
import BalanceHistory from 'components/BalanceHistory';

import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import BalancesSummary from 'components/BalancesSummary';

function FriendPage(): JSX.Element {
  const { t } = useTranslation();
  const { id } = useParams();
  const balanceId = Number(id);

  return (
    <Layout>
      <Layout.TopBar>
        <div className={styles.wrap}>
          <Layout.Back />
        </div>
        <Layout.Title>{t('page.balanceHistory.title')}</Layout.Title>
      </Layout.TopBar>
      <Layout.Content>
        <BalanceHistory balanceId={balanceId} />
      </Layout.Content>
    </Layout>
  );
}

export default FriendPage;
