import styles from './Slider.module.scss';

import {
  ReactNode,
  useState,
  MouseEventHandler,
  ComponentType,
  useCallback,
  useEffect,
  PropsWithChildren,
} from 'react';
import cn from 'classnames';
import { useScrollTo } from 'shared/hooks/useScrollTo';

export interface SliderProps<Slide> extends PropsWithChildren {
  slide?: Slide;
  slides: Slide[];
  firstSlide?: ReactNode;
  slideRenderer: ComponentType<Slide>;
  lastSlide?: ReactNode;
  selector?: string;
  sliderClassName?: string;
  slideClassName?: string;
  lastSlideClassName?: string;
  onSlideChange: (slide?: Slide) => void;
}

export const Slider = <Slide extends { id: number }>(
  props: SliderProps<Slide>
) => {
  const { slide, slides, slideRenderer, firstSlide, lastSlide, onSlideChange, children } =
    props;
  const [selected, setSelected] = useState<Slide | null>(slide ?? null);

  useEffect(() => {setSelected(slide ?? null)}, [slide]);

  const changeSelected = useCallback(
    (slide?: Slide): MouseEventHandler<HTMLLIElement> =>
      (e) => {
        e.preventDefault();
        setSelected(slide ?? null);
        onSlideChange(slide);
      },
    [onSlideChange]
  );

  const sliderRef = useScrollTo<HTMLUListElement>(`[data-id="${selected?.id}"]`);

  const SlideComponent = slideRenderer;

  return (
    <>
      {children}
      <ul ref={sliderRef} className={cn(styles.slider, props.sliderClassName)}>
        {firstSlide && <li className={props.sliderClassName}>{firstSlide}</li>}

        {slides.map((slide) => {
          return (
            <li
              key={slide.id}
              data-id={slide.id}
              data-selected={slide.id === selected?.id}
              onClick={changeSelected(slide)}
              className={cn(styles.slide, props.slideClassName)}
            >
              {/* @ts-ignore */}
              <SlideComponent {...slide} />
            </li>
          );
        })}

        {lastSlide && <li className={props.lastSlideClassName}>{lastSlide}</li>}
      </ul>
    </>
  );
};
