import styles from './CheckMark.module.scss';

import React from 'react';
import cn from 'classnames';

import Icon from 'components/Icon/Icon';
import { IconName } from 'components/Icon/IconList';

export type CheckMarkProps = {
  selected: boolean;
  disabled?: boolean;
};

function CheckMark({ selected, disabled }: CheckMarkProps): JSX.Element {
  return (
    <div
      className={cn(
        styles.wrap,
        selected && styles.selected,
        disabled && styles.disabled
      )}
    >
      {selected ? (
        <Icon
          name={IconName.Checkbox}
          width={10}
          height={10}
          viewBox="0 -2 15 15"
        />
      ) : null}
    </div>
  );
}

export default CheckMark;
