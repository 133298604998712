import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from "react-hot-toast";
import { useState } from 'react';
import { RootState } from 'shared/redux-store';
import { hide, ModalTypes } from 'slices/modal';

import Modal from 'components/Modal/Modal';
import ExchangeUnitInfo from 'modules/ExchangeInfo/components/ExchangeUnitInfo';
import { useExchangerStore } from '../ExchangerWidget';
import {
  CurrencyResponseDto,
  GenerateInvitationRequest,
  GoodsDto,
  GoodsResponseDto,
  TargetField,
  UserRelationType,
} from 'dtos';
import PaymentUserInfo from 'modules/ExchangeInfo/components/PaymentUserInfo';
import { Layout } from 'components/Layout/Layout';
import Button, { Variant } from 'components/Button/Button';
import { useGoodsInfo } from '../ExchangerWidget/hooks/useGetGoodsInfo';
import cn from 'classnames';
import InvitationService from 'api/invitation';

import styles from './GeneratePaymentInvitationModal.module.scss';

const LINK_COPIED_TIMEOUT: number = 1500;

function GeneratePaymentInvitationModal(): JSX.Element | null {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { visible } = useSelector(
    (state: RootState) => state.modal[ModalTypes.GeneratePaymentInvitation]
  );
  const { currentFormData, activeOption, tabs, inputTarget } = useExchangerStore();
  const [invitationBotLink, setInvitationBotLink] = useState<string>();
  const [linkCopied, setLinkCopied] = useState(false);

  const closeModal = (): void => {
    dispatch(hide({ id: ModalTypes.GeneratePaymentInvitation }));
    setInvitationBotLink(undefined);
    setLinkCopied(false);
  };

  let sellExchangeUnit: Partial<CurrencyResponseDto & GoodsResponseDto>;
  let buyExchangeUnit: Partial<CurrencyResponseDto & GoodsResponseDto>;
  const tab = tabs?.find((t) => t.name === activeOption);
  const { goodsResponseDto } = useGoodsInfo({
    good: currentFormData?.buyExchangeUnit as GoodsDto,
    cities: tab?.goods,
    subcategory: currentFormData?.subcategory,
  });
  if (activeOption !== 'Currency') {
    buyExchangeUnit = goodsResponseDto ?? (currentFormData?.buyExchangeUnit as Partial<CurrencyResponseDto & GoodsResponseDto>);
    const currency = tab?.currencies.find((currency) =>
      currency.code === currentFormData?.sellExchangeUnit.code
    );
    const paymentMethod = currency?.paymentMethods.find((pm) =>
      pm.paymentMethodType === currentFormData?.sellExchangeUnit.paymentMethod?.type
    );
    sellExchangeUnit = {
      code: currency?.code,
      paymentMethod: {
        type: paymentMethod?.paymentMethodType,
        localizedType: paymentMethod?.localizedPaymentMethodType,
        name: paymentMethod?.methods.find((method) =>
          method === currentFormData?.sellExchangeUnit.paymentMethod?.name
        ),
      }
    } as CurrencyResponseDto;
  } else {
    const sellCurrency = tab?.currencies.find((currency) =>
      currency.code === currentFormData?.sellExchangeUnit.code
    );
    const sellPaymentMethod = sellCurrency?.paymentMethods.find((pm) =>
      pm.paymentMethodType === currentFormData?.sellExchangeUnit.paymentMethod?.type
    );
    const buyCurrency = tab?.currencies.find((currency) =>
      currency.code === currentFormData?.buyExchangeUnit.code
    );
    const buyPaymentMethod = buyCurrency?.paymentMethods.find((pm) =>
      pm.paymentMethodType === currentFormData?.buyExchangeUnit.paymentMethod?.type
    );
    sellExchangeUnit = {
      code: sellCurrency?.code,
      paymentMethod: {
        type: sellPaymentMethod?.paymentMethodType,
        localizedType: sellPaymentMethod?.localizedPaymentMethodType,
        name: currentFormData?.sellExchangeUnit.paymentMethod?.name,
      }
    } as CurrencyResponseDto;
    buyExchangeUnit = {
      code: buyCurrency?.code,
      paymentMethod: {
        type: buyPaymentMethod?.paymentMethodType,
        localizedType: buyPaymentMethod?.localizedPaymentMethodType,
        name: currentFormData?.sellExchangeUnit.paymentMethod?.name,
      }
    } as CurrencyResponseDto;
  }

  const generateInvitationLink = async () => {
    const request = {
      userRelationType: UserRelationType.Customer,
      paymentInvitationForm: {
        sellCurrency: currentFormData?.sellExchangeUnit,
        sellCurrencyAmount: currentFormData?.sellExchangeUnitAmount,
        buyCurrency: currentFormData?.buyExchangeUnit,
        buyCurrencyAmount: currentFormData?.buyExchangeUnitAmount,
        targetField: inputTarget === 'buy' ? TargetField.BuyCurrency : TargetField.SellCurrency,
      },
    } as GenerateInvitationRequest;
    const { botLink } = await InvitationService.generateInvitation(request);
    return botLink;
  };

  const shareInvitation = () => {
    if (!invitationBotLink) {
      const invitationLink = generateInvitationLink();
      invitationLink
        .then((value) => {
          setInvitationBotLink(value);
          copyLink(value);
        })
        .catch(() => toast.error(t('status.smthWrong')));
    } else {
      copyLink(invitationBotLink);
    }
  };

  const copyLink = (link: string) => {
    setTimeout(() => {
      navigator.clipboard.writeText(link).then(() => {
        setLinkCopied(true);
        setTimeout(() => setLinkCopied(false), LINK_COPIED_TIMEOUT);
      });
    }, 0);
  };

  if (!visible || !currentFormData || !tabs) {
    return null;
  }

  return (
    <Modal visible={visible} onClose={closeModal} className={styles.modalContent} modalClassName={styles.modal}>
      <Layout.Content className={styles.content} unsetBottomMargin unsetTopPadding unsetLeftRightPadding>
        <Layout.TopBar className={styles.headerTitle}>
          {t('modal.createPaymentInvitation.confirmation')}
        </Layout.TopBar>
        <ExchangeUnitInfo
          exchangeUnit={buyExchangeUnit}
          amount={currentFormData.buyExchangeUnitAmount}
          isSelling={false}
          isCurrentUserProvider={true}
        />
        <PaymentUserInfo isInvitation={true} />
        <ExchangeUnitInfo
          exchangeUnit={sellExchangeUnit}
          amount={currentFormData.sellExchangeUnitAmount}
          isSelling={true}
          isCurrentUserProvider={true}
        />
        <div className={styles.buttons}>
          <Button className={styles.button} variant={Variant.Contained} onClick={shareInvitation} disabled={linkCopied}>
            {!linkCopied ? t('modal.createPaymentInvitation.shareInvite') : t('modal.createPaymentInvitation.copied')}
          </Button>
          <Button className={cn(styles.button, styles.closeButton)} variant={Variant.Contained} onClick={closeModal}>
            {!invitationBotLink ? t('modal.createPaymentInvitation.cancel') : t('modal.createPaymentInvitation.close')}
          </Button>
        </div>
      </Layout.Content>
    </Modal>
  );
}

export default GeneratePaymentInvitationModal;
