import styles from './PaymentsHistory.module.scss';

import React, { useEffect, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';

import { InfiniteList } from 'components/InfiniteList';
import Payment from 'components/Payment';

import type { UserDto } from 'dtos';

import { useGetInfinitePayments } from 'entities1/payments/model/services';

import { getIndexOfLastListElement } from 'shared/lib/arrays';

import { groupListByCreatedDate } from 'utils';
import {useTranslation} from "react-i18next";

export interface PaymentsHistoryProps {
  friendId?: UserDto['id'];
}

function PaymentsHistory({ friendId }: PaymentsHistoryProps): JSX.Element {
  const { ref, inView } = useInView();
  const { t } = useTranslation();

  const { data, fetchNextPage, isLoading, isFetchingNextPage } =
    useGetInfinitePayments({
      queryKey: ['payments', friendId ?? null, 'history'],
      participantId: friendId,
    });

  const groupedHistoryByCreatedDate = useMemo(() => {
    const listsOfPayments = data?.pages.map((p) => p.content) || [];
    const flatListOfPayments = listsOfPayments.flat();
    return groupListByCreatedDate(flatListOfPayments);
  }, [data?.pages]);

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage]);

  const indexOfLastElement = getIndexOfLastListElement(
    groupedHistoryByCreatedDate
  );

  return (
    <InfiniteList
      limit={20}
      loading={isLoading}
      loadingNextPage={isFetchingNextPage}
      listClassName={styles.history}
      emptyListPlaceholder={t('page.contactProfile.paymentsHistory.emptyListPlaceholder')}
    >
      {groupedHistoryByCreatedDate.map((p, idx) => {
        if (typeof p === 'string') {
          return <h3 key={p}>{p}</h3>;
        }
        return (
          <li key={p.id} ref={indexOfLastElement === idx ? ref : undefined}>
            <Payment payment={p} />
          </li>
        );
      })}
    </InfiniteList>
  );
}

export default PaymentsHistory;
