import { useMemo } from 'react';

import {
  hasAnyPermission,
  hasGuestPermission,
  hasMaintainPermission,
  hasModifyRatesPermission,
  hasProvidePermission,
} from 'entities1/user/model/roles';

import { useCurrentUser } from './useCurrentUser';

type UseCurrentUserPermissionsReturn = {
  user: ReturnType<typeof useCurrentUser>;

  isProvider: boolean;
  isGuest: boolean;
  isAnyPermission: boolean;

  canMaintain: boolean;
  canModifyRates: boolean;
};

export const useCurrentUserPermissions =
  (): UseCurrentUserPermissionsReturn => {
    const user = useCurrentUser();

    return useMemo(
      () => ({
        user,

        isProvider: hasProvidePermission(user),
        isGuest: hasGuestPermission(user),
        isAnyPermission: hasAnyPermission(user),

        canMaintain: hasMaintainPermission(user),
        canModifyRates: hasModifyRatesPermission(user),
      }),
      [user]
    );
  };
