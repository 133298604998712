import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { queryClient } from 'shared/config/reactQuery';

import { HorizontalProvidersList } from 'modules/HorizontalProvidersList';
import { useProvidersStore } from 'shared/store/providers';

import { Layout } from 'components/Layout/Layout';
import { ExchangerWidget, exchangerQueryKey } from 'widgets/ExchangerWidget';
import {
  INITIAL_LIMIT,
  INITIAL_PAGE,
  useLazyGetContactsQuery,
} from 'services/contacts';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import ChoosePaymentFriends from 'widgets/ChoosePaymentFriends';

const HomePage = () => {
  const { t } = useTranslation();
  const [getContacts] = useLazyGetContactsQuery();

  const { primaryProvider } = useProvidersStore();
  const { id: currentUserId } = useCurrentUser();
  const providerId: number | null = primaryProvider?.id ?? null;

  const removeExchangerStore = useCallback(() => {
    queryClient.removeQueries({
      queryKey: exchangerQueryKey(providerId),
      exact: true,
    });
  }, [providerId]);

  useEffect(() => {
    // Prefetching data for Network page
    queryClient.prefetchInfiniteQuery({
      queryKey: ['friends'],
      queryFn: async ({ pageParam = INITIAL_PAGE }) => {
        const res = await getContacts({
          page: pageParam,
          limit: INITIAL_LIMIT,
        }).unwrap();
        return { pageParam, ...res };
      },
    });
  }, []);

  return (
    <Layout>
      <Layout.TopBar>
        <Layout.Title>{t('page.exchange.title')}</Layout.Title>
      </Layout.TopBar>

      <Layout.Content>
        <HorizontalProvidersList onChangeProvider={removeExchangerStore} />

        <ExchangerWidget
          providerId={providerId}
          isMyself={currentUserId === providerId}
        />
      </Layout.Content>
      <ChoosePaymentFriends />
    </Layout>
  );
};

export default HomePage;
