import './styles/index.scss';

import React from 'react';
import { Toaster } from 'react-hot-toast';

import { withProviders } from './providers';

import { Routing } from 'pages';

import { withSwRegistration } from 'containers/withSwRegistration';

import './translation';

function App(): JSX.Element {
  return (
    <>
      <Routing />

      <Toaster
        toastOptions={{
          style: {
            background: '#333',
            color: '#fff',
          },
        }}
      />
    </>
  );
}

export default withProviders(withSwRegistration(App));
