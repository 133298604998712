import styles from '../Input.module.scss';

import React from 'react';
import cx from 'classnames';

import CurrencyInputComponent, {
  CurrencyInputProps as _CurrencyInputProps,
} from 'react-currency-input-field';

import { InputProps, InputType, InputVariant } from '../InputTypes';

export type CurrencyInputProps = _CurrencyInputProps & InputProps;

function CurrencyInput(
  {
    className,
    labelClassName,
    inputClassName,
    label,
    error,
    variant = InputVariant.OUTLINED,
    type = InputType.TEXT,
    min = 0,
    ...inputProps
  }: CurrencyInputProps,
  ref: React.Ref<HTMLInputElement>
) {
  return (
    <div className={cx(styles.field, { [styles.error]: error }, className)}>
      {label && <div className={cx(labelClassName, styles.label)}>{label}</div>}

      <CurrencyInputComponent
        ref={ref}
        className={cx(styles.input, styles[variant], inputClassName, {
          [styles.disabled]: inputProps.disabled,
        })}
        min={min}
        type={type}
        onBlur={inputProps.onBlur}
        disableAbbreviations
        groupSeparator=" "
        decimalSeparator='.'
        transformRawValue={rawValue => rawValue.replaceAll(',', '.')}
        {...inputProps}
      />
    </div>
  );
}

export default React.forwardRef(CurrencyInput);
