import { ReactNode, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';

export const RouterProvider = ({ children }: { children: ReactNode }) =>
  typeof window !== 'undefined' ? (
    <BrowserRouter>
      <Suspense fallback={<div />}>{children}</Suspense>
    </BrowserRouter>
  ) : (
    <>{children}</>
  );
