import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Layout } from 'components/Layout/Layout';
import {
  useChangePostponedToReadyForWorkStatusMutation,
  useCompleteTaskMutation,
  useDeleteTaskMutation,
  useGetTaskByIdQuery,
  usePrioritizeTasksMutation,
  useStillInProgressAnswerMutation,
  useTakeOnTaskMutation,
} from 'services/tasks';
import Button, { Variant } from 'components/Button/Button';
import ArchiveInboxIcon from 'components/icons/ArchiveInbox';
import AIPrioritizedIcon from 'components/icons/AIPrioritized';
import ArchiveOutboxIcon from 'components/icons/ArchiveOutbox';
import DeleteIcon from 'components/icons/Delete';
import TaskInfo from '../components/TaskInfo';
import { TASK_MAIN } from 'pages/paths';
import SkeletonWithWrapper from 'shared/ui/SkeletonWithWrapper';
import { ModalTypes, open } from 'slices/modal';
import TaskFormulatingModal from 'widgets/TaskFormulatingModal';
import { TaskStatus } from 'dtos';
import cn from 'classnames';
import styles from './index.module.scss';
import { FOCUS_TASK, PRIORITIZE } from 'const';
import PostponeTaskModal from 'widgets/PostponeTaskModal';
import HistoryList from 'components/icons/HistoryList';
import Calendar from 'components/icons/Calendar';
import TaskInProgressAnsweringModal from 'widgets/TaskInProgressAnsweringModal';
import ArrowRightBold from 'components/icons/ArrowRightBold';
import DelegateTaskModal from 'widgets/DelegateTaskModal';

const TaskPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const taskId = Number(id);
  const [isNeedPrioritize, setPrioritize] = useState(false);

  const [completeTask, { isLoading: isCompleting }] = useCompleteTaskMutation();
  const [prioritizeTasks, { isLoading: isPrioritizing }] = usePrioritizeTasksMutation();
  const [deleteTask, { isLoading: isDeleting }] = useDeleteTaskMutation();
  const [changePostponedToReadyForWorkStatus, { isLoading: isUnblocking }] =
    useChangePostponedToReadyForWorkStatusMutation();
  const [stillInProgressAnswer, { isLoading: isLoadingStillInProgress }] = useStillInProgressAnswerMutation();
  const [takeOnTask, { isLoading: isTakingOn}] = useTakeOnTaskMutation();

  const { data: task, isLoading } = useGetTaskByIdQuery(taskId);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const button = queryParams.get('button');
  const referrer = queryParams.get('referrer');
  const [isStillInProgressButtonVisible, setIsStillInProgressButtonVisible] = useState(false);

  useEffect(() => {
    if (task?.status === TaskStatus.InProgress && button === '1') {
      setIsStillInProgressButtonVisible(true);
    }
  }, [task]);

  if (isLoading) {
    return (
      <Layout.Content>
        <SkeletonWithWrapper count={3} height={110} />
      </Layout.Content>
    );
  }

  if (task === undefined) {
    navigate(TASK_MAIN);
    return null;
  }

  if (task.status === TaskStatus.InProgress) {
    localStorage.setItem(FOCUS_TASK, String(task.id));
  }

  const onAddNewTaskButtonClick = () => {
    dispatch(
      open({
        id: ModalTypes.TaskFormulating,
        data: {},
      })
    );
  };

  const onSetTaskButtonClick = async () => {
    dispatch(
      open({
        id: ModalTypes.TaskFormulating,
        data: { task, isNeedInitialAssessment: true },
      })
    );
  };

  const onCloseButtonClick = () => {
    if (isNeedPrioritize) {
      localStorage.setItem(PRIORITIZE, 'true');
    }
    navigate(referrer ?? TASK_MAIN);
  };

  const onCompleteButtonClick = async () => {
    try {
      await completeTask(taskId);
      await toast.promise(prioritizeTasks(), {
        loading: t('status.aiPrioritizing'),
        success: () => <b>{t('status.success')}</b>,
        error: () => <b>{t('status.smthWrong')}</b>,
      });
      navigate(TASK_MAIN);
    } catch (e) {
      toast.error(t('status.smthWrong'));
    }
  };

  const onDeleteButtonClick = async () => {
    navigate(TASK_MAIN);
    await toast.promise(deleteTask(taskId), {
      loading: t('status.deleting'),
      success: () => {
        return <b>{t('status.deleted')}</b>;
      },
      error: () => {
        navigate(`${TASK_MAIN}/${taskId}`)
        return <b>{t('status.smthWrong')}</b>;
      },
    });
  };

  const onPostponeButtonClick = async () => {
    dispatch(
      open({
        id: ModalTypes.PostponeTaskModal,
        data: { task: task },
      })
    );
  };

  const onUnblockButtonClick = async () => {
    await toast.promise(changePostponedToReadyForWorkStatus(taskId), {
      loading: t('status.processing'),
      success: () => {
        return <b>{t('status.success')}</b>;
      },
      error: () => <b>{t('status.smthWrong')}</b>,
    });
  };

  const onTakeOnButtonClick = async () => {
    await toast.promise(takeOnTask(taskId), {
      loading: t('status.processing'),
      success: () => {
        return <b>{t('status.success')}</b>;
      },
      error: () => <b>{t('status.smthWrong')}</b>,
    });
  };

  const onDelegateButtonClick = async () => {
    if (task.status === TaskStatus.InProgress) {
      setPrioritize(true);
    }
    dispatch(
      open({
        id: ModalTypes.DelegateTask,
        data: { task: task },
      })
    );
  };

  let onTopButtonClick;
  let topButtonText;
  if (task.status === TaskStatus.InProgress && !task.isDelegated) {
    topButtonText = t('page.taskInfo.buttons.complete');
    onTopButtonClick = onCompleteButtonClick;
  } else {
    topButtonText = t('page.taskInfo.buttons.close');
    onTopButtonClick = onCloseButtonClick;
  }
  const isDeleteButtonVisible = [
    TaskStatus.ToBeFormulated,
    TaskStatus.ReadyForWork,
    TaskStatus.Postponed,
  ].includes(task.status) && !task.isDelegated && !task.isDelegatedToUser;
  const isPostponeButtonVisible = [TaskStatus.ReadyForWork, TaskStatus.InProgress].includes(
    task.status
  ) && !task.isDelegated;
  const isSetTaskButtonVisible = task.status === TaskStatus.ToBeFormulated && !task.isDelegated;
  const isUnblockButtonVisible = task.status === TaskStatus.Postponed && !task.isDelegated;
  const isAddNewTaskButtonVisible = task.status === TaskStatus.InProgress && !task.isDelegated;

  const onHistoryButtonClick = () => {
    navigate(`/task/${taskId}/history`)
  }

  const onStillInProgressButtonClick = async () => {
    const response = await stillInProgressAnswer({ taskId: taskId }).unwrap();
    setIsStillInProgressButtonVisible(false);
    dispatch(
      open({
        id: ModalTypes.TaskInProgressAnswering,
        data: { task: task, taskStillInProgressDto: response.taskStillInProgressDto },
      })
    );
  };

  const isFlowBlocked = isLoadingStillInProgress

  return (
    <Layout>
      <Layout.TopBar className={styles.header}>
        <div className={cn(styles.button, styles.historyButton)} onClick={onHistoryButtonClick}>
          <HistoryList className={styles.historyIcon} />
        </div>
        <Button
          variant={Variant.Minimal}
          className={cn(styles.button, styles.topButton)}
          onClick={onTopButtonClick}
          disabled={isCompleting || isPrioritizing || isFlowBlocked}
        >
          {topButtonText}
        </Button>
      </Layout.TopBar>
      <Layout.Content unsetTopPadding>
        <TaskInfo task={task} />

        <div className={styles.buttons}>
          {isStillInProgressButtonVisible && (
            <Button
              variant={Variant.Minimal}
              className={cn(styles.button, styles.bottomButton, styles.blue)}
              disabled={isFlowBlocked}
              isLoading={isLoadingStillInProgress}
              onClick={onStillInProgressButtonClick}
            >
              <div className={styles.buttonChild}>
                <Calendar className={styles.buttonIcon} />
                <span>{t('page.taskInfo.buttons.stillInProgress')}</span>
              </div>
            </Button>
          )}

          {isPostponeButtonVisible && (
            <Button
              variant={Variant.Minimal}
              className={cn(styles.button, styles.bottomButton)}
              onClick={onPostponeButtonClick}
              disabled={isFlowBlocked}
            >
              <div className={styles.buttonChild}>
                <ArchiveInboxIcon className={styles.buttonIcon} />
                <span>{t('page.taskInfo.buttons.postpone')}</span>
              </div>
            </Button>
          )}

          {isSetTaskButtonVisible && (
            <Button
              variant={Variant.Minimal}
              className={cn(styles.button, styles.bottomButton)}
              onClick={onSetTaskButtonClick}
              disabled={isFlowBlocked}
            >
              <div className={styles.buttonChild}>
                <AIPrioritizedIcon className={styles.buttonIcon} />
                <span>{t('page.taskInfo.buttons.setTask')}</span>
              </div>
            </Button>
          )}

          {isUnblockButtonVisible && (
            <Button
              variant={Variant.Minimal}
              className={cn(styles.button, styles.bottomButton)}
              disabled={isUnblocking || isFlowBlocked}
              onClick={onUnblockButtonClick}
            >
              <div className={styles.buttonChild}>
                <ArchiveOutboxIcon className={styles.buttonIcon} />
                <span>{t('page.taskInfo.buttons.unblock')}</span>
              </div>
            </Button>
          )}

          {task.canBeDelegated && (
            <Button
              variant={Variant.Minimal}
              className={cn(styles.button, styles.bottomButton)}
              onClick={onDelegateButtonClick}
            >
              <div className={styles.buttonChild}>
                <ArrowRightBold className={cn(styles.buttonIcon, styles.takeOnButtonIcon)} />
                <span>{t('page.taskInfo.buttons.delegate')}</span>
              </div>
            </Button>
          )}

          {isDeleteButtonVisible && (
            <Button
              variant={Variant.Minimal}
              className={cn(styles.button, styles.bottomButton, styles.deleteButton)}
              disabled={isDeleting || isFlowBlocked}
              onClick={onDeleteButtonClick}
            >
              <div className={styles.buttonChild}>
                <DeleteIcon className={styles.buttonIcon} />
                <span>{t('page.taskInfo.buttons.delete')}</span>
              </div>
            </Button>
          )}

          {isAddNewTaskButtonVisible && (
            <Button
              variant={Variant.Minimal}
              className={cn(styles.button)}
              onClick={onAddNewTaskButtonClick}
              disabled={isFlowBlocked}
            >
              {t('page.taskInfo.buttons.addNewTask')}
            </Button>
          )}

          {task.canTakeOn && (
            <Button
              variant={Variant.Minimal}
              className={cn(styles.button, styles.bottomButton)}
              onClick={onTakeOnButtonClick}
              disabled={isTakingOn}
            >
              <div className={styles.buttonChild}>
                <ArrowRightBold className={cn(styles.buttonIcon, styles.takeOnButtonIcon)} />
                <span>{t('page.taskInfo.buttons.takeOn')}</span>
              </div>
            </Button>
          )}
        </div>
        <TaskFormulatingModal />
        <PostponeTaskModal setPrioritize={setPrioritize} />
        <TaskInProgressAnsweringModal />
        <DelegateTaskModal />
      </Layout.Content>
    </Layout>
  );
};

export default TaskPage;
