import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserTracing, reactRouterV6Instrumentation, init } from '@sentry/react';
import {
  CaptureConsole as CaptureConsoleIntegration,
  HttpClient as HttpClientIntegration,
} from '@sentry/integrations';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import App from './app';
// import {
//   migrateExchangerPaymentFormInLS,
//   runLSMigration,
// } from 'widgets/ExchangerWidget/utils/exchangerPaymentFormMigration';


import releaseInfo from './release-info';

// Из-за Sentry не найти где пишет console.log
if (process.env.NODE_ENV !== 'development') {
  init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || 'develop',
    release: releaseInfo.version,
    integrations: [
      // new Sentry.Replay(),
      new BrowserTracing({
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        routingInstrumentation: reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new CaptureConsoleIntegration({
        // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
        // levels: ['log', 'info', 'warn', 'error', 'debug', 'assert'],
      }),
      new HttpClientIntegration({
        // This array can contain tuples of `[begin, end]` (both inclusive),
        // single status codes, or a combination of both.
        // default: [[500, 599]]
        failedRequestStatusCodes: [[400, 599]],
      }),
    ],
    tracesSampleRate: 1.0,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    // replaysSessionSampleRate: 1.0,
  });
}

// runLSMigration();
// migrateExchangerPaymentFormInLS();

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(<App />);

