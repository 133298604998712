import { createApi } from '@reduxjs/toolkit/query/react'
import { ExchangerFormData, Language, UserDto } from 'dtos'
import { baseQuery } from '../fetchBaseQueryWithAuth';

export const callbackApi = createApi({
  reducerPath: 'callbackApi',
  baseQuery,
  endpoints: (builder) => ({
    getWhatsAppMessage: builder.query<void, { providerId: UserDto['id'], language: Language, form: ExchangerFormData }>({
      query: ({ providerId, language, form }) => ({
        url: `/callback/whatsapp/${providerId}`,
        method: 'POST',
        params: { language },
        body: { ...form },
        responseHandler: 'text',
      }),
    }),

    callMeBack: builder.mutation<void, { providerId: number, name: string, phone: string, language: string, utm: string, exchangerFormData: ExchangerFormData }>({
      query: ({ providerId, name, phone, language, utm, exchangerFormData }) => ({
        url: `/callback/call-me-back/${providerId}`,
        method: 'POST',
        params: { name, phone, language, utmTag: utm },
        body: { ...exchangerFormData }
      }),
    }),

  }),
});

export const {
  useGetWhatsAppMessageQuery,
  useLazyGetWhatsAppMessageQuery,
  useCallMeBackMutation
} = callbackApi;
